
<template>
  <div class="pageHeader">
    <h1 v-if="editFlag">案件編集({{ createRecordId }})</h1>
    <h1 v-if="copyFlag">案件複製</h1>
    <h1 v-if="createFlag">案件作成</h1>
    <button class="normalButton" @click="submitForm" :disabled="!UploadFlag">保存</button>
    <button class="normalButton" @click="inputCancel">キャンセル</button>
  </div>
  <div class="content-block">
    <div id="formImput">
      <form ref="myForm">
        <div class="FormGroup">
          <!-- 案件名 -->
          <h2>案件</h2>
          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Title">案件名</label>
                <span class="required">必須</span>
                <span class="tooltip-content">案件名の入力フォームです。(必須項目)</span>
              </div>
              <div class="inputBlock" :class="{ 'hasError': !errors.Title.flag }">
                <input type="text" :class="{ 'hasError': !errors.Title.flag }" id="Title" v-model="formData.Title" required />
                <span class="hasError-content">{{ errors.Title.msg }}</span>
              </div>
            </div>
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Client">取引先</label>
                <span class="required">必須</span>
                <span class="tooltip-content">取引先の入力フォームです。(必須項目)</span>
              </div>
              <div class="inputBlock" :class="{ 'hasError': !errors.Client.flag }">
                <select v-model="formData.Client">
                  <option></option>
                  <option v-for="(option, index) in clientSelectOption" :key="index" :value="option.CustomerName.value">{{ option.CustomerName.value }}</option>
                </select>
                <span class="hasError-content">{{ errors.Client.msg }}</span>
              </div>
            </div>
          </div>

          

          
          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="startDate">案件開始日</label>
                <span class="required">必須</span>
                <span class="tooltip-content">案件開始日の入力フォームです。(必須項目)</span>
              </div>
              <div class="inputBlock" :class="{ 'hasError': !errors.startDate.flag }">
                <input type="date" id="startDate" :class="{ 'hasError': !errors.startDate.flag }" v-model="formData.startDate" required />
                <span class="hasError-content">{{ errors.startDate.msg }}</span>
              </div>
            </div>
          
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="endDate">案件完了日</label>
                <span class="required">必須</span>
                <span class="tooltip-content">案件完了日の入力フォームです。(必須項目)</span>
              </div>
              <div class="inputBlock" :class="{ 'hasError': !errors.endDate.flag }">
                <input type="date" id="endDate" :class="{ 'hasError': !errors.endDate.flag }" v-model="formData.endDate" required />
                <span class="hasError-content">{{ errors.endDate.msg }}</span>
              </div>
            </div>
          </div>

          

          <!-- 案件開始日 -->
          <!-- <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="startDate">案件開始日</label>
                <span class="required">必須</span>
                <span class="tooltip-content">案件開始日の入力フォームです。(必須項目)</span>
              </div>
              <div class="inputBlock" :class="{ 'hasError': !errors.startDate.flag }">
                <input type="text" id="startDate" :class="{ 'hasError': !errors.startDate.flag }" v-model="formData.startDate" @input="DateFormat(formData.startDate,'start')" required />
                <span class="hasError-content">{{ errors.startDate.msg }}</span>
              </div>
            </div> -->
          <!-- 案件完了日 -->
            <!-- <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="endDate">案件完了日</label>
                <span class="required">必須</span>
                <span class="tooltip-content">案件完了日の入力フォームです。(必須項目)</span>
              </div>
              <div class="inputBlock" :class="{ 'hasError': !errors.endDate.flag }">
                <input type="text" id="endDate" :class="{ 'hasError': !errors.endDate.flag }" v-model="formData.endDate" @input="DateFormat(formData.endDate,'end')" required />
                <span class="hasError-content">{{ errors.endDate.msg }}</span>
              </div>
            </div>
          </div> -->

          <!-- 案件詳細 -->
          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="Detail">案件詳細</label>
              <span class="tooltip-content">案件詳細の入力フォームです。</span>
            </div>
            <div class="inputBlock">
              <textarea id="Detail" v-model="formData.Detail"></textarea>
            </div>
          </div>
        </div>

        <div class="FormGroup">
        <h2>収支の見込み</h2>
        <!-- 複数の項目をまとめて追加できるフォーム領域 -->
          <div class="inputCol inputCol-4items">
            <div class="inputBlock">
              <b-table striped hover :fields="prospectFields" :items="formData.prospect" class="celltable">
                <template #cell(edit)="data">
                <div class="cellnum">{{data.index + 1}}</div>
                  <b-button v-if="data.index > 0" class="m-1" variant="success" :dataCol="data.index" @click="removePurchase(data.index)"><img src="../assets/image/gomibako.png"></b-button>
                  <b-button v-else variant="success" class="m-1" disabled :dataCol="data.index" ><img src="../assets/image/gomibako.png"></b-button>
                  <b-button variant="success"  class="m-1" :dataCol="data.index" @click="addPurchase(data.index)">＋</b-button>
                </template>

                <template #cell()="data">
<!--                  <label :for="data.field.key+data.index">{{data.field.label}}</label>-->
                  <template v-if="data.field">
                    <template v-if="data.field.type !='select'">
                      <input class="m-1" :id="data.field.key+data.index" :type="data.field.type" v-model="formData.prospect[data.index][data.field.key]" @input="updateValue(formData.prospect[data.index][data.field.key],data.index,data.field),ararimath(data.index)" >
                    </template>
                    <template v-else>
                        <b-form-select :id="data.field.key+data.index" v-model="formData.prospect[data.index][data.field.key]" class="m-1">
                          <template v-if="this[data.field.target].length > 0">
                          <b-form-select-option v-for="item in this[data.field.target]" :key="item" :value="item[data.field.data].value">
                            {{item[data.field.data].value}}
                          </b-form-select-option>
                          </template>
                        </b-form-select>
                    </template>
<!--                    <p v-for="item in this[data.field.target]" :key="item">{{data.field.data}}{{item}}</p>-->
                  </template>
                 </template>

              </b-table>
            </div>
          </div>


          <div class="total">
            <div class="normal">
              <div>売上<span>{{ formatNumber(totalSales) }}円</span></div>
              <div>原価<span>{{ formatNumber(totalCost) }}円</span></div>
              <div>粗利<span>{{ formatNumber(totalGrossProfit) }}円</span></div>
            </div>
            <div class="normal challenge">
              <div>売上（チャレンジ目標）<span>{{ formatNumber(totalChallengeSales) }}円</span></div>
              <div>原価（チャレンジ目標）<span>{{ formatNumber(totalChallengeCost) }}円</span></div>
              <div>粗利（チャレンジ目標）<span>{{ formatNumber(totalChallengeGrossProfit) }}円</span></div>
            </div>
          </div>

    </div>
    <div class="FormGroup">
      <h2>担当情報</h2>
        <!-- 会社 -->
        <div class="inputCol inputCol-2">
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="company">会社</label>
              <span class="required">必須</span>
              <span class="tooltip-content">会社の入力フォームです。(必須項目)</span>
            </div>
            <div class="inputBlock" :class="{ 'hasError': !errors.company.flag }">
              <select id="company" :class="{ 'hasError': !errors.company.flag }" v-model="formData.company">
                <option></option>
                <option v-for="(option, index) in companySelectOption" :key="index" :value="option.CompanyName.value">{{ option.CompanyName.value }}</option>
              </select>
              <span class="hasError-content">{{ errors.company.msg }}</span>
            </div>
          </div>
        <!-- 部署 -->
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="department">部署</label>
              <span class="required">必須</span>
              <span class="tooltip-content">部署の入力フォームです。(必須項目)</span>
            </div>
            <div class="inputBlock" :class="{ 'hasError': !errors.department.flag }">
              <select id="department" :class="{ 'hasError': !errors.department.flag }" v-model="formData.department">
                <option></option>
                <option v-for="(option, index) in departmentSelectOption" :key="index" :value="option.Department_name.value">{{ option.Department_name.value }}</option>
              </select>
              <span class="hasError-content">{{ errors.department.msg }}</span>
            </div>
          </div>
        </div>

        <!-- 承認者 -->
        <div class="inputCol inputCol-2">
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="authorizer">承認者</label>
              <span class="required">必須</span>
              <span class="tooltip-content">承認者の入力フォームです。(必須項目)</span>
            </div>
            <div class="inputBlock" :class="{ 'hasError': !errors.authorizer.flag }">
              <select id="authorizer" :class="{ 'hasError': !errors.authorizer.flag }" v-model="formData.authorizer">
                <option></option>
                <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
              </select>
              <span class="hasError-content">{{ errors.authorizer.msg }}</span>
            </div>
          </div>
        <!-- 担当者 -->
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="manager">担当者</label>
              <span class="required">必須</span>
              <span class="tooltip-content">担当者の入力フォームです。(必須項目)</span>
            </div>
            <div class="inputBlock" :class="{ 'hasError': !errors.manager.flag }">
              <select id="manager" :class="{ 'hasError': !errors.manager.flag }" v-model="formData.manager">
                <option></option>
                <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
              </select>
              <span class="hasError-content">{{ errors.manager.msg }}</span>
            </div>
          </div>
        </div>

        <!-- 作業者 -->
        <div class="inputCol inputCol-2">
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="worker">作業者</label>
              <span class="required">必須</span>
              <span class="tooltip-content">作業者の入力フォームです。(必須項目)</span>
            </div>
            <div class="inputBlock" :class="{ 'hasError': !errors.worker.flag }">
              <select id="worker" :class="{ 'hasError': !errors.worker.flag }" v-model="formData.worker">
                <option></option>
                <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
              </select>
              <span class="hasError-content">{{ errors.worker.msg }}</span>
            </div>
          </div>
        <!-- 作成者 -->
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="author">作成者</label>
              <span class="required">必須</span>
              <span class="tooltip-content">作成者の入力フォームです。(自動入力)</span>
            </div>
            <div class="inputBlock">
              <input type="text" id="author" :class="{ 'hasError': !errors.author.flag }" v-model="formData.author" disabled>
              <span class="hasError-content">{{ errors.author.msg }}</span>
            </div>
          </div>
        </div>

    </div>

        <!-- 社内管理情報 -->
        
        <div class="FormGroup">
        <h2>社内管理情報</h2>
        <!-- 社内メモ -->
          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="internalMemo">社内メモ</label>
            </div>
            <div class="inputBlock">
              <textarea id="internalMemo" v-model="formData.internalMemo"></textarea>
            </div>
          </div>

        <!-- 関連ファイル -->
        
          <div class="inputCol inputCol-1">
            <div class="nameBlock">
              <label for="relatedFiles">関連ファイル</label>
            </div>
            <div class="inputBlock">
              <template v-if="formData.relatedFiles.length > 0">
                <div v-for="file in formData.relatedFiles" :key="file.name">{{ file.name }}</div>
                <!-- <label>変更</label><input type="file" id="relatedFiles" @change="handleFileChange" multiple /> -->
              </template>
              <template v-else>
                <input type="file" id="relatedFiles" @change="handleFileChange" multiple />
              </template>
              <template v-if="!UploadFlag">
                <img src="../assets/image/ZZ5H.gif" style="width: 25px;height: 25px;margin-left: 12px;">
              </template>
            </div>
          </div>
        </div>

      </form>
    </div>
    <button class="normalButton" @click="submitForm" :disabled="!UploadFlag" style="float: right; width: 140px;">保存</button>
  </div>
<div v-if="!dataLoading" class="loading">
  <img src="../assets/image/ZZ5H.gif">
</div>
</template>



<script>

export default {
  components: {

  },
  data() {
    return {
      formData: {
        // DataBase
        Title: '',
        Client: '',
        startDate: '',
        endDate: '',
        Detail: '',
        prospect:[
          {
            itemName:'',
            sales: '0',
            cost: '0',
            grossProfit: '0',
            sales_challenge: '0',
            cost_challenge: '0',
            grossProfit_challenge: '0',
          }
        ],

        total__sales: '',
        total__cost: '',
        total__grossProfit: '',
        total__sales_challenge: '',
        total__grossProfit_challenge: '',

        company: '',
        department: '',
        authorizer: '',
        manager: '',
        worker: '',
        author: '',
        internalMemo: '',
        relatedFiles: [], // ファイルデータを格納する配列
        login_data: '',
      },
      prospectTemplate:{
        itemName:'',
        sales: '0',
        cost: '0',
        grossProfit:'0',
        sales_challenge: '0',
        cost_challenge: '0',
        grossProfit_challenge: '0',
      },
      prospectArray :1,
      prospectFields:[
        {
          key:'itemName',
          label:'項目名',
          type:'text'
        },
        {
          key:'sales',
          label:'売上',
          type:'text'
        },
        {
          key:'cost',
          label:'原価',
          type:'text'
        },
        {
          key:'grossProfit',
          label:'粗利',
          type:'text'
        },
        {
          key:'sales_challenge',
          label:'売上(チャレンジ目標)',
          type:'text'
        },
        {
          key:'cost_challenge',
          label:'原価(チャレンジ目標)',
          type:'text'
        },
        {
          key:'grossProfit_challenge',
          label:'粗利(チャレンジ目標)',
          type:'text'
        },
        {
          key:'edit',
          label:'操作',
          type:'edit'
        }
      ],

      errors: {
        Title: { msg:'', flag: true},
        startDate: { msg:'', flag: true},
        endDate: { msg:'', flag: true},
        Client: { msg:'', flag: true},

        company: { msg:'', flag: true},
        department: { msg:'', flag: true},
        authorizer: { msg:'', flag: true},
        manager: { msg:'', flag: true},
        worker: { msg:'', flag: true},
        author: { msg:'', flag: true},
      },

      clientSelectOption: [],
      companySelectOption: [],
      departmentSelectOption: [],
      EmployeeSelectOption: [],

      selectedFiles: [],
      formGroups: [['']],
      errorMessage: '',
      hasError: '',

      copyFlag: '',
      editFlag: '',
      createFlag: '',

      UploadFlag: true,

    };
  },
  computed: {
    totalSales(){
      return this.formData.prospect.reduce((total, group) => total + (parseFloat(this.valueReplace(group.sales)) || 0), 0);
    },
    totalCost(){
      return this.formData.prospect.reduce((total, group) => total + (parseFloat(this.valueReplace(group.cost)) || 0), 0);
    },
    totalGrossProfit(){
      return this.formData.prospect.reduce((total, group) => total + (parseFloat(this.valueReplace(group.grossProfit)) || 0), 0);
    },
    totalChallengeSales() {
      // 複数のグループ内の売上（チャレンジ目標）を合算
      return this.formData.prospect.reduce((total, group) => total + (parseFloat(this.valueReplace(group.sales_challenge)) || 0), 0);
    },
    totalChallengeCost() {
      // 複数のグループ内の原価（チャレンジ目標）を合算
      return this.formData.prospect.reduce((total, group) => total + (parseFloat(this.valueReplace(group.cost_challenge)) || 0), 0);
    },
    totalChallengeGrossProfit() {
      // 複数のグループ内の粗利（チャレンジ目標）を合算
      return this.formData.prospect.reduce((total, group) => total + (parseFloat(this.valueReplace(group.grossProfit_challenge)) || 0), 0);
    },
    login_data:{
      get: function () {
        return this.$store.state.login_data
      },
      set: function (value) {
        this.$store.commit('login_data', value)
      }
    },
  },
  created() {
    if(this.$commonPlugin.getUrlParameter('record_id')){
      this.createRecordId = this.$commonPlugin.getUrlParameter('record_id'); // URLからパラメータを取得
    }else{
      this.createRecordId = ""
    }
    console.log('-----------getTestData--------------')
    this.getData();
    console.log('-----------getTestData--------------')
    this.getClientData();
    this.getEmployeeData();
    this.getCompanyData();
    this.getDepartmentData();
    this.copyFlag = this.$commonPlugin.getUrlParameter('copy');
    this.editFlag = this.$commonPlugin.getUrlParameter('edit');

    localStorage.setItem('hasSaved', 'false');

    if (this.copyFlag) {
      this.createRecordId = ""
      this.editFlag = false
    }
    if (!this.copyFlag && !this.editFlag){
      this.createFlag = true
    }
  },
  watch: {
    'formData.startDate': function(newDate) {
      // 案件終了日が未設定の場合のみ、案件開始日と同じ値を設定
      if (!this.formData.endDate) {
        this.formData.endDate = newDate;
      }
    },
  },
  methods: {
    ararimath(index) {
      const cost = this.valueReplace(this.formData.prospect[index].cost);
      const sales = this.valueReplace(this.formData.prospect[index].sales);

      const raw = Math.floor(sales - cost);
      this.formData.prospect[index]["grossProfit"] = this.formatNumber(raw);
    },
    updateValue(value,index,field) {
      const formatvalue = value.replace(/,/g, '');
      let rawValue = formatvalue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      this.formData.prospect[index][field.key] = rawValue
    },
    valueReplace(value){
      return value.replace(/,/g, '');
    },
    DateFormat(value,flag){
      console.log(flag);
      if(flag == "start"){
        this.formData.startDate = value.split('/').join('-');
        console.log(this.formData.startDate);
      }
      if(flag == "end"){
        this.formData.endDate = value.split('/').join('-');
        console.log(this.formData.endDate);
      }
    },
    formatNumber(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    getClientData: async function (){
      let params = {
        target: 'Client',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.clientSelectOption = authed.records;
    },
    getData : async function(){
      let params = {
        target: 'ProjectDetails',
        querys: JSON.stringify(
            [
              {
                //対象カラム
                column :'DeleteStatus',
                //比較条件
                cond   :'in',
                //比較内容
                data   : '使用',
              },
              {
                //対象カラム
                column :'$id',
                //比較条件
                cond   :'=',
                //比較内容
                data   :this.createRecordId,
              }
            ]
        ),
        // join : JSON.stringify(),
      };
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      let updateData = {
        record : authed.records[0]
      }
      this.$commonPlugin.dataUpdate(this.formData,updateData,this.prospectTemplate);
      this.dataLoading = true
    },
    getCompanyData: async function (){
      let params = {
        target: 'Company',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.companySelectOption= authed.records;
    },
    getEmployeeData: async function (){
      let params = {
        target: 'Employee',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.EmployeeSelectOption = authed.records;
    },
    getDepartmentData: async function (){
      let params = {
        target: 'Department',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.departmentSelectOption = authed.records;
    },
    removeCommasFromValues(input) {
      if (typeof input === 'string') {
        return input.replace(/,/g, '');
      } else if (Array.isArray(input)) {
        return input.map(this.removeCommasFromValues);
      } else if (typeof input === 'object' && input !== null) {
        const newObj = {};
        for (let key in input) {
          if (Object.prototype.hasOwnProperty.call(input, key)) {
            newObj[key] = this.removeCommasFromValues(input[key]);
          }
        }
        return newObj;
      }
      return input;
    },
    cleanProspectData() {
      this.formData.prospect = this.removeCommasFromValues(this.formData.prospect);
      console.log(this.formData.prospect);
    },
    submitForm() {
      localStorage.setItem('hasSaved', 'true');
      if (this.$commonPlugin.validateForm(this.formData,this.errors)) {
        this.createFormMethod();
      }else{
        alert('入力内容にエラーが発生しました');
      }
    },
    createFormMethod: async function(){
      console.log(this.formData);
      this.formData.LastUser = this.login_data['Names'].value

      this.cleanProspectData(this.formData.prospect);

      let pushArray = [];
      for(let key in this.formData){
        let data = {};
        data.target = key;
        data.value = this.formData[key];
        pushArray.push(data);
      }
      console.log(pushArray)
      let params = {
        target: 'ProjectDetails',
        user:this.login_data['Names'].value,
        data:JSON.stringify(pushArray),
        querys: JSON.stringify(
            [
              {
                //対象カラム
                column :'DeleteStatus',
                //比較条件
                cond   :'in',
                //比較内容
                data   : '使用',
              },
              {
                //対象カラム
                column :'$id',
                //比較条件
                cond   :'=',
                //比較内容
                data   :this.createRecordId,
              }
            ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_create_update_data_for_array_logic',params);
      console.log(authed);
      this.$commonPlugin.dataUpdate(this.formData,authed,this.prospectTemplate);

      if(authed.result.response === "失敗"){
        const msg = authed.result.errors;
        let messages = [];
        for (let key in msg) {
          if (msg[key].messages) {
            msg[key].messages.forEach((message) => {
              if (message !== "必須です。") {
                messages.push(message);
              }
            });
          }
        }
        alert('入力内容にエラーが発生しました。\n' +'・'+ messages.join('\n・'));
      }else{
        alert('保存しました。');
        var link = "";
        if(this.editFlag){
          link = "/ProjectDetails/"+authed.result.record.$id.value
          this.setData('ProjectDetails',authed.result.record.$id.value)
        }else{
          link = "/ProjectDetails/"+authed.result.id
          this.setData('ProjectDetails',authed.result.id)
        }
        this.$router.push(link);
      }
    },
    setData(page,id){
      this.setStrage('loginTimes',new Date());
      this.setStrage('pages',page);
      this.setStrage('id',id);
    },
    setStrage(target,value){
      localStorage.setItem(target,value);
    },
    handleFileChange(event) {
      this.UploadFlag = false;
      const files = event.target.files;
      let selectedFiles = [];
      // 選択したファイルを配列に追加
      for (let i = 0; i < files.length; i++) {
        if(this.formData[event.target.id] == ''){
          this.formData[event.target.id] = [];
        }
        this.formData[event.target.id].push(files[i]);
        selectedFiles.push(files[i]);
      }
      // console.log(event.target.id)
      this.createFileOnData(event.target.id,selectedFiles);
    },
    createFileOnData: async function(target,selectedFiles){
      console.log(selectedFiles)
      let params = {
        target  : 'ProjectDetails',
        data    :JSON.stringify([
          {
            target : 'Worker',
            value  : this.login_data['Names'].value,
          }
        ]),
        filesTarget : 'File',
        files       : (selectedFiles)?selectedFiles : '',
      }
      // console.log(this.selectedFiles)
      let response = '';

      response = await this.$commonPlugin.axiosFileMultiAPI('file_upload_onData',params);

      console.log(response)

      this.formData[target] = [];
      response.result.forEach(item => {
        this.formData[target].push(...item.create_data);
      });
      console.log(this.formData);
      this.UploadFlag = true;
    },




    addPurchase(index){
      this.$commonPlugin.addArray(this.formData,index,'prospect',this.prospectTemplate);
    },
    removePurchase(index){
      this.$commonPlugin.removeArray(this.formData,index,'prospect');
    },
    inputCancel(){
     // if (window.confirm('入力された内容を破棄してもよろしいですか？')) {
        var link = "/Project";
        this.$router.push(link);
    //  }
    }
  },
  mounted() {
    this.formData.author = this.login_data.Names.value;
  }
};

</script>