<template>
  <header v-if="showHeader">
    <b-row>
      <b-col cols="3">
        <router-link to="/Top">
          <img style="max-width: 200px; padding: 12px;" src="../../assets/image/logo.gif" class="logoimage">
        </router-link>
      </b-col>
      <b-col cols="6"></b-col>
      <b-col cols="3" class="logout-block">
        <div class="mx-2" v-if="login_data">ようこそ <ruby>{{login_data.Names.value}}<rt>{{login_data.NamesKana.value}}</rt></ruby> 様</div>
      </b-col>
    </b-row>
  </header>
</template>

<script>
export default {
  name: "commonHeader",
  created() {
      this.checkLogin()
    },
  computed: {
    login_data:{
      get: function () {
        return this.$store.state.login_data
      },
      set: function (value) {
        this.$store.commit('login_data', value)
      }
    },
    showHeader() {
      // 現在のルートから showHeader フラグを取得
      return this.$route.meta.showHeader !== false;
    }
  },
  methods:{
    checkLogin(){
      if(!this.login_data){
        this.$router.push({ name: 'ログイン' });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variable/variableColors";
@import "src/assets/scss/variable/variableSetting";

header{
  background: $base_background;
}
</style>