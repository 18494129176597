
<template>
  <div class="pageHeader">
    <h1 v-if="editFlag">稟議編集({{ createRecordId }})</h1>
    <h1 v-if="copyFlag">稟議複製</h1>
    <h1 v-if="createFlag">稟議作成</h1>
    <button class="normalButton" @click="submitForm" :disabled="!UploadFlag">保存</button>
    <button class="normalButton" @click="inputCancel">キャンセル</button>
  </div>
  <div class="content-block">
    <div id="formImput">
      <form ref="myForm">

        <div class="FormGroup">
          <!-- 案件名 -->
          <h2>紐付け情報</h2>
          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Project">案件名</label>
                <span class="tooltip-content">案件名の入力フォームです。(任意項目)</span>
              </div>
              <div class="inputBlock" :class="{ 'hasError': hasAlert.flag }">
                <input type="text" id="Project" :class="{ 'hasError': hasAlert.flag }" v-model="formData.Project" @input="getApplicationData(formData.Project)" />
                <span class="hasError-content">{{ hasAlert.text }}</span>
                <span class="modallink" @click="openModal()">選択する</span>
              </div>
            </div>
            <div class="inputCol-2-block" style="display:none">
              <div class="nameBlock">
                <label for="ProjectId">案件ID</label>
                <span class="tooltip-content">案件IDの確認フォームです。(任意項目)</span>
              </div>
              <div class="inputBlock" >
                <input type="text" id="ProjectId" v-model="formData.ProjectId" readonly />
              </div>
            </div>
          </div>
        </div>

<!-- モーダルウィンドウ -->
<div v-if="selectModalFlag === true" class="modal" style="display: flex;">
          <div class="modal-content">
            <h2>案件選択</h2>
            <img src="../assets/image/close.png" class="close-right" @click="closeModal">
            <div class="searchBox">
              <div class="searchBox-content">
                   <div>
                    <label>案件名</label>
                    <input type="text" placeholder="案件名" v-model="searchName" style="width: 50%;"/>
                  </div>
                <div class="searchBox-content-block">
                  <div>
                    <label>取引先</label>
                      <select v-model="searchClient">
                        <option></option>
                        <option v-for="(option, index) in clientSelectOption" :key="index" :value="option.CustomerName.value">{{ option.CustomerName.value }}</option>
                      </select>
                  </div>
                  <div>
                    <label>作業者</label>
                      <select v-model="searchWorker">
                        <option></option>
                        <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                      </select>
                  </div>
                  <div>
                    <label>作成者</label>
                      <select v-model="searchAuthor">
                        <option></option>
                        <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                      </select>
                  </div>
                  <div>
                    <label>作成日</label>
                    <input type="date" v-model="searchStart"/> ~
                    <input type="date" v-model="searchEnd"/>
                  </div>
                  <div>
                    <label>承認ステータス</label>
                      <select v-model="searchApproval">
                        <option></option>
                        <option>未承認</option>
                        <option>申請中</option>
                        <option>承認済み</option>
                      </select>
                  </div>
                </div>
              </div>
            </div>
            <table class="authorize-table">
              <thead>
                <th></th>
                <th>案件コード</th>
                <th>案件名</th>
                <th>取引先</th>
                <th>作成日時</th>
                <th>作業者</th>
                <th>作成者</th>
                <th>承認</th>
              </thead>
              <tbody>
                <tr v-for="(item, index) in paginatedData" :key="index">
                  <td @click="inputFormSelect(item.Title.value)" v-if="item.ApprovalStatus.value === '承認済み'"><span class="pointer">選択</span></td>
                  <td v-else></td>
                  <td>{{ item.$id.value }}</td>
                  <td>{{ item.Title.value }}</td>
                  <td>{{ item.Client.value }}</td>
                  <td>{{ formatDateTime(item.作成日時.value) }}</td>
                  <td>{{ item.worker.value }}</td>
                  <td>{{ item.author.value }}</td>
                  <td>{{ item.ApprovalStatus.value }}</td>
                </tr>
              </tbody>
            </table>
            <div class="pagination">
              <span>{{ currentPage }} / {{ totalPages }}</span>
              <img class="arrowButton" src="../assets/image/Expand_Arrow.png" @click="prevPage" :disabled="currentPage === 1">
              <img class="arrowButton" src="../assets/image/Expand_Arrow2.png" @click="nextPage" :disabled="currentPage * itemsPerPage >= totalFilteredItems" >
            </div>
            <div class="modal-button-block">
              <button class="normalButton" @click="closeModal()">閉じる</button>
            </div>
          </div>
        </div>
<!-- モーダルウィンドウ -->

        <div class="FormGroup">
          <!-- 案件名 -->
          <h2>稟議情報</h2>
          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Title">件名</label>
                <span class="required">必須</span>
                <span class="tooltip-content">件名の入力フォームです。(必須項目)</span>
              </div>
              <div class="inputBlock" :class="{ 'hasError': !errors.Title.flag }">
                <input type="text" :class="{ 'hasError': !errors.Title.flag }" id="Title" v-model="formData.Title" required />
                <span class="hasError-content">{{ errors.Title.msg }}</span>
              </div>
            </div>
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Type">稟議種別</label>
                <span class="required">必須</span>
                <span class="tooltip-content">稟議種別の入力フォームです。(必須項目)</span>
              </div>
              <div class="inputBlock" :class="{ 'hasError': !errors.Type.flag }">
              <select id="Type" :class="{ 'hasError': !errors.Type.flag }" v-model="formData.Type">
                <option></option>
                <option v-for="(option, index) in ApprovalTypeOption" :key="index" :value="option.value">{{ option.value }}</option>
              </select>
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="first__resolution">一次決議</label>
              <span class="tooltip-content">一次決議の入力フォームです。</span>
            </div>
            <div class="inputBlock">
              <!--
                <div style="width: 220px;">
                  <label>決議判定</label>
                  <select id="first__resolution_judgment" v-model="formData.first__resolution_judgment">
                    <option></option>
                    <option v-for="(option, index) in ketsugiOption" :key="index" :value="option.value">{{ option.value }}</option>
                  </select>
                </div>
              -->
              <div>
                <label>一次決議者</label>
                <input type="text" v-model="formData.first__resolution_person">
                <span class="modallink" @click="openModal__first()">選択する</span>
              </div>
              <!--
                <div>
                  <label>一次決議日</label>
                  <input type="date" v-model="formData.first__resolution_date">
                </div>
              -->
            </div>
          </div>

<!-- モーダルウィンドウ -->
<div v-if="selectModalFlag__first === true" class="modal" style="display: flex;">
          <div class="modal-content">
            <h2>一次決議者選択</h2>
            <img src="../assets/image/close.png" class="close-right" @click="closeModal__first">
            <div class="searchBox">
              <div class="searchBox-content">
                  <div>
                    <label>氏名</label>
                    <input type="text" placeholder="氏名" v-model="searchName__first" style="width: 50%;"/>
                  </div>
                  <div>
                    <label>肩書</label>
                    <input type="text" placeholder="肩書" v-model="searchTitle__first" style="width: 50%;"/>
                  </div>
                  <div>
                    <label>雇用形態</label>
                    <input type="text" placeholder="雇用形態" v-model="searchEmploymentStatus__first" style="width: 50%;"/>
                  </div>
              </div>
            </div>
            <table class="authorize-table">
              <thead>
                <th></th>
                <th>名前</th>
                <th>肩書</th>
                <th>雇用形態</th>
              </thead>
              <tbody>
                <tr v-for="(item, index) in paginatedData__first" :key="index">
                  <td @click="inputFormSelect__first(item.Names.value)"><span class="pointer">選択</span></td>
                  <td>{{ item.Names.value }}</td>
                  <td>{{ item.Title.value }}</td>
                  <td>{{ item.EmploymentStatus.value }}</td>
                </tr>
              </tbody>
            </table>
            <div class="pagination">
              <span>{{ currentPage__first }} / {{ totalPages__first }}</span>
              <img class="arrowButton" src="../assets/image/Expand_Arrow.png" @click="prevPage__first" :disabled="currentPage__first === 1">
              <img class="arrowButton" src="../assets/image/Expand_Arrow2.png" @click="nextPage__first" :disabled="currentPage__first * itemsPerPage >= totalFilteredItems__first" >
            </div>
            <div class="modal-button-block">
              <button class="normalButton" @click="closeModal__first()">閉じる</button>
            </div>
          </div>
        </div>
<!-- モーダルウィンドウ -->

          <!--
          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="first__resolution">最終決議</label>
              <span class="tooltip-content">最終決議の入力フォームです。</span>
            </div>
            <div class="inputBlock">
              <div style="width: 220px;">
                <label>決議判定</label>
                <select id="last__resolution_judgment" v-model="formData.last__resolution_judgment">
                  <option></option>
                  <option v-for="(option, index) in ketsugiOption" :key="index" :value="option.value">{{ option.value }}</option>
                </select>
              </div>
              <div>
                <label>最終決議者</label>
                <input type="text" v-model="formData.last__resolution_person">
              </div>
              <div>
                <label>最終決議日</label>
                <input type="date" v-model="formData.last__resolution_date">
              </div>
            </div>
          </div>
          -->
          <!--
          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="first__resolution_comment">決済者コメント</label>
              <span class="tooltip-content">決済者コメントの入力フォームです。</span>
            </div>
            <div class="inputBlock">
              <textarea id="first__resolution_comment" v-model="formData.first__resolution_comment"></textarea>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="last__resolution_comment">最終決済者コメント</label>
              <span class="tooltip-content">最終決済者コメントの入力フォームです。</span>
            </div>
            <div class="inputBlock">
              <textarea id="last__resolution_comment" v-model="formData.last__resolution_comment"></textarea>
            </div>
          </div>
          -->

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Date">提出日</label>
                <span class="tooltip-content">提出日の入力フォームです。</span>
              </div>
              <div class="inputBlock">
                <input type="Date" id="Date" v-model="formData.Date" />
              </div>
            </div>
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Implementation">実施日</label>
                <span class="tooltip-content">実施日の入力フォームです。</span>
              </div>
              <div class="inputBlock">
                <input type="date" id="Implementation" v-model="formData.Implementation" required />
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Department_code">部門コード</label>
                <span class="tooltip-content">部門コードの入力フォームです。</span>
              </div>
              <div class="inputBlock">
                <input style="min-width: 40px; width: 100px;" type="text" id="Department_code" v-model="formData.Department_code" @input="getApplicationData__Department(formData.Department_code)"/>
                <span class="modallink" @click="openModal__Code()">選択する</span>
              </div>
            </div>
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Department">部門名</label>
                <span class="tooltip-content">部門名の入力フォームです。</span>
              </div>
              <div class="inputBlock">
                <input type="text" id="Department" v-model="formData.Department" required />
              </div>
            </div>
          </div>

<!-- モーダルウィンドウ -->
<div v-if="selectModalFlag__Code === true" class="modal" style="display: flex;">
          <div class="modal-content">
            <h2>部門選択</h2>
            <img src="../assets/image/close.png" class="close-right" @click="closeModal__Code">
            <div class="searchBox">
              <div class="searchBox-content">
                <div>
                  <label>部門名</label>
                  <input type="text" placeholder="部門名" v-model="searchDepartment_name" style="width: 50%;"/>
                </div>
              </div>
            </div>
            <table class="authorize-table">
              <thead>
                <th></th>
                <th>部門コード</th>
                <th>部門名</th>
              </thead>
              <tbody>
                <tr v-for="(item, index) in paginatedData__Code" :key="index">
                  <td @click="inputFormSelect__Code(item.Department_cd.value)"><span class="pointer">選択</span></td>
                  <td>{{ item.Department_cd.value }}</td>
                  <td>{{ item.Department_name.value }}</td>
                </tr>
              </tbody>
            </table>
            <div class="pagination">
              <span>{{ currentPage__Code }} / {{ totalPages__Code }}</span>
              <img class="arrowButton" src="../assets/image/Expand_Arrow.png" @click="prevPage__Code" :disabled="currentPage__Code === 1">
              <img class="arrowButton" src="../assets/image/Expand_Arrow2.png" @click="nextPage__Code" :disabled="currentPage__Code * itemsPerPage >= totalFilteredItems__Code" >
            </div>
            <div class="modal-button-block">
              <button class="normalButton" @click="closeModal__Code()">閉じる</button>
            </div>
          </div>
        </div>
<!-- モーダルウィンドウ -->

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Submitter">提出者</label>
                <span class="tooltip-content">提出者の入力フォームです。</span>
              </div>
              <div class="inputBlock">
                <input type="text" id="Submitter" v-model="formData.Submitter" required />
                <span class="modallink" @click="openModal__Submitter()">選択する</span>
              </div>
            </div>
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Document">添付書類</label>
                <span class="tooltip-content">添付書類の入力フォームです。</span>
              </div>
              <div class="inputBlock">
                <template v-if="formData.Document.length > 0 ">
                  <div v-for="file in formData.Document" :key="file.name">{{ file.name }}</div>
<!--                  <label>変更</label><input type="file" id="Document" @change="handleFileChange" multiple />-->
                </template>
                <template v-else>
                  <input type="file" id="Document" @change="handleFileChange" multiple />
                </template>
                <template v-if="!UploadFlag">
                  <img src="../assets/image/ZZ5H.gif" style="width: 25px;height: 25px;margin-left: 12px;">
                </template>
<!--                <input type="file" id="Document" required />-->
              </div>
            </div>
          </div>

<!-- モーダルウィンドウ -->
<div v-if="selectModalFlag__Submitter === true" class="modal" style="display: flex;">
          <div class="modal-content">
            <h2>提出者選択</h2>
            <img src="../assets/image/close.png" class="close-right" @click="closeModal__Submitter">
            <div class="searchBox">
              <div class="searchBox-content">
                  <div>
                    <label>氏名</label>
                    <input type="text" placeholder="氏名" v-model="searchName__Submitter" style="width: 50%;"/>
                  </div>
                  <div>
                    <label>肩書</label>
                    <input type="text" placeholder="肩書" v-model="searchTitle__Submitter" style="width: 50%;"/>
                  </div>
                  <div>
                    <label>雇用形態</label>
                    <input type="text" placeholder="雇用形態" v-model="searchEmploymentStatus__Submitter" style="width: 50%;"/>
                  </div>
              </div>
            </div>
            <table class="authorize-table">
              <thead>
                <th></th>
                <th>名前</th>
                <th>肩書</th>
                <th>雇用形態</th>
              </thead>
              <tbody>
                <tr v-for="(item, index) in paginatedData__Submitter" :key="index">
                  <td @click="inputFormSelect__Submitter(item.Names.value)"><span class="pointer">選択</span></td>
                  <td>{{ item.Names.value }}</td>
                  <td>{{ item.Title.value }}</td>
                  <td>{{ item.EmploymentStatus.value }}</td>
                </tr>
              </tbody>
            </table>
            <div class="pagination">
              <span>{{ currentPage__Submitter }} / {{ totalPages__Submitter }}</span>
              <img class="arrowButton" src="../assets/image/Expand_Arrow.png" @click="prevPage__Submitter" :disabled="currentPage__Submitter === 1">
              <img class="arrowButton" src="../assets/image/Expand_Arrow2.png" @click="nextPage__Submitter" :disabled="currentPage__Submitter * itemsPerPage >= totalFilteredItems__Submitter" >
            </div>
            <div class="modal-button-block">
              <button class="normalButton" @click="closeModal__Submitter()">閉じる</button>
            </div>
          </div>
        </div>
<!-- モーダルウィンドウ -->


          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Budget">当初企画予算の有無</label>
                <span class="tooltip-content">当初企画予算の有無の入力フォームです。</span>
              </div>
              <div class="inputBlock">
                <div class="radio">
                  <div class="radio-block">
                    <label>なし</label>
                    <input type="radio" name="Budget" v-model="formData.Budget" value="なし">
                  </div>
                  <div class="radio-block">
                    <label>あり</label>
                    <input type="radio" name="Budget" v-model="formData.Budget" value="あり">
                  </div>
                </div>
              </div>
            </div>
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Amount">当初予算額</label>
                <span class="tooltip-content">当初予算額の入力フォームです。</span>
              </div>
              <div class="inputBlock">
                <input type="text" id="Amount" v-model="formData.Amount" required />
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="Purpose">目的・概要</label>
              <span class="tooltip-content">目的・概要の入力フォームです。</span>
            </div>
            <div class="inputBlock">
              <textarea id="Purpose" v-model="formData.Purpose"></textarea>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="Opinion">支払情報</label>
              <span class="tooltip-content">支払情報の入力フォームです。</span>
            </div>

            <div class="inputBlock">
              <b-table striped hover :fields="PurchaseAmountFields" :items="formData.PurchaseAmount" class="celltable">
                <template #cell(edit)="data">
                <div class="cellnum">{{data.index + 1}}</div>
                  <b-button v-if="data.index > 0" class="m-1" variant="success" :dataCol="data.index" @click="removePurchase(data.index)"><img src="../assets/image/gomibako.png"></b-button>
                  <b-button v-else variant="success" class="m-1" disabled :dataCol="data.index" ><img src="../assets/image/gomibako.png"></b-button>
                  <b-button variant="success"  class="m-1" :dataCol="data.index" @click="addPurchase(data.index)">＋</b-button>
                </template>

                <template #cell()="data">
<!--                  <label :for="data.field.key+data.index">{{data.field.label}}</label>-->
                  <template v-if="data.field">
                    <template v-if="data.field.type !='select'">
                      <input class="m-1" :id="data.field.key+data.index" :type="data.field.type" v-model="formData.PurchaseAmount[data.index][data.field.key]">
                    </template>
                    <template v-else>
                        <b-form-select :id="data.field.key+data.index" v-model="formData.PurchaseAmount[data.index][data.field.key]" class="m-1">
                          <template v-if="this[data.field.target].length > 0">
                          <b-form-select-option v-for="item in this[data.field.target]" :key="item" :value="item[data.field.data].value">
                            {{item[data.field.data].value}}
                          </b-form-select-option>
                          </template>
                        </b-form-select>
                    </template>
<!--                    <p v-for="item in this[data.field.target]" :key="item">{{data.field.data}}{{item}}</p>-->
                  </template>
                 </template>

              </b-table>
            </div>



          </div>
            <div class="total">
              <div class="normal">

                <div>発生費用<span>{{ formatNumber(totalSales) }}円</span></div>

              </div>
            </div>
          <div class="inputCol inputCol-textarea" style="border-top: 1px solid #ededed;">
            <div class="nameBlock">
              <label for="Opinion">決済者および関係部門意見</label>
              <span class="tooltip-content">決済者および関係部門意見の入力フォームです。</span>
            </div>
            <div class="inputBlock">
              <textarea id="Opinion" v-model="formData.Opinion"></textarea>
            </div>
          </div>

        </div>

        <div class="FormGroup">
      <h2>担当情報</h2>
        <!-- 会社 -->
        <div class="inputCol inputCol-2">
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="company">会社</label>
              <span class="required">必須</span>
              <span class="tooltip-content">会社の入力フォームです。(必須項目)</span>
            </div>
            <div class="inputBlock" :class="{ 'hasError': !errors.company.flag }">
              <select id="company" :class="{ 'hasError': !errors.company.flag }" v-model="formData.company">
                <option></option>
                <option v-for="(option, index) in companySelectOption" :key="index" :value="option.CompanyName.value">{{ option.CompanyName.value }}</option>
              </select>
              <span class="hasError-content">{{ errors.company.msg }}</span>
            </div>
          </div>
        <!-- 部署 -->
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="department">部署</label>
              <span class="required">必須</span>
              <span class="tooltip-content">部署の入力フォームです。(必須項目)</span>
            </div>
            <div class="inputBlock" :class="{ 'hasError': !errors.department.flag }">
              <select id="department" :class="{ 'hasError': !errors.department.flag }" v-model="formData.department">
                <option></option>
                <option v-for="(option, index) in departmentSelectOption" :key="index" :value="option.Department_name.value">{{ option.Department_name.value }}</option>
              </select>
              <span class="hasError-content">{{ errors.department.msg }}</span>
            </div>
          </div>
        </div>

        <!-- 承認者 -->
        <div class="inputCol inputCol-2">
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="authorizer">承認者</label>
              <span class="required">必須</span>
              <span class="tooltip-content">承認者の入力フォームです。(必須項目)</span>
            </div>
            <div class="inputBlock" :class="{ 'hasError': !errors.authorizer.flag }">
              <select id="authorizer" :class="{ 'hasError': !errors.authorizer.flag }" v-model="formData.authorizer">
                <option></option>
                <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
              </select>
              <span class="hasError-content">{{ errors.authorizer.msg }}</span>
            </div>
          </div>
        <!-- 担当者 -->
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="manager">担当者</label>
              <span class="required">必須</span>
              <span class="tooltip-content">担当者の入力フォームです。(必須項目)</span>
            </div>
            <div class="inputBlock" :class="{ 'hasError': !errors.manager.flag }">
              <select id="manager" :class="{ 'hasError': !errors.manager.flag }" v-model="formData.manager">
                <option></option>
                <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
              </select>
              <span class="hasError-content">{{ errors.manager.msg }}</span>
            </div>
          </div>
        </div>

        <!-- 作業者 -->
        <div class="inputCol inputCol-2">
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="worker">作業者</label>
              <span class="required">必須</span>
              <span class="tooltip-content">作業者の入力フォームです。(必須項目)</span>
            </div>
            <div class="inputBlock" :class="{ 'hasError': !errors.worker.flag }">
              <select id="worker" :class="{ 'hasError': !errors.worker.flag }" v-model="formData.worker">
                <option></option>
                <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
              </select>
              <span class="hasError-content">{{ errors.worker.msg }}</span>
            </div>
          </div>
        <!-- 作成者 -->
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="author">作成者</label>
              <span class="tooltip-content">作成者の入力フォームです。(自動入力)</span>
            </div>
            <div class="inputBlock">
              <input type="text" id="author" :class="{ 'hasError': !errors.author.flag }" v-model="formData.author" disabled>
              <span class="hasError-content">{{ errors.author.msg }}</span>
            </div>
          </div>
        </div>

    </div>

        <!-- 社内管理情報 -->
        
        <div class="FormGroup">
        <h2>社内管理情報</h2>
        <!-- 社内メモ -->
          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="internalMemo">社内メモ</label>
            </div>
            <div class="inputBlock">
              <textarea id="internalMemo" v-model="formData.internalMemo"></textarea>
            </div>
          </div>

        <!-- 関連ファイル -->
        
          <div class="inputCol inputCol-1">
            <div class="nameBlock">
              <label for="relatedFiles">関連ファイル</label>
            </div>
            <div class="inputBlock">
              <template v-if="formData.relatedFiles.length > 0">
                <div v-for="file in formData.relatedFiles" :key="file.name">{{ file.name }}</div>
                <!-- <label>変更</label><input type="file" id="Document" @change="handleFileChange" multiple /> -->
              </template>
              <template v-else>
                <input type="file" id="relatedFiles" @change="handleFileChange" multiple />
              </template>
              <template v-if="!UploadFlag">
                <img src="../assets/image/ZZ5H.gif" style="width: 25px;height: 25px;margin-left: 12px;">
              </template>
            </div>
          </div>
        </div>

      </form>
    </div>
    <button class="normalButton" @click="submitForm" :disabled="!UploadFlag" style="float: right; width: 140px;">保存</button>
  </div>
<div v-if="!dataLoading" class="loading">
  <img src="../assets/image/ZZ5H.gif">
</div>
</template>



<script>

export default {
  data() {
    return {
      hasAlert : {
        flag : false,
        text : ''
      },
      hasAlert__Department : {
        flag : false,
        text : ''
      },
      formData: {
        //DataBase
        Project   : '',
        ProjectId : '',

        Approval: '',
        first__resolution_judgment: '',
        first__resolution_person: '',
        first__resolution_date: '',
        last__resolution_judgment: '',
        last__resolution_person: '',
        last__resolution_date: '',
        first__resolution_comment: '',
        last__resolution_comment: '',
        Date: '',
        Implementation: '',
        Costs: '',
        Department_code: '',
        Department: '',
        Submitter: '',
        Document: [],
        Title: '',
        Type: '',
        Budget: 'なし',
        Amount: '',
        LastUser: '',
        Purpose: '',

        dataLoading : false,

        PurchaseAmount:[
          {
            money   :'',
            Retailer: '',
            Payment : '',
            Payment_Date: '',
            Account : '',
          }
        ],

        Opinion: '',

        company: '',
        department: '',

        authorizer: '',
        manager: '',
        worker: '',
        author: '',

        total: '',


        internalMemo: '',
        relatedFiles: [], // ファイルデータを格納する配列
      },
      PurchaseAmountTemplate:{
        money   :'',
        Retailer: '',
        Payment: '',
        Payment_Date: '',
        Account: '',
      },
      PurchaseAmountArray :1,
      PurchaseAmountFields:[
        {
          key:'money',
          label:'購入金額',
          type:'number'
        },
        {
          key:'Retailer',
          label:'購入先',
          type:'text'
        },
        {
          key:'Payment',
          label:'支払方法',
          type:'text'
        },
        {
          key:'Payment_Date',
          label:'支払日',
          type:'date'
        },
        {
          key:'Account',
          label:'勘定科目',
          type:'select',
          data:'Account',
          target:'AccountData'
        },
        {
          key:'edit',
          label:'操作',
          type:'edit'
        }
      ],
      //ココで必須チェック項目の定義
      errors: {
        Title: { msg:'', flag: true},
        Type: { msg:'', flag: true},
        company: { msg:'', flag: true},
        department: { msg:'', flag: true},
        authorizer: { msg:'', flag: true},
        manager: { msg:'', flag: true},
        worker: { msg:'', flag: true},
        author: { msg:'', flag: true},
        // internalMemo: { msg:'', flag: true},
      },


      companySelectOption: [],

      departmentSelectOption: [],

      authorizerSelectOption: [],

      managerSelectOption: [],

      workerSelectOption: [],

      authorSelectOption: [],

      clientSelectOption: [],

      ketsugiOption: [
        { value: '承認', label: '承認' },
        { value: '条件付き承認', label: '条件付き承認' },
        { value: '差戻し', label: '差戻し' },
        { value: '否決', label: '否決' },
      ],
      ApprovalTypeOption: [
        { value: '旅費交通稟議' },
        { value: '接待交際稟議' },
        { value: '会議費使用稟議' },
        { value: '物品購入稟議' },
        { value: '贈答関連稟議' },
        { value: '事業開設関連稟議' },
        { value: '異例処理' },
        { value: 'その他稟議' }
      ],

      AccountSelectOption: [
        { value: '社員福利厚生費', label: '社員福利厚生費' },
        { value: '広告宣伝費', label: '広告宣伝費' },
        { value: '募集費', label: '募集費' },
        { value: '地代家賃', label: '地代家賃' },
        { value: '共益費', label: '共益費' },
        { value: '通信費', label: '通信費' },
        { value: '旅費交通費', label: '旅費交通費' },
        { value: '支払手数料', label: '支払手数料' },
        { value: '車両関連費', label: '車両関連費' },
        { value: '租税公課', label: '租税公課' },
        { value: '消耗品費', label: '消耗品費' },
        { value: '減価償却費', label: '減価償却費' },
        { value: 'リース料', label: 'リース料' },
        { value: '水道光熱費', label: '水道光熱費' },
        { value: '会議費', label: '会議費' },
        { value: '荷造運賃', label: '荷造運賃' },
        { value: '接待交際費', label: '接待交際費' },
        { value: '修繕費', label: '修繕費' },
        { value: '新聞図書費', label: '新聞図書費' },
        { value: '諸会費', label: '諸会費' },
        { value: '賃借料', label: '賃借料' },
        { value: '保険料', label: '保険料' },
        { value: '雑費', label: '雑費' },
        { value: '外注工賃', label: '外注工賃' },
      ],

      selectedFiles: [],
      formGroups: [['']],
      errorMessage: '',
      hasError: '',
      createRecordId : '',
      copyFlag: '',
      dataLoading: false,

//モーダルウィンドウ
      selectModalFlag: false,
      currentPage: 1, // 現在のページ
      itemsPerPage: 10, // 1ページあたりのアイテム数
      tableData: [],
      searchName: "",
      searchAuthor: "",
      searchClient: "",
      searchWorker: "",
      searchApproval: "承認済み",
      searchStart: "",
      searchEnd: "",
//
//モーダルウィンドウ
      selectModalFlag__Code: false,
      currentPage__Code: 1, // 現在のページ
      itemsPerPage__Code: 10, // 1ページあたりのアイテム数
      tableData__Code: [],
      searchDepartment_name: "",
//
//モーダルウィンドウ
selectModalFlag__Submitter: false,
      currentPage__Submitter: 1, // 現在のページ
      itemsPerPage__Submitter: 10, // 1ページあたりのアイテム数
      tableData__Submitter: [],
      searchName__Submitter: "",
      searchTitle__Submitter: "",
      searchEmploymentStatus__Submitter: "",
//
//モーダルウィンドウ
selectModalFlag__first: false,
      currentPage__first: 1, // 現在のページ
      itemsPerPage__first: 10, // 1ページあたりのアイテム数
      tableData__first: [],
      searchName__first: "",
      searchTitle__first: "",
      searchEmploymentStatus__first: "",
//
      UploadFlag: true,
    };
  },
  created() {
    localStorage.setItem('hasSaved', 'false');
    this.dataLoading = false
    if(this.$commonPlugin.getUrlParameter('record_id')){
      this.createRecordId = this.$commonPlugin.getUrlParameter('record_id'); // URLからパラメータを取得
    }else{
      this.createRecordId = ""
    }
    if (this.createRecordId) {
      this.isEditFlag = true
    }
    const record_idParam = this.$commonPlugin.getUrlParameter('record_id');
    if(record_idParam){
      this.createRecordId = this.$commonPlugin.getUrlParameter('record_id'); // URLからパラメータを取得
    }
    const ProjectTitleParam = this.$commonPlugin.getUrlParameter('project_title');
    if(ProjectTitleParam){
      this.formData.Project = this.$commonPlugin.getUrlParameter('project_title');
      this.getApplicationData(this.formData.Project);
    }
    const ProjectIdParam = this.$commonPlugin.getUrlParameter('project_id');
    if(ProjectIdParam){
      this.formData.ProjectId = this.$commonPlugin.getUrlParameter('project_id');
    }

    console.log('-----------getData--------------')
    this.getData();
    this.getAccountData();
    this.getEmployeeData();
    this.getCompanyData();
    this.getDepartmentData();
    this.getClientData();
    this.getTableData();
    this.getTableData__Code();
    this.getTableData__Submitter();
    this.getTableData__first();
    this.copyFlag = this.$commonPlugin.getUrlParameter('copy');
    this.editFlag = this.$commonPlugin.getUrlParameter('edit');
    if (this.copyFlag) {
      this.createRecordId = ""
      this.editFlag = false
    }
    if (!this.copyFlag && !this.editFlag){
      this.createFlag = true
    }
  },
  computed: {
//モーダルウィンドウ
filteredTableData() {
      console.log(this.tableData)
        let filteredData = this.tableData.filter((item) => {

        const startDate = new Date(this.searchStart);
        const endDate = new Date(this.searchEnd);
        
        const itemDate = new Date(item.作成日時.value);

          return (
            (this.searchName === "" || item.Title.value.includes(this.searchName)) &&
            (this.searchClient === "" || item.Client.value === this.searchClient) &&
            (this.searchWorker === "" || item.worker.value === this.searchWorker) && 
            (this.searchAuthor === "" || item.author.value === this.searchAuthor) &&
            (this.searchApproval === "" || item.ApprovalStatus.value === this.searchApproval) &&
            (isNaN(startDate) || itemDate >= startDate) && // 開始日が未指定または作成日時が開始日以降
            (isNaN(endDate) || itemDate <= endDate) // 終了日が未指定または作成日時が終了日以前
          );
        });
        return filteredData;
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filteredTableData.slice(startIndex, endIndex);
    },
    totalPages() {
      const totalPagesNum = Math.ceil(this.filteredTableData.length / this.itemsPerPage);
      return totalPagesNum;
    },
//
//モーダルウィンドウ
filteredTableData__Code() {
      console.log(this.tableData__Code)
        let filteredData__Code = this.tableData__Code.filter((item) => {

          return (
            (this.searchDepartment_name === "" || item.Department_name.value.includes(this.searchDepartment_name))
          );
        });
        return filteredData__Code;
    },
    paginatedData__Code() {
      const startIndex = (this.currentPage__Code - 1) * this.itemsPerPage__Code;
      const endIndex = startIndex + this.itemsPerPage__Code;
      return this.filteredTableData__Code.slice(startIndex, endIndex);
    },
    totalPages__Code() {
      const totalPagesNum = Math.ceil(this.filteredTableData__Code.length / this.itemsPerPage__Code);
      return totalPagesNum;
    },
//
//モーダルウィンドウ
filteredTableData__Submitter() {
      console.log(this.tableData__Submitter)
        let filteredData = this.tableData__Submitter.filter((item) => {
          return (
            (this.searchName__Submitter === "" || item.Name.value.includes(this.searchName__Submitter)) &&
            (this.searchTitle__Submitter === "" || item.Title.value === this.searchTitle__Submitter) &&
            (this.searchEmploymentStatus__Submitter === "" || item.EmploymentStatus.value === this.searchEmploymentStatus__Submitter)
          );
        });
        return filteredData;
    },
    paginatedData__Submitter() {
      const startIndex = (this.currentPage__Submitter - 1) * this.itemsPerPage__Submitter;
      const endIndex = startIndex + this.itemsPerPage__Submitter;
      return this.filteredTableData__Submitter.slice(startIndex, endIndex);
    },
    totalPages__Submitter() {
      const totalPagesNum = Math.ceil(this.filteredTableData__Submitter.length / this.itemsPerPage__Submitter);
      return totalPagesNum;
    },
//
//モーダルウィンドウ
filteredTableData__first() {
      console.log(this.tableData__first)
      let filteredData__first = this.tableData__first.filter((item) => {
          return (
            (this.searchName__first === "" || item.Name.value.includes(this.searchName__first)) &&
            (this.searchTitle__first === "" || item.Title.value === this.searchTitle__first) &&
            (this.searchEmploymentStatus__first === "" || item.EmploymentStatus.value === this.searchEmploymentStatus__first)
          );
        });
        return filteredData__first;
    },
    paginatedData__first() {
      const startIndex = (this.currentPage__first - 1) * this.itemsPerPage__first;
      const endIndex = startIndex + this.itemsPerPage__first;
      return this.filteredTableData__first.slice(startIndex, endIndex);
    },
    totalPages__first() {
      const totalPagesNum = Math.ceil(this.filteredTableData__first.length / this.itemsPerPage__first);
      return totalPagesNum;
    },
//
    login_data:{
      get: function () {
        return this.$store.state.login_data
      },
      set: function (value) {
        this.$store.commit('login_data', value)
      }
    },
    AccountData:{
      get: function () {
        return this.$store.state.AccountData
      },
      set: function (value) {
        this.$store.commit('AccountData', value)
      }
    },
    totalSales(){
      return this.formData.PurchaseAmount.reduce((total, group) => total + (parseFloat(group.money) || 0), 0);
    },
  },
  methods: {
    formatNumber(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    getClientData: async function (){
      let params = {
        target: 'Client',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.clientSelectOption = authed.records;
    },
//モーダルウィンドウ
    inputFormSelect(value){
      this.formData.Project = value
      this.getApplicationData(value)
      this.closeModal()
    },
    openModal() {
      this.selectModalFlag = true; // モーダルを表示
    },
    closeModal() {
      this.selectModalFlag = false; // モーダルを非表示
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    getTableData: async function (){
      let params = {
        target: 'ProjectDetails',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      this.tableData = authed.records;
      console.log(authed)
    },
    formatDateTime(dateTimeString) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', timeZoneName: 'short', weekday: 'short' };
      const dateTime = new Date(dateTimeString);
      const stringDate = dateTime.toLocaleString('ja-JP', options);
      if (stringDate === "Invalid Date") {
        return "";
      } else {
        return stringDate.replace("JST", '').replace(',', '');
      }
    },
//
//モーダルウィンドウ
inputFormSelect__first(value){
      this.formData.first__resolution_person = value
      this.closeModal__first()
    },
    openModal__first() {
      this.selectModalFlag__first = true; // モーダルを表示
    },
    closeModal__first() {
      this.selectModalFlag__first = false; // モーダルを非表示
    },
    prevPage__first() {
      if (this.currentPage__first > 1) {
        this.currentPage__first--;
      }
    },
    nextPage__first() {
      if (this.currentPage__first < this.totalPages__first) {
        this.currentPage__first++;
      }
    },
    getTableData__first: async function (){
      let params = {
        target: 'Employee',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      this.tableData__first = authed.records;
      console.log(authed)
    },
//
//モーダルウィンドウ
inputFormSelect__Code(value){
      this.formData.Department_code = value
      this.getApplicationData__Department(value)
      this.closeModal__Code()
    },
    openModal__Code() {
      this.selectModalFlag__Code = true; // モーダルを表示
    },
    closeModal__Code() {
      this.selectModalFlag__Code = false; // モーダルを非表示
    },
    prevPage__Code() {
      if (this.currentPage__Code > 1) {
        this.currentPage__Code--;
      }
    },
    nextPage__Code() {
      if (this.currentPage__Code < this.totalPages__Code) {
        this.currentPage__Code++;
      }
    },
    getTableData__Code: async function (){
      let params = {
        target: 'Department',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      this.tableData__Code = authed.records;
      console.log(authed)
    },
//
//モーダルウィンドウ
    inputFormSelect__Submitter(value){
      this.formData.Submitter = value
      this.closeModal__Submitter()
    },
    openModal__Submitter() {
      this.selectModalFlag__Submitter = true; // モーダルを表示
    },
    closeModal__Submitter() {
      this.selectModalFlag__Submitter = false; // モーダルを非表示
    },
    prevPage__Submitter() {
      if (this.currentPage__Submitter > 1) {
        this.currentPage__Submitter--;
      }
    },
    nextPage__Submitter() {
      if (this.currentPage__Submitter < this.totalPages__Submitter) {
        this.currentPage__Submitter++;
      }
    },
    getTableData__Submitter: async function (){
      let params = {
        target: 'Employee',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      this.tableData__Submitter = authed.records;
      console.log(authed)
    },
//
    getAccountData : async function(){
      let params = {
        target: 'AccountItem',
        querys: JSON.stringify(
            [
              {
                //対象カラム
                column :'DeleteStatus',
                //比較条件
                cond   :'in',
                //比較内容
                data   :'使用',
              }
            ]
        ),
        // join : JSON.stringify(),
      };
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      this.AccountData = authed.records;
      console.log(this.AccountData)
    },
    getCompanyData: async function (){
      let params = {
        target: 'Company',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.companySelectOption= authed.records;
    },
    getEmployeeData: async function (){
      let params = {
        target: 'Employee',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.EmployeeSelectOption = authed.records;
    },
    getDepartmentData: async function (){
      let params = {
        target: 'Department',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.departmentSelectOption = authed.records;
    },
    getApplicationData : async function(ls_target){
      const targetColumn = {
        //formData = database
        ProjectId : 'record_id',
        company   : 'company',
        department: 'department',
        authorizer: 'authorizer',
        manager   : 'manager',
        worker    : 'worker',
      }
      let params = {
        target: 'ProjectDetails',
        querys: JSON.stringify(
            [
              {
                //対象カラム
                column :'DeleteStatus',
                //比較条件
                cond   :'in',
                //比較内容
                data   :'使用',
              },
              {
                //対象カラム
                column :'Title',
                //比較条件
                cond   :'in',
                //比較内容
                data   : ls_target,
              }
            ]
        ),
        // join : JSON.stringify(),
      };
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      let database = authed.records;
      if(!database.length){
        this.hasAlert.flag = true
        this.hasAlert.text = 'データが見つかりません'

        for (let key in targetColumn) {
          this.formData[key] = '';
        }

        return false;
      }else{
        this.hasAlert.flag = false
        this.hasAlert.text = ''
      }
      // フォーム復元
      // 復元したいカラム = 復元するデータ
      for(let i = 0; i < database.length;i++){
        for (let key in targetColumn) {
          if(database[i][targetColumn[key]]){
            this.formData[key] = database[i][targetColumn[key]].value;
          }
        }
      }
    },
    getData : async function(){
      let params = {
        target: 'Application',
        querys: JSON.stringify(
            [
              {
                //対象カラム
                column :'DeleteStatus',
                //比較条件
                cond   :'in',
                //比較内容
                data   : '使用',
              },
              {
                //対象カラム
                column :'$id',
                //比較条件
                cond   :'=',
                //比較内容
                data   :this.createRecordId,
              }
            ]
        ),
        // join : JSON.stringify(),
      };
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      let updateData = {
        record : authed.records[0]
      }
      this.$commonPlugin.dataUpdate(this.formData,updateData,this.PurchaseAmountTemplate);
      this.dataLoading = true
    },
    getApplicationData__Department : async function(ls_target){
      const targetColumn = {
        //formData = database
        Department   : 'Department_name',
      }
      let params = {
        target: 'Department',
        querys: JSON.stringify(
            [
              {
                //対象カラム
                column :'DeleteStatus',
                //比較条件
                cond   :'in',
                //比較内容
                data   :'使用',
              },
              {
                //対象カラム
                column :'Department_cd',
                //比較条件
                cond   :'in',
                //比較内容
                data   : ls_target,
              }
            ]
        ),
        // join : JSON.stringify(),
      };
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      let database = authed.records;
      if(!database.length){
        this.hasAlert__Department.flag = true
        this.hasAlert__Department.text = 'データが見つかりません'

        for (let key in targetColumn) {
          this.formData[key] = '';
        }

        return false;
      }else{
        this.hasAlert__Department.flag = false
        this.hasAlert__Department.text = ''
      }
      // フォーム復元
      // 復元したいカラム = 復元するデータ
      for(let i = 0; i < database.length;i++){
        for (let key in targetColumn) {
          if(database[i][targetColumn[key]]){
            this.formData[key] = database[i][targetColumn[key]].value;
          }
        }
      }
    },
    addPurchase(index){
      this.$commonPlugin.addArray(this.formData,index,'PurchaseAmount',this.PurchaseAmountTemplate);
    },
    removePurchase(index){
      this.$commonPlugin.removeArray(this.formData,index,'PurchaseAmount');
    },
    submitForm() {
      localStorage.setItem('hasSaved', 'true');
      if (this.$commonPlugin.validateForm(this.formData,this.errors)) {
        this.createFormMethod();
      }else{
        alert('入力内容にエラーが発生しました。');
      }
    },
    createFormMethod: async function(){
      this.formData.total = this.totalSales
      console.log(this.formData);
      this.formData.LastUser = this.login_data["Names"].value
      let pushArray = [];
      for(let key in this.formData){
        let data = {};
        data.target = key;
        data.value = this.formData[key];
        pushArray.push(data);
      }
      let params = {
        target: 'Application',
        data:JSON.stringify(pushArray),
        user: this.login_data['Names'].value,
        querys: JSON.stringify(
            [
              {
                //対象カラム
                column :'DeleteStatus',
                //比較条件
                cond   :'in',
                //比較内容
                data   : '使用',
              },
              {
                //対象カラム
                column :'$id',
                //比較条件
                cond   :'=',
                //比較内容
                data   :this.createRecordId,
              }
            ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_create_update_data_for_array_logic',params);
      this.$commonPlugin.dataUpdate(this.formData,authed,this.PurchaseAmountTemplate);
      if(authed.result.response === "失敗"){
        const msg = authed.result.errors;
        let messages = [];
        for (let key in msg) {
          if (msg[key].messages) {
            msg[key].messages.forEach((message) => {
              if (message !== "必須です。") {
                messages.push(message);
              }
            });
          }
        }
        alert('入力内容にエラーが発生しました。\n' +'・'+ messages.join('\n・'));
      }else{
        alert('保存しました。');
        var link = "";
        if(this.editFlag){
          link = "/ApprovalDetails/"+authed.result.record.$id.value
          this.setData('ApprovalDetails',authed.result.record.$id.value)
        }else{
          link = "/ApprovalDetails/"+authed.result.id
          this.setData('ApprovalDetails',authed.result.id)
        }
        this.$router.push(link);
      }
    },
    setData(page,id){
      this.setStrage('loginTimes',new Date());
      this.setStrage('pages',page);
      this.setStrage('id',id);
    },
    setStrage(target,value){
      localStorage.setItem(target,value);
    },
    inputCancel(){
    //  if (window.confirm('入力された内容を破棄してもよろしいですか？')) {
        var link = "/Approval";
        this.$router.push(link);
    //  }
    },
    handleFileChange(event) {
      this.UploadFlag = false;
      const files = event.target.files;
      let selectedFiles = [];
      // 選択したファイルを配列に追加
      for (let i = 0; i < files.length; i++) {
        if(this.formData[event.target.id] == ''){
          this.formData[event.target.id] = [];
        }
        this.formData[event.target.id].push(files[i]);
        selectedFiles.push(files[i]);
      }
      // console.log(event.target.id)
      this.createFileOnData(event.target.id,selectedFiles);
    },
    createFileOnData: async function(target,selectedFiles){
      let params = {
        target  : 'Application',
        data    :JSON.stringify([
          {
            target : 'Worker',
            value  : this.login_data['Names'].value,
          }
        ]),
        filesTarget : 'File',
        files       : (selectedFiles)?selectedFiles : '',
      }
      // console.log(this.selectedFiles)
      let response = '';

      response = await this.$commonPlugin.axiosFileMultiAPI('file_upload_onData',params);

      console.log(response)
      this.formData[target] = [];
      response.result.forEach(item => {
        this.formData[target].push(...item.create_data);
      });
      console.log(this.formData);
      this.UploadFlag = true;
    },
  },
  mounted() {
    this.formData.author = this.login_data.Names.value;
    this.formData.Submitter = this.login_data.Names.value;
    this.searchAuthor = this.login_data.Names.value;
    this.searchWorker = this.login_data.Names.value;
  }
};

</script>