<template>
  <div class="pagetopButton" v-if="dataLoading">
    <div class="custom-select">
      <label>回答:</label>
      <div class="selected-option" @click="toggleDropdown__answer">
        {{ displayData[0].$join_data.Contract.records[0].AnswerStatus.value }}<i class="fas fa-caret-down"></i>
      </div>
        <div class="options" :class="{ 'open': isDropdownOpen__answer }">
          <div>
            <input class="optionsInput" type="radio" name="AnswerStatus" v-model="statusData.AnswerStatus" value="対応中">
            <label>対応中</label>
          </div>
          <div>
            <input class="optionsInput" type="radio" name="AnswerStatus" v-model="statusData.AnswerStatus" value="締結済み">
            <label>締結済み</label>
          </div>
          <div>
            <input class="optionsInput" type="radio" name="AnswerStatus" v-model="statusData.AnswerStatus" value="破棄">
            <label>破棄</label>
          </div>
          <button @click="saveOption__answer" class="normalButton" :disabled="!ApprovalStatus">変更</button>
        </div>
    </div>
    <div class="custom-select">
      <label>送付:</label>
      <div class="selected-option"  @click="toggleDropdown__sending">
        {{ displayData[0].$join_data.Contract.records[0].SendingStatus.value }}<i class="fas fa-caret-down"></i>
      </div>
        <div class="options" :class="{ 'open': isDropdownOpen__sending }">
          <div>
            <input class="optionsInput" type="radio" name="SendingStatus" v-model="SendingStatusData.SendingStatus" value="未送付">
            <label>未送付</label>
          </div>
          <div>
            <input class="optionsInput" type="radio" name="SendingStatus" v-model="SendingStatusData.SendingStatus" value="送付済み">
            <label>送付済み</label>
          </div>
          <button @click="saveOption__sending" class="normalButton" :disabled="!ApprovalStatus">変更</button>
        </div>
    </div>
    <div class="custom-select">
      <label>承認:</label>
      <button class="selected-option" @click="openModal" style="display:block; text-align:center;" :class="{'shinseityu': displayData[0].$join_data.Contract.records[0].ApprovalStatus.value === '申請中'}">
      {{ displayData[0].$join_data.Contract.records[0].ApprovalStatus.value }}
      </button>
      <div class="modal" v-if="isModalOpen" style="display:flex;">
        <div class="modal-content">
          <h2>承認</h2>
          <img src="../assets/image/close.png" class="close-right" @click="closeModal">
          <table class="authorize-table">
            <thead>
              <th>申請者</th>
              <th>申請日時</th>
              <th>申請内容</th>
              <th>承認者</th>
              <th>承認日時</th>
              <th>承認結果</th>
            </thead>
            <tbody>
              <tr v-for="(item, index) in paginatedData" :key="index">
                <td>{{ item.Applicant.value }}</td>
                <td>{{ formatDateTime(item.ApplicationDateAndTime.value) }}</td>
                <td>{{ item.ApplicationContents.value }}</td>
                <td>{{ item.Authorizer.value }}</td>
                <td>{{ formatDateTime(item.ApprovalDateAndTime.value) }}</td>
                <td>{{ item.ApprovalResults.value }}</td>
              </tr>
            </tbody>
          </table>
          <div class="pagination">
            <span>{{ currentPage }} / {{ totalPages }}</span>
            <img class="arrowButton" src="../assets/image/Expand_Arrow.png" @click="prevPage" :disabled="currentPage === 1">
            <img class="arrowButton" src="../assets/image/Expand_Arrow2.png" @click="nextPage" :disabled="currentPage * itemsPerPage >= totalFilteredItems" >
          </div>
          <div class="modal-button-block">
            <button class="normalButton" @click="closeModal">閉じる</button>
            <button class="normalButton" @click="submitForm('承認依頼')">承認依頼</button>
            <button class="normalButton" @click="submitForm('差し戻し依頼')">差し戻し依頼</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pageHeader" v-if="dataLoading">
    <h1>契約詳細</h1>
    <div style="position:relative;">
      <select class="normalButton"
        style="
          padding-left: 6px;
          padding-right: 3px;
          width: 32px;
          min-width: auto;
          letter-spacing: -3px;
          appearance: none;
          height: 34px;
          margin-top: 3px;
          background: #F7F5F5;
        "
        v-model="ModalOption"
      >
        <option style="display:none">・・・</option>
        <option value="複製">複製</option>
        <option value="変更履歴">変更履歴</option>
        <option value="発行履歴">発行履歴</option>
      </select>
      <router-link :to="editLink">
      <button class="normalButton" :disabled=" displayData[0].$join_data.Contract.records[0].ApprovalStatus.value === '承認済み' ">編集</button>
    </router-link>
      <select class="normalButton"
        v-model="CreateOption"
        style="top: -33px;height: 34px;margin-right:8px; background: rgb(247, 245, 245);"
        v-if="ApprovalStatus"
      >
        <option style="display:none">新規作成</option>
        <option value="契約">契約</option>
        <option value="注文(受注)">注文(受注)</option>
        <option value="注文(発注)">注文(発注)</option>
      </select>
      <button class="normalButton" v-else @click="isApprovalCheck">新規作成</button>
    <div class="modal" v-if="isModalOpen__change" style="display:flex;">
      <div class="modal-content">
        <h2>変更履歴</h2>
        <img src="../assets/image/close.png" class="close-right" @click="closeModal">
        <table class="authorize-table">
          <thead>
            <tr>
              <th>実行者</th>
              <th>変更日時</th>
              <th>変更内容</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in paginatedData__Revision" :key="index">
              <td>{{ item.updateUser.value }}</td>
              <td>{{ formatDateTime(item.created_at.value) }}</td>
              <td>
                <div class="changeItem" v-for="(changeItem, changeIndex) in item.changedProperties" :key="changeIndex">
                  <div class="changeItemName">{{ changeItem.key }}</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <span>{{ currentPage }} / {{ totalPages }}</span>
          <img class="arrowButton" src="../assets/image/Expand_Arrow.png" @click="prevPage" :disabled="currentPage === 1">
          <img class="arrowButton" src="../assets/image/Expand_Arrow2.png" @click="nextPage" :disabled="currentPage * itemsPerPage >= totalFilteredItems" >
        </div>
        <div class="modal-button-block">
          <button class="normalButton" @click="closeModal">閉じる</button>
        </div>
      </div>
    </div>
    <div class="modal" v-if="isModalOpen__hakko" style="display:flex;">
      <div class="modal-content">
        <h2>発行履歴</h2>
        <img src="../assets/image/close.png" class="close-right" @click="closeModal">
        <table class="authorize-table">
          <thead>
            <tr>
              <th>実行者</th>
              <th>発行日時</th>
              <th>発行種別</th>
              <th>発行内容</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in paginatedData__Hakko" :key="index">
              <td>{{ item.user.value }}</td>
              <td>{{ formatDateTime(item.created_at.value) }}</td>
              <td>{{ item.types.value }}</td>
              <td>{{ item.contents.value }}</td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <span>{{ currentPage }} / {{ totalPages__Hakko }}</span>
          <img class="arrowButton" src="../assets/image/Expand_Arrow.png" @click="prevPage" :disabled="currentPage === 1">
          <img class="arrowButton" src="../assets/image/Expand_Arrow2.png" @click="nextPage" :disabled="currentPage * itemsPerPage >= totalFilteredItems" >
        </div>
        <div class="modal-button-block">
          <button class="normalButton" @click="closeModal">閉じる</button>
        </div>
      </div>
    </div>
    </div>
  </div>
  <div class="pageHeaderBlock" v-if="dataLoading">
    <div class="pageHeaderContent">
      <div v-if="tableData.length > 0">
        <span style="margin-left: 8px;">最終更新者</span>
        <span><img src="../../src/assets/image/AlarmClock.png">{{ formatDateTime(displayData[0].$join_data.Contract.records[0].更新日時.value) }}</span>
        <span><img src="../../src/assets/image/MaleUser.png">{{ displayData[0].$join_data.Contract.records[0].LastUser.value }}</span>
        <span style="margin-left: 8px;">最終承認者</span>
        <span>
          <img src="../../src/assets/image/Update.png">
          <template v-if="tableData[0].ApprovalResults.value === '承認済み'">
          {{ formatDateTime(paginatedData[0].更新日時.value) }}
          </template>
        </span>
        <span>
          <img src="../../src/assets/image/MaleUser_a.png">
          <template v-if="tableData[0].ApprovalResults.value === '承認済み'">
          {{ paginatedData[0].Authorizer.value }}
          </template>
        </span>
      </div>
      <div v-else>
        <span style="margin-left: 8px;">最終更新者</span>
        <span><img src="../../src/assets/image/AlarmClock.png">{{ formatDateTime(displayData[0].$join_data.Contract.records[0].更新日時.value) }}</span>
        <span><img src="../../src/assets/image/MaleUser.png">{{ displayData[0].$join_data.Contract.records[0].LastUser.value }}</span>
        <span style="margin-left: 8px;">最終承認者</span>
        <span><img src="../../src/assets/image/Update.png"></span>
        <span><img src="../../src/assets/image/MaleUser_a.png"></span>
      </div>
    </div>
  </div>

<div class="side-block-template" v-if="dataLoading">
  <div class="content-block side">
    <div id="formImput">
        <div class="FormGroup">
          <!-- 案件名 -->
          <h2>紐付け情報</h2>
          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="projectName">案件</label>
              </div>
              <div class="inputBlock">
                <div>{{ displayData[0].$join_data.Contract.records[0].ProjectTitle.value }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="FormGroup">
          <h2>契約情報</h2>
          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="name">契約名</label>
              </div>
              <div class="inputBlock">
                <div>{{ displayData[0].$join_data.Contract.records[0].Title.value }}</div>
              </div>
            </div>
          <!-- 取引先 -->
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="client">取引先</label>
              </div>
              <div class="inputBlock">
                <div>{{ displayData[0].$join_data.Contract.records[0].Client.value }}</div>
              </div>
            </div>
          </div>
        </div>



        <div class="FormGroup" v-if="displayData[0].$join_data.Contract.records[0].Type.value == ''|| displayData[0].$join_data.Contract.records[0].Type.value == '派遣契約'">
          <h3>労働者派遣契約書</h3>
          <button class="toggleSection" @click="toggleSection('労働者派遣個別契約書')">
            <img class="toggleButton" src="../assets/image/Expand_Arrow.png" v-if="showSections['労働者派遣個別契約書']">
            <img class="toggleButton_1" src="../assets/image/Expand_Arrow.png" v-if="!showSections['労働者派遣個別契約書']">
          </button>
          <div v-if="showSections['労働者派遣個別契約書']">
          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="worker__ID">ID</label>
              </div>
              <div class="inputBlock">
                {{ displayData[0].$join_data.Contract.records[0].worker__ID.value }}
              </div>
            </div>
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="worker__Date">発行日</label>
              </div>
              <div class="inputBlock">
                {{ displayData[0].$join_data.Contract.records[0].worker__Date.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="">派遣先事業所の名称</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>名称</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__Date.value }}
              </div>
              <div>
                <label>郵便番号</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__postal.value }}
              </div>
              <div>
                <label>所在地</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__location.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="Workplace">就業場所</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>名称</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__location__name.value }}
              </div>
              <div>
                <label>郵便番号</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__location__postal.value }}
              </div>
              <div>
                <label>所在地</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__location__location.value }}
              </div>
              <div>
                <label>電話番号</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__location__num.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="worker__organization">組織単位(及び組織長)</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>名称</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__organization.value }}
              </div>
              <div>
                <label>組織長氏名</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__organization__name.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="worker__destination__department">派遣先責任者</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>部署</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__destination__department.value }}
              </div>
              <div>
                <label>役職</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__destination__post.value }}
              </div>
              <div>
                <label>氏名</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__destination__name.value }}
              </div>
              <div>
                <label>電話番号</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__destination__num.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="Command">指揮命令者</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>部署</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__command__department.value }}
              </div>
              <div>
                <label>役職</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__command__post.value }}
              </div>
              <div>
                <label>氏名</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__command__name.value }}
              </div>
              <div>
                <label>電話番号</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__command__num.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="worker__content">業務内容</label>
            </div>
            <div class="inputBlock">
              <div v-html="nl2br(displayData[0].$join_data.Contract.records[0].worker__content.value)"></div>
            </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="worker__conflict_date">派遣先事業所における期間制限に抵触する日</label>
              </div>
              <div class="inputBlock">
                {{ displayData[0].$join_data.Contract.records[0].worker__conflict_date.value }}
              </div>
            </div>
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="period">派遣期間</label>
              </div>
              <div class="inputBlock" style="display: flex; align-items: center;">
                {{ displayData[0].$join_data.Contract.records[0].worker__startDate.value }}~
                {{ displayData[0].$join_data.Contract.records[0].worker__endDate.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
              <div class="nameBlock">
                <label for="worker__workday">就業日、勤務時間</label>
              </div>
              <div class="inputBlock">
                {{ displayData[0].$join_data.Contract.records[0].worker__workday.value }}
              </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block" style="width: 100%;">
              <div class="nameBlock">
                <label for="worker__overtime">時間外労働</label>
              </div>
              <div class="inputBlock">
                {{ displayData[0].$join_data.Contract.records[0].worker__overtime.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block" style="width: 100%;">
              <div class="nameBlock">
                <label for="worker__holiday_work">休日労働</label>
              </div>
              <div class="inputBlock">
                {{ displayData[0].$join_data.Contract.records[0].worker__holiday_work.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="DispatchSource">派遣料金</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>1時間 当り</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__hourly.value }}
              </div>
              <div>
                <label>通勤交通費</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__transportation.value }}
              </div>
              <div style="width: 90%;">
                <label>備考</label>
                <div v-html="nl2br(displayData[0].$join_data.Contract.records[0].worker__remarks.value)"></div>
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block" style="width: 100%;">
              <div class="nameBlock">
                <label for="worker__payroll">給与計算</label>
              </div>
              <div class="inputBlock">
                {{ displayData[0].$join_data.Contract.records[0].worker__payroll.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="worker__rate">時間外、休日又は深夜労働者に支払われる割増賃金率</label>
            </div>
            <div class="inputBlock">
              {{ displayData[0].$join_data.Contract.records[0].worker__rate.value }}
            </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block" style="width: 100%;">
              <div class="nameBlock">
                <label>賃金支払日</label>
              </div>
              <div class="inputBlock">
                {{ displayData[0].$join_data.Contract.records[0].worker__payment.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block" style="width: 100%;">
              <div class="nameBlock">
                <label>賃金の支払い方法</label>
              </div>
              <div class="inputBlock">
                {{ displayData[0].$join_data.Contract.records[0].worker__payment_method.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block" style="width: 100%;">
              <div class="nameBlock">
                <label>派遣人数</label>
              </div>
              <div class="inputBlock">
                {{ displayData[0].$join_data.Contract.records[0].worker__personnel.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label>苦情処理申出先（派遣先）</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>部署</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__destination_person_department.value }}
              </div>
              <div>
                <label>氏名</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__destination_person_name.value }}
              </div>
              <div>
                <label>電話番号</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__destination_person_num.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label>苦情処理申出先（派遣元）</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>部署</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__complaint_department.value }}
              </div>
              <div>
                <label>氏名</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__complaint_name.value }}
              </div>
              <div>
                <label>電話番号</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__complaint_num.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label>安全及び衛生</label>
            </div>
            <div class="inputBlock">
              <div v-html="nl2br(displayData[0].$join_data.Contract.records[0].worker__safety.value)"></div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="worker__handling">苦情の苦情処理方法連携体制等処理方法</label>
            </div>
            <div class="inputBlock">
              <div v-html="nl2br(displayData[0].$join_data.Contract.records[0].worker__handling.value)"></div>
            </div>
          </div>  

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="worker__textarea_1">派遣契約解除の場合の措置</label>
            </div>
            <div class="inputBlock">
              <div v-html="nl2br(displayData[0].$join_data.Contract.records[0].worker__textarea_1.value)"></div>
            </div>
          </div>
          
          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="worker__textarea_5">就業日等</label>
            </div>
            <div class="inputBlock">
              <div v-html="nl2br(displayData[0].$join_data.Contract.records[0].worker__textarea_5.value)"></div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="worker__textarea_2">便宜供与</label>
            </div>
            <div class="inputBlock">
              <div v-html="nl2br(displayData[0].$join_data.Contract.records[0].worker__textarea_2.value)"></div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="worker__textarea_4">無期解雇又は60歳以上に限定するか否かの別</label>
            </div>
            <div class="inputBlock">
              <div v-html="nl2br(displayData[0].$join_data.Contract.records[0].worker__textarea_4.value)"></div>
            </div>
          </div>
          
          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="worker__textarea_3">紛争防止措置</label>
            </div>
            <div class="inputBlock">
              <div v-html="nl2br(displayData[0].$join_data.Contract.records[0].worker__textarea_3.value)"></div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="Owner">派遣元事業所の名称</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>名称</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__owner__name.value }}
              </div>
              <div>
                <label>郵便番号</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__owner__postal.value }}
              </div>
              <div>
                <label>所在地</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__owner__location.value }}
              </div>
              <div>
                <label>電話番号</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__owner__num.value }}
              </div>
            </div>
          </div>
          
          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="Owner">派遣元責任者</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>部署</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__source__department.value }}
              </div>
              <div>
                <label>役職</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__source__post.value }}
              </div>
              <div>
                <label>氏名</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__source__name.value }}
              </div>
              <div>
                <label>電話番号</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__source__num.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="Owner">営業担当</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>部署</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__manager__department.value }}
              </div>
              <div>
                <label>氏名</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__manager__name.value }}
              </div>
              <div>
                <label>電話番号</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__manager__num.value }}
              </div>
            </div>
          </div>

          </div>
        </div>

        <div class="FormGroup" v-if="displayData[0].$join_data.Contract.records[0].Type.value == ''|| displayData[0].$join_data.Contract.records[0].Type.value == '派遣契約'">
          <h3>明示書</h3>
          <button type="button" class="toggleSection" @click="toggleSection('明示書')">
            <img class="toggleButton" src="../assets/image/Expand_Arrow.png" v-if="showSections['明示書']">
            <img class="toggleButton_1" src="../assets/image/Expand_Arrow.png" v-if="!showSections['明示書']">
          </button>
          <div v-if="showSections['明示書']">
          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="worker__ID">ID</label>
              </div>
              <div class="inputBlock">
                {{ displayData[0].$join_data.Contract.records[0].worker__ID.value }}
              </div>
            </div>
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="worker__Date">発行日</label>
              </div>
              <div class="inputBlock">
                {{ displayData[0].$join_data.Contract.records[0].worker__Date.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="">労働者所在地</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>郵便番号</label>
                {{ displayData[0].$join_data.Contract.records[0].explicit__worker_postal.value }}
              </div>
              <div>
                <label>所在地</label>
                {{ displayData[0].$join_data.Contract.records[0].explicit__worker_location.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block" style="width: 100%;">
              <div class="nameBlock">
                <label for="explicit__worker_name">労働者氏名</label>
              </div>
              <div class="inputBlock">
                {{ displayData[0].$join_data.Contract.records[0].explicit__worker_name.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="">派遣先事業者の名称</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>名称</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__Name.value }}
              </div>
              <div>
                <label>郵便番号</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__postal.value }}
              </div>
              <div>
                <label>所在地</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__location.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="">就業場所</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>名称</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__location__name.value }}
              </div>
              <div>
                <label>郵便番号</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__location__postal.value }}
              </div>
              <div>
                <label>所在地</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__location__location.value }}
              </div>
              <div>
                <label>電話番号</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__location__num.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="">組織単位</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>名称</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__organization.value }}
              </div>
              <div>
                <label>組織長氏名</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__organization__name.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="">派遣先責任者</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>部署</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__destination__department.value }}
              </div>
              <div>
                <label>責任者氏名</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__destination__name.value }}
              </div>
              <div>
                <label>電話番号</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__destination__num.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="">指揮命令者</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>部署</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__command__department.value }}
              </div>
              <div>
                <label>指揮命令者氏名</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__command__name.value }}
              </div>
              <div>
                <label>電話番号</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__command__num.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="worker__content">業務内容</label>
            </div>
            <div class="inputBlock">
              <div v-html="nl2br(displayData[0].$join_data.Contract.records[0].worker__content.value)"></div>
            </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="period">派遣期間</label>
              </div>
              <div class="inputBlock" style="display: flex; align-items: center;">
                {{ displayData[0].$join_data.Contract.records[0].worker__startDate.value }}~
                {{ displayData[0].$join_data.Contract.records[0].worker__endDate.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="worker__conflict_date">派遣先事業所における期間制限に抵触する日</label>
              </div>
              <div class="inputBlock">
                {{ displayData[0].$join_data.Contract.records[0].worker__conflict_date.value }}
              </div>
            </div>
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="explicit__conflict_date">派遣労働者における期間制限に抵触する日</label>
              </div>
              <div class="inputBlock">
                {{ displayData[0].$join_data.Contract.records[0].explicit__conflict_date.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
              <div class="nameBlock">
                <label for="worker__workday">就業日、勤務時間</label>
              </div>
              <div class="inputBlock">
                <div v-html="nl2br(displayData[0].$join_data.Contract.records[0].worker__workday.value)"></div>
              </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block" style="width: 100%;">
              <div class="nameBlock">
                <label for="worker__overtime">時間外労働</label>
              </div>
              <div class="inputBlock">
                {{ displayData[0].$join_data.Contract.records[0].worker__overtime.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block" style="width: 100%;">
              <div class="nameBlock">
                <label for="worker__holiday_work">休日労働</label>
              </div>
              <div class="inputBlock">
                {{ displayData[0].$join_data.Contract.records[0].worker__holiday_work.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="DispatchSource">給与交通費</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>1時間 当り</label>
                {{ displayData[0].$join_data.Contract.records[0].explicit__hourly.value }}
              </div>
              <div>
                <label>通勤交通費</label>
                {{ displayData[0].$join_data.Contract.records[0].explicit__transportation.value }}
              </div>
              <div style="width: 90%;">
                <label>備考</label>
                <div v-html="nl2br(displayData[0].$join_data.Contract.records[0].worker__remarks.value)"></div>
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block" style="width: 100%;">
              <div class="nameBlock">
                <label for="explicit__average">当該事業所における派遣料金平均額</label>
              </div>
              <div class="inputBlock">
                {{ displayData[0].$join_data.Contract.records[0].explicit__average.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block" style="width: 100%;">
              <div class="nameBlock">
                <label for="worker__payroll">給与計算</label>
              </div>
              <div class="inputBlock">
                {{ displayData[0].$join_data.Contract.records[0].worker__payroll.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
              <div class="nameBlock">
                <label for="worker__rate">時間外、休日又は深夜労働者に支払われる割増賃金率</label>
              </div>
              <div class="inputBlock">
                <div v-html="nl2br(displayData[0].$join_data.Contract.records[0].worker__rate.value)"></div>
              </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block" style="width:100%">
              <div class="nameBlock">
                <label for="worker__payment">賃金支払日</label>
              </div>
              <div class="inputBlock">
                {{ displayData[0].$join_data.Contract.records[0].worker__payment.value }}
              </div>
            </div>
          </div>
          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block" style="width:100%">
              <div class="nameBlock">
                <label for="worker__payment_method">賃金の支払い方法</label>
              </div>
              <div class="inputBlock">
                {{ displayData[0].$join_data.Contract.records[0].worker__payment_method.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="DestinationComplaint">苦情処理申出先（派遣先）</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>部署</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__payment_method.value }}
              </div>
              <div>
                <label>氏名</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__destination_person_name.value }}
              </div>
              <div>
                <label>電話番号</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__destination_person_num.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="SourceComplaint">苦情処理申出先（派遣元）</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>部署</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__complaint_department.value }}
              </div>
              <div>
                <label>氏名</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__complaint_name.value }}
              </div>
              <div>
                <label>電話番号</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__complaint_num.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="worker__safety">安全及び衛生</label>
            </div>
            <div class="inputBlock">
              <div v-html="nl2br(displayData[0].$join_data.Contract.records[0].worker__safety.value)"></div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="worker__handling">苦情の苦情処理方法連携体制等処理方法</label>
            </div>
            <div class="inputBlock">
              <div v-html="nl2br(displayData[0].$join_data.Contract.records[0].worker__handling.value)"></div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="explicit__contract_end">派遣契約解除の場合の措置</label>
            </div>
            <div class="inputBlock">
              <div v-html="nl2br(displayData[0].$join_data.Contract.records[0].explicit__contract_end.value)"></div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="explicit__career">派遣労働者に対するキャリアアップ措置</label>
            </div>
            <div class="inputBlock">
              <div v-html="nl2br(displayData[0].$join_data.Contract.records[0].explicit__career.value)"></div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="worker__textarea_1">派遣契約解除の場合の措置</label>
            </div>
            <div class="inputBlock">
              <div v-html="nl2br(displayData[0].$join_data.Contract.records[0].worker__textarea_1.value)"></div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="explicit__update">派遣期間更新の有無</label>
            </div>
            <div class="inputBlock">
              <div v-html="nl2br(displayData[0].$join_data.Contract.records[0].explicit__update.value)"></div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="explicit__vacation">休暇</label>
            </div>
            <div class="inputBlock">
              <div v-html="nl2br(displayData[0].$join_data.Contract.records[0].explicit__vacation.value)"></div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="explicit__wage">賃金</label>
            </div>
            <div class="inputBlock">
              <div v-html="nl2br(displayData[0].$join_data.Contract.records[0].explicit__wage.value)"></div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="explicit__employment">雇用の終了</label>
            </div>
            <div class="inputBlock">
              <div v-html="nl2br(displayData[0].$join_data.Contract.records[0].explicit__employment.value)"></div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="worker__textarea_2">便宜供与</label>
            </div>
            <div class="inputBlock">
              <div v-html="nl2br(displayData[0].$join_data.Contract.records[0].worker__textarea_2.value)"></div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="">社会保険等</label>
            </div>
            <div class="inputBlock" style="display: block;">
              <div>
                <label>健康保険</label>
                {{ displayData[0].$join_data.Contract.records[0].explicit__health_insurance.value }}
              </div>
              <div>
                <label>厚生年金</label>
                {{ displayData[0].$join_data.Contract.records[0].explicit__pension.value }}
              </div>
              <div>
                <label>雇用保険</label>
                {{ displayData[0].$join_data.Contract.records[0].explicit__insurance.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="explicit__not_considered">みなし制度の適用可能性</label>
            </div>
            <div class="inputBlock">
              <div v-html="nl2br(displayData[0].$join_data.Contract.records[0].explicit__not_considered.value)"></div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="worker__textarea_3">紛争防止措置</label>
            </div>
            <div class="inputBlock">
              <div v-html="nl2br(displayData[0].$join_data.Contract.records[0].worker__textarea_3.value)"></div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="">雇用管理の改善に関する事項に係る相談窓口</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>名称</label>
                {{ displayData[0].$join_data.Contract.records[0].explicit__window.value }}
              </div>
              <div>
                <label>電話番号</label>
                {{ displayData[0].$join_data.Contract.records[0].explicit__window_num.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="explicit__introduction">紹介予定派遣の場合</label>
            </div>
            <div class="inputBlock">
              <div v-html="nl2br(displayData[0].$join_data.Contract.records[0].explicit__introduction.value)"></div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="explicit__remark">備考</label>
            </div>
            <div class="inputBlock">
              <div v-html="nl2br(displayData[0].$join_data.Contract.records[0].explicit__remark.value)"></div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="Owner">派遣元事業所の名称</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>名称</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__owner__name.value }}
              </div>
              <div>
                <label>郵便番号</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__owner__postal.value }}
              </div>
              <div>
                <label>所在地</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__owner__location.value }}
              </div>
              <div>
                <label>電話番号</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__owner__num.value }}
              </div>
            </div>
          </div>
          
          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="Owner">派遣元責任者</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>部署</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__source__department.value }}
              </div>
              <div>
                <label>役職</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__source__post.value }}
              </div>
              <div>
                <label>氏名</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__source__name.value }}
              </div>
              <div>
                <label>電話番号</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__source__num.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="Owner">営業担当</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>部署</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__manager__department.value }}
              </div>
              <div>
                <label>氏名</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__manager__name.value }}
              </div>
              <div>
                <label>電話番号</label>
                {{ displayData[0].$join_data.Contract.records[0].worker__manager__num.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="explicit__notes">注意事項</label>
            </div>
            <div class="inputBlock">
              <div v-html="nl2br(displayData[0].$join_data.Contract.records[0].explicit__notes.value)"></div>
            </div>
          </div>



          </div>
        </div>

        <div class="FormGroup" v-if="displayData[0].$join_data.Contract.records[0].Type.value == ''|| displayData[0].$join_data.Contract.records[0].Type.value == '派遣契約'">
          <h3>派遣先への通知書</h3>
          <button class="toggleSection" @click="toggleSection('派遣先への通知')">
            <img class="toggleButton" src="../assets/image/Expand_Arrow.png" v-if="showSections['派遣先への通知']">
            <img class="toggleButton_1" src="../assets/image/Expand_Arrow.png" v-if="!showSections['派遣先への通知']">
          </button>
          <div v-if="showSections['派遣先への通知']">
          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="worker__ID">ID</label>
              </div>
              <div class="inputBlock">
                {{ displayData[0].$join_data.Contract.records[0].worker__ID.value }}
              </div>
            </div>
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="worker__Date">発行日</label>
              </div>
              <div class="inputBlock">
                {{ displayData[0].$join_data.Contract.records[0].worker__Date.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="Owner">派遣先</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>郵便番号</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].worker__postal.value }}</div>
              </div>
              <div>
                <label>所在地</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].worker__location.value }}</div>
              </div>
              <div>
                <label>名称</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].worker__location__name.value }}</div>
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="Destination">派遣元</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>郵便番号</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].worker__owner__postal.value }}</div>
              </div>
              <div>
                <label>所在地</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].worker__owner__location.value }}</div>
              </div>
              <div>
                <label>名称</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].worker__owner__name.value }}</div>
              </div>
              <div>
                <label>電話番号</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].worker__owner__num.value }}</div>
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="">派遣元担当者</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>部署</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].worker__source__department.value }}</div>
              </div>
              <div>
                <label>氏名</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].worker__source__name.value }}</div>
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="explicit__worker_name">派遣者氏名</label>
              </div>
              <div class="inputBlock">
                <div>{{ displayData[0].$join_data.Contract.records[0].explicit__worker_name.value }}</div>
              </div>
            </div>
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="notification__sex">性別</label>
              </div>
              <div class="inputBlock">
                <div>{{ displayData[0].$join_data.Contract.records[0].notification__sex.value }}</div>
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Personnel">年齢層</label>
              </div>
              <div class="inputBlock">
                <div>{{ displayData[0].$join_data.Contract.records[0].notification__age_select.value }}</div>
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="">派遣期間</label>
              </div>
              <div class="inputBlock">
                {{ displayData[0].$join_data.Contract.records[0].worker__startDate.value }}~
                {{ displayData[0].$join_data.Contract.records[0].worker__endDate.value }}
              </div>
            </div>
          </div>
          
          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Name">雇用形態</label>
              </div>
              <div class="inputBlock">
                <div>{{ displayData[0].$join_data.Contract.records[0].notification__status.value }}</div>
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="">雇用期間</label>
              </div>
              <div class="inputBlock">
                {{ displayData[0].$join_data.Contract.records[0].notification__period_start.value }}~
                {{ displayData[0].$join_data.Contract.records[0].notification__period_end.value }}
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="Destination">保険加入状況</label>
            </div>
            <div class="inputBlock" style="display:block;">
              <div style="display:flex;">
                <div style="min-width: 180px;">
                  <label>雇用保険</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].explicit__insurance.value }}</div>
                </div>
                <div style="min-width: 180px;">
                  <label>（加入予定日）</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].notification__insurance_date.value }}</div>
                </div>
                <div style="min-width: 180px;">
                  <label>（理由）</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].notification__insurance_reason.value }}</div>
                </div>
              </div>
              <div style="display:flex;">
                <div style="min-width: 180px;">
                  <label>健康保険</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].explicit__health_insurance.value }}</div>
                </div>
                <div style="min-width: 180px;">
                  <label>（加入予定日）</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].notification__health_insurance_date.value }}</div>
                </div>
                <div style="min-width: 180px;">
                  <label>（理由）</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].notification__health_insurance_reason.value }}</div>
                </div>
              </div>
              <div style="display:flex;">
                <div style="min-width: 180px;">
                  <label>厚生年金保険</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].explicit__pension.value }}</div>
                </div>
                <div style="min-width: 180px;">
                  <label>（加入予定日）</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].notification__pension_date.value }}</div>
                </div>
                <div style="min-width: 180px;">
                  <label>（理由）</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].notification__pension_reason.value }}</div>
                </div>
              </div>
            </div>
          </div>

          </div>
        </div>


        <div class="FormGroup" v-if="displayData[0].$join_data.Contract.records[0].Type.value == ''|| displayData[0].$join_data.Contract.records[0].Type.value == '派遣契約'">
          <h3>派遣元管理台帳</h3>
          <button class="toggleSection" @click="toggleSection('派遣元管理台帳')">
            <img class="toggleButton" src="../assets/image/Expand_Arrow.png" v-if="showSections['派遣元管理台帳']">
            <img class="toggleButton_1" src="../assets/image/Expand_Arrow.png" v-if="!showSections['派遣元管理台帳']">
          </button>
          <div v-if="showSections['派遣元管理台帳']">

            <div class="inputCol inputCol-2">
              <div class="inputCol-2-block">
                <div class="nameBlock">
                  <label for="worker__ID">ID</label>
                </div>
                <div class="inputBlock">
                  {{ displayData[0].$join_data.Contract.records[0].worker__ID.value }}
                </div>
              </div>
              <div class="inputCol-2-block">
                <div class="nameBlock">
                  <label for="worker__Date">発行日</label>
                </div>
                <div class="inputBlock">
                  {{ displayData[0].$join_data.Contract.records[0].worker__Date.value }}
                </div>
              </div>
            </div>

            <div class="inputCol inputCol-4items">
              <div class="nameBlock">
                <label for="">派遣労働者</label>
              </div>
              <div class="inputBlock">
                <div>
                  <label>氏名</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].explicit__worker_name.value }}</div>
                </div>
                <div>
                  <label>郵便番号</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].explicit__worker_postal.value }}</div>
                </div>
                <div>
                  <label>住所</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].explicit__worker_location.value }}</div>
                </div>
                <div>
                  <label>電話番号</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].dispatch_source__worker_num.value }}</div>
                </div>
                <div>
                  <label>携帯番号</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].dispatch_source__worker_cellphonenum.value }}</div>
                </div>
              </div>
            </div>

            <div class="inputCol inputCol-2">
              <div class="inputCol-2-block">
                <div class="nameBlock">
                  <label for="worker__Name">派遣先の名称</label>
                </div>
                <div class="inputBlock">
                  {{ displayData[0].$join_data.Contract.records[0].worker__Name.value }}
                </div>
              </div>
            </div>

            <div class="inputCol inputCol-4items">
              <div class="nameBlock">
                <label for="">派遣先事業所の名称</label>
              </div>
              <div class="inputBlock">
                <div>
                  <label>郵便番号</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].worker__postal.value }}</div>
                </div>
                <div>
                  <label>所在地</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].worker__location.value }}</div>
                </div>
                <div>
                  <label>名称</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].worker__Name.value }}</div>
                </div>
              </div>
            </div>  

            <div class="inputCol inputCol-4items">
              <div class="nameBlock">
                <label for="">就業場所</label>
              </div>
              <div class="inputBlock">
                <div>
                  <label>郵便番号</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].worker__location__postal.value }}</div>
                </div>
                <div>
                  <label>所在地</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].worker__location__location.value }}</div>
                </div>
                <div>
                  <label>名称</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].worker__location__name.value }}</div>
                </div>
              </div>
            </div>  

            <div class="inputCol inputCol-4items">
              <div class="nameBlock">
                <label for="">組織単位（及び組織長）</label>
              </div>
              <div class="inputBlock">
                <div>
                  <label>部署</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].worker__organization.value }}</div>
                </div>
                <div>
                  <label>氏名</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].worker__organization__name.value }}</div>
                </div>
              </div>
            </div>  

            <div class="inputCol inputCol-4items">
              <div class="nameBlock">
                <label for="">派遣先責任者</label>
              </div>
              <div class="inputBlock">
                <div>
                  <label>部署</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].worker__destination__department.value }}</div>
                </div>
                <div>
                  <label>氏名</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].worker__destination__name.value }}</div>
                </div>
              </div>
            </div>  

            <div class="inputCol inputCol-4items">
              <div class="nameBlock">
                <label for="">指揮命令者</label>
              </div>
              <div class="inputBlock">
                <div>
                  <label>部署</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].worker__command__department.value }}</div>
                </div>
                <div>
                  <label>氏名</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].worker__command__name.value }}</div>
                </div>
              </div>
            </div>  

            <div class="inputCol inputCol-textarea">
              <div class="nameBlock">
                <label for="worker__content">業務内容</label>
              </div>
              <div class="inputBlock">
                <div v-html="nl2br(displayData[0].$join_data.Contract.records[0].worker__content.value)"></div>
              </div>
            </div>

            <div class="inputCol inputCol-2">
              <div class="inputCol-2-block">
                <div class="nameBlock">
                  <label for="">派遣期間</label>
                </div>
                <div class="inputBlock">
                  {{ displayData[0].$join_data.Contract.records[0].worker__startDate.value }}~
                  {{ displayData[0].$join_data.Contract.records[0].worker__endDate.value }}
                </div>
              </div>
            </div>

            <div class="inputCol inputCol-2">
              <div class="inputCol-2-block">
                <div class="nameBlock">
                  <label for="worker__conflict_date">派遣事業所における期間制限</label>
                </div>
                <div class="inputBlock">
                  {{ displayData[0].$join_data.Contract.records[0].worker__conflict_date.value }}
                </div>
              </div>
              <div class="inputCol-2-block">
                <div class="nameBlock">
                  <label for="period">雇用契約期間</label>
                </div>
                <div class="inputBlock" style="display: flex; align-items: center;">
                  {{ displayData[0].$join_data.Contract.records[0].worker__startDate.value }}~
                  {{ displayData[0].$join_data.Contract.records[0].worker__endDate.value }}
                </div>
              </div>
            </div>

            <div class="inputCol inputCol-2">
              <div class="inputCol-2-block">
                <div class="nameBlock">
                  <label for="Name">無期雇用か有期雇用かの別</label>
                </div>
                <div class="inputBlock">
                  <div>{{ displayData[0].$join_data.Contract.records[0].notification__status.value }}</div>
                </div>
              </div>
            </div>

            <div class="inputCol inputCol-textarea">
                <div class="nameBlock">
                  <label for="worker__workday">就業日、勤務時間</label>
                </div>
                <div class="inputBlock">
                  {{ displayData[0].$join_data.Contract.records[0].worker__workday.value }}
                </div>
            </div>

            <div class="inputCol inputCol-2">
              <div class="inputCol-2-block" style="width: 100%;">
                <div class="nameBlock">
                  <label for="worker__overtime">時間外労働</label>
                </div>
                <div class="inputBlock">
                  {{ displayData[0].$join_data.Contract.records[0].worker__overtime.value }}
                </div>
              </div>
            </div>

            <div class="inputCol inputCol-2">
              <div class="inputCol-2-block" style="width: 100%;">
                <div class="nameBlock">
                  <label for="worker__holiday_work">休日労働</label>
                </div>
                <div class="inputBlock">
                  {{ displayData[0].$join_data.Contract.records[0].worker__holiday_work.value }}
                </div>
              </div>
            </div>

            <div class="inputCol inputCol-4items">
              <div class="nameBlock">
                <label for="">社会保険加入状況</label>
              </div>
              <div class="inputBlock" style="display: block;">
                <div>
                  <label>健康保険</label>
                  {{ displayData[0].$join_data.Contract.records[0].explicit__health_insurance.value }}
                </div>
                <div>
                  <label>厚生年金</label>
                  {{ displayData[0].$join_data.Contract.records[0].explicit__pension.value }}
                </div>
                <div>
                  <label>雇用保険</label>
                  {{ displayData[0].$join_data.Contract.records[0].explicit__insurance.value }}
                </div>
              </div>
            </div>

            <div class="inputCol inputCol-2">
              <div class="inputCol-2-block" style="width: 100%;">
                <div class="nameBlock">
                  <label for="dispatch_source__employment">就業状況</label>
                </div>
                <div class="inputBlock">
                  {{ displayData[0].$join_data.Contract.records[0].dispatch_source__employment.value }}
                </div>
              </div>
            </div>

            <div class="inputCol inputCol-textarea">
                <div class="nameBlock">
                  <label for="dispatch_source__introduction">紹介予定派遣に関する事項</label>
                </div>
                <div class="inputBlock">
                  <div v-html="nl2br(displayData[0].$join_data.Contract.records[0].dispatch_source__introduction.value)"></div>
                </div>
            </div>

            <div class="inputCol inputCol-4items">
              <div class="nameBlock">
                <label for="">日雇派遣における例外確認</label>
              </div>
              <div class="inputBlock" style="display: block;">
                <div>
                  <label>確認事項</label>
                  {{ displayData[0].$join_data.Contract.records[0].dispatch_source__day_checklist.value }}
                </div>
                <div>
                  <label>確認書類</label>
                  {{ displayData[0].$join_data.Contract.records[0].dispatch_source__day_checkdocument.value }}
                </div>
                <div>
                  <label>確認日</label>
                  {{ displayData[0].$join_data.Contract.records[0].dispatch_source__day_checkday.value }}
                </div>
              </div>
            </div>

            <div class="inputCol inputCol-2">
              <div class="inputCol-2-block" style="width: 100%;">
                <div class="nameBlock">
                  <label for="dispatch_source__complaint">派遣労働者からの苦情の処理状況</label>
                </div>
                <div class="inputBlock">
                  {{ displayData[0].$join_data.Contract.records[0].dispatch_source__complaint.value }}
                </div>
              </div>
            </div>

            <div class="inputCol inputCol-4items">
              <div class="nameBlock">
                <label for="">キャリアコンサルティング・教育訓練の日時及び内容</label>
              </div>
              <div class="inputBlock" style="display: block;">
                <div>
                  <label>実施日時</label>
                  {{ displayData[0].$join_data.Contract.records[0].dispatch_source__training_day.value }}
                </div>
                <div>
                  <label>実施内容</label>
                  {{ displayData[0].$join_data.Contract.records[0].dispatch_source__training_content.value }}
                </div>
              </div>
            </div>

            <div class="inputCol inputCol-4items">
              <div class="nameBlock">
                <label for="">雇用安定措置の内容</label>
              </div>
              <div class="inputBlock" style="display: block;">
                <div>
                  <label>派遣先への直接雇用依頼</label>
                  {{ displayData[0].$join_data.Contract.records[0].dispatch_source__stable_directly.value }}
                </div>
                <div>
                  <label>他の派遣先の紹介</label>
                  {{ displayData[0].$join_data.Contract.records[0].dispatch_source__stable_introduction.value }}
                </div>
                <div>
                  <label>期間を定めない雇用機会の確保</label>
                  {{ displayData[0].$join_data.Contract.records[0].dispatch_source__stable_no_fixed.value }}
                </div>
                <div>
                  <label>その他</label>
                  {{ displayData[0].$join_data.Contract.records[0].dispatch_source__stable_other.value }}
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="FormGroup" v-if="displayData[0].$join_data.Contract.records[0].Type.value == ''|| displayData[0].$join_data.Contract.records[0].Type.value == '派遣契約'">
          <h3>派遣先管理台帳</h3>
          <button class="toggleSection" @click="toggleSection('派遣先管理台帳')">
            <img class="toggleButton" src="../assets/image/Expand_Arrow.png" v-if="showSections['派遣先管理台帳']">
            <img class="toggleButton_1" src="../assets/image/Expand_Arrow.png" v-if="!showSections['派遣先管理台帳']">
          </button>
          <div v-if="showSections['派遣先管理台帳']">
          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="explicit__worker_name">派遣労働者の氏名</label>
              </div>
              <div class="inputBlock">
                <div>{{ displayData[0].$join_data.Contract.records[0].explicit__worker_name.value }}</div>
              </div>
            </div>
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="issue">派遣元事業主の名称</label>
              </div>
              <div class="inputBlock">
                <div>{{ displayData[0].$join_data.Contract.records[0].worker__source__name.value }}</div>
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="Destination">派遣元事業主の事業所</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>名称</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].worker__owner__name.value }}</div>
              </div>
              <div>
                <label>郵便番号</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].worker__owner__postal.value }}</div>
              </div>
              <div>
                <label>所在地</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].worker__owner__location.value }}</div>
              </div>
              <div>
                <label>電話番号</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].worker__owner__num.value }}</div>
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="issue">無期雇用か有期雇用かの別</label>
              </div>
              <div class="inputBlock">
                <div>{{ displayData[0].$join_data.Contract.records[0].notification__status.value }}</div>
              </div>
            </div>
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="issue">協定対象派遣労働者かの別</label>
              </div>
              <div class="inputBlock">
                <div>{{ displayData[0].$join_data.Contract.records[0].management__worker.value }}</div>
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="issue">組織単位(及び組織長)</label>
              </div>
              <div class="inputBlock">
                <div>{{ displayData[0].$join_data.Contract.records[0].worker__organization__name.value }}</div>
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="textarea7">従事した業務の種類</label>
            </div>
            <div class="inputBlock">
              <div>{{ displayData[0].$join_data.Contract.records[0].management__business.value }}</div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="">責任の程度</label>
            </div>
            <div class="inputBlock">
              <div>{{ displayData[0].$join_data.Contract.records[0].management__responsibility.value }}</div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="">業務に従事した事業所</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>名称</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].worker__location__name.value }}</div>
              </div>
              <div>
                <label>郵便番号</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].worker__location__postal.value }}</div>
              </div>
              <div>
                <label>所在地</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].worker__location__location.value }}</div>
              </div>
              <div>
                <label>電話番号</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].worker__location__num.value }}</div>
              </div>
            </div>
          </div>
        
          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="Destination">教育訓練を行った日時及び内容</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>日付</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].management__education_date_1.value }}</div>
              </div>
              <div>
                <label>内容</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].management__education_content_1.value }}</div>
              </div>
              <div>
                <label>日付</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].management__education_date_2.value }}</div>
              </div>
              <div>
                <label>内容</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].management__education_content_2.value }}</div>
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="Destination">就業状況</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>就業日</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].management__employment_date.value }}</div>
              </div>
              <div>
                <label>就業時間</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].management__employment_time.value }}</div>
              </div>
              <div>
                <label>休憩時間</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].management__employment_break.value }}</div>
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="Destination">派遣先責任者</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>所属部署</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].worker__destination__department.value }}</div>
              </div>
              <div>
                <label>連絡先(TEL)</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].worker__destination__num.value }}</div>
              </div>
              <div>
                <label>役職</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].worker__destination__post.value }}</div>
              </div>
              <div>
                <label>氏名</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].worker__destination__name.value }}</div>
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="">派遣元責任者</label>
            </div>
            <div class="inputBlock">
              <div>
                <label>所属部署</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].worker__source__department.value }}</div>
              </div>
              <div>
                <label>連絡先(TEL)</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].worker__source__num.value }}</div>
              </div>
              <div>
                <label>役職</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].worker__source__post.value }}</div>
              </div>
              <div>
                <label>氏名</label>
                <div>{{ displayData[0].$join_data.Contract.records[0].worker__source__name.value }}</div>
              </div>
            </div>
          </div>    

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="textarea7">期限制限のない労働者派遣に関する事項</label>
            </div>
            <div class="inputBlock">
              <div>{{ displayData[0].$join_data.Contract.records[0].management__worker_deadline.value }}</div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="Destination">雇用保険及び社会保険の被保険者資格取得届の提出の有無</label>
            </div>
            <div class="inputBlock" style="display:block;">
              <div style="display:flex;">
                <div style="min-width: 180px;">
                  <label>雇用保険の加入状況</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].explicit__insurance.value }}</div>
                </div>
                <div style="min-width: 180px;">
                  <label>理由</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].notification__insurance_reason.value }}</div>
                </div>
              </div>
              <div style="display:flex;">
                <div style="min-width: 180px;">
                  <label>健康保険の加入状況</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].explicit__health_insurance.value }}</div>
                </div>
                <div style="min-width: 180px;">
                  <label>理由</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].notification__health_insurance_reason.value }}</div>
                </div>
              </div>
              <div style="display:flex;">
                <div style="min-width: 180px;">
                  <label>厚生年金保険の加入状況</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].explicit__pension.value }}</div>
                </div>
                <div style="min-width: 180px;">
                  <label>理由</label>
                  <div>{{ displayData[0].$join_data.Contract.records[0].notification__pension_reason.value }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="textarea7">派遣労働者からの苦情処理状況</label>
            </div>
            <div class="inputBlock">
              <div>{{ displayData[0].$join_data.Contract.records[0].management__complaint.value }}</div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="textarea7">備考</label>
            </div>
            <div class="inputBlock">
              <div>{{ displayData[0].$join_data.Contract.records[0].management__memo.value }}</div>
            </div>
          </div>

          </div>
        </div>

        <div class="FormGroup" v-if="displayData[0].$join_data.Contract.records[0].Type.value == ''|| displayData[0].$join_data.Contract.records[0].Type.value == '派遣契約'">
          <h3>その他契約書</h3>
          <div class="inputCol inputCol-1">
            <div class="nameBlock">
              <label for="Timesheet">シフト・タイムシート添付用</label>
            </div>
            <div class="inputBlock">
              <div>
                <ul class="filelist">
                  <li>
                    <div v-for="key in displayData[0].$join_data.Contract.records[0]['Timesheet']['value']" :key="key.name">
                      <span style="margin-bottom:0;" @click="downloadFile('Contract',key.fileKey,key.name);">
                      {{key.name}}
                      <img src="../../src/assets/image/dl.png">
                      </span>
                      {{fileKeyDate(key.fileKey)}}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>
        <div class="FormGroup">
          <h3>その他契約書</h3>

          <div class="inputCol inputCol-1">
            <div class="nameBlock">
              <label for="Files">契約書ファイル</label>
            </div>
            <div class="inputBlock">
              <div>
                <ul class="filelist">
                  <li>
                    <div v-for="key in displayData[0].$join_data.Contract.records[0]['Files']['value']" :key="key.name">
                      <span style="margin-bottom:0;" @click="downloadFile('Contract',key.fileKey,key.name);">
                      {{key.name}}
                      <img src="../../src/assets/image/dl.png">
                      </span>
                      {{fileKeyDate(key.fileKey)}}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>

        <div class="FormGroup">
          <h2>担当情報</h2>
            <!-- 会社 -->
            <div class="inputCol inputCol-2">
              <div class="inputCol-2-block">
                <div class="nameBlock">
                  <label for="company">会社</label>
                </div>
                <div class="inputBlock">
                  <div>{{ displayData[0].$join_data.Contract.records[0].company.value }}</div>
                </div>
              </div>
            <!-- 部署 -->
              <div class="inputCol-2-block">
                <div class="nameBlock">
                  <label for="department">部署</label>
                </div>
                <div class="inputBlock">
                  <div>{{ displayData[0].$join_data.Contract.records[0].department.value }}</div>
                </div>
              </div>
            </div>

            <!-- 承認者 -->
            <div class="inputCol inputCol-2">
              <div class="inputCol-2-block">
                <div class="nameBlock">
                  <label for="authorizer">承認者</label>
                </div>
                <div class="inputBlock">
                  <div>{{ displayData[0].$join_data.Contract.records[0].authorizer.value }}</div>
                </div>
              </div>
            <!-- 担当者 -->
              <div class="inputCol-2-block">
                <div class="nameBlock">
                  <label for="manager">担当者</label>
                </div>
                <div class="inputBlock">
                  <div>{{ displayData[0].$join_data.Contract.records[0].manager.value }}</div>
                </div>
              </div>
            </div>

            <!-- 作業者 -->
            <div class="inputCol inputCol-2">
              <div class="inputCol-2-block">
                <div class="nameBlock">
                  <label for="worker">作業者</label>
                </div>
                <div class="inputBlock">
                  <div>{{ displayData[0].$join_data.Contract.records[0].worker.value }}</div>
                </div>
              </div>
            <!-- 作成者 -->
              <div class="inputCol-2-block">
                <div class="nameBlock">
                  <label for="author">作成者</label>
                </div>
                <div class="inputBlock">
                  <div>{{ displayData[0].$join_data.Contract.records[0].author.value }}</div>
                </div>
              </div>
            </div>

        </div>

        <!-- 社内管理情報 -->
        
        <div class="FormGroup">
          <h2>社内管理情報</h2>
            <!-- 社内メモ -->
              <div class="inputCol inputCol-textarea">
                <div class="nameBlock">
                  <label for="internalMemo">社内メモ</label>
                </div>
                <div class="inputBlock">
                  <div>{{ displayData[0].$join_data.Contract.records[0].internalMemo.value }}</div>
                </div>
              </div>

            <!-- 関連ファイル -->
            
          <div class="inputCol inputCol-1">
            <div class="nameBlock">
              <label for="relatedFiles">関連ファイル</label>
            </div>
            <div class="inputBlock">
              <div>
                <ul class="filelist">
                  <li>
                    <div v-for="key in displayData[0].$join_data.Contract.records[0]['relatedFiles']['value']" :key="key.name">
                      <span style="margin-bottom:0;" @click="downloadFile('Contract',key.fileKey,key.name);">
                      {{key.name}}
                      <img src="../../src/assets/image/dl.png">
                      </span>
                      {{fileKeyDate(key.fileKey)}}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>

    </div>
  </div>
  <div class="side-block">
    <div class="item-group">
    <h2>案件</h2>
      <div class="item-group-item" v-if="side_ProjectData.length > 0">
        <ul>
          <li v-for="(item, index) in side_ProjectData" :key="index">
            <div>
              <div>
              <router-link :to="{ name: '案件詳細', params: { id: item.$id.value } }">
              {{ item.Title.value }}
              </router-link>
              </div>
            </div>
            <div>
              <span>{{ item.AnswerStatus.value }}</span>
              <span>{{ item.SendingStatus.value }}</span>
              <span>{{ item.ApprovalStatus.value }}</span>
            </div>
          </li>
        </ul>
        <div class="table-content zisseki">
          <table>
            <thead>
              <th></th>
              <th>売上</th>
              <th>費用</th>
              <th>粗利</th>
            </thead>
            <tbody>
              <tr>
                <td>見込み</td>
                <td>{{ totalSales.toLocaleString() }}円</td>
                <td>{{ totalCost.toLocaleString() }}円</td>
                <td>{{ totalGrossProfit.toLocaleString() }}円</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="item-group">
      <h2>契約</h2>
      <div class="item-group-item border-item" v-if="side_ContractData.length > 0">
        <ul>
          <li v-for="(item, index) in side_ContractData" :key="index">
            <div>
              <div>
              <router-link :to="{ name: '契約詳細', params: { id: item.$id.value } }">
              {{ item.Title.value }}
              </router-link>
              </div>
              <div>( {{ item.$id.value }} )</div>
            </div>
            <div>
              <span>{{ item.AnswerStatus.value }}</span>
              <span>{{ item.SendingStatus.value }}</span>
              <span>{{ item.ApprovalStatus.value }}</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="item-group-item border-item" v-else>
      <p>紐づけられたデータがありません。</p>
      </div>
    </div>

    <div class="item-group">
      <h2>注文</h2>
      <div class="item-group-item border-item" v-if="side_OrderData.length > 0">
        <ul>
          <li v-for="(item, index) in side_OrderData" :key="index">
            <div>
              <div>
              <router-link :to="{ name: '注文詳細', params: { id: item.$id.value } }">
              {{ item.Title.value }}
              </router-link>
              </div>
              <div>[{{ item.Order.value }}]( {{ item.$id.value }} )</div>
            </div>
            <div>
              <span>{{ item.AnswerStatus.value }}</span>
              <span>{{ item.SendingStatus.value }}</span>
              <span>{{ item.ApprovalStatus.value }}</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="item-group-item border-item" v-else>
      <p>紐づけられたデータがありません。</p>
      </div>
    </div>

    <div class="item-group">
      <h2>見積</h2>
      <div class="item-group-item border-item" v-if="side_QuotationData.length > 0">
        <ul>
          <li v-for="(item, index) in side_QuotationData" :key="index">
            <div>
              <div>
              <router-link :to="{ name: '見積詳細', params: { id: item.$id.value } }">
              {{ item.Title.value }}
              </router-link>
              </div>
              <div>[{{ item.Order.value }}]( {{ item.$id.value }} )</div>
            </div>
            <div class="option-block">
              <div>
                <span>{{ item.AnswerStatus.value }}</span>
                <span>{{ item.SendingStatus.value }}</span>
                <span>{{ item.ApprovalStatus.value }}</span>
              </div>
              <div class="amount">
                {{formatNumber(item.tax_included.value)}}円
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="item-group-item border-item" v-else>
      <p>紐づけられたデータがありません。</p>
      </div>
    </div>


    <div class="item-group">
      <h2>請求</h2>
      <div class="item-group-item border-item" v-if="side_ClaimData.length > 0">
        <ul>
          <li v-for="(item, index) in side_ClaimData" :key="index">
            <div>
              <div>
              <router-link :to="{ name: '請求詳細', params: { id: item.$id.value } }">
              {{ item.Title.value }}
              </router-link>
              </div>
              <div>[{{ item.Order.value }}]( {{ item.$id.value }} )</div>
            </div>
            <div class="option-block">
              <div>
                <span>{{ item.AnswerStatus.value }}</span>
                <span>{{ item.SendingStatus.value }}</span>
                <span>{{ item.ApprovalStatus.value }}</span>
                <span class="white">{{ item.SalesStatus.value }}</span>
              </div>
              <div class="amount">
                {{formatNumber(item.item__total.value)}}円
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="item-group-item border-item" v-else>
      <p>紐づけられたデータがありません。</p>
      </div>
    </div>


  </div>
</div>
<div v-if="!dataLoading" class="loading">
  <img src="../assets/image/ZZ5H.gif">
</div>
</template>



<script>

export default {
  data() {
    return {
      tableData: [],

      paymentstableData: [
        { name:"項目名1", sales:"1,000,000", sales_challenge:"1,000,000", cost:"1,000,000", cost_challenge:"1,000,000", grossProfit:"1,000,000", grossProfit_challenge:"1,000,000", },
        { name:"項目名1", sales:"1,000,000", sales_challenge:"1,000,000", cost:"1,000,000", cost_challenge:"1,000,000", grossProfit:"1,000,000", grossProfit_challenge:"1,000,000", },
        { name:"項目名1", sales:"1,000,000", sales_challenge:"1,000,000", cost:"1,000,000", cost_challenge:"1,000,000", grossProfit:"1,000,000", grossProfit_challenge:"1,000,000", },
        { name:"項目名1", sales:"1,000,000", sales_challenge:"1,000,000", cost:"1,000,000", cost_challenge:"1,000,000", grossProfit:"1,000,000", grossProfit_challenge:"1,000,000", },
      ],

      zissakitableData: [
        { name:"見込み", sales:"5,000,000", keihi:"2,000,000", arari:"3,000,000" },
        { name:"見込み(チャレンジ目標)", sales:"6,000,000", keihi:"2,500,000", arari:"3,500,000" },
        { name:"実績", sales:"3,000,000", keihi:"4,000,000", arari:"-1,000,000" },
      ],

      uriagetableData: [
        { name:"請求名サンプル", code:"S-0000000011", client:"取引先名サンプル", manager:"担当者名サンプル", amount:"400,000" },
        { name:"請求名サンプル", code:"S-0000000012", client:"取引先名サンプル", manager:"担当者名サンプル", amount:"400,000" },
        { name:"請求名サンプル", code:"S-0000000013", client:"取引先名サンプル", manager:"担当者名サンプル", amount:"400,000" },
        { name:"請求名サンプル", code:"S-0000000014", client:"取引先名サンプル", manager:"担当者名サンプル", amount:"400,000" },
        { name:"請求名サンプル", code:"S-0000000015", client:"取引先名サンプル", manager:"担当者名サンプル", amount:"400,000" },
        { name:"請求名サンプル", code:"S-0000000016", client:"取引先名サンプル", manager:"担当者名サンプル", amount:"400,000" },
      ],

      displayData: [],

      isDropdownOpen: false,
      options1: ["対応中", "完了", "中止"],
      options2: ["未送付", "送付済み"],
      Label1: "回答",
      Label2: "送付",
      isModalOpen: false,
      isEditFlag: false,

      totalSales: 0,
      totalSalesChallenge: 0,
      totalCost: 0,
      totalCostChallenge: 0,
      totalGrossProfit: 0,
      totalGrossProfitChallenge: 0,

      totalUriageData: 0,
      totalHiyouData: 0,
      totalExpensesData: 0,
      totalApplicantData: 0,
      totalArariData: 0,

      totalAmount: 0,

      dataLoading : false,
      statusData: {
        AnswerStatus: '',
      },
      SendingStatusData: {
        SendingStatus: '',
      },

      isDropdownOpen__answer: '',
      isDropdownOpen__sending: '',

      createRecordId : '',
      ApprovalStatus: false,
      CreateOption:"新規作成",
      ModalOption:"・・・",

      formData: {
        Code: "",
        id:"",
        Type : "契約",
        Title : "",
        Applicant : "",
        ApplicationContents : "",
      },
      currentPage: 1, // 現在のページ
      itemsPerPage: 10, // 1ページあたりのアイテム数
      editLink: '',
      showSections: {
        '労働者派遣個別契約書': false,
        '明示書': false,
        '派遣先への通知': false,
        '派遣元管理台帳': false,
        '派遣先管理台帳': false,
      },

      isModalOpen__change: false,
      isModalOpen__hakko: false,

      Data: {
        id:"",
        Title:"",
        ApprovalStatus:"",
      },
      side_ProjectData: [],
      side_ContractData: [],
      side_OrderData: [],
      side_QuotationData: [],
      side_ClaimData: [],

      AggFlag: true,
    };
  },
  watch: {
    ModalOption(newVal) {
      console.log(newVal);
      const projectId = this.$route.params.id;
      if(newVal === "複製"){
        if (window.confirm('複製しますか？')) {
          this.$router.push({ 
            path: '/ContractCreate',
            query: { record_id: projectId, copy:true } 
          })
        }
        this.ModalOption = "・・・";
      }
      if(newVal === "変更履歴"){
        console.log(newVal)
        this.isModalOpen__change = true;
        this.ModalOption = "・・・";
      }
      if(newVal === "発行履歴"){
        console.log(newVal)
        this.isModalOpen__hakko = true;
        this.ModalOption = "・・・";
      }
    },
    CreateOption(newVal) {
      const projectId = this.displayData[0].$join_data.Contract.records[0].ProjectId.value
      const project_title = this.displayData[0].$join_data.Contract.records[0].ProjectTitle.value
      if(newVal === "注文(受注)"){
        this.$router.push({ 
          name: "注文作成", // 選択されたページの名前
          query: { 
            record_id: '', // クエリパラメータ1
            project_id: projectId,
            project_title : project_title,
            g:'受注',
          }
        });
      }
      if(newVal === "注文(発注)"){
        this.$router.push({ 
          name: "注文作成", // 選択されたページの名前
          query: { 
            record_id: '', // クエリパラメータ1
            project_id: projectId,
            project_title : project_title,
            g:'発注',
          }
        });
      }
    },
  },
  computed: {
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.tableData.slice(startIndex, endIndex);
    },
    paginatedData__Revision(){
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      console.log(this.RevisionData)
      return this.RevisionData.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.tableData.length / this.itemsPerPage);
    },
    login_data:{
      get: function () {
        return this.$store.state.login_data
      },
      set: function (value) {
        this.$store.commit('login_data', value)
      }
    },
  },
  created() {
    this.getData();
    this.getAutorizeData();
    const projectId = this.$route.params.id;
    this.editLink = { path: '/ContractCreate', query: { record_id: projectId , edit: true} }
    
  },
  methods: {
    downloadFile: async function(target,fileKey,fileName){
      let params = {
        target : target,
        fileKey: fileKey,
        fileName:fileName
      }
      let authed = await this.$commonPlugin.axiosAPI('downloadFile',params);
      // console.log(authed);
      this.downloadFiles(authed,fileName)
    },
    // Base64文字列を受け取り、ファイルをダウンロードする関数
    downloadFiles: async function(base64String, fileName) {
      // Base64文字列をデコードしてバイナリデータを取得
      const binaryString = atob(base64String);

      // バイナリデータをUint8Arrayに変換
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      // バイナリデータをBlobオブジェクトに変換
      const blob = new Blob([bytes], { type: 'application/octet-stream' });

      // BlobオブジェクトからURLを生成
      const url = URL.createObjectURL(blob);

      // ダウンロードリンクを生成
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;

      // リンクをクリックしてダウンロードを開始
      link.click();

      // 不要になったURLを解放
      URL.revokeObjectURL(url);
    },
    saveOption__answer: async function(){
      console.log(this.statusData.AnswerStatus);
      let pushArray = [];
      for(let key in this.statusData){
        let data = {};
        data.target = key;
        data.value = this.statusData[key];
        pushArray.push(data);
      }
      let params = {
        target: 'Contract',
        data:JSON.stringify(pushArray),
        querys: JSON.stringify(
            [
              {
                //対象カラム
                column :'$id',
                //比較条件
                cond   :'=',
                //比較内容
                data   :this.$route.params.id,
              }
            ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_create_update_data_for_array_logic',params);
      this.$commonPlugin.dataUpdate(this.formData,authed);
      if(authed.result.response === "失敗"){
        alert('入力内容にエラーが発生しました。');
      }else{
        alert('保存しました。');
        this.getData();
        this.getAutorizeData();
      }
    },
    saveOption__sending: async function(){
      console.log(this.SendingStatusData.SendingStatus);
      let pushArray = [];
      for(let key in this.SendingStatusData){
        let data = {};
        data.target = key;
        data.value = this.SendingStatusData[key];
        pushArray.push(data);
      }
      let params = {
        target: 'Contract',
        data:JSON.stringify(pushArray),
        querys: JSON.stringify(
            [
              {
                //対象カラム
                column :'$id',
                //比較条件
                cond   :'=',
                //比較内容
                data   :this.$route.params.id,
              }
            ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_create_update_data_for_array_logic',params);
      this.$commonPlugin.dataUpdate(this.formData,authed);
      if(authed.result.response === "失敗"){
        alert('入力内容にエラーが発生しました。');
      }else{
        alert('保存しました。');
        this.getData();
        this.getAutorizeData();
      }
    },
    formatNumber(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    selectOption(option) {
      this.selectedOption = option;
    },
    saveOption() {
      this.isDropdownOpen = false;
      console.log('保存されたオプション:', this.selectedOption);
    },
    formatDateTime(dateTimeString) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', timeZoneName: 'short', weekday: 'short' };
      const dateTime = new Date(dateTimeString);
      const stringDate = dateTime.toLocaleString('ja-JP', options);
      if (stringDate === "Invalid Date") {
        return "";
      } else {
        return stringDate.replace("JST", '').replace(',', '');
      }
    },
    toggleDropdown__answer() {
      this.isDropdownOpen__answer = !this.isDropdownOpen__answer;
    },
    toggleDropdown__sending() {
      this.isDropdownOpen__sending = !this.isDropdownOpen__sending;
    },
    isApprovalCheck(){
      const Approval = this.displayData[0].$join_data.Contract.records[0].ApprovalStatus.value
      if(Approval !== "承認済み"){
        this.openModal()
      }
    },
    getData: async function (){
      const projectId = this.$route.params.id;
      console.log(projectId)
      let params = {
        target: 'Matter',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            },
          ]
        ),
        join : JSON.stringify(
          [
            {
              target : 'Contract',
              querys: JSON.stringify(
                [
                  {
                    column : 'DeleteStatus',
                    cond   : 'in',
                    flag   : false,
                    data   : '使用',
                  },
                  {
                    column : '$id',
                    cond   : '=',
                    flag   : false,
                    data   : projectId,
                  }
                ]
              )
            },
            {
              target : 'Revision',
              querys: JSON.stringify(
                [
                  {
                    column : 'targetRecordID',
                    cond   : '=',
                    flag   : false,
                    data   : projectId,
                  },
                  {
                    column : 'APPID',
                    cond   : '=',
                    flag   : false,
                    data   : '67',
                  }
                ],
              )
            },
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.displayData = authed
      console.log(this.displayData[0].$join_data.Revision.records);
      if(this.displayData[0].$join_data.Revision.records){
        this.RevisionComparison(this.displayData[0].$join_data.Revision.records);
      }

      this.statusData.AnswerStatus = this.displayData[0].$join_data.Contract.records[0].AnswerStatus.value
      this.sideProjectID = this.displayData[0].$join_data.Contract.records[0].ProjectId.value
      this.SendingStatusData.SendingStatus = this.displayData[0].$join_data.Contract.records[0].SendingStatus.value
      if(this.displayData[0].$join_data.Contract.records[0].ApprovalStatus.value === "承認済み"){
        this.ApprovalStatus = true
      }
      this.dataLoading = true
      this.getSideBlockContentData();
    },
    RevisionComparison(records) {
        records.forEach(record => {
            if (record.old_text && record.old_text.value.length > 5) {
                const newData = JSON.parse(record.new_text.value);
                const old_text = JSON.parse(record.old_text.value);
                
                    const oldData = old_text.records[0];
                    const changedProperties = this.compareData(oldData, newData);
                    record.changedProperties = changedProperties;
                
            }
        });
        this.RevisionData = records;
    },
    compareData(oldData, newData) {
        const changedProperties = [];
        // oldData と newData の両方に存在するキーを取得
        const commonKeys = oldData ? Object.keys(oldData).filter(key => Object.prototype.hasOwnProperty.call(newData, key)) : [];
        commonKeys.forEach(key => {
            // typeとidは無視する
            if (key === 'type' || key === 'id') {
                return;
            }
            if (Array.isArray(oldData[key])) {
                // 配列の場合、配列の各要素を比較
                const arrayChanges = this.compareArrays(oldData[key], newData[key]);
                if (arrayChanges.length > 0) {
                    // 変更がある場合、変更を記録
                    changedProperties.push({
                        key,
                        changes: arrayChanges
                    });
                }
            } else if (typeof oldData[key] === 'object') {
                // オブジェクトの場合、個々のプロパティを比較
                const objectChanges = this.compareObjects(oldData[key], newData[key]);
                if (objectChanges.length > 0) {
                    // 変更がある場合、変更を記録
                    changedProperties.push({
                        key,
                        changes: objectChanges
                    });
                }
            } else {
                // 配列でもオブジェクトでもない場合は、値が異なる場合のみ変更を記録
                if (JSON.stringify(oldData[key]) !== JSON.stringify(newData[key])) {
                    changedProperties.push({
                        key,
                        old_value: oldData[key],
                        new_value: newData[key]
                    });
                }
            }
        });

        return changedProperties;
    },
    compareArrays(oldArray, newArray) {
        const changes = [];
        // 新しい配列の要素数が古い配列の要素数と異なる場合、変更があるとしてすべての要素を記録
        if (oldArray.length !== newArray.length) {
            for (let i = 0; i < newArray.length; i++) {
                changes.push({
                    index: i,
                    new_value: newArray[i]
                });
            }
            return changes;
        }

        // 各要素を比較
        for (let i = 0; i < oldArray.length; i++) {
            const subChanges = this.compareData(oldArray[i], newArray[i]);
            if (subChanges.length > 0) {
                // 変更がある場合、変更を記録
                changes.push({
                    index: i,
                    changes: subChanges
                });
            }
        }
        return changes;
    },
    compareObjects(oldObject, newObject) {
        const changedProperties = [];
        // 新しいオブジェクトのすべてのキーを走査
        for (const key in newObject) {
            // typeとidは無視する
            if (key === 'type' || key === 'id') {
                continue;
            }
            // キーが古いオブジェクトにも存在する場合、値を比較
            if (Object.prototype.hasOwnProperty.call(oldObject, key)) {
                // オブジェクトまたは配列の場合、再帰的に比較
                if (typeof oldObject[key] === 'object' || Array.isArray(oldObject[key])) {
                    const subChanges = this.compareData(oldObject[key], newObject[key]);
                    if (subChanges.length > 0) {
                        // 変更がある場合、変更を記録
                        changedProperties.push(...subChanges);
                    }
                } else {
                    // オブジェクトでも配列でもない場合は値を比較
                    if (JSON.stringify(oldObject[key]) !== JSON.stringify(newObject[key])) {
                        // 変更がある場合、変更を記録
                        changedProperties.push({
                            key,
                            old_value: oldObject[key],
                            new_value: newObject[key]
                        });
                    }
                }
            } else {
                // キーが古いオブジェクトに存在しない場合、新しい値を記録
                changedProperties.push({
                    key,
                    old_value: undefined,
                    new_value: newObject[key]
                });
            }
        }
        return changedProperties;
    },
    calculateTotals() {
      console.log(this.side_ProjectData[0])
      this.totalSales = this.side_ProjectData[0].prospect.value.reduce((total, item) => total + parseFloat(item.value.sales.value.replace(/,/g, '')), 0);
      this.totalCost = this.side_ProjectData[0].prospect.value.reduce((total, item) => total + parseFloat(item.value.cost.value.replace(/,/g, '')), 0);
      this.totalSalesChallenge = this.side_ProjectData[0].prospect.value.reduce((total, item) => total + parseFloat(item.value.sales_challenge.value.replace(/,/g, '')), 0);
      this.totalCost = this.side_ProjectData[0].prospect.value.reduce((total, item) => total + parseFloat(item.value.cost.value.replace(/,/g, '')), 0);
      this.totalCostChallenge = this.side_ProjectData[0].prospect.value.reduce((total, item) => total + parseFloat(item.value.cost_challenge.value.replace(/,/g, '')), 0);
      this.totalGrossProfit = this.side_ProjectData[0].prospect.value.reduce((total, item) => total + parseFloat(item.value.grossProfit.value.replace(/,/g, '')), 0);
      this.totalGrossProfitChallenge = this.side_ProjectData[0].prospect.value.reduce((total, item) => total + parseFloat(item.value.grossProfit_challenge.value.replace(/,/g, '')), 0);
    },
    calculateDifference(itemName1, itemName2, property) {
      const num1 = parseFloat(this.zissakitableData.find((item) => item.name === itemName1)[property].replace(/,/g, ''));
      const num2 = parseFloat(this.zissakitableData.find((item) => item.name === itemName2)[property].replace(/,/g, ''));
      const difference = num1 - num2;
      // 正か負かを判別して、+か-を表示
      if (difference > 0) {
        return `+${difference.toLocaleString()}`;
      } else if (difference < 0) {
        return `-${Math.abs(difference).toLocaleString()}`;
      } else {
        return '±0'; // 差額が0の場合
      }
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    saveAuthorize() {
      console.log("承認依頼");
    },
    editButton() {
      this.isEditFlag = true;
    },
    submitButton() {
      this.isEditFlag = false;
    },
    openModal() {
      this.isModalOpen = true; // モーダルを表示
    },
    closeModal() {
      this.isModalOpen = false; // モーダルを非表示
      this.isModalOpen__change = false;
      this.isModalOpen__hakko = false;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    getAutorizeData: async function (){
      const projectId = this.$route.params.id;
      let params = {
        target: 'Agreement',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            },
            {
              column :'Type',
              cond   : '=',
              flag   : false,
              data   : '契約',
            },
            {
              column :'Code',
              cond   : '=',
              flag   : false,
              data   : projectId,
            }
          ]
        )
      };
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.tableData = authed.records
      var approvalRequestFound = true;
      for (let i = 0; i < this.tableData.length; i++) {
        console.log(this.tableData[i]);
        if (this.tableData[i].ApplicationContents.value == '承認依頼') {
          approvalRequestFound = true;
        }
        if (approvalRequestFound && this.tableData[i].ApprovalResults.value !== '承認済み') {
          this.AggFlag = false;
          return;
        }
      }
    },
    submitForm(value) {
      this.formData.ApplicationContents = value
      this.formData.Code = this.$route.params.id;
      this.formData.id = this.$route.params.id;
      this.formData.Title = this.displayData[0].$join_data.Contract.records[0].Title.value
      this.createFormMethod();
    },
    createFormMethod: async function(){
      this.formData.Applicant = this.login_data.Names.value
      console.log(this.formData);
      let pushArray = [];
      for(let key in this.formData){
        let data = {};
        data.target = key;
        data.value = this.formData[key];
        pushArray.push(data);
      }
      let params = {
        target: 'Agreement',
        data:JSON.stringify(pushArray),
        querys: JSON.stringify(
            [
              {
                //対象カラム
                column :'DeleteStatus',
                //比較条件
                cond   :'in',
                //比較内容
                data   : '使用',
              },
              {
                //対象カラム
                column :'$id',
                //比較条件
                cond   :'=',
                //比較内容
                data   :this.createRecordId,
              }
            ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_create_update_data_for_array_logic',params);
      this.$commonPlugin.dataUpdate(this.formData,authed,this.PurchaseAmountTemplate);
      this.createFormMethod__Data();
    },
    createFormMethod__Data: async function(){
      this.Data.id = this.$route.params.id;
      this.Data.Title = this.displayData[0].$join_data.Contract.records[0].Title.value
      this.Data.ApprovalStatus = "申請中"
      let pushArray = [];
      for(let key in this.Data){
        let data = {};
        data.target = key;
        data.value = this.Data[key];
        pushArray.push(data);
      }
      let params = {
        target: 'Contract',
        data:JSON.stringify(pushArray),
        querys: JSON.stringify(
            [
              {
                //対象カラム
                column :'$id',
                //比較条件
                cond   :'=',
                //比較内容
                data   :this.$route.params.id,
              }
            ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_create_update_data_for_array_logic',params);
      this.$commonPlugin.dataUpdate(this.formData,authed,this.PurchaseAmountTemplate);
      if(authed.result.response === "失敗"){
        alert('入力内容にエラーが発生しました。');
      }else{
        alert('保存しました。');
        window.location.reload();
      }
    },
    toggleSection(sectionName) {
      this.showSections[sectionName] = !this.showSections[sectionName];
    },
    getSideBlockContentData: async function (){
      const projectId = this.displayData[0].$join_data.Contract.records[0].ProjectId.value
      console.log(projectId)
      let params = {
        target: 'ProjectDetails',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            },
            {
              column :'$id',
              cond   : '=',
              flag   : false,
              data   : projectId,
            }
          ]
        )
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.side_ProjectData = authed.records

      params = {
        target: 'Contract',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            },
            {
              column :'ProjectId',
              cond   : '=',
              flag   : false,
              data   : projectId,
            }
          ]
        )
      };
      console.log(params)
      authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.side_ContractData = authed.records

      params = {
        target: 'Order',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            },
            {
              column :'projectId',
              cond   : '=',
              flag   : false,
              data   : projectId,
            }
          ]
        )
      };
      console.log(params)
      authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.side_OrderData = authed.records

      params = {
        target: 'Quotation',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            },
            {
              column :'ProjectId',
              cond   : '=',
              flag   : false,
              data   : projectId,
            }
          ]
        )
      };
      console.log(params)
      authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.side_QuotationData = authed.records

      params = {
        target: 'Claim',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            },
            {
              column :'ProjectId',
              cond   : '=',
              flag   : false,
              data   : projectId,
            }
          ]
        )
      };
      console.log(params)
      authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.side_ClaimData = authed.records
      this.calculateTotals();
    },
    fileKeyDate(value){
      const Y = value.substr( 0, 4 );
      const M = value.substr( 4, 2 );
      const D = value.substr( 6, 2 );
      const H = value.substr( 8, 2 );
      const S = value.substr( 10, 2 );
      const val = Y + "-" + M + "-" + D + "T" + H + ":" + S + ":00Z";
      const aaa = this.formatDateTime(val);
      return aaa;
    },
    nl2br(text) {
      return text.replace(/\n/g, '<br>')
    }
  },
  components: {
  },
};

</script>
