<template>
  <div class="sideMenu">
    <nav>
      <div class="menuBlock">
      <hr style="margin-top:8px;">
      <div><router-link to="/Top"><img src="../../../src/assets/image/menu_icon_top.png"><label>トップ</label></router-link></div>
      <hr>
      <div @click="toggleSection('案件')" class="toggleMenu">
        <div>
          <img src="../../../src/assets/image/menu_icon_anken_active.png" >案件
        </div>
        <div>
          <img class="toggleButton" src="../../../src/assets/image/Expand_Arrow.png" v-if="showSections['案件']">
          <img class="toggleButton_1" src="../../../src/assets/image/Expand_Arrow.png" v-if="!showSections['案件']">
        </div>
      </div>
      <div v-if="showSections['案件']" class="toggleContent">
        <div><router-link to="/Project" @click="setData('Project')">案件一覧</router-link></div>
        <div><router-link :to="{ path: '/ProjectCreate', query: { record_id: '' } }" @click="setData('ProjectCreate',{ record_id: '' })">新規作成</router-link></div>
      </div>

      <div @click="toggleSection('契約')" class="toggleMenu">
        <div>
          <img src="../../../src/assets/image/menu_icon_keiyaku.png">契約
        </div>
        <div>
          <img class="toggleButton" src="../../../src/assets/image/Expand_Arrow.png" v-if="showSections['契約']">
          <img class="toggleButton_1" src="../../../src/assets/image/Expand_Arrow.png" v-if="!showSections['契約']">
        </div>
      </div>
      <div v-if="showSections['契約']" class="toggleContent">
        <div><router-link to="/Contract" @click="setData('Contract')">契約一覧</router-link></div>
        <div><router-link :to="{ path: '/ContractCreate', query: { record_id: '' } }" @click="setData('ContractCreate',{ record_id: '' })">派遣契約作成</router-link></div>
        <div><router-link :to="{ path: '/sub_ContractCreate', query: { record_id: '',type: '基本契約' } }" @click="setData('ContractCreate',{ record_id: '' })">基本契約作成</router-link></div>
        <div><router-link :to="{ path: '/sub_ContractCreate', query: { record_id: '',type: '個別契約' } }" @click="setData('ContractCreate',{ record_id: '' })">個別契約作成</router-link></div>
        <div><router-link :to="{ path: '/sub_ContractCreate', query: { record_id: '',type: 'その他' } }" @click="setData('ContractCreate',{ record_id: '' })">その他契約作成</router-link></div>
      </div>

      <div @click="toggleSection('注文')" class="toggleMenu">
        <div>
          <img src="../../../src/assets/image/menu_icon_tyumon.png">注文
        </div>
        <div>
          <img class="toggleButton" src="../../../src/assets/image/Expand_Arrow.png" v-if="showSections['注文']">
          <img class="toggleButton_1" src="../../../src/assets/image/Expand_Arrow.png" v-if="!showSections['注文']">
        </div>
      </div>
      <div v-if="showSections['注文']" class="toggleContent">
        <div><router-link to="/Order"  @click="setData('Order')">注文一覧</router-link></div>
        <div><router-link :to="{ path: '/OrderCreate', query: { record_id: ''  } }" @click="setData('OrderCreate',{ record_id: '' })">新規作成</router-link></div>
      </div>


      <div @click="toggleSection('見積')" class="toggleMenu">
        <div>
          <img src="../../../src/assets/image/menu_icon_mitsumori_active.png">見積
        </div>
        <div>
          <img class="toggleButton" src="../../../src/assets/image/Expand_Arrow.png" v-if="showSections['見積']">
          <img class="toggleButton_1" src="../../../src/assets/image/Expand_Arrow.png" v-if="!showSections['見積']">
        </div>
      </div>
      <div v-if="showSections['見積']" class="toggleContent">
        <div><router-link to="/Quotation" @click="setData('Quotation')">見積一覧</router-link></div>
        <div><router-link :to="{ path: '/QuotationCreate', query: { record_id: ''  } }" @click="setData('QuotationCreate',{ record_id: '' })">新規作成</router-link></div>
      </div>


      <div @click="toggleSection('請求')" class="toggleMenu">
        <div>
          <img src="../../../src/assets/image/menu_icon_seikyu_active.png">請求
        </div>
        <div>
          <img class="toggleButton" src="../../../src/assets/image/Expand_Arrow.png" v-if="showSections['請求']">
          <img class="toggleButton_1" src="../../../src/assets/image/Expand_Arrow.png" v-if="!showSections['請求']">
        </div>
      </div>
      <div v-if="showSections['請求']" class="toggleContent">
        <div><router-link to="/Claim" @click="setData('Claim')">請求一覧</router-link></div>
        <div><router-link :to="{ path: '/ClaimCreate', query: { record_id: ''  } }" @click="setData('ClaimCreate',{ record_id: '' })">新規作成</router-link></div>
      </div>



      <div @click="toggleSection('経費')" class="toggleMenu">
        <div>
          <img src="../../../src/assets/image/Cost.png">経費
        </div>
        <div>
          <img class="toggleButton" src="../../../src/assets/image/Expand_Arrow.png" v-if="showSections['経費']">
          <img class="toggleButton_1" src="../../../src/assets/image/Expand_Arrow.png" v-if="!showSections['経費']">
        </div>
      </div>
      <div v-if="showSections['経費']" class="toggleContent">
        <div><router-link to="/Expenses" @click="setData('Expenses')">経費一覧</router-link></div>
        <div><router-link :to="{ path: '/ExpensesCreate', query: { record_id: ''  } }" @click="setData('ExpensesCreate',{ record_id: '' })">新規作成</router-link></div>
      </div>



      <div @click="toggleSection('稟議')" class="toggleMenu">
        <div>
          <img src="../../../src/assets/image/Reserve.png">稟議
        </div>
        <div>
          <img class="toggleButton" src="../../../src/assets/image/Expand_Arrow.png" v-if="showSections['稟議']">
          <img class="toggleButton_1" src="../../../src/assets/image/Expand_Arrow.png" v-if="!showSections['稟議']">
        </div>
      </div>
      <div v-if="showSections['稟議']" class="toggleContent">
        <div><router-link to="/Approval" @click="setData('Approval')">稟議一覧</router-link></div>
        <div><router-link :to="{ path: '/ApprovalCreate', query: { record_id: ''  } }" @click="setData('ApprovalCreate',{ record_id: '' })">新規作成</router-link></div>
      </div>

      <hr>

      <div class="toggleMenu option">
      <div><router-link to="/settingPage" class="menulink"><img src="../../../src/assets/image/menu_icon_settei.png">設定</router-link></div>
      </div>

      <div class="toggleMenu option">
      <div><router-link to="logout" class="menulink" @click="logout()"><img src="../../../src/assets/image/menu_icon_logout.png">ログアウト</router-link></div>
      </div>


      </div>
      <div class="closeBlock">
        <div>
          <router-link to="/Top">
            <img src="../../../src/assets/image/menu_icon_top.png">
          </router-link>
          <p>トップ</p>
        </div>
        <hr style="margin-left: 22px;">
        <div>
          <router-link to="/Project">
            <img src="../../../src/assets/image/menu_icon_anken_active.png">
          </router-link>
          <p>案件</p>
        </div>
        <div>
          <router-link to="/Contract">
            <img src="../../../src/assets/image/menu_icon_keiyaku.png">
          </router-link>
          <p>契約</p>
        </div>
        <div>
          <router-link to="/Order">
            <img src="../../../src/assets/image/menu_icon_tyumon.png">
          </router-link>
          <p>注文</p>
        </div>
        <div>
          <router-link to="/Quotation">
            <img src="../../../src/assets/image/menu_icon_mitsumori_active.png">
          </router-link>
          <p>見積</p>
        </div>
        <div>
          <router-link to="/Claim">
            <img src="../../../src/assets/image/menu_icon_seikyu_active.png">
          </router-link>
          <p>請求</p>
        </div>
        <div>
          <router-link to="/Expenses">
            <img src="../../../src/assets/image/Cost.png">
          </router-link>
          <p>経費</p>
        </div>
        <div>
          <router-link to="/Approval">
            <img src="../../../src/assets/image/Reserve.png">
          </router-link>
          <p>稟議</p>
        </div>
        <hr style="margin-left: 22px;">
        <div>
          <router-link to="/設定">
            <img src="../../../src/assets/image/menu_icon_settei.png">
          </router-link>
          <p>設定</p>
        </div>
        <div>
          <router-link to="/設定" @click="logout()">
            <img src="../../../src/assets/image/menu_icon_logout.png">
          </router-link>
          <p>ログアウト</p>
        </div>
      </div>


      
    
<!-- <div><button @click="createTest">作成テスト</button></div> -->
<!-- <div><input type="file" multiple @change="handleFileChange"/><button @click="createFileTest">ファイル</button></div> -->

    </nav>
  </div>
</template>

<script>
export default {
  name: 'SideMenu',
  components: {
  },
  created() {
   this.m_data_method();
  },
  data() {
    return {
      showSections: {
        '案件': false,   // 最初は表示
        '契約': false,
        '注文': false,
        '見積': false,
        '請求': false,
        '経費': false,
        '稟議': false,
        '新規作成': false,
      },
      files: null,
      DBtarget:{
        Company    :'CompanyName',
        Department :'Department_name',
        Employee   :'Names',
      },
    };
  },
  computed: {
    isActivePage() {
      return (
          this.$route.name === 'Project' ||
          this.$route.name === 'ProjectDetails'
          // 他の条件を追加
      );
    },
    m_data:{
      get: function () {
        return this.$store.state.m_data
      },
      set: function (value) {
        this.$store.commit('m_data', value)
      }
    },
    m_data_option:{
      get: function () {
        return this.$store.state.m_data_option;
      },
      set: function (value) {
        this.$store.commit('m_data_option', value)
      }
    }
  },
  methods:{
    setData(page,query){
      this.setStrage('loginTimes',new Date());
      this.setStrage('pages',page);
      this.setStrage('query',JSON.stringify(query));
      this.setStrage('id',undefined);
    },
    getStrage(target){
      return localStorage.getItem(target);
    },
    setStrage(target,value){
      localStorage.setItem(target,value);
    },
    m_data_method: async function(){
      for (const DBkey in this.DBtarget) {
        this.m_get_data(DBkey);
      }
    },
    createOption:function(data){
      let result_data = [] ;
      for (const target in data) {
        for(let i = 0; i < data[target].length; i++){

          let dataset = {
            value :data[target][i][this.DBtarget[target]].value,
            label :data[target][i][this.DBtarget[target]].value,
          };
          result_data[i] = dataset
        }
      }
     return result_data
    },
    m_get_data : async function (target){
      let params = {
        target: target,
        querys: JSON.stringify(
            [
              {
                //対象カラム
                column: 'DeleteStatus',
                //比較条件
                cond: 'in',
                //比較内容
                data: '使用',
              }
            ]
        // join :''
        ),
      };
      // console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      // console.log(authed)
      if(authed.records){
        this.m_data[target] = authed.records;
        let dataset = {};
            dataset[target] = authed.records;
        this.m_data_option[target] = this.createOption(dataset);
      }
    },
    test: async function (){
      let params = {
        target: 'Matter',
        querys: JSON.stringify(
            [
                {
              //対象カラム
              column :'DeleteStatus',
              //比較条件
              cond   :'in',
              //比較内容
              data   :'使用',
            }
          ]
        ),
        join : JSON.stringify(
            [
                /**
                 *企業データ取得
                 */
              {
                target : 'Client',
                querys: JSON.stringify(
                  [
                    {
                      //対象カラム
                      column : 'DeleteStatus',
                      //比較条件
                      cond   : 'in',
                      //比較内容をｶﾗﾑ参照とする場合true
                      flag   : false,
                      data   : '使用',
                    },
                    {
                      //対象カラム
                      column : 'record_id',
                      //比較条件
                      cond   : '=',
                      //比較内容をｶﾗﾑ参照とする場合true
                      flag   : true,
                      data   : 'BusinessId',
                    }
                  ]
                )
              },
              /**
               * 案件詳細取得
               */
              {
                target : 'ProjectDetails',
                querys: JSON.stringify(
                    [
                      {
                        //対象カラム
                        column : 'DeleteStatus',
                        //比較条件
                        cond   : 'in',
                        //比較内容をｶﾗﾑ参照とする場合true
                        flag   : false,
                        data   : '使用',
                      },
                      {
                        //対象カラム
                        column : 'record_id',
                        //比較条件
                        cond   : '=',
                        //比較内容をｶﾗﾑ参照とする場合true
                        flag   : true,
                        data   : 'ProjectDetailsID',
                      }
                    ]
                )
              },
              /**
               * 関連ファイル取得
               */
              {
                target : 'File',
                querys: JSON.stringify(
                    [
                      {
                        //対象カラム
                        column : 'DeleteStatus',
                        //比較条件
                        cond   : 'in',
                        //比較内容をｶﾗﾑ参照とする場合true
                        flag   : false,
                        data   : '使用',
                      },
                      {
                        //対象カラム
                        column : 'record_id',
                        //比較条件
                        cond   : '=',
                        //比較内容をｶﾗﾑ参照とする場合true
                        flag   : true,
                        data   : 'RelatedFileId',
                      }
                    ]
                )
              }
            ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
    },
    createFileTest: async function(){
      let params = {
        target  : 'File',
        data    :JSON.stringify([
          {
            target : 'fileTitle',
            value  : 'テストファイル',
          }
        ]),
        querys: JSON.stringify(
            [
              {
                //対象カラム
                column : 'DeleteStatus',
                //比較条件
                cond   : 'in',
                //比較内容をｶﾗﾑ参照とする場合true
                flag   : false,
                data   : '使用',
              }
            ]
        ),
        filesTarget : 'File',
        files       : (this.files)?this.files : ''
      }
      console.log(this.files)
      let response = '';
      // if(this.isFileList(this.files)){
        response = await this.$commonPlugin.axiosFileMultiAPI('file_upload',params);
      // }else{
      //   response = await this.$commonPlugin.axiosFileAPI('file_upload',params);
      // }

      console.log(response)
    },
    isFileList :function (object) {
      return object instanceof FileList;
    },
    handleFileChange(event) {
      // 選択されたファイルの情報を取得
      const file = event.target.files;
      // モデルを更新
      this.files = file;
    },
    createTest: async function(){
      let params = {
        target: 'Matter',
        data:JSON.stringify([
          {
            target : 'ProjectDetailsID',
            value  : "2",
          },{
            target : 'DeleteStatus',
            value  : '使用',
          }
        ]),
        querys: JSON.stringify(
            [
              {
                //対象カラム
                column :'DeleteStatus',
                //比較条件
                cond   :'in',
                //比較内容
                data   : '使用',
              },
              {
                //対象カラム
                column :'record_id',
                //比較条件
                cond   :'=',
                //比較内容
                data   : 9,
              }
            ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_create_update_data_for_array_logic',params);
      console.log(authed)
    },
    logout(){
      if(!confirm('ログアウトしますがよろしいですか？')){
        return false;
      }
      this.removeStrage('loginTimes');
      this.removeStrage('login_data');
      this.$router.push({ name: 'ログイン' });
    },
    removeStrage(target){
      localStorage.removeItem(target);
    },
    toggleSection(sectionName) {
      this.showSections[sectionName] = !this.showSections[sectionName];
    },

  },

}
</script>

<style lang="scss">
  .sideMenu{
    position: sticky;
    top:0;
  }
  .pageHeader{
    position: sticky;
    top:0;
    z-index: 2;
  }
</style>