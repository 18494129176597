
<template>
<div class="pageHeader">
  <h1>経費一覧</h1>
  <router-link :to="{ path: '/ExpensesCreate', query: { record_id: '' } }">
    <button class="normalButton">新規作成</button>
  </router-link>
</div>
  <div class="content-block">
    <div class="searchBox">
      <div class="searchBox-content">
        <h2>経費情報</h2>
        <button class="toggleSection" @click="toggleSection('案件情報')">
          <img class="toggleButton" src="../assets/image/Expand_Arrow.png" v-if="showSections['案件情報']">
          <img class="toggleButton_1" src="../assets/image/Expand_Arrow.png" v-if="!showSections['案件情報']">
        </button>
        <div class="searchBox-content-block" v-if="showSections['案件情報']">
          <div style="display: flex; margin-bottom: 0;">
            <div>
              <label>経費コード</label>
              <input type="text" placeholder="検索" v-model="searchCode" />
            </div>
            <div>
              <label>件名</label>
              <input type="text" placeholder="検索" v-model="searchName" style="width: 300px;"/>
            </div>
          </div>
          <div style="display: flex; margin-bottom: 0;">
            <div>
              <label>申請日時</label>
              <input type="date" v-model="StartDate__start"/> ~
              <input type="date" v-model="StartDate__end"/>
            </div>
          </div>
        </div>
      </div>
      <div class="searchBox-content">
        <h2>担当情報</h2>
        <button class="toggleSection" @click="toggleSection('担当情報')">
          <img class="toggleButton" src="../assets/image/Expand_Arrow.png" v-if="showSections['担当情報']">
          <img class="toggleButton_1" src="../assets/image/Expand_Arrow.png" v-if="!showSections['担当情報']">
        </button>
        <div class="searchBox-content-block" v-if="showSections['担当情報']">
          <div>
            <label>申請者</label>
              <select v-model="searchManager">
                <option></option>
                <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
              </select>
          </div>
          <div>
            <label>承認者</label>
              <select v-model="searchAuthorizer">
                <option></option>
                <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
              </select>
          </div>
        </div>
      </div>
      <div class="searchBox-content">
        <h2>ステータス</h2>
        <button class="toggleSection" @click="toggleSection('ステータス')">
          <img class="toggleButton" src="../assets/image/Expand_Arrow.png" v-if="showSections['ステータス']">
          <img class="toggleButton_1" src="../assets/image/Expand_Arrow.png" v-if="!showSections['ステータス']">
        </button>
        <div class="searchBox-content-block" v-if="showSections['ステータス']">
          <div>
            <label>承認結果</label>
              <select v-model="searchAuthorize">
                <option></option>
                <option v-for="(option, index) in authorizeSelectOption" :key="index" :value="option.value">{{ option.label }}</option>
              </select>
          </div>
          <div>
            <label>対応状況</label>
              <select v-model="searchAnswer">
                <option></option>
                <option v-for="(option, index) in answerSelectOption" :key="index" :value="option.value">{{ option.label }}</option>
              </select>
          </div>
        </div>
      </div>
    </div>
    <div class="clearButtonBlock">
      <button @click="inputClear" class="clearButton normalButton">絞り込み条件をリセット</button>
    </div>
    <div class="listBox">
      <table style="width:1200px;" v-if= "paginatedData.length > 0">
        <thead>
          <tr>
            <th @click="sortTable('$id')">経費コード
              <span v-if="sortKey === '$id'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('subject')">件名
              <span v-if="sortKey === 'subject'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('author')">申請者
              <span v-if="sortKey === 'author'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('Date')">申請日時
              <span v-if="sortKey === 'Date'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('authorizer')">承認者
              <span v-if="sortKey === 'authorizer'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('ApprovalStatus')">承認結果
              <span v-if="sortKey === 'ApprovalStatus'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('AnswerStatus')">対応状況
              <span v-if="sortKey === 'AnswerStatus'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in paginatedData" :key="index">
            <td>{{ item.$id.value }}</td>
            <td>
            <router-link :to="{ name: '経費詳細', params: { id: item.$id.value } }" @click="setData('ExpensesDetails',item.$id.value)">
              {{ item.subject.value }}
            </router-link>
            </td>
            <td>{{ item.author.value }}</td>
            <td>{{ item.Date.value }}</td>
            <td>{{ item.authorizer.value }}</td>
            <td>{{ item.ApprovalStatus.value }}</td>
            <td>{{ item.AnswerStatus.value }}</td>
          </tr>
        </tbody>
      </table>
      <div v-else>
        <p class="notFound">一覧に表示できるデータがありません</p>
      </div>
    </div>
      <div class="pagination">
      <span>{{ currentPage }} / {{ totalPages }}</span>
        <img class="arrowButton" src="../assets/image/Expand_Arrow.png" @click="prevPage" :disabled="currentPage === 1">
        <img class="arrowButton" src="../assets/image/Expand_Arrow2.png" @click="nextPage" :disabled="currentPage * itemsPerPage >= totalFilteredItems" >
      </div>
  </div>
</template>

<script>

export default {
  components: {

  },
  data() {
    return {
      showSections: {
        '案件情報': true,   // 最初は表示
        '担当情報': false,  // 最初は非表示
        'ステータス': false,  // 最初は非表示
      },
      tableData: [],

      clientSelectOption: [],

      companySelectOption: [],
    
      departmentSelectOption: [],

      EmployeeSelectOption: [],

      authorizeSelectOption: [
        { value: '承認済み', label: '承認済み' },
        { value: '申請中', label: '申請中' },
        { value: '未承認', label: '未承認' },
      ],

      answerSelectOption: [
        { value: '対応中', label: '対応中' },
        { value: '精算済み', label: '精算済み' },
        { value: '中止', label: '中止' },
      ],

      AccountSelectOption: [
        { value: '社員福利厚生費', label: '社員福利厚生費' },
        { value: '広告宣伝費', label: '広告宣伝費' },
        { value: '募集費', label: '募集費' },
        { value: '地代家賃', label: '地代家賃' },
        { value: '共益費', label: '共益費' },
        { value: '通信費', label: '通信費' },
        { value: '旅費交通費', label: '旅費交通費' },
        { value: '支払手数料', label: '支払手数料' },
        { value: '車両関連費', label: '車両関連費' },
        { value: '租税公課', label: '租税公課' },
        { value: '消耗品費', label: '消耗品費' },
        { value: '減価償却費', label: '減価償却費' },
        { value: 'リース料', label: 'リース料' },
        { value: '水道光熱費', label: '水道光熱費' },
        { value: '会議費', label: '会議費' },
        { value: '荷造運賃', label: '荷造運賃' },
        { value: '接待交際費', label: '接待交際費' },
        { value: '修繕費', label: '修繕費' },
        { value: '新聞図書費', label: '新聞図書費' },
        { value: '諸会費', label: '諸会費' },
        { value: '賃借料', label: '賃借料' },
        { value: '保険料', label: '保険料' },
        { value: '雑費', label: '雑費' },
        { value: '外注工賃', label: '外注工賃' },
      ],




      // 以下はフィルタリングやページネーションに使用するデータ
      searchCode: "",
      searchName: "",
      searchClient: "",
      searchCompany: "",
      searchDepartment: "",
      searchAuthorizer: "",
      searchManager: "",
      searchWorker: "",
      searchAuthor: "",
      searchAuthorize: "",
      searchAnswer: "",
      searchAccount: "",
      StartDate__start: "",
      StartDate__end: "",

      minEarningsProspect: "",
      maxEarningsProspect: "",
      minEarningsAchievements: "",
      maxEarningsAchievements: "",

      sortKey: "code",
      sortDesc: false,
      currentPage: 1,
      itemsPerPage: 10,
    };
  },
  computed: {
    // フィルタリングされたデータを計算
    filteredTableData() {
      let filteredData = this.tableData.filter((item) => {
        const startDate = new Date(this.StartDate__start);
        const endDate = new Date(this.StartDate__end);
        const itemDate = new Date(item.Date.value);
        return (
          (this.searchCode === "" || item.$id.value.includes(this.searchCode)) &&
          (this.searchName === "" || item.subject.value.includes(this.searchName)) &&
          (this.searchCompany === "" || item.company === this.searchCompany) &&
          (this.searchDepartment === "" || item.department === this.searchDepartment) &&
          (this.searchAuthorizer === "" || item.authorizer.value === this.searchAuthorizer) &&
          (this.searchManager === "" || item.author.value === this.searchManager) && 
          (this.searchWorker === "" || item.worker.value === this.searchWorker) && 
          (this.searchAuthor === "" || item.author.value === this.searchAuthor) && 
          (this.searchAuthorize === "" || item.ApprovalStatus.value === this.searchAuthorize) && 
          (this.searchAnswer === "" || item.AnswerStatus.value === this.searchAnswer) && 
          (this.searchAccount === "" || item.PurchaseAmount.value[0].value.Account.value.includes(this.searchAccount)) && 
          (isNaN(startDate) || itemDate >= startDate) && // 開始日が未指定または作成日時が開始日以降
          (isNaN(endDate) || itemDate <= endDate) && // 終了日が未指定または作成日時が終了日以前
          this.isAmountInRange(
            item.Amount,
            this.minEarningsProspect,
            this.maxEarningsProspect
          )
        );
      });

        let sortkey = this.sortKey;
        // ソート
        if(filteredData){
          if (this.sortDesc) {
            filteredData.sort(function(a,b){
              if(!a[sortkey] || !b[sortkey]){return false}
              let aData = (!isNaN(a[sortkey]['value']))?Number(a[sortkey]['value']):a[sortkey]['value'];
              let bData = (!isNaN(b[sortkey]['value']))?Number(b[sortkey]['value']):b[sortkey]['value'];
              return (aData > bData)?1:-1
            })
          } else {
            filteredData.sort(function(a,b){
              if(!a[sortkey] || !b[sortkey]){return false}
              let aData = (!isNaN(a[sortkey]['value']))?Number(a[sortkey]['value']):a[sortkey]['value'];
              let bData = (!isNaN(b[sortkey]['value']))?Number(b[sortkey]['value']):b[sortkey]['value'];
              return (aData < bData)?1:-1
            })
          }
        }
        console.log(filteredData)
        return filteredData;
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filteredTableData.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.filteredTableData.length / this.itemsPerPage);
    },
  },
  created() {
    this.getTableData();
    this.getClientData();
    this.getCompanyData();
    this.getDepartmentData();
    this.getEmployeeData();
  },
  mounted() {
    this.getTableData();
    this.getClientData();
    this.getCompanyData();
    this.getDepartmentData();
    this.getEmployeeData();
  },
  methods: {
    getTableData: async function (){
      let params = {
        target: 'Expenses',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      this.tableData = authed.records;
      console.log(authed)
    },
    getClientData: async function (){
      let params = {
        target: 'Client',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.clientSelectOption = authed.records;
    },
    getCompanyData: async function (){
      let params = {
        target: 'Company',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.companySelectOption = authed.records;
    },
    getDepartmentData: async function (){
      let params = {
        target: 'Department',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.departmentSelectOption = authed.records;
    },
    getEmployeeData: async function (){
      let params = {
        target: 'Employee',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.EmployeeSelectOption = authed.records;
    },
    isAmountInRange(amount, minAmount, maxAmount) {
      const numericAmount = parseFloat(amount);
      if (isNaN(numericAmount)) {
        return true; // amountが数値でない場合はフィルタリングせずに表示
      }

      if (minAmount !== "" && numericAmount < parseFloat(minAmount)) {
        return false;
      }
      if (maxAmount !== "" && numericAmount > parseFloat(maxAmount)) {
        return false;
      }

      return true;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    sortTable(key) {
      if (this.sortKey === key) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortKey = key;
        this.sortDesc = false;
      }
    },
    toggleSection(sectionName) {
      this.showSections[sectionName] = !this.showSections[sectionName];
    },
    formatNumber(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    inputClear(){
      this.searchCode = "";
      this.searchName = "";
      this.searchClient = "";
      this.searchCompany = "";
      this.searchDepartment = "";
      this.searchAuthorizer = "";
      this.searchManager = "";
      this.searchWorker = "";
      this.searchAuthor = "";
      this.searchAuthorize = "";
      this.searchAnswer = "";

      this.searchAccount = "";

      this.StartDate__start = "";
      this.StartDate__end = "";
      this.minEarningsProspect = "";
      this.maxEarningsProspect = "";
      this.minEarningsAchievements = "";
      this.maxEarningsAchievements = "";
    },
    setData(page,id){
      this.setStrage('loginTimes',new Date());
      this.setStrage('pages',page);
      this.setStrage('id',id);
    },
    setStrage(target,value){
      localStorage.setItem(target,value);
    },
  },
};
</script>