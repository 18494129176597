
<template>
<div class="pageHeader">
  <h1>稟議一覧</h1>
  <router-link :to="{ path: '/ApprovalCreate', query: { record_id: '16' } }">
    <button class="normalButton">新規作成</button>
  </router-link>
</div>
  <div class="content-block">
    <div class="searchBox">
      <div class="searchBox-content">
        <h2>稟議情報</h2>
        <button class="toggleSection" @click="toggleSection('案件情報')">
          <img class="toggleButton" src="../assets/image/Expand_Arrow.png" v-if="showSections['案件情報']">
          <img class="toggleButton_1" src="../assets/image/Expand_Arrow.png" v-if="!showSections['案件情報']">
        </button>
        <div class="searchBox-content-block" v-if="showSections['案件情報']">
          <div style="display: flex; margin-bottom: 0;">
            <div>
              <label>対象コード</label>
              <input type="text" placeholder="検索" v-model="searchCode" />
            </div>
            <div>
              <label>件名</label>
              <input style="width:300px;" type="text" placeholder="検索" v-model="searchName" />
            </div>
          </div>

          <div style="display: flex; margin-bottom: 0;">
            <div>
              <label>案件名</label>
              <input style="width:300px;" type="text" placeholder="検索" v-model="searchProject" />
            </div>
          </div>

          <div style="display: flex; margin-bottom: 0;">
            <div>
              <label>提出日</label>
              <input type="date" v-model="StartDate__start"/> ~ 
              <input type="date" v-model="StartDate__end"/>
            </div>
            <div>
              <label>実施日</label>
              <input type="date" v-model="Implementation__start"/> ~ 
              <input type="date" v-model="Implementation__end"/>
            </div>
            <div>
              <label>発生費用</label>
              <input type="text" v-model='minEarningsProspect' /> ~ 
              <input type="text" v-model='maxEarningsProspect' />
            </div>
          </div>
          <div style="display: flex; margin-bottom: 0;">
            <div>
              <label>稟議種別</label>
              <select v-model="searchType">
                <option></option>
                <option v-for="(option, index) in ApprovalTypeOption" :key="index" :value="option.value">{{ option.value }}</option>
              </select>
            </div>
            <div>
              <label>一時決議判定</label>
              <select v-model="searchketsugi1">
                <option></option>
                <option v-for="(option, index) in ketsugi__1Option" :key="index" :value="option.value">{{ option.value }}</option>
              </select>
            </div>
            <div>
              <label>最終決議判定</label>
              <select v-model="searchketsugi2">
                <option></option>
                <option v-for="(option, index) in ketsugi__2Option" :key="index" :value="option.value">{{ option.value }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="searchBox-content">
        <h2>担当情報</h2>
        <button class="toggleSection" @click="toggleSection('担当情報')">
          <img class="toggleButton" src="../assets/image/Expand_Arrow.png" v-if="showSections['担当情報']">
          <img class="toggleButton_1" src="../assets/image/Expand_Arrow.png" v-if="!showSections['担当情報']">
        </button>
        <div class="searchBox-content-block" v-if="showSections['担当情報']">
          <div style="display: flex; margin-bottom: 0;">
            <div>
              <label>申請者</label>
                <select v-model="searchManager">
                  <option></option>
                  <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                </select>
            </div>
            <div>
              <label>承認者</label>
                <select v-model="searchAuthorizer">
                  <option></option>
                  <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                </select>
            </div>
          </div>
        </div>
      </div>
      <div class="searchBox-content">
        <h2>ステータス</h2>
        <button class="toggleSection" @click="toggleSection('ステータス')">
          <img class="toggleButton" src="../assets/image/Expand_Arrow.png" v-if="showSections['ステータス']">
          <img class="toggleButton_1" src="../assets/image/Expand_Arrow.png" v-if="!showSections['ステータス']">
        </button>
        <div class="searchBox-content-block" v-if="showSections['ステータス']">
          <div>
            <label>承認結果</label>
              <select v-model="searchAuthorize">
                <option></option>
                <option v-for="(option, index) in authorizeSelectOption" :key="index" :value="option.value">{{ option.value }}</option>
              </select>
          </div>
          <div>
            <label>対応状況</label>
              <select v-model="searchAnswer">
                <option></option>
                <option v-for="(option, index) in answerSelectOption" :key="index" :value="option.value">{{ option.value }}</option>
              </select>
          </div>
        </div>
      </div>
    </div>
    <div class="clearButtonBlock">
      <button @click="inputClear" class="clearButton normalButton">絞り込み条件をリセット</button>
    </div>
    <div class="listBox">
      <table v-if= "paginatedData.length > 0">
        <thead>
          <tr>
            <th @click="sortTable('$id')">稟議コード
              <span v-if="sortKey === '$id'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('Title')">件名
              <span v-if="sortKey === 'Title'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('Project')">案件名
              <span v-if="sortKey === 'Project'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('Date')">提出日
              <span v-if="sortKey === 'Date'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('Implementation')">実施日
              <span v-if="sortKey === 'Implementation'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('total')">発生費用
              <span v-if="sortKey === 'total'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>     
            <th @click="sortTable('Type')">稟議種別
              <span v-if="sortKey === 'Type'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('first__resolution_judgment')">一次決議判定
              <span v-if="sortKey === 'first__resolution_judgment'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('last__resolution_judgment')">最終決議判定
              <span v-if="sortKey === 'last__resolution_judgment'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('author')">申請者
              <span v-if="sortKey === 'author'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('作成日時')">申請日時
              <span v-if="sortKey === '作成日時'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('ApprovalStatus')">承認結果
              <span v-if="sortKey === 'ApprovalStatus'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('AnswerStatus')">対応状況
              <span v-if="sortKey === 'AnswerStatus'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in paginatedData" :key="index">
            <td>{{ item.$id.value }}</td>
            <td>
            <router-link :to="{ name: '稟議詳細', params: { id: item.$id.value } }" @click="setData('ApprovalDetails',item.$id.value)">
            {{ item.Title.value }}
            </router-link>
            </td>
            <td>{{ item.Project.value }}</td>
            <td>{{ item.Date.value }}</td>
            <td>{{ item.Implementation.value }}</td>
            <td>{{ item.total.value }}円</td>
            <td>{{ item.Type.value }}</td>
            <td>{{ item.first__resolution_judgment.value }}</td>
            <td>{{ item.last__resolution_judgment.value }}</td>
            <td>{{ item.author.value }}</td>
            <td>{{ formatDateTime(item.作成日時.value) }}</td>
            <td>{{ item.ApprovalStatus.value }}</td>
            <td>{{ item.AnswerStatus.value }}</td>
            <td><img src="../assets/image/gomibako.png" @click="recordDelete(item.$id.value)" style="cursor: pointer;position: relative;top: -2px;"></td>
          </tr>
        </tbody>
      </table>
      <div v-else>
        <p class="notFound">一覧に表示できるデータがありません</p>
      </div>
    </div>
      <div class="pagination">
      <span>{{ currentPage }} / {{ totalPages }}</span>
        <img class="arrowButton" src="../assets/image/Expand_Arrow.png" @click="prevPage" :disabled="currentPage === 1">
        <img class="arrowButton" src="../assets/image/Expand_Arrow2.png" @click="nextPage" :disabled="currentPage * itemsPerPage >= totalFilteredItems" >
      </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      showSections: {
        '案件情報': true,   // 最初は表示
        '担当情報': false,  // 最初は非表示
        'ステータス': false,  // 最初は非表示
      },
      
      formData: {
        DeleteStatus: '削除',
      },

      tableData: [],

      clientSelectOption: [],

      companySelectOption: [],
    
      departmentSelectOption: [],

      EmployeeSelectOption: [],

      ApprovalTypeOption: [
        { value: '旅費交通稟議' },
        { value: '接待交際稟議' },
        { value: '会議費使用稟議' },
        { value: '物品購入稟議' },
        { value: '贈答関連稟議' },
        { value: '事業開設関連稟議' },
        { value: '異例処理' },
        { value: 'その他稟議' }
      ],

      AccountSelectOption: [
        { value: '社員福利厚生費', label: '社員福利厚生費' },
        { value: '広告宣伝費', label: '広告宣伝費' },
        { value: '募集費', label: '募集費' },
        { value: '地代家賃', label: '地代家賃' },
        { value: '共益費', label: '共益費' },
        { value: '通信費', label: '通信費' },
        { value: '旅費交通費', label: '旅費交通費' },
        { value: '支払手数料', label: '支払手数料' },
        { value: '車両関連費', label: '車両関連費' },
        { value: '租税公課', label: '租税公課' },
        { value: '消耗品費', label: '消耗品費' },
        { value: '減価償却費', label: '減価償却費' },
        { value: 'リース料', label: 'リース料' },
        { value: '水道光熱費', label: '水道光熱費' },
        { value: '会議費', label: '会議費' },
        { value: '荷造運賃', label: '荷造運賃' },
        { value: '接待交際費', label: '接待交際費' },
        { value: '修繕費', label: '修繕費' },
        { value: '新聞図書費', label: '新聞図書費' },
        { value: '諸会費', label: '諸会費' },
        { value: '賃借料', label: '賃借料' },
        { value: '保険料', label: '保険料' },
        { value: '雑費', label: '雑費' },
        { value: '外注工賃', label: '外注工賃' },
      ],

      authorizeSelectOption: [
        { value: '承認済み', label: '承認済み' },
        { value: '申請中', label: '申請中' },
        { value: '未承認', label: '未承認' },
      ],

      answerSelectOption: [
        { value: '対応中', label: '対応中' },
        { value: '決裁済み', label: '決裁済み' },
        { value: '見送り', label: '見送り' },
      ],

      ketsugi__1Option: [
        { value: '承認', label: '承認' },
        { value: '条件付き承認', label: '条件付き承認' },
        { value: '差戻し', label: '差戻し' },
        { value: '否決', label: '否決' },
      ],
      ketsugi__2Option: [
        { value: '承認', label: '承認' },
        { value: '条件付き承認', label: '条件付き承認' },
        { value: '差戻し', label: '差戻し' },
        { value: '否決', label: '否決' },
      ],


      // 以下はフィルタリングやページネーションに使用するデータ
      searchCode: "",
      searchName: "",
      searchProject: "",
      searchType: "",
      searchClient: "",
      searchCompany: "",
      searchDepartment: "",
      searchAuthorizer: "",
      searchManager: "",
      searchWorker: "",
      searchAuthor: "",
      searchAuthorize: "",
      searchAnswer: "",
      searchfirst__resolution_person: "",
      searchlast__resolution_person: "",
      searchketsugi1: "",
      searchketsugi2: "",
      StartDate__start: "",
      StartDate__end: "",
      Implementation__start: "",
      Implementation__end: "",

      searchAccount: "",

      StartDate: "",
      EndDate: "",
      minEarningsProspect: "",
      maxEarningsProspect: "",
      minEarningsAchievements: "",
      maxEarningsAchievements: "",

      sortKey: "$id",
      sortDesc: false,
      currentPage: 1,
      itemsPerPage: 10,
    };
  },
  computed: {
    login_data:{
      get: function () {
        return this.$store.state.login_data
      },
      set: function (value) {
        this.$store.commit('login_data', value)
      }
    },
    // フィルタリングされたデータを計算
    filteredTableData() {
      let filteredData = this.tableData;
      filteredData = this.tableData.filter((item) => {
        const startDate = new Date(this.StartDate__start);
        const endDate = new Date(this.StartDate__end);
        const itemDate = new Date(item.Date.value);

        const startDate_Im = new Date(this.Implementation__start);
        const endDate_Im = new Date(this.Implementation__end);
        const itemDate_Im = new Date(item.Implementation.value);
        return (
          (this.searchCode === "" || item.$id.value.includes(this.searchCode)) &&
          (this.searchName === "" || item.Title.value.includes(this.searchName)) &&
          (this.searchProject === "" || item.Project.value.includes(this.searchProject)) &&
          (this.searchType === "" || item.Type.value === this.searchType) &&
          (this.searchAuthorizer === "" || item.authorizer.value === this.searchAuthorizer) &&
          (this.searchManager === "" || item.author.value === this.searchManager) && 
          (this.searchAuthorize === "" || item.ApprovalStatus.value === this.searchAuthorize) && 
          (this.searchAnswer === "" || item.AnswerStatus.value === this.searchAnswer) && 
          (this.searchAccount === "" || item.Account_item === this.searchAccount) && 
          (this.searchfirst__resolution_person === "" || item.first__resolution_person.includes(this.searchfirst__resolution_person)) && 
          (this.searchlast__resolution_person === "" || item.last__resolution_person.includes(this.searchlast__resolution_person)) && 
          (this.searchketsugi1 === "" || item.first__resolution_judgment.value === this.searchketsugi1) && 
          (this.searchketsugi2 === "" || item.last__resolution_judgment.value === this.searchketsugi2) && 
          (isNaN(startDate) || itemDate >= startDate) && // 開始日が未指定または作成日時が開始日以降
          (isNaN(endDate) || itemDate <= endDate) && // 終了日が未指定または作成日時が終了日以前
          (isNaN(startDate_Im) || itemDate_Im >= startDate_Im) && // 開始日が未指定または作成日時が開始日以降
          (isNaN(endDate_Im) || itemDate_Im <= endDate_Im) && // 終了日が未指定または作成日時が終了日以前
          this.isAmountInRange(
            item.total.value,
            this.minEarningsProspect,
            this.maxEarningsProspect
          )
        );
      });

      console.log('-----------')
      console.log(this.sortKey);
      console.log(this.sortDesc);
      let sortkey = this.sortKey;

      if(filteredData){
        // ソート
        if (this.sortDesc) {
          filteredData.sort(function(a,b){
            if(!a[sortkey] || !b[sortkey]){return false}
            let aData = (!isNaN(a[sortkey]['value']))?Number(a[sortkey]['value']):a[sortkey]['value'];
            let bData = (!isNaN(b[sortkey]['value']))?Number(b[sortkey]['value']):b[sortkey]['value'];
            return (aData > bData)?1:-1
          })
        } else {
          filteredData.sort(function(a,b){
            if(!a[sortkey] || !b[sortkey]){return false}
            let aData = (!isNaN(a[sortkey]['value']))?Number(a[sortkey]['value']):a[sortkey]['value'];
            let bData = (!isNaN(b[sortkey]['value']))?Number(b[sortkey]['value']):b[sortkey]['value'];
            return (aData < bData)?1:-1
          })
        }
      }
      console.log(filteredData)

      return filteredData;
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filteredTableData.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.filteredTableData.length / this.itemsPerPage);
    },
  },
  created() {
    this.getTableData();
    this.getClientData();
    this.getCompanyData();
    this.getDepartmentData();
    this.getEmployeeData();
  },
  mounted() {
    this.getTableData();
    this.getClientData();
    this.getCompanyData();
    this.getDepartmentData();
    this.getEmployeeData();
  },
  methods: {
    getTableData: async function (){
      let params = {
        target: 'Application',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      this.tableData = authed.records;
      console.log(authed)
    },
    getClientData: async function (){
      let params = {
        target: 'Client',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.clientSelectOption = authed.records;
    },
    getCompanyData: async function (){
      let params = {
        target: 'Company',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.companySelectOption = authed.records;
    },
    getDepartmentData: async function (){
      let params = {
        target: 'Department',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.departmentSelectOption = authed.records;
    },
    getEmployeeData: async function (){
      let params = {
        target: 'Employee',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.EmployeeSelectOption = authed.records;
    },
    isAmountInRange(amount, minAmount, maxAmount) {
      const numericAmount = parseFloat(amount);
      if (isNaN(numericAmount)) {
        return true; // amountが数値でない場合はフィルタリングせずに表示
      }
      if (minAmount !== "" && numericAmount < parseFloat(minAmount)) {
        return false;
      }
      if (maxAmount !== "" && numericAmount > parseFloat(maxAmount)) {
        return false;
      }

      return true;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    sortTable(key) {
      if (this.sortKey === key) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortKey = key;
        this.sortDesc = false;
      }
    },
    toggleSection(sectionName) {
      this.showSections[sectionName] = !this.showSections[sectionName];
    },
    formatNumber(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    formatDateTime(dateTimeString) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', timeZoneName: 'short', weekday: 'short' };
      const dateTime = new Date(dateTimeString);
      const stringDate = dateTime.toLocaleString('ja-JP', options);
      if (stringDate === "Invalid Date") {
        return "";
      } else {
        return stringDate.replace("JST", '').replace(',', '');
      }
    },
    inputClear(){
      this.searchCode = "";
      this.searchName = "";
      this.searchProject = "";
      this.searchOrders = "";
      this.searchClient = "";
      this.searchCompany = "";
      this.searchDepartment = "";
      this.searchAuthorizer = "";
      this.searchManager = "";
      this.searchWorker = "";
      this.searchAuthor = "";
      this.searchAuthorize = "";
      this.searchAnswer = "";
      this.searchType = "";
      this.StartDate__start = "";
      this.StartDate__end = "";
      this.Implementation__start = "";
      this.Implementation__end = "";

      this.StartDate = "";
      this.EndDate = "";
      this.minAmount = "";
      this.maxAmount = "";
    },
    setData(page,id){
      this.setStrage('loginTimes',new Date());
      this.setStrage('pages',page);
      this.setStrage('id',id);
    },
    setStrage(target,value){
      localStorage.setItem(target,value);
    },
    recordDelete: async function(id){
      if (window.confirm('レコードを削除してもよろしいですか？')) {
        this.formData.DeleteStatus = '削除'
        let pushArray = [];
        for(let key in this.formData){
          let data = {};
          data.target = key;
          data.value = this.formData[key];
          pushArray.push(data);
        }
        let params = {
          target: 'Application',
          data:JSON.stringify(pushArray),
          user: this.login_data['Names'].value,
          querys: JSON.stringify(
              [
                {
                  //対象カラム
                  column :'DeleteStatus',
                  //比較条件
                  cond   :'in',
                  //比較内容
                  data   : '使用',
                },
                {
                  //対象カラム
                  column :'$id',
                  //比較条件
                  cond   :'=',
                  //比較内容
                  data   :id,
                }
              ]
          ),
        };
        console.log(params)
        let authed = await this.$commonPlugin.axiosAPI('kintone_create_update_data_for_array_logic',params);
        this.$commonPlugin.dataUpdate(this.formData,authed,this.PurchaseAmountTemplate);
        if(authed.result.response === "失敗"){
          alert('入力内容にエラーが発生しました。');
        }else{
          alert('削除しました。');
          window.location.reload()
        }
      }
    }
  },
};
</script>