
<template>
  <div class="pageHeader">
    <h1 v-if="editFlag">請求編集({{ createRecordId }})</h1>
    <h1 v-if="copyFlag">請求複製</h1>
    <h1 v-if="createFlag">請求作成</h1>
    <button class="normalButton" @click="submitForm" :disabled="!UploadFlag">保存</button>
    <button class="normalButton" @click="inputCancel">キャンセル</button>
  </div>
  <div class="content-block">
    <div id="formImput">
      <form ref="myForm">
        <div class="FormGroup">
          <!-- 案件名 -->
          <h2>紐付け情報</h2>
          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="ProjectTitle">案件名</label>
                <span class="required">必須</span>
                <span class="tooltip-content">案件名の入力フォームです。(必須項目)</span>
              </div>
              <div class="inputBlock" :class="{ 'hasError': hasAlert.flag }">
                <input type="text" id="ProjectTitle" :class="{ 'hasError': hasAlert.flag }" v-model="formData.ProjectTitle" @input="getApplicationData(formData.ProjectTitle)" />
                <span class="modallink" @click="openModal()">選択する</span>
                <span class="hasError-content">{{ hasAlert.text }}</span>
              </div>
            </div>
            <div class="inputCol-2-block" style="display:none">
              <div class="nameBlock">
                <label for="ProjectId">案件ID</label>
                <span class="required">必須</span>
                <span class="tooltip-content">案件IDの確認フォームです。(任意項目)</span>
              </div>
              <div class="inputBlock" >
                <input type="text" id="ProjectId" v-model="formData.ProjectId" readonly />
              </div>
            </div>
          </div>

<!-- モーダルウィンドウ -->
<div v-if="selectModalFlag === true" class="modal" style="display: flex;">
          <div class="modal-content">
            <h2>案件選択</h2>
            <img src="../assets/image/close.png" class="close-right" @click="closeModal">
            <div class="searchBox">
              <div class="searchBox-content">
                   <div>
                    <label>案件名</label>
                    <input type="text" placeholder="案件名" v-model="searchName" style="width: 50%;"/>
                  </div>
                <div class="searchBox-content-block">
                  <div>
                    <label>取引先</label>
                      <select v-model="searchClient">
                        <option></option>
                        <option v-for="(option, index) in clientSelectOption" :key="index" :value="option.CustomerName.value">{{ option.CustomerName.value }}</option>
                      </select>
                  </div>
                  <div>
                    <label>作業者</label>
                      <select v-model="searchWorker">
                        <option></option>
                        <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                      </select>
                  </div>
                  <div>
                    <label>作成者</label>
                      <select v-model="searchAuthor">
                        <option></option>
                        <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                      </select>
                  </div>
                  <div>
                    <label>作成日</label>
                    <input type="date" v-model="searchStart"/> ~
                    <input type="date" v-model="searchEnd"/>
                  </div>
                </div>
                <div class="searchBox-content-block">
                  <div>
                    <label>承認ステータス</label>
                      <select v-model="searchApproval">
                        <option></option>
                        <option>未承認</option>
                        <option>申請中</option>
                        <option>承認済み</option>
                      </select>
                  </div>
                  <div>
                    <label>回答ステータス</label>
                      <select v-model="searchAnswer">
                        <option></option>
                        <option>対応中</option>
                        <option>完了</option>
                        <option>中止</option>
                      </select>
                  </div>
                </div>
              </div>
            </div>
            <table class="authorize-table">
              <thead>
                <th></th>
                <th>案件コード</th>
                <th>案件名</th>
                <th>取引先</th>
                <th>作成日時</th>
                <th>作業者</th>
                <th>作成者</th>
                <th>承認</th>
                <th>回答</th>
              </thead>
              <tbody>
                <tr v-for="(item, index) in paginatedData" :key="index">
                  <td @click="inputFormSelect(item.Title.value)" v-if="item.ApprovalStatus.value === '承認済み'"><span class="pointer">選択</span></td>
                  <td v-else></td>
                  <td>{{ item.$id.value }}</td>
                  <td>{{ item.Title.value }}</td>
                  <td>{{ item.Client.value }}</td>
                  <td>{{ formatDateTime(item.作成日時.value) }}</td>
                  <td>{{ item.worker.value }}</td>
                  <td>{{ item.author.value }}</td>
                  <td>{{ item.ApprovalStatus.value }}</td>
                  <td>{{ item.AnswerStatus.value }}</td>
                </tr>
              </tbody>
            </table>
            <div class="pagination">
              <span>{{ currentPage }} / {{ totalPages }}</span>
              <img class="arrowButton" src="../assets/image/Expand_Arrow.png" @click="prevPage" :disabled="currentPage === 1">
              <img class="arrowButton" src="../assets/image/Expand_Arrow2.png" @click="nextPage" :disabled="currentPage * itemsPerPage >= totalFilteredItems" >
            </div>
            <div class="modal-button-block">
              <button class="normalButton" @click="closeModal()">閉じる</button>
            </div>
          </div>
        </div>
<!-- モーダルウィンドウ -->

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="OrderName">注文名</label>
                <span class="required">必須</span>
                <span class="tooltip-content">注文名の入力フォームです。(必須項目)</span>
              </div>
              <div class="inputBlock" :class="{ 'hasError': !errors.OrderName.flag }">
                <input type="text" id="OrderName" v-model="formData.OrderName" required :class="{ 'hasError': !errors.OrderName.flag }" />
                <span class="modallink" @click="openModal__Order()">選択する</span>
                <span class="hasError-content">{{ errors.OrderName.msg }}</span>
              </div>
            </div>
          </div>
        
<!-- モーダルウィンドウ -->
<div v-if="selectModalFlag__Order === true" class="modal" style="display: flex;">
          <div class="modal-content">
            <h2>注文選択</h2>
            <img src="../assets/image/close.png" class="close-right" @click="closeModal__Order">
            <div class="searchBox">
              <div class="searchBox-content">
                  <div>
                    <label>注文名</label>
                    <input type="text" placeholder="注文名" v-model="searchName__Order" style="width: 50%;"/>
                  </div>
                  <div>
                    <label>案件名</label>
                    <input type="text" placeholder="案件名" v-model="searchName__Order__Project" style="width: 50%;"/>
                  </div>
                <div class="searchBox-content-block">
                  <div>
                    <label>取引先</label>
                      <select v-model="searchClient__Order">
                        <option></option>
                        <option v-for="(option, index) in clientSelectOption" :key="index" :value="option.CustomerName.value">{{ option.CustomerName.value }}</option>
                      </select>
                  </div>
                  <div>
                    <label>作業者</label>
                      <select v-model="searchWorker__Order">
                        <option></option>
                        <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                      </select>
                  </div>
                  <div>
                    <label>作成者</label>
                      <select v-model="searchAuthor__Order">
                        <option></option>
                        <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                      </select>
                  </div>
                  <div>
                    <label>作成日</label>
                    <input type="date" v-model="searchStart__Order"/> ~
                    <input type="date" v-model="searchEnd__Order"/>
                  </div>
                </div>
                <div class="searchBox-content-block">
                  <div>
                    <label>承認ステータス</label>
                      <select v-model="searchApproval__Order">
                        <option></option>
                        <option>未承認</option>
                        <option>申請中</option>
                        <option>承認済み</option>
                      </select>
                  </div>
                  <div>
                    <label>回答ステータス</label>
                      <select v-model="searchAnswer__Order">
                        <option></option>
                        <option>対応中</option>
                        <option>受注済み</option>
                        <option>発注済み</option>
                        <option>失注</option>
                        <option>見送り</option>
                      </select>
                  </div>
                </div>
              </div>
            </div>
            <table class="authorize-table">
              <thead>
                <th></th>
                <th>案件コード</th>
                <th>案件名</th>
                <th>取引先</th>
                <th>作成日時</th>
                <th>作業者</th>
                <th>作成者</th>
                <th>承認</th>
                <th>回答</th>
              </thead>
              <tbody>
                <tr v-for="(item, index) in paginatedData__Order" :key="index">
                  <td @click="inputFormSelect__Order(item.Title.value)" v-if="item.ApprovalStatus.value === '承認済み'"><span class="pointer">選択</span></td>
                  <td v-else></td>
                  <td>{{ item.$id.value }}</td>
                  <td>{{ item.Title.value }}</td>
                  <td>{{ item.Client.value }}</td>
                  <td>{{ formatDateTime(item.作成日時.value) }}</td>
                  <td>{{ item.worker.value }}</td>
                  <td>{{ item.author.value }}</td>
                  <td>{{ item.ApprovalStatus.value }}</td>
                  <td>{{ item.AnswerStatus.value }}</td>
                </tr>
              </tbody>
            </table>
            <div class="pagination">
              <span>{{ currentPage__Order }} / {{ totalPages__Order }}</span>
              <img class="arrowButton" src="../assets/image/Expand_Arrow.png" @click="prevPage__Order" :disabled="currentPage__Order === 1">
              <img class="arrowButton" src="../assets/image/Expand_Arrow2.png" @click="nextPage__Order" :disabled="currentPage__Order * itemsPerPage__Order >= totalFilteredItems__Order" >
            </div>
            <div class="modal-button-block">
              <button class="normalButton" @click="closeModal__Order">閉じる</button>
            </div>
          </div>
        </div>
<!-- モーダルウィンドウ -->

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
                <div class="nameBlock">
                  <label for="Quotation">見積名</label>
                  <span class="required">必須</span>
                  <span class="tooltip-content">見積名の入力フォームです。(必須項目)</span>
                </div>
                <div class="inputBlock" :class="{ 'hasError': !errors.Quotation.flag }">
                  <input type="text" id="Quotation" v-model="formData.Quotation" required :class="{ 'hasError': !errors.Quotation.flag }" />
                  <span class="modallink" @click="openModal__Quotation()">選択する</span>
                  <span class="hasError-content">{{ errors.Quotation.msg }}</span>
                </div>
            </div>
          </div>
        </div>

<!-- モーダルウィンドウ -->
        <div v-if="selectModalFlag__Quotation === true" class="modal" style="display: flex;">
          <div class="modal-content">
            <h2>見積選択</h2>
            <img src="../assets/image/close.png" class="close-right" @click="closeModal__Quotation">
            <div class="searchBox">
              <div class="searchBox-content">
                  <div>
                    <label>見積名</label>
                    <input type="text" placeholder="注文名" v-model="searchName__Quotation" style="width: 50%;"/>
                  </div>
                  <div>
                    <label>案件名</label>
                    <input type="text" placeholder="案件名" v-model="searchName__Quotation__Project" style="width: 50%;"/>
                  </div>
                <div class="searchBox-content-block">
                  <div>
                    <label>取引先</label>
                      <select v-model="searchClient__Quotation">
                        <option></option>
                        <option v-for="(option, index) in clientSelectOption" :key="index" :value="option.CustomerName.value">{{ option.CustomerName.value }}</option>
                      </select>
                  </div>
                  <div>
                    <label>作業者</label>
                      <select v-model="searchWorker__Quotation">
                        <option></option>
                        <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                      </select>
                  </div>
                  <div>
                    <label>作成者</label>
                      <select v-model="searchAuthor__Quotation">
                        <option></option>
                        <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                      </select>
                  </div>
                  <div>
                    <label>作成日</label>
                    <input type="date" v-model="searchStart__Quotation"/> ~
                    <input type="date" v-model="searchEnd__Quotation"/>
                  </div>
                  <div>
                    <label>承認ステータス</label>
                      <select v-model="searchApproval__Quotation">
                        <option></option>
                        <option>未承認</option>
                        <option>申請中</option>
                        <option>承認済み</option>
                      </select>
                  </div>
                  <div>
                    <label>回答ステータス</label>
                      <select v-model="searchAnswer__Quotation">
                        <option></option>
                        <option>対応中</option>
                        <option>受注済み</option>
                        <option>発注済み</option>
                        <option>失注</option>
                        <option>見送り</option>
                      </select>
                  </div>
                </div>
              </div>
            </div>
            <table class="authorize-table">
              <thead>
                <th></th>
                <th>見積コード</th>
                <th>見積名</th>
                <th>案件名</th>
                <th>取引先</th>
                <th>作成日時</th>
                <th>作業者</th>
                <th>作成者</th>
                <th>承認</th>
                <th>回答</th>
              </thead>
              <tbody>
                <tr v-for="(item, index) in paginatedData__Quotation" :key="index">
                  <td @click="inputFormSelect__Quotation(item.Title.value)" v-if="item.ApprovalStatus.value === '承認済み'"><span class="pointer">選択</span></td>
                  <td>{{ item.$id.value }}</td>
                  <td>{{ item.Title.value }}</td>
                  <td>{{ item.Project.value }}</td>
                  <td>{{ item.Client.value }}</td>
                  <td>{{ formatDateTime(item.作成日時.value) }}</td>
                  <td>{{ item.worker.value }}</td>
                  <td>{{ item.author.value }}</td>
                  <td>{{ item.ApprovalStatus.value }}</td>
                  <td>{{ item.AnswerStatus.value }}</td>
                </tr>
              </tbody>
            </table>
            <div class="pagination">
              <span>{{ currentPage__Quotation }} / {{ totalPages__Quotation }}</span>
              <img class="arrowButton" src="../assets/image/Expand_Arrow.png" @click="prevPage__Quotation" :disabled="currentPage__Quotation === 1">
              <img class="arrowButton" src="../assets/image/Expand_Arrow2.png" @click="nextPage__Quotation" :disabled="currentPage__Quotation * itemsPerPage__Quotation >= totalFilteredItems__Quotation" >
            </div>
            <div class="modal-button-block">
              <button class="normalButton" @click="closeModal__Quotation">閉じる</button>
            </div>
          </div>
        </div>
<!-- モーダルウィンドウ -->

        <div class="FormGroup">
          <h2>請求情報</h2>
          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Order">受注/発注</label>
                <span class="required">必須</span>
                <span class="tooltip-content">受注/発注の入力フォームです。(必須項目)</span>
              </div>
              <div class="inputBlock">
                <div class="radio" v-if=!isEditFlag >
                  <div class="radio-block">
                    <label>受注</label>
                    <input type="radio" name="order" v-model="formData.Order" value="受注">
                  </div>
                  <div class="radio-block">
                    <label>発注</label>
                    <input type="radio" name="order" v-model="formData.Order" value="発注">
                  </div>
                </div>
                <div v-else>
                {{ formData.Order }}
                </div>
                <span>*保存後は変更不可。</span>
              </div>

            </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Title">請求名</label>
                <span class="required">必須</span>
                <span class="tooltip-content">請求名の入力フォームです。(必須項目)</span>
              </div>
              <div class="inputBlock" :class="{ 'hasError': !errors.Title.flag }">
                <input type="text" id="Title" v-model="formData.Title" required :class="{ 'hasError': !errors.Title.flag }" />
                <span class="hasError-content">{{ errors.Title.msg }}</span>
              </div>
            </div>
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Client">取引先</label>
                <span class="required">必須</span>
                <span class="tooltip-content">取引先の入力フォームです。(必須項目)</span>
              </div>
              <div class="inputBlock" :class="{ 'hasError': !errors.Client.flag }">
                <select v-model="formData.Client" @change="ClientInput(formData.Client)">
                  <option></option>
                  <option v-for="(option, index) in clientSelectOption" :key="index" :value="option.CustomerName.value">{{ option.CustomerName.value }}</option>
                </select>
                <span class="hasError-content">{{ errors.Client.msg }}</span>
              </div>
            </div>
          </div>

        </div>

        <div class="FormGroup">
          <h2>請求書記載内容</h2>
          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="Consignor">委託者情報</label>
              <span class="tooltip-content">委託者情報の入力フォームです。</span>
            </div>
            <div class="inputBlock">
              <div>
                <label>会社名</label>
                <input type="text" v-model="formData.Consignor__company">
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="Trustee">受託者情報</label>
              <span class="tooltip-content">受託者情報の入力フォームです。</span>
            </div>
            <div class="inputBlock">
              <div>
                <label>会社名</label>
                <input type="text" v-model="formData.Entrustor__company">
              </div>
              <div>
                <label>住所</label>
                <input type="text" v-model="formData.Entrustor__location">
              </div>
              <div>
                <label>電話番号</label>
                <input type="text" v-model="formData.Entrustor__number">
              </div>
              <div>
                <label>FAX</label>
                <input type="text" v-model="formData.Entrustor__FAX">
              </div>
              <div>
                <label>メールアドレス</label>
                <input type="text" v-model="formData.Entrustor__mail">
              </div>
              <div>
                <label>担当者</label>
                <input type="text" v-model="formData.Entrustor__manager">
              </div>

              <div class="flex-5-item">
                <label>銀行名</label>
                <input type="text" v-model="formData.Entrustor__bank">
              </div>
              <div class="flex-5-item">
                <label>銀行名(カナ)</label>
                <input type="text" v-model="formData.Entrustor__bank_kana">
              </div>
              <div class="flex-5-item">
                <label>銀行番号</label>
                <input type="text" v-model="formData.Entrustor__bank_num">
              </div>
              <div class="flex-5-item">
                <label>支店名</label>
                <input type="text" v-model="formData.Entrustor__bank_name">
              </div>
              <div class="flex-5-item">
                <label>支店名(カナ)</label>
                <input type="text" v-model="formData.Entrustor__bank_name_kana">
              </div>
              
              
              <div class="flex-5-item">
                <label>支店番号</label>
                <input type="text" v-model="formData.Entrustor__bank_name_num">
              </div>
              <div class="flex-5-item">
                <label>口座種別</label>
                <input type="text" v-model="formData.Entrustor__account">
              </div>
              <div class="flex-5-item">
                <label>口座番号</label>
                <input type="text" v-model="formData.Entrustor__account_num">
              </div>
              <div class="flex-5-item">
              </div>
              <div class="flex-5-item">
              </div>
              <div>
                <label>受取人名</label>
                <input type="text" v-model="formData.Entrustor__receiver">
              </div>
              <div>
                <label>受取人名(カナ)</label>
                <input type="text" v-model="formData.Entrustor__receiver_kana">
              </div>

              <!-- <div>
                <label>適格請求書発行</label>
                <input type="text" v-model="formData.Entrustor__invoice">
              </div>       -->
              <div>
                <label>適格請求書発行事業者登録番号</label>
                <input type="text" v-model="formData.Entrustor__invoice_num">
              </div>

              
              
            </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="dDateate">発行日</label>
                <span class="tooltip-content">発行日の入力フォームです。</span>
              </div>
              <div class="inputBlock">
                <input type="date" id="Date" v-model="formData.Date"/>
              </div>
            </div>
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="num">請求番号</label>
                <span class="tooltip-content">請求番号の入力フォームです。</span>
              </div>
              <div class="inputBlock">
                <input type="text" id="num" v-model="formData.num"/>
              </div>
            </div>
          </div>
          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="name">件名</label>
                <span class="tooltip-content">件名の入力フォームです。</span>
              </div>
              <div class="inputBlock">
                <input type="text" id="name" v-model="formData.name"/>
              </div>
            </div>
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="deadline">支払期限</label>
                <span class="tooltip-content">支払期限の入力フォームです。</span>
              </div>
              <div class="inputBlock">
                <input type="date" id="deadline" v-model="formData.deadline"/>
              </div>
            </div>
          </div>


          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="memo">備考</label>
              <span class="tooltip-content">備考の入力フォームです。</span>
            </div>
            <div class="inputBlock">
              <textarea id="memo" v-model="formData.memo"></textarea>
            </div>
          </div>

          <div class="inputCol inputCol-1">
            <div class="nameBlock">
              <label for="Files">注文書ファイル</label>
            </div>
            <div class="inputBlock">
              <template v-if="formData.Files.length > 0">
                <div v-for="file in formData.Files" :key="file.name">{{ file.name }}</div>
                <!-- <label>変更</label><input type="file" id="Document" @change="handleFileChange" multiple /> -->
              </template>
              <template v-else>
                <input type="file" id="Files" @change="handleFileChange" multiple />
              </template>
              <template v-if="!UploadFlag">
                <img src="../assets/image/ZZ5H.gif" style="width: 25px;height: 25px;margin-left: 12px;">
              </template>
            </div>
          </div>


    </div>

        <div class="FormGroup">
        <h2>請求書の明細</h2>
        <div class="inputCol inputCol-1">
          <div class="nameBlock">
            <label for="sub">頭紙表示</label>
          </div>
          <div class="inputBlock">
            <div class="radio">
              <div class="radio-block">
                <label>OFF</label>
                <input type="radio" name="sub" v-model="formData.Sub" value="OFF" :disabled="totalFlag">
              </div>
              <div class="radio-block">
                <label>ON</label>
                <input type="radio" name="sub" v-model="formData.Sub" value="ON" :disabled="totalFlag">
              </div>
            </div>
            <span>*15件以上の場合、ONになります。</span>
          </div>
        </div>
<!-- 複数の項目をまとめて追加できるフォーム領域 -->
          <div class="inputCol inputCol-4items">
            <div class="inputBlock">
              <b-table striped hover :fields="itemTableFields" :items="formData.itemTable" class="celltable">
                <template #cell(edit)="data">
                <div class="cellnum">{{data.index + 1}}</div>
                  <b-button v-if="data.index > 0" class="m-1" variant="success" :dataCol="data.index" @click="removePurchase(data.index)"><img src="../assets/image/gomibako.png"></b-button>
                  <b-button v-else variant="success" class="m-1" disabled :dataCol="data.index" ><img src="../assets/image/gomibako.png"></b-button>
                  <b-button variant="success"  class="m-1" :dataCol="data.index" @click="addPurchase(data.index)">＋</b-button>
                </template>

                <template #cell()="data">
<!--                  <label :for="data.field.key+data.index">{{data.field.label}}</label>-->
                  <template v-if="data.field">
                    <template v-if="data.field.type !='select'">
                      <div v-if="data.field.key === 'item__amount'" class="amount">
                        {{ formData.itemTable[data.index][data.field.key] }}
                      </div>
                      <div v-else>
                        <input class="m-1" :id="data.field.key+data.index" :type="data.field.type" v-model="formData.itemTable[data.index][data.field.key]" @input="updateTotal(data.index),updateValue(formData.itemTable[data.index][data.field.key],data.index,data.field)">
                      </div>
                    </template>
                    <template v-else>
                        <b-form-select :id="data.field.key+data.index" v-model="formData.itemTable[data.index][data.field.key]" class="m-1">
                          <template v-if="this[data.field.target].length > 0">
                          <b-form-select-option v-for="item in this[data.field.target]" :key="item" :value="item[data.field.data].value">
                            {{item[data.field.data].value}}
                          </b-form-select-option>
                          </template>
                        </b-form-select>
                    </template>
<!--                    <p v-for="item in this[data.field.target]" :key="item">{{data.field.data}}{{item}}</p>-->
                  </template>
                 </template>

              </b-table>
            </div>
          </div>





      <div class="total">
        <div class="normal sp">
          <div style="display:flex;    justify-content: flex-end;">
            <div>内消費税10％対象<span><input type="number" id="item__10" v-model="formData.item__10"></span></div>
            <div>内消費税8％対象<span><input type="number" id="item__8" v-model="formData.item__8"></span></div>
            <div>課税対象合計<span><input type="number" id="item__tax_total" v-model="formData.item__tax_total"></span></div>
          </div>
          <div style="display:flex;    justify-content: flex-end;">
            <div>消費税(10％)<span><input type="number" id="item__tax_10" v-model="formData.item__tax_10"></span></div>
            <div>消費税(8％)<span><input type="number" id="item__tax_8" v-model="formData.item__tax_8"></span></div>
            <div>消費税合計<span><input type="number" id="item__taxtotal" v-model="formData.item__taxtotal"></span></div>
          </div>
          <div style="display:flex;    justify-content: flex-end;">
            <div>非課税対象合計<span><input type="number" id="item__not_tax" v-model="formData.item__not_tax"></span></div>
          </div>
          <div style="display:flex;    justify-content: flex-end;">
            <div>合計<span><input type="number" id="item__total" v-model="formData.item__total"></span></div>
          </div>
        </div>
      </div>
      
    </div>




    <div class="FormGroup">
      <h2>担当情報</h2>
        <!-- 会社 -->
        <div class="inputCol inputCol-2">
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="company">会社</label>
              <span class="required">必須</span>
              <span class="tooltip-content">会社の入力フォームです。(必須項目)</span>
            </div>
            <div class="inputBlock" :class="{ 'hasError': !errors.company.flag }">
              <select id="company" :class="{ 'hasError': !errors.company.flag }" v-model="formData.company">
                <option></option>
                <option v-for="(option, index) in m_data_option.Company" :key="index" :value="option.value">{{ option.label }}</option>
              </select>
              <span class="hasError-content">{{ errors.company.msg }}</span>
            </div>
          </div>
        <!-- 部署 -->
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="department">部署</label>
              <span class="required">必須</span>
              <span class="tooltip-content">部署の入力フォームです。(必須項目)</span>
            </div>
            <div class="inputBlock" :class="{ 'hasError': !errors.department.flag }">
              <select id="department" :class="{ 'hasError': !errors.department.flag }" v-model="formData.department">
                <option></option>
                <option v-for="(option, index) in m_data_option.Department" :key="index" :value="option.value">{{ option.label }}</option>
              </select>
              <span class="hasError-content">{{ errors.department.msg }}</span>
            </div>
          </div>
        </div>

        <!-- 承認者 -->
        <div class="inputCol inputCol-2">
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="authorizer">承認者</label>
              <span class="required">必須</span>
              <span class="tooltip-content">承認者の入力フォームです。(必須項目)</span>
            </div>
            <div class="inputBlock" :class="{ 'hasError': !errors.authorizer.flag }">
              <select id="authorizer" :class="{ 'hasError': !errors.authorizer.flag }" v-model="formData.authorizer">
                <option></option>
                <option v-for="(option, index) in m_data_option.Employee" :key="index" :value="option.value">{{ option.label }}</option>
              </select>
              <span class="hasError-content">{{ errors.authorizer.msg }}</span>
            </div>
          </div>
        <!-- 担当者 -->
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="manager">担当者</label>
              <span class="required">必須</span>
              <span class="tooltip-content">担当者の入力フォームです。(必須項目)</span>
            </div>
            <div class="inputBlock" :class="{ 'hasError': !errors.manager.flag }">
              <select id="manager" :class="{ 'hasError': !errors.manager.flag }" v-model="formData.manager">
                <option></option>
                <option v-for="(option, index) in m_data_option.Employee" :key="index" :value="option.value">{{ option.label }}</option>
              </select>
              <span class="hasError-content">{{ errors.manager.msg }}</span>
            </div>
          </div>
        </div>

        <!-- 作業者 -->
        <div class="inputCol inputCol-2">
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="worker">作業者</label>
              <span class="required">必須</span>
              <span class="tooltip-content">作業者の入力フォームです。(必須項目)</span>
            </div>
            <div class="inputBlock" :class="{ 'hasError': !errors.worker.flag }">
              <select id="worker" :class="{ 'hasError': !errors.worker.flag }" v-model="formData.worker">
                <option></option>
                <option v-for="(option, index) in m_data_option.Employee" :key="index" :value="option.value">{{ option.label }}</option>
              </select>
              <span class="hasError-content">{{ errors.worker.msg }}</span>
            </div>
          </div>
        <!-- 作成者 -->
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="author">作成者</label>
              <span class="tooltip-content">作成者の入力フォームです。(自動入力)</span>
            </div>
            <div class="inputBlock">
              <select id="author" :class="{ 'hasError': !errors.author.flag }" v-model="formData.author">
                <option></option>
                <option v-for="(option, index) in m_data_option.Employee" :key="index" :value="option.value">{{ option.label }}</option>
              </select>
              <span class="hasError-content">{{ errors.author.msg }}</span>
            </div>
          </div>
        </div>

    </div>

        <!-- 社内管理情報 -->

        <div class="FormGroup">
        <h2>社内管理情報</h2>
        <!-- 社内メモ -->
          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="internalMemo">社内メモ</label>
              <span class="tooltip-content">社内メモの入力フォームです。</span>
            </div>
            <div class="inputBlock">
              <textarea id="internalMemo" v-model="formData.internalMemo"></textarea>
            </div>
          </div>

        <!-- 関連ファイル -->
        
          <div class="inputCol inputCol-1">
            <div class="nameBlock">
              <label for="relatedFiles">関連ファイル</label>
            </div>
            <div class="inputBlock">
              <template v-if="formData.relatedFiles.length > 0">
                <div v-for="file in formData.relatedFiles" :key="file.name">{{ file.name }}</div>
                <!-- <label>変更</label><input type="file" id="Document" @change="handleFileChange" multiple /> -->
              </template>
              <template v-else>
                <input type="file" id="relatedFiles" @change="handleFileChange" multiple />
              </template>
              <template v-if="!UploadFlag">
                <img src="../assets/image/ZZ5H.gif" style="width: 25px;height: 25px;margin-left: 12px;">
              </template>
            </div>
          </div>

        </div>
      </form>
    </div>
    <button class="normalButton" @click="submitForm" :disabled="!UploadFlag" style="float: right; width: 140px;">保存</button>
  </div>
<div v-if="!dataLoading" class="loading">
  <img src="../assets/image/ZZ5H.gif">
</div>
</template>



<script>

export default {
  data() {
    return {
      formData: {
        ProjectTitle: '',
        ProjectId: '',
        OrderName: '',
        Quotation: '',
        Order: '',
        Title: '',
        Client: '',
        Sub: 'OFF',
        Consignor__company: '',
        Entrustor__company: '',
        Entrustor__location: '',
        Entrustor__number: '',
        Entrustor__FAX: '',
        Entrustor__mail: '',
        Entrustor__manager: '',
        Entrustor__bank: '',
        Entrustor__bank_kana: '',
        Entrustor__bank_num: '',
        Entrustor__bank_name: '',
        Entrustor__bank_name_kana: '',
        Entrustor__bank_name_num: '',
        Entrustor__account: '',
        Entrustor__account_num: '',
        Entrustor__receiver: '',
        Entrustor__receiver_kana: '',
        Entrustor__invoice: '',
        Entrustor__invoice_num: '',
        Date: '',
        num: '',
        name: '',
        deadline: '',
        memo: '',
        itemTable:[
          {
            item   :'',
            item__quantity: '0',
            item__unit : '',
            item__price: '0',
            item__tax: '0',
            item__amount: '0',
          }
        ],

        item__10: '0',
        item__8: '0',
        item__tax_total: '0',
        item__tax_10: '0',
        item__tax_8: '0',
        item__taxtotal: '0',
        item__not_tax: '0',
        item__total: '0',

        company: '',
        department: '',
        authorizer: '',
        manager: '',
        worker: '',
        author: '',

        LastUser: '',

        relatedFiles: [], // ファイルデータを格納する配列
        Files: [],
      },
      errors: {
        Title: { msg:'', flag: true},
        ProjectTitle: { msg:'', flag: true},
        Quotation: { msg:'', flag: true},
        OrderName: { msg:'', flag: true},

        Client: { msg:'', flag: true},
        company: { msg:'', flag: true},
        department: { msg:'', flag: true},
        authorizer: { msg:'', flag: true},
        manager: { msg:'', flag: true},
        worker: { msg:'', flag: true},
        author: { msg:'', flag: true},
      },

      selectedFiles: [],
      formGroups: [['']],

      itemTableTemplate:{
        item   :'',
        item__quantity: '0',
        item__unit : '',
        item__price: '0',
        item__tax: '0',
        item__amount: '0',
      },
      itemTableArray :1,
      itemTableFields:[
        {
          key:'item',
          label:'内訳',
          type:'text'
        },
        {
          key:'item__price',
          label:'単価',
          type:'text'
        },
        {
          key:'item__quantity',
          label:'数量',
          type:'text'
        },
        {
          key:'item__unit',
          label:'単位',
          type:'text'
        },
        {
          key:'item__tax',
          label:'消費税率',
          type:'text'
        },
        {
          key:'item__amount',
          label:'金額',
          type:'text'
        },
        {
          key:'edit',
          label:'操作',
          type:'edit'
        }
      ],

//
      clientSelectOption: [],
      companySelectOption: [],
      departmentSelectOption: [],
      EmployeeSelectOption: [],

      hasAlert : {
        flag : false,
        text : ''
      },
      totalFlag: false,
      isEditFlag : false,

      copyFlag: '',
      editFlag: '',
      createFlag: '',
      dataLoading: false,
//
//モーダルウィンドウ
      selectModalFlag: false,
      currentPage: 1, // 現在のページ
      itemsPerPage: 10, // 1ページあたりのアイテム数
      tableData: [],
      searchName: "",
      searchAuthor: "",
      searchClient: "",
      searchWorker: "",
      searchApproval: "承認済み",
      searchAnswer: "対応中",
      searchStart: "",
      searchEnd: "",
//
//モーダルウィンドウ
      selectModalFlag__Order: false,
      currentPage__Order: 1, // 現在のページ
      itemsPerPage__Order: 10, // 1ページあたりのアイテム数
      tableData__Order: [],
      searchName__Order: "",
      searchName__Order__Project: "",
      searchAuthor__Order: "",
      searchClient__Order: "",
      searchWorker__Order: "",
      searchApproval__Order: "承認済み",
      searchAnswer__Order: "対応中",
      searchStart__Order: "",
      searchEnd__Order: "",
//
//モーダルウィンドウ
      selectModalFlag__Quotation: false,
      currentPage__Quotation: 1, // 現在のページ
      itemsPerPage__Quotation: 10, // 1ページあたりのアイテム数
      tableData__Quotation: [],
      searchName__Quotation: "",
      searchName__Quotation__Project: "",
      searchAuthor__Quotation: "",
      searchClient__Quotation: "",
      searchWorker__Quotation: "",
      searchApproval__Quotation: "承認済み",
      searchAnswer__Quotation: "対応中",
      searchStart__Quotation: "",
      searchEnd__Quotation: "",
//
      UploadFlag: true,

      beforeRecord : [],
    };
  },
  computed: {
//計算
    itemTotals: function() {
      return this.formData.itemTable.map(item => item.item__price * item.item__quantity);
    },

//モーダルウィンドウ
    filteredTableData() {
      console.log(this.tableData)
        let filteredData = this.tableData.filter((item) => {

        const startDate = new Date(this.searchStart);
        const endDate = new Date(this.searchEnd);
        
        const itemDate = new Date(item.作成日時.value);

          return (
            (this.searchName === "" || item.Title.value.includes(this.searchName)) &&
            (this.searchClient === "" || item.Client.value === this.searchClient) &&
            (this.searchWorker === "" || item.worker.value === this.searchWorker) && 
            (this.searchAuthor === "" || item.author.value === this.searchAuthor) &&
            (this.searchApproval === "" || item.ApprovalStatus.value === this.searchApproval) &&
            (this.searchAnswer === "" || item.AnswerStatus.value === this.searchAnswer) &&
            (isNaN(startDate) || itemDate >= startDate) && // 開始日が未指定または作成日時が開始日以降
            (isNaN(endDate) || itemDate <= endDate) // 終了日が未指定または作成日時が終了日以前
          );
        });
        return filteredData;
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filteredTableData.slice(startIndex, endIndex);
    },
    totalPages() {
      const totalPagesNum = Math.ceil(this.filteredTableData.length / this.itemsPerPage);
      return totalPagesNum;
    },
//
//モーダルウィンドウ
    filteredTableData__Order() {
      console.log(this.tableData__Order)
        let filteredData__Order = this.tableData__Order.filter((item) => {
          const startDate = new Date(this.searchStart__Order);
          const endDate = new Date(this.searchEnd__Order);
          
          const itemDate = new Date(item.作成日時.value);
          return (
            (this.searchName__Order === "" || item.Title.value.includes(this.searchName__Order)) &&
            (this.searchName__Order__Project === "" || item.Project.value.includes(this.searchName__Order__Project)) &&
            (this.searchClient__Order === "" || item.Client.value === this.searchClient__Order) &&
            (this.searchWorker__Order === "" || item.worker.value === this.searchWorker__Order) && 
            (this.searchAuthor__Order === "" || item.author.value === this.searchAuthor__Order) &&
            (this.searchApproval__Order === "" || item.ApprovalStatus.value === this.searchApproval__Order) &&
            (this.searchAnswer__Order === "" || item.AnswerStatus.value === this.searchAnswer__Order) &&
            (isNaN(startDate) || itemDate >= startDate) && // 開始日が未指定または作成日時が開始日以降
            (isNaN(endDate) || itemDate <= endDate) // 終了日が未指定または作成日時が終了日以前
          );
        });
        return filteredData__Order;
    },
    paginatedData__Order() {
      const startIndex = (this.currentPage__Order - 1) * this.itemsPerPage__Order;
      const endIndex = startIndex + this.itemsPerPage__Order;
      return this.filteredTableData__Order.slice(startIndex, endIndex);
    },
    totalPages__Order() {
      const totalPagesNum = Math.ceil(this.filteredTableData__Order.length / this.itemsPerPage__Order);
      return totalPagesNum;
    },
//
//モーダルウィンドウ
    filteredTableData__Quotation() {
      console.log(this.tableData__Quotation)
        let filteredData__Quotation = this.tableData__Quotation.filter((item) => {
          const startDate = new Date(this.searchStart__Quotation);
          const endDate = new Date(this.searchEnd__Quotation);
          const itemDate = new Date(item.作成日時.value);
          return (
            (this.searchName__Quotation === "" || item.Title.value.includes(this.searchName__Quotation)) &&
            (this.searchName__Quotation__Project === "" || item.Title.value.includes(this.searchName__Quotation__Project)) &&
            (this.searchClient__Quotation === "" || item.Client.value === this.searchClient__Quotation) &&
            (this.searchWorker__Quotation === "" || item.worker.value === this.searchWorker__Quotation) && 
            (this.searchAuthor__Quotation === "" || item.author.value === this.searchAuthor__Quotation) &&
            (this.searchApproval__Quotation === "" || item.ApprovalStatus.value === this.searchApproval__Quotation) &&
            (this.searchAnswer__Quotation === "" || item.AnswerStatus.value === this.searchAnswer__Quotation) &&
            (isNaN(startDate) || itemDate >= startDate) && // 開始日が未指定または作成日時が開始日以降
            (isNaN(endDate) || itemDate <= endDate) // 終了日が未指定または作成日時が終了日以前
          );
        });
        return filteredData__Quotation;
    },
    paginatedData__Quotation() {
      const startIndex = (this.currentPage__Quotation - 1) * this.itemsPerPage__Quotation;
      const endIndex = startIndex + this.itemsPerPage__Quotation;
      return this.filteredTableData__Quotation.slice(startIndex, endIndex);
    },
    totalPages__Quotation() {
      const totalPagesNum = Math.ceil(this.filteredTableData__Quotation.length / this.itemsPerPage__Quotation);
      return totalPagesNum;
    },
//
    m_data_option:{
      get: function () {
        return this.$store.state.m_data_option;
      },
      set: function (value) {
        this.$store.commit('m_data_option', value)
      }
    },
    totalSales(){
      return this.formData.prospect.reduce((total, group) => total + (parseFloat(group.sales) || 0), 0);
    },
    totalCost(){
      return this.formData.prospect.reduce((total, group) => total + (parseFloat(group.cost) || 0), 0);
    },
    totalGrossProfit(){
      return this.formData.prospect.reduce((total, group) => total + (parseFloat(group.grossProfit) || 0), 0);
    },
    totalChallengeSales() {
      // 複数のグループ内の売上（チャレンジ目標）を合算
      return this.formData.prospect.reduce((total, group) => total + (parseFloat(group.sales_challenge) || 0), 0);
    },
    totalChallengeCost() {
      // 複数のグループ内の原価（チャレンジ目標）を合算
      return this.formData.prospect.reduce((total, group) => total + (parseFloat(group.cost_challenge) || 0), 0);
    },
    totalChallengeGrossProfit() {
      // 複数のグループ内の粗利（チャレンジ目標）を合算
      return this.formData.prospect.reduce((total, group) => total + (parseFloat(group.grossProfit_challenge) || 0), 0);
    },
    login_data:{
      get: function () {
        return this.$store.state.login_data
      },
      set: function (value) {
        this.$store.commit('login_data', value)
      }
    },
  },
  methods: {
    updateValue(value,index,field) {
      console.log(value);
      const formatvalue = value.replace(/,/g, '');
      let rawValue = formatvalue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      this.formData.itemTable[index][field.key] = rawValue
    },
    valueReplace(value){
      return value.replace(/,/g, '');
    },
    valueReplaceUpdate(value){
      return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    valueReplaceManagement(value){
      const valued = value.replace(/,/g, '');
      this.formData.management = valued.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    cleanProspectData() {
      this.formData.itemTable = this.removeCommasFromValues(this.formData.itemTable);
      console.log(this.formData.itemTable);
    },
    removeCommasFromValues(input) {
      if (typeof input === 'string') {
        return input.replace(/,/g, '');
      } else if (Array.isArray(input)) {
        return input.map(this.removeCommasFromValues);
      } else if (typeof input === 'object' && input !== null) {
        const newObj = {};
        for (let key in input) {
          if (Object.prototype.hasOwnProperty.call(input, key)) {
            newObj[key] = this.removeCommasFromValues(input[key]);
          }
        }
        return newObj;
      }
      return input;
    },
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, '0');
      const day = today.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
//計算
    updateTotal(index) {
      // 合計金額を計算し、formDataにセット
      const tax = 1 + this.formData.itemTable[index].item__tax / 100
      console.log(tax)
      const price = this.valueReplace(this.formData.itemTable[index].item__price);
      const quantity = this.valueReplace(this.formData.itemTable[index].item__quantity);
      const total = Math.floor(price * quantity * tax);
      this.formData.itemTable[index].item__amount = this.formatNumber(total);
    },
    formatNumber(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
//モーダルウィンドウ
    inputFormSelect(value){
      this.formData.ProjectTitle = value
      this.getApplicationData(value)
      this.closeModal()
    },
    openModal() {
      this.selectModalFlag = true; // モーダルを表示
    },
    closeModal() {
      this.selectModalFlag = false; // モーダルを非表示
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    getTableData: async function (){
      let params = {
        target: 'Contract',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      const ProjectID = [];
      for(let i = 0; i < authed.records.length;i++){
        ProjectID.push(authed.records[i].ProjectId.value);
      }
      let uniqueProjectID = [...new Set(ProjectID)];
      console.log(uniqueProjectID);

      let ProjectRecord = [];
      for(let s = 0; s < uniqueProjectID.length;s++){
        console.log(uniqueProjectID[s])
        let params2 = {
          target: 'ProjectDetails',
          querys: JSON.stringify(
            [
              {
                column :'DeleteStatus',
                cond   :'in',
                data   :'使用',
              },
              {
                //対象カラム
                column :'$id',
                //比較条件
                cond   :'=',
                //比較内容
                data   :uniqueProjectID[s],
              }
            ]
          ),
        };
        let authed2 = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params2);
        console.log(authed2);
        ProjectRecord.push(authed2.records[0]);
      }
      console.log(ProjectRecord);
      this.tableData = ProjectRecord;
    },
    // getTableData: async function (){
    //   let params = {
    //     target: 'ProjectDetails',
    //     querys: JSON.stringify(
    //       [
    //         {
    //           column :'DeleteStatus',
    //           cond   :'in',
    //           data   :'使用',
    //         }
    //       ]
    //     ),
    //   };
    //   console.log(params)
    //   let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
    //   this.tableData = authed.records;
    //   console.log(authed)
    // },
    formatDateTime(dateTimeString) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', timeZoneName: 'short', weekday: 'short' };
      const dateTime = new Date(dateTimeString);
      const stringDate = dateTime.toLocaleString('ja-JP', options);
      if (stringDate === "Invalid Date") {
        return "";
      } else {
        return stringDate.replace("JST", '').replace(',', '');
      }
    },
//
//モーダルウィンドウ
    inputFormSelect__Order(value){
      this.formData.OrderName = value
      this.closeModal__Order()
    },
    openModal__Order() {
      this.selectModalFlag__Order = true; // モーダルを表示
    },
    closeModal__Order() {
      this.selectModalFlag__Order = false; // モーダルを非表示
    },
    prevPage__Order() {
      if (this.currentPage__Order > 1) {
        this.currentPage__Order--;
      }
    },
    nextPage__Order() {
      if (this.currentPage__Order < this.totalPages__Order) {
        this.currentPage__Order++;
      }
    },
    getTableData__Order: async function (){
      let params = {
        target: 'Order',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      this.tableData__Order = authed.records;
      console.log(authed)
    },
//
//取引先情報取得
ClientInput(ls_target){
      if(ls_target == ""){
        return false;
      }
      let data = this.clientSelectOption;
      const foundItems = data.filter(item => item.CustomerName.value === ls_target);
      console.log(foundItems);
      if(this.formData.Order == "受注"){
        //自社情報を委託者情報に引用
        this.formData.Consignor__company = "株式会社リップルコミュニティ";
        //取引先情報を受託者情報に引用
        this.formData.Entrustor__company = foundItems[0].CustomerName.value;
        this.formData.Entrustor__location= foundItems[0].Prefectures.value+foundItems[0].Municipalities.value+foundItems[0].BuildingName.value;
        this.formData.Entrustor__manager= foundItems[0].BusinessPartnerPersonNames.value;
        this.formData.Entrustor__number= foundItems[0].BusinessPartnerPersonTelephoneNumber.value;
        this.formData.Entrustor__mail= foundItems[0].BusinessPartnerPersonEmailAddress.value;
        
        this.formData.Entrustor__bank = foundItems[0].BankName.value;
        this.formData.Entrustor__bank_kana = foundItems[0].BankNameKana.value;
        this.formData.Entrustor__bank_num = foundItems[0].BankNumber.value;
        this.formData.Entrustor__bank_name = foundItems[0].BranchName.value;
        this.formData.Entrustor__bank_name_kana = foundItems[0].BranchNameKana.value;
        this.formData.Entrustor__bank_name_num = foundItems[0].BranchNumber.value;
        this.formData.Entrustor__account = foundItems[0].ByCourseType.value;
        this.formData.Entrustor__account_num = foundItems[0].AccountNumber.value;
        this.formData.Entrustor__receiver = foundItems[0].RecipientName.value;
        this.formData.Entrustor__receiver_kana = foundItems[0].RecipientNameKana.value;
        this.formData.Entrustor__invoice_num = foundItems[0].InvoiceRegistrationNumber.value;
      }
      if(this.formData.Order == "発注"){
        //自社情報を受託者情報に引用
        this.formData.Entrustor__company = "株式会社リップルコミュニティ";
        this.formData.Entrustor__location = "東京都新宿区西新宿6-12-7ストーク"
        this.formData.Entrustor__manager = "";
        this.formData.Entrustor__number = "0353154043";
        this.formData.Entrustor__mail = "info@ripty.jp";

        this.formData.Entrustor__bank = "";
        this.formData.Entrustor__bank_kana = "";
        this.formData.Entrustor__bank_num = "";
        this.formData.Entrustor__bank_name = "";
        this.formData.Entrustor__bank_name_kana = "";
        this.formData.Entrustor__bank_name_num = "";
        this.formData.Entrustor__account = "";
        this.formData.Entrustor__account_num = "";
        this.formData.Entrustor__receiver = "";
        this.formData.Entrustor__receiver_kana = "";
        this.formData.Entrustor__invoice_num = "";
        //取引先情報を委託者情報に引用
        this.formData.Consignor__company = foundItems[0].CustomerName.value;
      }
},
//モーダルウィンドウ
    inputFormSelect__Quotation(value){
      this.formData.Quotation = value
      this.closeModal__Quotation()
    },
    openModal__Quotation() {
      this.selectModalFlag__Quotation = true; // モーダルを表示
    },
    closeModal__Quotation() {
      this.selectModalFlag__Quotation = false; // モーダルを非表示
    },
    prevPage__Quotation() {
      if (this.currentPage__Quotation > 1) {
        this.currentPage__Quotation--;
      }
    },
    nextPage__Quotation() {
      if (this.currentPage__Quotation < this.totalPages__Quotation) {
        this.currentPage__Quotation++;
      }
    },

    generateInvoiceNumber(value) {
      const currentDate = new Date();
      const year = currentDate.getFullYear().toString();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');

      console.log(year + month);
      console.log(value);
      // 前回の年月と最後の請求番号を取得する

      const lastYearMonth = value.slice(0, 6);
      const lastInvoiceNumber = value;

      console.log(lastYearMonth);
      console.log(lastInvoiceNumber);

      if (lastYearMonth !== year + month) {
        this.formData.num = year + month + "0001";
      }else{
        const lastNumber = parseInt(lastInvoiceNumber.slice(-4));
        const newNumber = (lastNumber + 1).toString().padStart(4, '0');

        const newInvoiceNumber = year + month + newNumber;
        this.formData.num = newInvoiceNumber;
      }
    },
    getTableData__Quotation: async function (){
      let params = {
        target: 'Quotation',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      this.tableData__Quotation = authed.records;
      console.log(authed)
    },
//
    getClientData: async function (){
      let params = {
        target: 'Client',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.clientSelectOption = authed.records;
    },
    getClaimData: async function(){
      let params = {
        target: 'Claim',
        querys: JSON.stringify(
            [
              {
                //対象カラム
                column :'DeleteStatus',
                //比較条件
                cond   :'in',
                //比較内容
                data   : '使用',
              }
            ]
        ),
        // join : JSON.stringify(),
      };
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      this.generateInvoiceNumber(authed.records[0].num.value);
      this.dataLoading = true
    },
    getData : async function(){
      let params = {
        target: 'Claim',
        querys: JSON.stringify(
            [
              {
                //対象カラム
                column :'DeleteStatus',
                //比較条件
                cond   :'in',
                //比較内容
                data   : '使用',
              },
              {
                //対象カラム
                column :'$id',
                //比較条件
                cond   :'=',
                //比較内容
                data   :this.createRecordId,
              }
            ]
        ),
        // join : JSON.stringify(),
      };
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      let updateData = {
        record : authed.records[0]
      }
      console.log("updateData");
      console.log(updateData);
      this.$commonPlugin.dataUpdate(this.formData,updateData,this.itemTableTemplate);
      this.dataLoading = true
    },
    getCompanyData: async function (){
      let params = {
        target: 'Company',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.companySelectOption= authed.records;
    },
    getEmployeeData: async function (){
      let params = {
        target: 'Employee',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.EmployeeSelectOption = authed.records;
    },
    getDepartmentData: async function (){
      let params = {
        target: 'Department',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.departmentSelectOption = authed.records;
    },
    getApplicationData : async function(ls_target){
      const targetColumn = {
        //formData = database
        ProjectId : 'record_id',
        company   : 'company',
        department: 'department',
        authorizer: 'authorizer',
        manager   : 'manager',
        worker    : 'worker',
        Client    : 'Client',
      }
      let params = {
        target: 'ProjectDetails',
        querys: JSON.stringify(
            [
              {
                //対象カラム
                column :'DeleteStatus',
                //比較条件
                cond   :'in',
                //比較内容
                data   :'使用',
              },
              {
                //対象カラム
                column :'Title',
                //比較条件
                cond   :'in',
                //比較内容
                data   : ls_target,
              }
            ]
        ),
        // join : JSON.stringify(),
      };
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      let database = authed.records;
      if(!database.length){
        this.hasAlert.flag = true
        this.hasAlert.text = 'データが見つかりません'

        for (let key in targetColumn) {
          this.formData[key] = '';
        }

        return false;
      }else{
        this.hasAlert.flag = false
        this.hasAlert.text = ''
      }
      // フォーム復元
      // 復元したいカラム = 復元するデータ
      for(let i = 0; i < database.length;i++){
        for (let key in targetColumn) {
          if(database[i][targetColumn[key]]){
            this.formData[key] = database[i][targetColumn[key]].value;
            this.searchName__Order__Project = database[i].Title.value
            this.searchName__Quotation__Project = database[i].Title.value
          }
        }
      }
    },
    submitForm() {
      localStorage.setItem('hasSaved', 'true');
      if (this.$commonPlugin.validateForm(this.formData,this.errors)) {
        this.createFormMethod();
      }else{
        alert('入力内容にエラーが発生しました。');
      }
    },
    addPurchase(index){
      console.log(this.formData.itemTable.length);
      this.$commonPlugin.addArray(this.formData,index,'itemTable',this.itemTableTemplate);
      if(this.formData.itemTable.length > 14){
        console.log(index)
        this.formData.Sub = "ON";
        this.totalFlag = true;
      }else{
        this.formData.Sub = "OFF";
        this.totalFlag = false;
      }
    },
    removePurchase(index){
      console.log();
      this.$commonPlugin.removeArray(this.formData,index,'itemTable');
      if(this.formData.itemTable.length > 14){
        this.formData.Sub = "ON";
        this.totalFlag = true;
      }else{
        this.formData.Sub = "OFF";
        this.totalFlag = false;
      }
    },
    createFormMethod: async function(){
      console.log(this.formData);
      let pushArray = [];
      this.formData.LastUser = this.login_data["Names"].value
      for(let key in this.formData){
        let data = {};
        data.target = key;
        data.value = this.formData[key];
        pushArray.push(data);
      }
      let params = {
        target: 'Claim',
        data:JSON.stringify(pushArray),
        user: this.login_data["Names"].value,
        querys: JSON.stringify(
            [
              {
                //対象カラム
                column :'DeleteStatus',
                //比較条件
                cond   :'in',
                //比較内容
                data   : '使用',
              },
              {
                //対象カラム
                column :'$id',
                //比較条件
                cond   :'=',
                //比較内容
                data   :this.createRecordId,
              }
            ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_create_update_data_for_array_logic',params);
      this.$commonPlugin.dataUpdate(this.formData,authed,this.itemTableTemplate);
      if(authed.result.response === "失敗"){
        const msg = authed.result.errors;
        let messages = [];
        for (let key in msg) {
          if (msg[key].messages) {
            msg[key].messages.forEach((message) => {
              if (message !== "必須です。") {
                messages.push(message);
              }
            });
          }
        }
        alert('入力内容にエラーが発生しました。\n' +'・'+ messages.join('\n・'));
      }else{
        alert('保存しました。');
        var link = "";
        if(this.editFlag){
          link = "/ClaimDetails/"+authed.result.record.$id.value
          this.setData('ClaimDetails',authed.result.record.$id.value)
        }else{
          link = "/ClaimDetails/"+authed.result.id
          this.setData('ClaimDetails',authed.result.id)
        }
        this.$router.push(link);
      }
    },
    setData(page,id){
      this.setStrage('loginTimes',new Date());
      this.setStrage('pages',page);
      this.setStrage('id',id);
    },
    setStrage(target,value){
      localStorage.setItem(target,value);
    },
    inputCancel(){
    //  if (window.confirm('入力された内容を破棄してもよろしいですか？')) {
        var link = "/Claim";
        this.$router.push(link);
    //  }
    },
    handleFileChange(event) {
      this.UploadFlag = false;
      const files = event.target.files;
      let selectedFiles = [];
      // 選択したファイルを配列に追加
      for (let i = 0; i < files.length; i++) {
        if(this.formData[event.target.id] == ''){
          this.formData[event.target.id] = [];
        }
        this.formData[event.target.id].push(files[i]);
        selectedFiles.push(files[i]);
      }
      // console.log(event.target.id)
      this.createFileOnData(event.target.id,selectedFiles);
    },
    createFileOnData: async function(target,selectedFiles){
      let params = {
        target  : 'Claim',
        data    :JSON.stringify([
          {
            target : 'Worker',
            value  : this.login_data['Names'].value,
          }
        ]),
        filesTarget : 'File',
        files       : (selectedFiles)?selectedFiles : '',
      }
      // console.log(this.selectedFiles)
      let response = '';

      response = await this.$commonPlugin.axiosFileMultiAPI('file_upload_onData',params);

      console.log(response)
      this.formData[target] = [];
      response.result.forEach(item => {
        this.formData[target].push(...item.create_data);
      });
      console.log(this.formData);
      this.UploadFlag = true;
    },
  },
  created() {
    this.formData.Date = this.getCurrentDate();
    localStorage.setItem('hasSaved', 'false');
    // 受注・発注あるとき
    const urlParam = this.$commonPlugin.getUrlParameter('g'); // URLからパラメータを取得
    if (urlParam) {
      this.formData.Order = urlParam;
    }
    if(this.$commonPlugin.getUrlParameter('record_id')){
      this.createRecordId = this.$commonPlugin.getUrlParameter('record_id'); // URLからパラメータを取得
    }else{
      this.createRecordId = ""
    }
    if (this.createRecordId) {
      this.isEditFlag = true
    }
    const record_idParam = this.$commonPlugin.getUrlParameter('record_id');
    if(record_idParam){
      this.createRecordId = this.$commonPlugin.getUrlParameter('record_id'); // URLからパラメータを取得
    }
    const ProjectTitleParam = this.$commonPlugin.getUrlParameter('project_title');
    if(ProjectTitleParam){
      this.formData.ProjectTitle = this.$commonPlugin.getUrlParameter('project_title');
      this.getApplicationData(this.formData.ProjectTitle);
    }
    const ProjectIdParam = this.$commonPlugin.getUrlParameter('project_id');
    if(ProjectIdParam){
      this.formData.ProjectId = this.$commonPlugin.getUrlParameter('project_id');
    }

    console.log('-----------getData--------------')
    this.getData();
    this.getClientData();
    this.getEmployeeData();
    this.getCompanyData();
    this.getDepartmentData();
    this.getTableData();
    this.getTableData__Order();
    this.getTableData__Quotation();
    this.getClaimData();
    this.copyFlag = this.$commonPlugin.getUrlParameter('copy');
    this.editFlag = this.$commonPlugin.getUrlParameter('edit');
    if (this.copyFlag) {
      this.createRecordId = ""
      this.editFlag = false
    }
    if (!this.copyFlag && !this.editFlag){
      this.createFlag = true
    }
  },
  mounted() {
    this.formData.author = this.login_data.Names.value
    this.searchAuthor = this.login_data.Names.value;
    this.searchWorker = this.login_data.Names.value;
    this.searchAuthor__Order= this.login_data.Names.value;
    this.searchWorker__Order = this.login_data.Names.value;
    this.searchAuthor__Quotation = this.login_data.Names.value;
    this.searchWorker__Quotation = this.login_data.Names.value;
  }
};


</script>