import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import BootstrapVue3 from 'bootstrap-vue-3'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

import commonPlugin from '@/assets/script/plugins/common'

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import '@fortawesome/fontawesome-free/css/all.css'; // この行を追加



library.add(faCoffee);

const commonPluginInstance = new commonPlugin();
const app = createApp(App);

app.config.globalProperties.$commonPlugin = commonPluginInstance;
app
    .use(store)
    .use(BootstrapVue3)
    .use(router)
    .component('fa', FontAwesomeIcon)
    .mount('#app')



