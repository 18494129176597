
<template>
  <div class="pageHeader">
    <h1 v-if="editFlag">注文編集({{ createRecordId }})</h1>
    <h1 v-if="copyFlag">注文複製</h1>
    <h1 v-if="createFlag">注文作成</h1>
    <button class="normalButton" @click="submitForm" :disabled="!UploadFlag">保存</button>
    <button class="normalButton" @click="inputCancel">キャンセル</button>
  </div>
  <div class="content-block">
    <div id="formImput">
      <form ref="myForm">
        <div class="FormGroup">
          <!-- 案件名 -->
          <h2>紐付け情報</h2>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Project">案件名</label>
                <span class="required">必須</span>
                <span class="tooltip-content">案件名の入力フォームです。(必須項目)</span>
              </div>
              <div class="inputBlock" :class="{ 'hasError': hasAlert.flag }">
                <input type="text" id="Project" :class="{ 'hasError': hasAlert.flag }" v-model="formData.Project" @input="getApplicationData(formData.Project)" />
                <span class="hasError-content">{{ hasAlert.text }}</span>
                <span class="modallink" @click="openModal()">選択する</span>
              </div>
            </div>
            <div class="inputCol-2-block" style="display:none;">
              <div class="nameBlock">
                <label for="projectId">案件ID</label>
                <span class="tooltip-content">案件IDの確認フォームです。(任意項目)</span>
              </div>
              <div class="inputBlock" >
                <input type="text" id="projectId" v-model="formData.projectId" readonly />
              </div>
            </div>
          </div>
        </div>
<!-- モーダルウィンドウ -->
        <div v-if="selectModalFlag === true" class="modal" style="display: flex;">
          <div class="modal-content">
            <h2>案件選択</h2>
            <img src="../assets/image/close.png" class="close-right" @click="closeModal">
            <div class="searchBox">
              <div class="searchBox-content">
                   <div>
                    <label>案件名</label>
                    <input type="text" placeholder="案件名" v-model="searchName" style="width: 50%;"/>
                  </div>
                <div class="searchBox-content-block">
                  <div>
                    <label>取引先</label>
                      <select v-model="searchClient">
                        <option></option>
                        <option v-for="(option, index) in clientSelectOption" :key="index" :value="option.CustomerName.value">{{ option.CustomerName.value }}</option>
                      </select>
                  </div>
                  <div>
                    <label>作業者</label>
                      <select v-model="searchWorker">
                        <option></option>
                        <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                      </select>
                  </div>
                  <div>
                    <label>作成者</label>
                      <select v-model="searchAuthor">
                        <option></option>
                        <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                      </select>
                  </div>
                  <div>
                    <label>作成日</label>
                    <input type="date" v-model="searchStart"/> ~
                    <input type="date" v-model="searchEnd"/>
                  </div>
                </div>
                <div class="searchBox-content-block">
                  <div>
                    <label>承認ステータス</label>
                      <select v-model="searchApproval">
                        <option></option>
                        <option>未承認</option>
                        <option>申請中</option>
                        <option>承認済み</option>
                      </select>
                  </div>
                  <div>
                    <label>回答ステータス</label>
                      <select v-model="searchAnswer">
                        <option></option>
                        <option>対応中</option>
                        <option>完了</option>
                        <option>中止</option>
                      </select>
                  </div>
                </div>
              </div>
            </div>
            <table class="authorize-table">
              <thead>
                <th></th>
                <th>案件コード</th>
                <th>案件名</th>
                <th>取引先</th>
                <th>作成日時</th>
                <th>作業者</th>
                <th>作成者</th>
                <th>承認</th>
                <th>回答</th>
              </thead>
              <tbody>
                <tr v-for="(item, index) in paginatedData" :key="index">
                  <td @click="inputFormSelect(item.Title.value)" v-if="item.ApprovalStatus.value === '承認済み'"><span class="pointer">選択</span></td>
                  <td v-else></td>
                  <td>{{ item.$id.value }}</td>
                  <td>{{ item.Title.value }}</td>
                  <td>{{ item.Client.value }}</td>
                  <td>{{ formatDateTime(item.作成日時.value) }}</td>
                  <td>{{ item.worker.value }}</td>
                  <td>{{ item.author.value }}</td>
                  <td>{{ item.ApprovalStatus.value }}</td>
                  <td>{{ item.AnswerStatus.value }}</td>
                </tr>
              </tbody>
            </table>
            <div class="pagination">
              <span>{{ currentPage }} / {{ totalPages }}</span>
              <img class="arrowButton" src="../assets/image/Expand_Arrow.png" @click="prevPage" :disabled="currentPage === 1">
              <img class="arrowButton" src="../assets/image/Expand_Arrow2.png" @click="nextPage" :disabled="currentPage * itemsPerPage >= totalFilteredItems" >
            </div>
            <div class="modal-button-block">
              <button class="normalButton" @click="closeModal">閉じる</button>
            </div>
          </div>
        </div>
<!-- モーダルウィンドウ -->

        <div class="FormGroup">
          <h2>注文情報</h2>
          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Order">受注/発注</label>
                <span class="required">必須</span>
                <span class="tooltip-content">受注/発注の入力フォームです。(必須項目)</span>
              </div>
              <div class="inputBlock">
                <div class="radio" v-if=!isEditFlag >
                  <div class="radio-block">
                    <label>受注</label>
                    <input type="radio" name="order" v-model="formData.Order" value="受注" @change="OrderInput('受注')">
                  </div>
                  <div class="radio-block">
                    <label>発注</label>
                    <input type="radio" name="order" v-model="formData.Order" value="発注" @change="OrderInput('発注')">
                  </div>
                </div>
                <div v-else>
                {{ formData.Order }}
                </div>
                <span>*保存後は変更不可。</span>
              </div>

            </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Title">注文名</label>
                <span class="required">必須</span>
                <span class="tooltip-content">注文名の入力フォームです。(必須項目)</span>
              </div>
              <div class="inputBlock" :class="{ 'hasError': !errors.Title.flag }">
                <input type="text" id="Title" v-model="formData.Title" :class="{ 'hasError': !errors.Title.flag }" />
                <span class="hasError-content">{{ errors.Title.msg }}</span>
              </div>
            </div>
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Client">取引先</label>
                <span class="required">必須</span>
                <span class="tooltip-content">取引先の入力フォームです。(必須項目)</span>
              </div>
              <div class="inputBlock" :class="{ 'hasError': !errors.Client.flag }">
                <select v-model="formData.Client" @change="ClientInput(formData.Client)">
                  <option></option>
                  <option v-for="(option, index) in clientSelectOption" :key="index" :value="option.CustomerName.value">{{ option.CustomerName.value }}</option>
                </select>
                <span class="hasError-content">{{ errors.Client.msg }}</span>
              </div>
            </div>
          </div>

        </div>

        <div class="FormGroup">
          <h2>注文書記載内容</h2>
          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="Consignor">委託者情報</label>
              <span class="tooltip-content">委託者情報の入力フォームです。</span>
            </div>
            <div class="inputBlock">
              <div>
                <label>会社名</label>
                <input type="text" v-model="formData.Consignor__company">
              </div>
              <div>
              </div>
              <div>
                <label>契約者の役職</label>
                <input type="text" v-model="formData.Consignor__post">
              </div>
              <div>
                <label>契約者の氏名</label>
                <input type="text" v-model="formData.Consignor__name">
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="Trustee">受託者情報</label>
              <span class="tooltip-content">受託者情報の入力フォームです。</span>
            </div>
            <div class="inputBlock">
              <div>
                <label>会社名</label>
                <input type="text" v-model="formData.Entrustor__company">
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Date__issue">発行日</label>
                <span class="tooltip-content">発行日の入力フォームです。</span>
              </div>
              <div class="inputBlock">
                <input type="date" id="Date__issue" v-model="formData.Date__issue"/>
              </div>
            </div>
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Date__conclusion">締結日</label>
                <span class="tooltip-content">締結日の入力フォームです。</span>
              </div>
              <div class="inputBlock">
                <input type="date" id="Date__conclusion" v-model="formData.Date__conclusion"/>
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="ConcludingSentence">契約締結文</label>
              <span class="tooltip-content">契約締結文の入力フォームです。</span>
            </div>
            <div class="inputBlock">
              <textarea id="ConcludingSentence" v-model="formData.ConcludingSentence"></textarea>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="Content">業務内容</label>
              <span class="tooltip-content">業務内容の入力フォームです。</span>
            </div>
            <div class="inputBlock">
              <textarea id="Content" v-model="formData.Content"></textarea>
            </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Location">実施場所</label>
                <span class="tooltip-content">実施場所の入力フォームです。</span>
              </div>
              <div class="inputBlock">
                <input type="text" id="Location" v-model="formData.Location"/>
              </div>
            </div>
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="ContractPeriod">契約期間</label>
                <span class="tooltip-content">契約期間の入力フォームです。</span>
              </div>
              <div class="inputBlock">
                <input style="width:45%;" type="date" id="Date__start" v-model="formData.Date__start"/>
                 ~ 
                <input style="width:45%;" type="date" id="Date__end" v-model="formData.Date__end"/>
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Commission">委託料</label>
                <span class="tooltip-content">委託料の入力フォームです。</span>
              </div>
              <div class="inputBlock">
                <input type="text" id="Commission" v-model="formData.Commission"/>
              </div>
            </div>
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Payment">支払条件</label>
                <span class="tooltip-content">支払条件の入力フォームです。</span>
              </div>
              <div class="inputBlock">
                <input type="text" id="Payment" v-model="formData.Payment"/>
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="Remarks">備考</label>
              <span class="tooltip-content">備考の入力フォームです。</span>
            </div>
            <div class="inputBlock">
              <textarea id="Remarks" v-model="formData.Remarks"></textarea>
            </div>
          </div>

          <div class="inputCol inputCol-1">
            <div class="nameBlock">
              <label for="Files">関連ファイル</label>
            </div>
            <div class="inputBlock">
              <template v-if="formData.Files.length > 0">
                <div v-for="file in formData.Files" :key="file.name">{{ file.name }}</div>
                <!-- <label>変更</label><input type="file" id="Document" @change="handleFileChange" multiple /> -->
              </template>
              <template v-else>
                <input type="file" id="Files" @change="handleFileChange" multiple />
              </template>
              <template v-if="!UploadFlag">
                <img src="../assets/image/ZZ5H.gif" style="width: 25px;height: 25px;margin-left: 12px;">
              </template>
            </div>
          </div>

        </div>

    <div class="FormGroup">
      <h2>担当情報</h2>
        <!-- 会社 -->
        <div class="inputCol inputCol-2">
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="company">会社</label>
              <span class="required">必須</span>
              <span class="tooltip-content">会社の入力フォームです。(必須項目)</span>
            </div>
            <div class="inputBlock" :class="{ 'hasError': !errors.company.flag }">
              <select id="company" :class="{ 'hasError': !errors.company.flag }" v-model="formData.company">
                <option></option>
                <option v-for="(option, index) in companySelectOption" :key="index" :value="option.CompanyName.value">{{ option.CompanyName.value }}</option>
              </select>
              <span class="hasError-content">{{ errors.company.msg }}</span>
            </div>
          </div>
        <!-- 部署 -->
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="department">部署</label>
              <span class="required">必須</span>
              <span class="tooltip-content">部署の入力フォームです。(必須項目)</span>
            </div>
            <div class="inputBlock" :class="{ 'hasError': !errors.department.flag }">
              <select id="department" :class="{ 'hasError': !errors.department.flag }" v-model="formData.department">
                <option></option>
                <option v-for="(option, index) in departmentSelectOption" :key="index" :value="option.Department_name.value">{{ option.Department_name.value }}</option>
              </select>
              <span class="hasError-content">{{ errors.department.msg }}</span>
            </div>
          </div>
        </div>

        <!-- 承認者 -->
        <div class="inputCol inputCol-2">
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="authorizer">承認者</label>
              <span class="required">必須</span>
              <span class="tooltip-content">承認者の入力フォームです。(必須項目)</span>
            </div>
            <div class="inputBlock" :class="{ 'hasError': !errors.authorizer.flag }">
              <select id="authorizer" :class="{ 'hasError': !errors.authorizer.flag }" v-model="formData.authorizer">
                <option></option>
                <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
              </select>
              <span class="hasError-content">{{ errors.authorizer.msg }}</span>
            </div>
          </div>
        <!-- 担当者 -->
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="manager">担当者</label>
              <span class="required">必須</span>
              <span class="tooltip-content">担当者の入力フォームです。(必須項目)</span>
            </div>
            <div class="inputBlock" :class="{ 'hasError': !errors.manager.flag }">
              <select id="manager" :class="{ 'hasError': !errors.manager.flag }" v-model="formData.manager">
                <option></option>
                <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
              </select>
              <span class="hasError-content">{{ errors.manager.msg }}</span>
            </div>
          </div>
        </div>

        <!-- 作業者 -->
        <div class="inputCol inputCol-2">
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="worker">作業者</label>
              <span class="required">必須</span>
              <span class="tooltip-content">作業者の入力フォームです。(必須項目)</span>
            </div>
            <div class="inputBlock" :class="{ 'hasError': !errors.worker.flag }">
              <select id="worker" :class="{ 'hasError': !errors.worker.flag }" v-model="formData.worker">
                <option></option>
                <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
              </select>
              <span class="hasError-content">{{ errors.worker.msg }}</span>
            </div>
          </div>
        <!-- 作成者 -->
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="author">作成者</label>
              <span class="required">必須</span>
              <span class="tooltip-content">作成者の入力フォームです。(自動入力)</span>
            </div>
            <div class="inputBlock">
              <input type="text" id="author" :class="{ 'hasError': !errors.author.flag }" v-model="formData.author" disabled>
              <span class="hasError-content">{{ errors.author.msg }}</span>
            </div>
          </div>
        </div>

    </div>

        <!-- 社内管理情報 -->
        
        <div class="FormGroup">
        <h2>社内管理情報</h2>
        <!-- 社内メモ -->
          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="internalMemo">社内メモ</label>
              <span class="tooltip-content">社内メモの入力フォームです。</span>
            </div>
            <div class="inputBlock">
              <textarea id="internalMemo" v-model="formData.internalMemo"></textarea>
            </div>
          </div>

        <!-- 関連ファイル -->
        
          <div class="inputCol inputCol-1">
            <div class="nameBlock">
              <label for="relatedFiles">関連ファイル</label>
            </div>
            <div class="inputBlock">
              <template v-if="formData.relatedFiles.length > 0">
                <div v-for="file in formData.relatedFiles" :key="file.name">{{ file.name }}</div>
                <!-- <label>変更</label><input type="file" id="Document" @change="handleFileChange" multiple /> -->
              </template>
              <template v-else>
                <input type="file" id="relatedFiles" @change="handleFileChange" multiple />
              </template>
              <template v-if="!UploadFlag">
                <img src="../assets/image/ZZ5H.gif" style="width: 25px;height: 25px;margin-left: 12px;">
              </template>
            </div>
          </div>

        </div>
      </form>
    </div>
    <button class="normalButton" @click="submitForm" :disabled="!UploadFlag" style="float: right; width: 140px;">保存</button>
  </div>

<div v-if="!dataLoading" class="loading">
  <img src="../assets/image/ZZ5H.gif">
</div>
</template>



<script>

export default {
  components: {
  },
  data() {
    return {
      formData: {
        Project: '',
        LastUser: '',
        projectId: '',
        Order: '',
        Title: '',
        Client: '',
        Consignor__company: '',
        Consignor__post: '',
        Consignor__name: '',
        Entrustor__company: '',
        Date__issue: '',
        Date__conclusion: '',
        ConcludingSentence: '貴殿との間で２０２３年５月３１日に締結した「業務委託契約書」（以下「基本契約」という）に基づき、弊社は貴社に対して下記の通り業務を委託します。',
        Content: '',
        Location: '',
        Date__start: '',
        Date__end: '',
        Commission: '',
        Payment: '',
        Remarks: '・「別紙」仕様書／見積書による業務内容及び範囲等に変更が生じた場合、委託者及び受託者の協議をもって、本委託料の変更をすることができる。\n・個別契約の履行に際して、作業従事者に対し、指揮命令は行わないものとする。\n・委託者は、受託者が受託した業務の遂行において委託者に損害を与えた場合、受託者に対し、賠償を請求できるものとする。',

        company: '',
        department: '',
        authorizer: '',
        manager: '',
        worker: '',
        author: '',
        internalMemo: '',

        Files: [],
        relatedFiles: [], // ファイルデータを格納する配列
      },

      errors: {
        Project: { msg:'', flag: true},
        Title: {msg:'', flag: true},
        Order: { msg:'', flag: true},
        Client: { msg:'', flag: true},
        company: { msg:'', flag: true},
        department: { msg:'', flag: true},
        authorizer: { msg:'', flag: true},
        manager: { msg:'', flag: true},
        worker: { msg:'', flag: true},
        author: { msg:'', flag: true},
      },

//
      clientSelectOption: [],
      companySelectOption: [],
      departmentSelectOption: [],
      EmployeeSelectOption: [],

      hasAlert : {
        flag : false,
        text : ''
      },

      isEditFlag : false,

      selectedFiles: [],
      formGroups: [['']],

      copyFlag: '',
      editFlag: '',
      createFlag: '',

//モーダルウィンドウ
      selectModalFlag: false,
      currentPage: 1, // 現在のページ
      itemsPerPage: 10, // 1ページあたりのアイテム数
      tableData: [],
      searchName: "",
      searchAuthor: "",
      searchClient: "",
      searchWorker: "",
      searchApproval: "承認済み",
      searchAnswer: "対応中",
      searchStart: "",
      searchEnd: "",
//
      UploadFlag: true,
    };
  },
  computed: {
//モーダルウィンドウ
filteredTableData() {
        console.log(this.tableData);
        let filteredData = this.tableData.filter((item) => {
        console.log(item)
        const startDate = new Date(this.searchStart);
        const endDate = new Date(this.searchEnd);
        const itemDate = new Date(item.作成日時.value);

        return (
          (this.searchName === "" || item.Title.value.includes(this.searchName)) &&
          (this.searchClient === "" || item.Client.value === this.searchClient) &&
          (this.searchWorker === "" || item.worker.value === this.searchWorker) && 
          (this.searchAuthor === "" || item.author.value === this.searchAuthor) &&
          (this.searchApproval === "" || item.ApprovalStatus.value === this.searchApproval) &&
          (this.searchAnswer === "" || item.AnswerStatus.value === this.searchAnswer) &&
          (isNaN(startDate) || itemDate >= startDate) &&  //開始日が未指定または作成日時が開始日以降
          (isNaN(endDate) || itemDate <= endDate)  //終了日が未指定または作成日時が終了日以前
        );
      });
      return filteredData;
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      console.log(this.filteredTableData);
      return this.filteredTableData.slice(startIndex, endIndex);
    },
    totalPages() {
      const totalPagesNum = Math.ceil(this.filteredTableData.length / this.itemsPerPage);
      return totalPagesNum;
    },
//
    totalSales(){
      return this.formData.prospect.reduce((total, group) => total + (parseFloat(group.sales) || 0), 0);
    },
    totalCost(){
      return this.formData.prospect.reduce((total, group) => total + (parseFloat(group.cost) || 0), 0);
    },
    totalGrossProfit(){
      return this.formData.prospect.reduce((total, group) => total + (parseFloat(group.grossProfit) || 0), 0);
    },
    totalChallengeSales() {
      // 複数のグループ内の売上（チャレンジ目標）を合算
      return this.formData.prospect.reduce((total, group) => total + (parseFloat(group.sales_challenge) || 0), 0);
    },
    totalChallengeCost() {
      // 複数のグループ内の原価（チャレンジ目標）を合算
      return this.formData.prospect.reduce((total, group) => total + (parseFloat(group.cost_challenge) || 0), 0);
    },
    totalChallengeGrossProfit() {
      // 複数のグループ内の粗利（チャレンジ目標）を合算
      return this.formData.prospect.reduce((total, group) => total + (parseFloat(group.grossProfit_challenge) || 0), 0);
    },
    login_data:{
      get: function () {
        return this.$store.state.login_data
      },
      set: function (value) {
        this.$store.commit('login_data', value)
      }
    },
  },
  created() {
    localStorage.setItem('hasSaved', 'false');
    const urlParam = this.$commonPlugin.getUrlParameter('g'); // URLからパラメータを取得
    if (urlParam) {
      this.formData.Order = urlParam;
    }
    if(this.$commonPlugin.getUrlParameter('record_id')){
      this.createRecordId = this.$commonPlugin.getUrlParameter('record_id'); // URLからパラメータを取得
    }else{
      this.createRecordId = ""
    }

    const record_idParam = this.$commonPlugin.getUrlParameter('record_id');
    if(record_idParam){
      this.createRecordId = this.$commonPlugin.getUrlParameter('record_id'); // URLからパラメータを取得
    }
    const ProjectTitleParam = this.$commonPlugin.getUrlParameter('project_title');
    if(ProjectTitleParam){
      this.formData.Project = this.$commonPlugin.getUrlParameter('project_title');
      this.getApplicationData(this.formData.Project);
    }
    const ProjectIdParam = this.$commonPlugin.getUrlParameter('project_id');
    if(ProjectIdParam){
      this.formData.ProjectId = this.$commonPlugin.getUrlParameter('project_id');
    }

    console.log('-----------getTestData--------------')
    this.getData();
    console.log('-----------getTestData--------------')
    this.getClientData();
    this.getEmployeeData();
    this.getCompanyData();
    this.getDepartmentData();

    this.formData.Date__issue = this.getCurrentDate();

//モーダルウィンドウ
    this.getTableData();
//

    this.copyFlag = this.$commonPlugin.getUrlParameter('copy');
    this.editFlag = this.$commonPlugin.getUrlParameter('edit');
    if (this.copyFlag) {
      this.createRecordId = ""
      this.editFlag = false
    }
    if (!this.copyFlag && !this.editFlag){
      this.createFlag = true
    }
  },
  methods: {
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, '0');
      const day = today.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
//モーダルウィンドウ
    inputFormSelect(value){
      this.formData.Project = value
      this.getApplicationData(value);
      this.ClientInput(this.formData.Client)
      this.closeModal()
    },
    openModal() {
      this.selectModalFlag = true; // モーダルを表示
    },
    closeModal() {
      this.selectModalFlag = false; // モーダルを非表示
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    getTableData: async function (){
      let params = {
        target: 'Contract',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      const ProjectID = [];
      for(let i = 0; i < authed.records.length;i++){
        ProjectID.push(authed.records[i].ProjectId.value);
      }
      let uniqueProjectID = [...new Set(ProjectID)];
      console.log(uniqueProjectID);

      let ProjectRecord = [];
      for(let s = 0; s < uniqueProjectID.length;s++){
        console.log(uniqueProjectID[s])
        let params2 = {
          target: 'ProjectDetails',
          querys: JSON.stringify(
            [
              {
                column :'DeleteStatus',
                cond   :'in',
                data   :'使用',
              },
              {
                //対象カラム
                column :'$id',
                //比較条件
                cond   :'=',
                //比較内容
                data   :uniqueProjectID[s],
              }
            ]
          ),
        };
        let authed2 = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params2);
        console.log(authed2);
        ProjectRecord.push(authed2.records[0]);
      }
      console.log(ProjectRecord);
      this.tableData = ProjectRecord;
    },
    formatDateTime(dateTimeString) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', timeZoneName: 'short', weekday: 'short' };
      const dateTime = new Date(dateTimeString);
      const stringDate = dateTime.toLocaleString('ja-JP', options);
      if (stringDate === "Invalid Date") {
        return "";
      } else {
        return stringDate.replace("JST", '').replace(',', '');
      }
    },
//
//取引先情報取得
    ClientInput(ls_target){
      if(ls_target == ""){
        this.formData.Consignor__company = "";
        this.formData.Consignor__name = "";
        this.formData.Entrustor__company = "";
      }
      let data = this.clientSelectOption;
      const foundItems = data.filter(item => item.CustomerName.value === ls_target);
      console.log(foundItems);

      if(this.formData.Order == "受注"){
        //委託者情報に引用

        this.formData.Consignor__company = foundItems[0].CustomerName.value
        this.formData.Consignor__name = foundItems[0].BusinessPartnerPersonNames.value
        //役職が必要？
        //受託者情報に自社情報を引用
        this.formData.Entrustor__company = "株式会社リップルコミュニティ";
      }
      if(this.formData.Order == "発注"){
        this.formData.Consignor__company = "株式会社リップルコミュニティ";
        this.formData.Consignor__name = "山口 龍佑";
        //役職が必要？
        this.formData.Entrustor__company = foundItems[0].CustomerName.value;
      }
    },
    OrderInput(ls_target){
      console.log(ls_target);
        this.formData.Consignor__company = "";
        this.formData.Consignor__name = "";
        this.formData.Entrustor__company = "";
    },
//

    getClientData: async function (){
      let params = {
        target: 'Client',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            },
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.clientSelectOption = authed.records;
    },
    getData : async function(){
      let params = {
        target: 'Order',
        querys: JSON.stringify(
            [
              {
                //対象カラム
                column :'DeleteStatus',
                //比較条件
                cond   :'in',
                //比較内容
                data   : '使用',
              },
              {
                //対象カラム
                column :'$id',
                //比較条件
                cond   :'=',
                //比較内容
                data   :this.createRecordId,
              }
            ]
        ),
        // join : JSON.stringify(),
      };
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      let updateData = {
        record : authed.records[0]
      }
      this.$commonPlugin.dataUpdate(this.formData,updateData);
      this.dataLoading = true
    },
    getEmployeeData: async function (){
      let params = {
        target: 'Employee',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.EmployeeSelectOption = authed.records;
    },
    getCompanyData: async function (){
      let params = {
        target: 'Company',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.companySelectOption= authed.records;
    },
    getDepartmentData: async function (){
      let params = {
        target: 'Department',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.departmentSelectOption = authed.records;
    },
    getApplicationData : async function(ls_target){
      const targetColumn = {
        //formData = database
        projectId : 'record_id',
        company   : 'company',
        department: 'department',
        authorizer: 'authorizer',
        manager   : 'manager',
        worker    : 'worker',
        Client    : 'Client',
      }
      let params = {
        target: 'ProjectDetails',
        querys: JSON.stringify(
            [
              {
                //対象カラム
                column :'DeleteStatus',
                //比較条件
                cond   :'in',
                //比較内容
                data   :'使用',
              },
              {
                //対象カラム
                column :'Title',
                //比較条件
                cond   :'in',
                //比較内容
                data   : ls_target,
              }
            ]
        ),
        // join : JSON.stringify(),
      };
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      let database = authed.records;
      console.log(database)
      if(!database.length){
        this.hasAlert.flag = true
        this.hasAlert.text = 'データが見つかりません'

        for (let key in targetColumn) {
          this.formData[key] = '';
        }

        return false;
      }else{
        this.hasAlert.flag = false
        this.hasAlert.text = ''
      }
      // フォーム復元
      // 復元したいカラム = 復元するデータ
      for(let i = 0; i < database.length;i++){
        for (let key in targetColumn) {
          if(database[i][targetColumn[key]]){
            this.formData[key] = database[i][targetColumn[key]].value;
          }
        }
      }
    },
    submitForm() {
      localStorage.setItem('hasSaved', 'true');
      if (this.$commonPlugin.validateForm(this.formData,this.errors)) {
        this.createFormMethod();
      }else{
        alert('入力内容にエラーが発生しました。');
      }
    },
    createFormMethod: async function(){
      console.log(this.formData);
      this.formData.LastUser = this.login_data["Names"].value
      console.log(this.formData);
      let pushArray = [];
      for(let key in this.formData){
        let data = {};
        data.target = key;
        data.value = this.formData[key];
        pushArray.push(data);
      }
      let params = {
        target: 'Order',
        data:JSON.stringify(pushArray),
        user: this.login_data["Names"].value,
        querys: JSON.stringify(
            [
              {
                //対象カラム
                column :'DeleteStatus',
                //比較条件
                cond   :'in',
                //比較内容
                data   : '使用',
              },
              {
                //対象カラム
                column :'$id',
                //比較条件
                cond   :'=',
                //比較内容
                data   :this.createRecordId,
              }
            ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_create_update_data_for_array_logic',params);
      this.$commonPlugin.dataUpdate(this.formData,authed);

      if(authed.result.response === "失敗"){
        const msg = authed.result.errors;
        let messages = [];
        for (let key in msg) {
          if (msg[key].messages) {
            msg[key].messages.forEach((message) => {
              if (message !== "必須です。") {
                messages.push(message);
              }
            });
          }
        }
        alert('入力内容にエラーが発生しました。\n' +'・'+ messages.join('\n・'));
      }else{
        alert('保存しました。');
        var link = "";
        if(this.editFlag){
          link = "/OrderDetails/"+authed.result.record.$id.value
          this.setData('OrderDetails',authed.result.record.$id.value)
        }else{
          console.log(authed);
          link = "/OrderDetails/"+authed.result.id
          this.setData('OrderDetails',authed.result.id.value)
        }
        this.$router.push(link);
      }
    },
    setData(page,id){
      this.setStrage('loginTimes',new Date());
      this.setStrage('pages',page);
      this.setStrage('id',id);
    },
    setStrage(target,value){
      localStorage.setItem(target,value);
    },
    handleFileChange(event) {
      this.UploadFlag = false;
      const files = event.target.files;
      let selectedFiles = [];
      // 選択したファイルを配列に追加
      for (let i = 0; i < files.length; i++) {
        if(this.formData[event.target.id] == ''){
          this.formData[event.target.id] = [];
        }
        this.formData[event.target.id].push(files[i]);
        selectedFiles.push(files[i]);
      }
      // console.log(event.target.id)
      this.createFileOnData(event.target.id,selectedFiles);
    },
    createFileOnData: async function(target,selectedFiles){
      let params = {
        target  : 'Order',
        data    :JSON.stringify([
          {
            target : 'Worker',
            value  : this.login_data['Names'].value,
          }
        ]),
        filesTarget : 'File',
        files       : (selectedFiles)?selectedFiles : '',
      }
      // console.log(this.selectedFiles)
      let response = '';

      response = await this.$commonPlugin.axiosFileMultiAPI('file_upload_onData',params);

      console.log(response)
      this.formData[target] = [];
      response.result.forEach(item => {
        this.formData[target].push(...item.create_data);
      });
      console.log(this.formData);
      this.UploadFlag = true;
    },
    addGroup() {
      // 新しいグループを追加
      this.formGroups.push(['']);
    },
    removeGroup(index) {
      // グループを削除
      this.formGroups.splice(index, 1);
    },
    inputCancel(){
    //  if (window.confirm('入力された内容を破棄してもよろしいですか？')) {
        var link = "/Order";
        this.$router.push(link);
    //  }
    }
  },
  mounted() {
    this.formData.author = this.login_data.Names.value;
    this.searchAuthor = this.login_data.Names.value;
    this.searchWorker = this.login_data.Names.value;
    console.log(this.login_data)
  }
};


</script>