
<template>
<div class="modal" v-if="isModalOpen" style="display:flex;">
  <div class="modal-content">
    <h2>{{ this.ModalTitle }}</h2>
    <img src="../assets/image/close.png" class="close-right" @click="closeModal">
      <div id="formImput">
        <div class="FormGroup">
          <!-- 案件名 -->
          <h2 style="font-size:18px;margin-top:20px;margin-bottom:12px;">紐付け情報</h2>
          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="ProjectTitle">案件名</label>
                <span class="required">必須</span>
                <span class="tooltip-content">案件名の入力フォームです。(必須項目)</span>
              </div>
              <div class="inputBlock" :class="{ 'hasError': hasAlert.flag }">
                <input type="text" id="ProjectTitle" :class="{ 'hasError': hasAlert.flag }" v-model="formData.ProjectTitle" @input="getApplicationData(formData.ProjectTitle)" />
                <span class="hasError-content">{{ hasAlert.text }}</span>
                <span class="modallink" @click="openModal__Project()">選択する</span>
              </div>
            </div>
            <div class="inputCol-2-block" style="display:none;">
              <div class="nameBlock">
                <label for="ProjectId">案件ID</label>
                <span class="tooltip-content">案件IDの確認フォームです。(任意項目)</span>
              </div>
              <div class="inputBlock" >
                <input type="text" id="ProjectId" v-model="formData.ProjectId" readonly />
              </div>
            </div>
          </div>
        </div>
        <div class="FormGroup">
          <h2 style="font-size:18px;margin-top:20px;margin-bottom:12px;">契約情報</h2>
          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Title">契約名</label>
                <span class="required">必須</span>
                <span class="tooltip-content">契約名の入力フォームです。(必須項目)</span>
              </div>
              <div class="inputBlock" :class="{ 'hasError': !errors.Title.flag }">
                <input type="text" id="Title" v-model="formData.Title" required  :class="{ 'hasError': !errors.Title.flag }" />
                <span class="hasError-content">{{ errors.Title.msg }}</span>
              </div>
            </div>
          <!-- 取引先 -->
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Client">取引先</label>
                <span class="required">必須</span>
                <span class="tooltip-content">取引先の入力フォームです。(必須項目)</span>
              </div>
              <div class="inputBlock" :class="{ 'hasError': !errors.Client.flag }">
                <select v-model="formData.Client" :class="{ 'hasError': !errors.Title.flag }">
                  <option></option>
                  <option v-for="(option, index) in clientSelectOption" :key="index" :value="option.CustomerName.value">{{ option.CustomerName.value }}</option>
                </select>
                <span class="hasError-content">{{ errors.Client.msg }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="FormGroup">
          <div class="inputCol inputCol-1">
            <div class="nameBlock">
              <label for="Files">契約書ファイル</label>
            </div>
            <div class="inputBlock">
              <template v-if="formData.Files.length > 0">
                <div v-for="file in formData.Files" :key="file.name">{{ file.name }}</div>
                <!-- <label>変更</label><input type="file" id="Document" @change="handleFileChange" multiple /> -->
              </template>
              <template v-else>
                <input type="file" id="Files" @change="handleFileChange" multiple />
              </template>
            </div>
          </div>
        </div>
        <div class="FormGroup">
          <h2 style="font-size:18px;margin-top:20px;margin-bottom:12px;">担当情報</h2>
            <div class="inputCol inputCol-2">
              <div class="inputCol-2-block">
                <div class="nameBlock">
                  <label for="company">会社</label>
                  <span class="required">必須</span>
                  <span class="tooltip-content">会社の入力フォームです。(必須項目)</span>
                </div>
                <div class="inputBlock" :class="{ 'hasError': !errors.company.flag }">
                  <select id="company" :class="{ 'hasError': !errors.company.flag }" v-model="formData.company">
                    <option></option>
                    <option v-for="(option, index) in companySelectOption" :key="index" :value="option.CompanyName.value">{{ option.CompanyName.value }}</option>
                  </select>
                  <span class="hasError-content">{{ errors.company.msg }}</span>
                </div>
              </div>
              <div class="inputCol-2-block">
                <div class="nameBlock">
                  <label for="department">部署</label>
                  <span class="required">必須</span>
                  <span class="tooltip-content">部署の入力フォームです。(必須項目)</span>
                </div>
                <div class="inputBlock" :class="{ 'hasError': !errors.department.flag }">
                  <select id="department" :class="{ 'hasError': !errors.department.flag }" v-model="formData.department">
                    <option></option>
                    <option v-for="(option, index) in departmentSelectOption" :key="index" :value="option.Department_name.value">{{ option.Department_name.value }}</option>
                  </select>
                  <span class="hasError-content">{{ errors.department.msg }}</span>
                </div>
              </div>
            </div>
            <div class="inputCol inputCol-2">
              <div class="inputCol-2-block">
                <div class="nameBlock">
                  <label for="authorizer">承認者</label>
                  <span class="required">必須</span>
                  <span class="tooltip-content">承認者の入力フォームです。(必須項目)</span>
                </div>
                <div class="inputBlock" :class="{ 'hasError': !errors.authorizer.flag }">
                  <select id="authorizer" :class="{ 'hasError': !errors.authorizer.flag }" v-model="formData.authorizer">
                    <option></option>
                    <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                  </select>
                  <span class="hasError-content">{{ errors.authorizer.msg }}</span>
                </div>
              </div>

              <div class="inputCol-2-block">
                <div class="nameBlock">
                  <label for="manager">担当者</label>
                  <span class="required">必須</span>
                  <span class="tooltip-content">担当者の入力フォームです。(必須項目)</span>
                </div>
                <div class="inputBlock" :class="{ 'hasError': !errors.manager.flag }">
                  <select id="manager" :class="{ 'hasError': !errors.manager.flag }" v-model="formData.manager">
                    <option></option>
                    <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                  </select>
                  <span class="hasError-content">{{ errors.manager.msg }}</span>
                </div>
              </div>
            </div>
            <div class="inputCol inputCol-2">
              <div class="inputCol-2-block">
                <div class="nameBlock">
                  <label for="worker">作業者</label>
                  <span class="required">必須</span>
                  <span class="tooltip-content">作業者の入力フォームです。(必須項目)</span>
                </div>
                <div class="inputBlock" :class="{ 'hasError': !errors.worker.flag }">
                  <select id="worker" :class="{ 'hasError': !errors.worker.flag }" v-model="formData.worker">
                    <option></option>
                    <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                  </select>
                  <span class="hasError-content">{{ errors.worker.msg }}</span>
                </div>
              </div>

              <div class="inputCol-2-block">
                <div class="nameBlock">
                  <label for="author">作成者</label>
                  <span class="required">必須</span>
                  <span class="tooltip-content">作成者の入力フォームです。(自動入力)</span>
                </div>
                <div class="inputBlock">
                  <input type="text" id="author" :class="{ 'hasError': !errors.author.flag }" v-model="formData.author" disabled>
                  <span class="hasError-content">{{ errors.author.msg }}</span>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="modal-button-block">
        <button class="normalButton" @click="submitForm">保存</button>
        <button class="normalButton" @click="closeModal">閉じる</button>
      </div>
  </div>

<!-- モーダルウィンドウ -->
<div v-if="selectModalFlag__Project === true" class="modal" style="display: flex;">
          <div class="modal-content">
            <h2>案件選択</h2>
            <img src="../assets/image/close.png" class="close-right" @click="closeModal__Project">
            <div class="searchBox">
              <div class="searchBox-content">
                  <div>
                    <label>案件名</label>
                    <input type="text" placeholder="案件名" v-model="searchName__Project" style="width: 50%;"/>
                  </div>
                <div class="searchBox-content-block">
                  <div>
                    <label>取引先</label>
                      <select v-model="searchClient__Project">
                        <option></option>
                        <option v-for="(option, index) in clientSelectOption" :key="index" :value="option.CustomerName.value">{{ option.CustomerName.value }}</option>
                      </select>
                  </div>
                  <div>
                    <label>作業者</label>
                      <select v-model="searchWorker__Project">
                        <option></option>
                        <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                      </select>
                  </div>
                  <div>
                    <label>作成者</label>
                      <select v-model="searchAuthor__Project">
                        <option></option>
                        <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                      </select>
                  </div>
                  <div>
                    <label>作成日</label>
                    <input type="date" v-model="searchStart__Project"/> ~
                    <input type="date" v-model="searchEnd__Project"/>
                  </div>
                  <div>
                    <label>承認ステータス</label>
                      <select v-model="searchApproval__Project">
                        <option></option>
                        <option>未承認</option>
                        <option>申請中</option>
                        <option>承認済み</option>
                      </select>
                  </div>
                </div>
              </div>
            </div>
            <table class="authorize-table">
              <thead>
                <th></th>
                <th>案件コード</th>
                <th>案件名</th>
                <th>取引先</th>
                <th>作成日時</th>
                <th>作業者</th>
                <th>作成者</th>
                <th>承認</th>
              </thead>
              <tbody>
                <tr v-for="(item, index) in paginatedData__Project" :key="index">
                  <td @click="inputFormSelect__Project(item.Title.value)" v-if="item.ApprovalStatus.value === '承認済み'"><span class="pointer">選択</span></td>
                  <td v-else></td>
                  <td>{{ item.$id.value }}</td>
                  <td>{{ item.Title.value }}</td>
                  <td>{{ item.Client.value }}</td>
                  <td>{{ formatDateTime(item.作成日時.value) }}</td>
                  <td>{{ item.worker.value }}</td>
                  <td>{{ item.author.value }}</td>
                  <td>{{ item.ApprovalStatus.value }}</td>
                </tr>
              </tbody>
            </table>
            <div class="pagination">
              <span>{{ currentPage__Project }} / {{ totalPages__Project }}</span>
              <img class="arrowButton" src="../assets/image/Expand_Arrow.png" @click="prevPage__Project" :disabled="currentPage__Project === 1">
              <img class="arrowButton" src="../assets/image/Expand_Arrow2.png" @click="nextPage__Project" :disabled="currentPage__Project * itemsPerPage__Project >= totalFilteredItems__Project" >
            </div>
            <div class="modal-button-block">
              <button class="normalButton" @click="closeModal__Project">閉じる</button>
            </div>
          </div>
        </div>
<!-- モーダルウィンドウ -->
</div>
<div class="pageHeader">
  <h1>契約一覧</h1>
    <select class="normalButton" v-model="CreateOption">
      <option style="display:none;">新規作成</option>
      <option value="派遣">派遣</option>
      <option value="基本契約">基本契約</option>
      <option value="個別契約">個別契約</option>
      <option value="その他">その他</option>
    </select>
</div>
  <div class="content-block">
    <div class="searchBox">
      <div class="searchBox-content">
        <h2>契約情報</h2>
        <button class="toggleSection" @click="toggleSection('契約情報')">
          <img class="toggleButton" src="../assets/image/Expand_Arrow.png" v-if="showSections['契約情報']">
          <img class="toggleButton_1" src="../assets/image/Expand_Arrow.png" v-if="!showSections['契約情報']">
        </button>
        <div class="searchBox-content-block" v-if="showSections['契約情報']">
          <div style="display: flex; margin-bottom: 0;">
            <div>
              <label>契約コード</label>
              <input type="text" placeholder="契約コード" v-model="searchCode" />
            </div>
            <div>
              <label>契約名</label>
              <input type="text" placeholder="契約名" v-model="searchName" style="width: 300px;"/>
            </div>
          </div>
          <div style="display: flex; margin-bottom: 0;">
            <div>
              <label>案件名</label>
              <input type="text" placeholder="案件名" v-model="searchProjectName" style="width: 300px;"/>
            </div>
            <div>
              <label>取引先</label>
                <select v-model="searchClient">
                  <option></option>
                  <option v-for="(option, index) in clientSelectOption" :key="index" :value="option.CustomerName.value">{{ option.CustomerName.value }}</option>
                </select>
            </div>
          </div>
          <div style="display: flex; margin-bottom: 0;">
            <div>
              <label>発行日</label>
              <input type="date" v-model="StartDate__start"/> ~
              <input type="date" v-model="StartDate__end"/>
            </div>
          </div>
        </div>
      </div>
      <div class="searchBox-content">
        <h2>担当情報</h2>
        <button class="toggleSection" @click="toggleSection('担当情報')">
          <img class="toggleButton" src="../assets/image/Expand_Arrow.png" v-if="showSections['担当情報']">
          <img class="toggleButton_1" src="../assets/image/Expand_Arrow.png" v-if="!showSections['担当情報']">
        </button>
        <div class="searchBox-content-block" v-if="showSections['担当情報']">
          <div style="display: flex; margin-bottom: 0;">
            <div>
              <label>会社</label>
                <select v-model="searchCompany">
                  <option></option>
                  <option v-for="(option, index) in companySelectOption" :key="index" :value="option.CompanyName.value">{{ option.CompanyName.value }}</option>
                </select>
            </div>
            <div>
              <label>担当部署</label>
                <select v-model="searchDepartment">
                  <option></option>
                  <option v-for="(option, index) in departmentSelectOption" :key="index" :value="option.Department_name.value">{{ option.Department_name.value }}</option>
                </select>
            </div>
          </div>
          <div style="display: flex; margin-bottom: 0;">
            <div>
              <label>承認者</label>
                <select v-model="searchAuthorizer">
                  <option></option>
                  <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                </select>
            </div>
            <div>
              <label>担当者</label>
                <select v-model="searchManager">
                  <option></option>
                  <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                </select>
            </div>
            <div>
              <label>作業者</label>
                <select v-model="searchWorker">
                  <option></option>
                  <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                </select>
            </div>
            <div>
              <label>作成者</label>
                <select v-model="searchAuthor">
                  <option></option>
                  <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                </select>
            </div>
          </div>
        </div>
      </div>
      <div class="searchBox-content">
        <h2>ステータス</h2>
        <button class="toggleSection" @click="toggleSection('ステータス')">
          <img class="toggleButton" src="../assets/image/Expand_Arrow.png" v-if="showSections['ステータス']">
          <img class="toggleButton_1" src="../assets/image/Expand_Arrow.png" v-if="!showSections['ステータス']">
        </button>
        <div class="searchBox-content-block" v-if="showSections['ステータス']">
          <div>
            <label>承認</label>
              <select v-model="searchAuthorize">
                <option></option>
                <option v-for="(option, index) in authorizeSelectOption" :key="index" :value="option.value">{{ option.label }}</option>
              </select>
          </div>
          <div>
            <label>回答</label>
              <select v-model="searchAnswer">
                <option></option>
                <option v-for="(option, index) in answerSelectOption" :key="index" :value="option.value">{{ option.label }}</option>
              </select>
          </div>
        </div>
      </div>
    </div>
    <div class="clearButtonBlock">
      <button @click="inputClear" class="clearButton normalButton">絞り込み条件をリセット</button>
    </div>
    <div class="listBox">
      <table v-if= "paginatedData.length > 0">
        <thead>
          <tr>
            <th @click="sortTable('$id')">契約コード
              <span v-if="sortKey === '$id'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('Title')">契約名
              <span v-if="sortKey === 'Title'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('ProjectTitle')">案件名
              <span v-if="sortKey === 'ProjectTitle'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('Type')">種類
              <span v-if="sortKey === 'Type'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('Client')">取引先
              <span v-if="sortKey === 'Client'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('worker__Date')">発行日
              <span v-if="sortKey === 'worker__Date'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('company')">会社
              <span v-if="sortKey === 'company'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('department')">部署
              <span v-if="sortKey === 'department'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('authorizer')">承認者
              <span v-if="sortKey === 'authorizer'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('manager')">担当者
              <span v-if="sortKey === 'manager'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('worker')">作業者
              <span v-if="sortKey === 'worker'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('author')">作成者
              <span v-if="sortKey === 'author'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('ApprovalStatus')">承認
              <span v-if="sortKey === 'ApprovalStatus'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('AnswerStatus')">回答
              <span v-if="sortKey === 'AnswerStatus'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in paginatedData" :key="index">
            <td>{{ item.$id.value }}</td>
            <td>
            <router-link :to="{ name: '契約詳細', params: { id: item.$id.value } }" @click="setData('ContractDetails',item.$id.value)">
            {{ item.Title.value }}
            </router-link>
            </td>
            <td>{{ item.ProjectTitle.value }}</td>
            <td>{{ item.Type.value }}</td>
            <td>{{ item.Client.value }}</td>
            <td>{{ item.worker__Date.value }}</td>
            <td>{{ item.company.value }}</td>
            <td>{{ item.department.value }}</td>
            <td>{{ item.authorizer.value }}</td>
            <td>{{ item.manager.value }}</td>
            <td>{{ item.worker.value }}</td>
            <td>{{ item.author.value }}</td>
            <td>{{ item.ApprovalStatus.value }}</td>
            <td>{{ item.AnswerStatus.value }}</td>
          </tr>
        </tbody>
      </table>
      <div v-else>
        <p class="notFound">一覧に表示できるデータがありません</p>
      </div>
    </div>
          <div class="pagination">
      <span>{{ currentPage }} / {{ totalPages }}</span>
        <img class="arrowButton" src="../assets/image/Expand_Arrow.png" @click="prevPage" :disabled="currentPage === 1">
        <img class="arrowButton" src="../assets/image/Expand_Arrow2.png" @click="nextPage" :disabled="currentPage * itemsPerPage >= totalFilteredItems" >
      </div>
  </div>
</template>

<script>

export default {
  components: {

  },
  data() {
    return {
      showSections: {
        '契約情報': true,   // 最初は表示
        '担当情報': false,  // 最初は非表示
        'ステータス': false,  // 最初は非表示
      },
      tableData: [],

      clientSelectOption: [],

      companySelectOption: [],
    
      departmentSelectOption: [],

      EmployeeSelectOption: [],

      authorizeSelectOption: [
        { value: '承認済み', label: '承認済み' },
        { value: '申請中', label: '申請中' },
        { value: '未承認', label: '未承認' },
      ],

      answerSelectOption: [
        { value: '対応中', label: '対応中' },
        { value: '締結済み', label: '締結済み' },
        { value: '破棄', label: '破棄' },
      ],

      // 以下はフィルタリングやページネーションに使用するデータ
      searchCode: "",
      searchName: "",
      searchProjectName: "",
      searchClient: "",
      searchCompany: "",
      searchDepartment: "",
      searchAuthorizer: "",
      searchManager: "",
      searchWorker: "",
      searchAuthor: "",
      searchAuthorize: "",
      searchAnswer: "",

      StartDate__start: "",
      StartDate__end: "",
      minEarningsProspect: "",
      maxEarningsProspect: "",
      minEarningsAchievements: "",
      maxEarningsAchievements: "",

      sortKey: "code",
      sortDesc: false,
      currentPage: 1,
      itemsPerPage: 10,

      isModalOpen: false,
      CreateOption:"新規作成",
//
      ModalTitle: "",
      formData: {
        ProjectTitle: '',
        ProjectId: '',
        Title: '',
        Client: '',
        company: '',
        department: '',
        authorizer: '',
        manager: '',
        worker: '',
        author: '',
        Files: [],
        relatedFiles: [], // ファイルデータを格納する配列
        LastUser: '',
        Type: '',
      },
      errors: {
        Title: { msg:'', flag: true},
        ProjectTitle: { msg:'', flag: true},
        Client: { msg:'', flag: true},
        company: { msg:'', flag: true},
        department: { msg:'', flag: true},
        authorizer: { msg:'', flag: true},
        manager: { msg:'', flag: true},
        worker: { msg:'', flag: true},
        author: { msg:'', flag: true},
      },
      errorMessage: '',
      hasError: '',
      hasAlert : {
        flag : false,
        text : ''
      },
      createRecordId: "",
//
//モーダルウィンドウ
      selectModalFlag__Project: false,
      currentPage__Project: 1, // 現在のページ
      itemsPerPage__Project: 10, // 1ページあたりのアイテム数
      tableData__Project: [],
      searchName__Project: "",
      searchAuthor__Project: "",
      searchClient__Project: "",
      searchWorker__Project: "",
      searchApproval__Project: "承認済み",
      searchStart__Project: "",
      searchEnd__Project: "",
//
    };
  },
  computed: {
//モーダルウィンドウ
filteredTableData__Project() {
      console.log(this.tableData__Project)
        let filteredData = this.tableData__Project.filter((item) => {
        const startDate = new Date(this.searchStart__Project);
        const endDate = new Date(this.searchEnd__Project);
        const itemDate = new Date(item.作成日時.value);

          return (
            (this.searchName__Project === "" || item.Title.value.includes(this.searchName__Project)) &&
            (this.searchClient__Project === "" || item.Client.value === this.searchClient__Project) &&
            (this.searchWorker__Project === "" || item.worker.value === this.searchWorker__Project) && 
            (this.searchAuthor__Project === "" || item.author.value === this.searchAuthor__Project) &&
            (this.searchApproval__Project === "" || item.ApprovalStatus.value === this.searchApproval__Project) &&
            (isNaN(startDate) || itemDate >= startDate) && // 開始日が未指定または作成日時が開始日以降
            (isNaN(endDate) || itemDate <= endDate) // 終了日が未指定または作成日時が終了日以前
          );
        });
        return filteredData;
    },
    paginatedData__Project() {
      const startIndex = (this.currentPage__Project - 1) * this.itemsPerPage__Project;
      const endIndex = startIndex + this.itemsPerPage__Project;
      return this.filteredTableData__Project.slice(startIndex, endIndex);
    },
    totalPages__Project() {
      const totalPagesNum = Math.ceil(this.filteredTableData__Project.length / this.itemsPerPage__Project);
      return totalPagesNum;
    },
//
    // フィルタリングされたデータを計算
    filteredTableData() {
      let filteredData = this.tableData.filter((item) => {
        const startDate = new Date(this.StartDate__start);
        const endDate = new Date(this.StartDate__end);
        const itemDate = new Date(item.worker__Date.value);
        return (
          (this.searchCode === "" || item.$id.value.includes(this.searchCode)) &&
          (this.searchName === "" || item.Title.value.includes(this.searchName)) &&
          (this.searchProjectName === "" || item.ProjectTitle.value.includes(this.searchProjectName)) &&
          (this.searchClient === "" || item.Client.value === this.searchClient) &&
          (this.searchCompany === "" || item.company.value === this.searchCompany) &&
          (this.searchDepartment === "" || item.department.value === this.searchDepartment) &&
          (this.searchAuthorizer === "" || item.authorizer.value === this.searchAuthorizer) &&
          (this.searchManager === "" || item.manager.value === this.searchManager) && 
          (this.searchWorker === "" || item.worker.value === this.searchWorker) && 
          (this.searchAuthor === "" || item.author.value === this.searchAuthor) && 
          (this.searchAuthorize === "" || item.ApprovalStatus.value === this.searchAuthorize) && 
          (this.searchAnswer === "" || item.AnswerStatus.value === this.searchAnswer) && 
          (isNaN(startDate) || itemDate >= startDate) && // 開始日が未指定または作成日時が開始日以降
          (isNaN(endDate) || itemDate <= endDate) && // 終了日が未指定または作成日時が終了日以前
          this.isAmountInRange( 
            item.earnings_prospect,
            this.minEarningsProspect,
            this.maxEarningsProspect
          ) &&
          this.isAmountInRange(
            item.earnings_achievements,
            this.minEarningsAchievements,
            this.maxEarningsAchievements
          )
        );
      });

        let sortkey = this.sortKey;
        // ソート
        if(filteredData){
          if (this.sortDesc) {
            filteredData.sort(function(a,b){
              if(!a[sortkey] || !b[sortkey]){return false}
              let aData = (!isNaN(a[sortkey]['value']))?Number(a[sortkey]['value']):a[sortkey]['value'];
              let bData = (!isNaN(b[sortkey]['value']))?Number(b[sortkey]['value']):b[sortkey]['value'];
              return (aData > bData)?1:-1
            })
          } else {
            filteredData.sort(function(a,b){
              if(!a[sortkey] || !b[sortkey]){return false}
              let aData = (!isNaN(a[sortkey]['value']))?Number(a[sortkey]['value']):a[sortkey]['value'];
              let bData = (!isNaN(b[sortkey]['value']))?Number(b[sortkey]['value']):b[sortkey]['value'];
              return (aData < bData)?1:-1
            })
          }
        }
        console.log(filteredData)
        return filteredData;
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filteredTableData.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.filteredTableData.length / this.itemsPerPage);
    },
    login_data:{
      get: function () {
        return this.$store.state.login_data
      },
      set: function (value) {
        this.$store.commit('login_data', value)
      }
    },
  },
  watch: {
    CreateOption(newVal) {
      if(newVal === "派遣"){
        this.$router.push({
          path: 'ContractCreate' ,
          query: { record_id: '' },
        });
      }
      if(newVal !=="新規作成"){
        this.ModalTitle = newVal
        this.formData.Type = newVal
        this.isModalOpen = true
      }
    }
  },
  created() {
    this.getTableData();
    this.getTableData__Project();
    this.getClientData();
    this.getCompanyData();
    this.getDepartmentData();
    this.getEmployeeData();
  },
  mounted() {
    this.getTableData();
    this.getTableData__Project();
    this.getClientData();
    this.getCompanyData();
    this.getDepartmentData();
    this.getEmployeeData();
    this.formData.author = this.login_data.Names.value;
  },
  methods: {
    formatDateTime(dateTimeString) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', timeZoneName: 'short', weekday: 'short' };
      const dateTime = new Date(dateTimeString);
      const stringDate = dateTime.toLocaleString('ja-JP', options);
      if (stringDate === "Invalid Date") {
        return "";
      } else {
        return stringDate.replace("JST", '').replace(',', '');
      }
    },
    inputFormSelect__Project(value){
      this.formData.ProjectTitle = value
      this.getApplicationData(value)
      this.closeModal__Project()
    },
    openModal__Project() {
      this.selectModalFlag__Project = true; // モーダルを表示
    },
    closeModal__Project() {
      this.selectModalFlag__Project = false; // モーダルを非表示
    },
    prevPage__Project() {
      if (this.currentPage__Project > 1) {
        this.currentPage__Project--;
      }
    },
    nextPage__Project() {
      if (this.currentPage__Project < this.totalPages) {
        this.currentPage__Project++;
      }
    },
    getTableData__Project: async function (){
      let params = {
        target: 'ProjectDetails',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      this.tableData__Project = authed.records;
      console.log(authed)
    },
    submitForm() {
      if (this.$commonPlugin.validateForm(this.formData,this.errors)) {
        this.createFormMethod();
      }else{
        alert('入力内容にエラーが発生しました。');
      }
    },
    createFormMethod: async function(){
      console.log(this.formData);
      this.formData.LastUser = this.login_data["Names"].value
      let pushArray = [];
      for(let key in this.formData){
        let data = {};
        data.target = key;
        data.value = this.formData[key];
        pushArray.push(data);
      }
      console.log(pushArray)
      let params = {
        target: 'Contract',
        data:JSON.stringify(pushArray),
        user: this.login_data.Names.value,
        querys: JSON.stringify(
            [
              {
                //対象カラム
                column :'DeleteStatus',
                //比較条件
                cond   :'in',
                //比較内容
                data   : '使用',
              },
              {
                //対象カラム
                column :'$id',
                //比較条件
                cond   :'=',
                //比較内容
                data   :this.createRecordId,
              }
            ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_create_update_data_for_array_logic',params);
      console.log(authed);
      this.$commonPlugin.dataUpdate(this.formData,authed);

      if(authed.result.response === "失敗"){
        alert('入力内容にエラーが発生しました。');
      }else{
        alert('保存しました。');
        var link = "/ContractDetails/"+authed.result.id
        this.$router.push(link);
      }
    },
    handleFileChange(event) {
      const files = event.target.files;
      let selectedFiles = [];
      // 選択したファイルを配列に追加
      for (let i = 0; i < files.length; i++) {
        if(this.formData[event.target.id] == ''){
          this.formData[event.target.id] = [];
        }
        this.formData[event.target.id].push(files[i]);
        selectedFiles.push(files[i]);
      }
      // console.log(event.target.id)
      this.createFileOnData(event.target.id,selectedFiles);
    },
    createFileOnData: async function(target,selectedFiles){
      let params = {
        target  : 'Contract',
        data    :JSON.stringify([
          {
            target : 'Worker',
            value  : this.login_data['Names'].value,
          }
        ]),
        filesTarget : 'File',
        files       : (selectedFiles)?selectedFiles : '',
      }
      // console.log(this.selectedFiles)
      let response = '';

      response = await this.$commonPlugin.axiosFileMultiAPI('file_upload_onData',params);

      console.log(response)
      this.formData[target] = [];
      response.result.forEach(item => {
        this.formData[target].push(...item.create_data);
      });
      console.log(this.formData);
    },
    getTableData: async function (){
      let params = {
        target: 'Contract',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      this.tableData = authed.records;
      console.log(authed)
    },
    getClientData: async function (){
      let params = {
        target: 'Client',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.clientSelectOption = authed.records;
    },
    getCompanyData: async function (){
      let params = {
        target: 'Company',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.companySelectOption = authed.records;
    },
    getDepartmentData: async function (){
      let params = {
        target: 'Department',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.departmentSelectOption = authed.records;
    },
    getEmployeeData: async function (){
      let params = {
        target: 'Employee',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.EmployeeSelectOption = authed.records;
    },
    getApplicationData : async function(ls_target){
      const targetColumn = {
        //formData = database
        ProjectId : 'record_id',
        company   : 'company',
        department: 'department',
        authorizer: 'authorizer',
        manager   : 'manager',
        worker    : 'worker',
      }
      let params = {
        target: 'ProjectDetails',
        querys: JSON.stringify(
            [
              {
                //対象カラム
                column :'DeleteStatus',
                //比較条件
                cond   :'in',
                //比較内容
                data   :'使用',
              },
              {
                //対象カラム
                column :'Title',
                //比較条件
                cond   :'in',
                //比較内容
                data   : ls_target,
              }
            ]
        ),
        // join : JSON.stringify(),
      };
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      let database = authed.records;
      if(!database.length){
        this.hasAlert.flag = true
        this.hasAlert.text = 'データが見つかりません'

        for (let key in targetColumn) {
          this.formData[key] = '';
        }

        return false;
      }else{
        this.hasAlert.flag = false
        this.hasAlert.text = ''
      }
      // フォーム復元
      // 復元したいカラム = 復元するデータ
      for(let i = 0; i < database.length;i++){
        for (let key in targetColumn) {
          if(database[i][targetColumn[key]]){
            this.formData[key] = database[i][targetColumn[key]].value;
          }
        }
      }
    },
    openModal() {
      this.isModalOpen = true; // モーダルを表示
    },
    closeModal() {
      if (window.confirm('入力された内容を破棄してもよろしいですか？')) {
        this.CreateOption = "新規作成";
        this.formData =  {
          ProjectTitle: '',
          ProjectId: '',
          Title: '',
          Client: '',
          Type: '',
          company: '',
          department: '',
          authorizer: '',
          manager: '',
          worker: '',
          author: '',
          internalMemo: '',
          Files: [],
          relatedFiles: [], // ファイルデータを格納する配列
          LastUser: '',
        },
        this.isModalOpen = false; // モーダルを非表示
      }
    },
    isAmountInRange(amount, minAmount, maxAmount) {
      const numericAmount = parseFloat(amount);
      if (isNaN(numericAmount)) {
        return true; // amountが数値でない場合はフィルタリングせずに表示
      }

      if (minAmount !== "" && numericAmount < parseFloat(minAmount)) {
        return false;
      }
      if (maxAmount !== "" && numericAmount > parseFloat(maxAmount)) {
        return false;
      }

      return true;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    sortTable(key) {
      if (this.sortKey === key) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortKey = key;
        this.sortDesc = false;
      }
    },
    toggleSection(sectionName) {
      this.showSections[sectionName] = !this.showSections[sectionName];
    },
    inputClear(){
      this.searchCode = "";
      this.searchName = "";
      this.searchProjectName = "";
      this.searchClient = "";
      this.searchCompany = "";
      this.searchDepartment = "";
      this.searchAuthorizer = "";
      this.searchManager = "";
      this.searchWorker = "";
      this.searchAuthor = "";
      this.searchAuthorize = "";
      this.searchAnswer = "";
      this.StartDate__start = "";
      this.StartDate__end = "";
      this.minEarningsProspect = "";
      this.maxEarningsProspect = "";
      this.minEarningsAchievements = "";
      this.maxEarningsAchievements = "";
    },
    setData(page,id){
      this.setStrage('loginTimes',new Date());
      this.setStrage('pages',page);
      this.setStrage('id',id);
    },
    setStrage(target,value){
      localStorage.setItem(target,value);
    },
  },
};
</script>