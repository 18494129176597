
<template>
<div class="pageHeader">
  <h1>案件一覧</h1>
  <router-link :to="{ path: '/ProjectCreate', query: { record_id: '' } }">
    <button class="normalButton">新規作成</button>
  </router-link>
</div>
  <div class="content-block">
    <div class="searchBox">
      <div class="searchBox-content">
        <h2>案件情報</h2>
        <button class="toggleSection" @click="toggleSection('案件情報')">
          <img class="toggleButton" src="../assets/image/Expand_Arrow.png" v-if="showSections['案件情報']">
          <img class="toggleButton_1" src="../assets/image/Expand_Arrow.png" v-if="!showSections['案件情報']">
        </button>
        <div class="searchBox-content-block" v-if="showSections['案件情報']">
          <div style="display: flex; margin-bottom: 0;">
            <div>
              <label>案件コード</label>
              <input type="text" placeholder="案件コード" v-model="searchCode" />
            </div>
            <div>
              <label>案件名</label>
              <input type="text" placeholder="案件名" v-model="searchName" style="width: 300px;"/>
            </div>
            <div>
              <label>取引先</label>
                <select v-model="searchClient">
                  <option></option>
                  <option v-for="(option, index) in clientSelectOption" :key="index" :value="option.CustomerName.value">{{ option.CustomerName.value }}</option>
                </select>
            </div>
          </div>
          <div style="display: flex; margin-bottom: 0;">
            <div>
              <label>案件開始予定日</label>
              <input type="date" v-model="StartDate__start"/> ~
              <input type="date" v-model="StartDate__end"/>
            </div>
            <div>
              <label>案件終了予定日</label>
              <input type="date" v-model="EndDate__start"/> ~
              <input type="date" v-model="EndDate__end"/>
            </div>
          </div>
          <div style="display: flex; margin-bottom: 0;">
            <div>
              <label>売上合計(チャレンジ目標)</label>
              <input type="text" v-model='minEarningsProspect' /> ~ <input type="text" v-model='maxEarningsProspect' />
            </div>
            <div>
              <label>売上合計(実績)</label>
              <input type="text" v-model='minEarningsAchievements' /> ~ <input type="text" v-model='maxEarningsAchievements' />
            </div>
          </div>
        </div>
      </div>
      <div class="searchBox-content">
        <h2>担当情報</h2>
        <button class="toggleSection" @click="toggleSection('担当情報')">
          <img class="toggleButton" src="../assets/image/Expand_Arrow.png" v-if="showSections['担当情報']">
          <img class="toggleButton_1" src="../assets/image/Expand_Arrow.png" v-if="!showSections['担当情報']">
        </button>
        <div class="searchBox-content-block" v-if="showSections['担当情報']">
          <div style="display: flex; margin-bottom: 0;">
            <div>
              <label>会社</label>
                <select v-model="searchCompany">
                  <option></option>
                  <option v-for="(option, index) in companySelectOption" :key="index" :value="option.CompanyName.value">{{ option.CompanyName.value }}</option>
                </select>
            </div>
            <div>
              <label>担当部署</label>
                <select v-model="searchDepartment">
                  <option></option>
                  <option v-for="(option, index) in departmentSelectOption" :key="index" :value="option.Department_name.value">{{ option.Department_name.value }}</option>
                </select>
            </div>
          </div>
          <div style="display: flex; margin-bottom: 0;">
            <div>
              <label>承認者</label>
                <select v-model="searchAuthorizer">
                  <option></option>
                  <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                </select>
            </div>
            <div>
              <label>担当者</label>
                <select v-model="searchManager">
                  <option></option>
                  <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                </select>
            </div>
            <div>
              <label>作業者</label>
                <select v-model="searchWorker">
                  <option></option>
                  <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                </select>
            </div>
            <div>
              <label>作成者</label>
                <select v-model="searchAuthor">
                  <option></option>
                  <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                </select>
            </div>
          </div>
        </div>
      </div>
      <div class="searchBox-content">
        <h2>ステータス</h2>
        <button class="toggleSection" @click="toggleSection('ステータス')">
          <img class="toggleButton" src="../assets/image/Expand_Arrow.png" v-if="showSections['ステータス']">
          <img class="toggleButton_1" src="../assets/image/Expand_Arrow.png" v-if="!showSections['ステータス']">
        </button>
        <div class="searchBox-content-block" v-if="showSections['ステータス']">
          <div>
            <label>承認</label>
              <select v-model="searchAuthorize">
                <option></option>
                <option v-for="(option, index) in authorizeSelectOption" :key="index" :value="option.value">{{ option.label }}</option>
              </select>
          </div>
          <div>
            <label>回答</label>
              <select v-model="searchAnswer">
                <option></option>
                <option v-for="(option, index) in answerSelectOption" :key="index" :value="option.value">{{ option.label }}</option>
              </select>
          </div>
        </div>
      </div>
    </div>
    <div class="clearButtonBlock">
      <button @click="inputClear" class="clearButton normalButton">絞り込み条件をリセット</button>
    </div>
    <div class="listBox">
      <table v-if= "paginatedData.length > 0">
        <thead>
          <tr>
            <th @click="sortTable('$id')">案件コード
              <span v-if="sortKey === '$id'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('Title')">案件名
              <span v-if="sortKey === 'Title'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('Client')">取引先
              <span v-if="sortKey === 'Client'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('startDate')">案件開始予定日
              <span v-if="sortKey === 'startDate'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('endDate')">案件終了予定日
              <span v-if="sortKey === 'endDate'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('sales_challenge')">売上合計(チャレンジ目標)
              <span v-if="sortKey === 'sales_challenge'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('sales')">売上合計(実績)
              <span v-if="sortKey === 'sales'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('company')">会社
              <span v-if="sortKey === 'company'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('department')">部署
              <span v-if="sortKey === 'department'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('authorizer')">承認者
              <span v-if="sortKey === 'authorizer'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('manager')">担当者
              <span v-if="sortKey === 'manager'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('worker')">作業者
              <span v-if="sortKey === 'worker'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('author')">作成者
              <span v-if="sortKey === 'author'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('authorize')">承認
              <span v-if="sortKey === 'authorize'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('answer')">回答
              <span v-if="sortKey === 'answer'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in paginatedData" :key="index">
            <td>{{ item.$id.value }}</td>
            <td>
              <router-link :to="{ name: '案件詳細', params: { id: item.$id.value } }" @click="setData('ProjectDetails',item.$id.value)">
                {{ item.Title.value }}
              </router-link>
            </td>
            <td>{{ item.Client.value }}</td>
            <td>{{ item.startDate.value }}</td>
            <td>{{ item.endDate.value }}</td>
            <td>{{ formatNumber(item.total__sales_challenge.value) }}円</td>
            <td>{{ formatNumber(item.total__sales.value) }}円</td>
            <td>{{ item.company.value }}</td>
            <td>{{ item.department.value }}</td>
            <td>{{ item.authorizer.value }}</td>
            <td>{{ item.manager.value }}</td>
            <td>{{ item.worker.value }}</td>
            <td>{{ item.author.value }}</td>
            <td>{{ item.ApprovalStatus.value }}</td>
            <td>{{ item.AnswerStatus.value }}</td>
          </tr>
        </tbody>
      </table>
      <div v-else>
        <p class="notFound">一覧に表示できるデータがありません</p>
      </div>
    </div>
    <div class="pagination">
      <span>{{ currentPage }} / {{ totalPages }}</span>
      <img class="arrowButton" src="../assets/image/Expand_Arrow.png" @click="prevPage" :disabled="currentPage === 1">
      <img class="arrowButton" src="../assets/image/Expand_Arrow2.png" @click="nextPage" :disabled="currentPage * itemsPerPage >= totalFilteredItems" >
    </div>
  </div>
</template>

<script>

export default {
  components: {

  },
  data() {
    return {
      showSections: {
        '案件情報': true,   // 最初は表示
        '担当情報': false,  // 最初は非表示
        'ステータス': false,  // 最初は非表示
      },

      tableData: [],

      clientSelectOption: [],

      companySelectOption: [],
    
      departmentSelectOption: [],

      EmployeeSelectOption: [],

      authorizeSelectOption: [
        { value: '承認済み', label: '承認済み' },
        { value: '申請中', label: '申請中' },
        { value: '未承認', label: '未承認' },
      ],

      answerSelectOption: [
        { value: '対応中', label: '対応中' },
        { value: '完了', label: '完了' },
        { value: '中止', label: '中止' },
      ],




      // 以下はフィルタリングやページネーションに使用するデータ
      searchCode: "",
      searchName: "",
      searchClient: "",
      searchCompany: "",
      searchDepartment: "",
      searchAuthorizer: "",
      searchManager: "",
      searchWorker: "",
      searchAuthor: "",
      searchAuthorize: "",
      searchAnswer: "",

      StartDate__start: "",
      StartDate__end: "",
      EndDate__start: "",
      EndDate__end: "",

      minEarningsProspect: "",
      maxEarningsProspect: "",
      minEarningsAchievements: "",
      maxEarningsAchievements: "",

      sortKey: "$id",
      sortDesc: false,
      currentPage: 1,
      itemsPerPage: 10,
    };
  },
  computed: {
    // フィルタリングされたデータを計算
    filteredTableData() {
      console.log(this.tableData)
        let filteredData = this.tableData.filter((item) => {

          const startDate__start = new Date(this.StartDate__start);
          const endDate__start = new Date(this.StartDate__end);
          const itemDate__start = new Date(item.startDate.value);

          const startDate__end = new Date(this.EndDate__start);
          const endDate__end = new Date(this.EndDate__end);
          const itemDate__end = new Date(item.endDate.value);

          return (
            (this.searchCode === "" || item.$id.value.includes(this.searchCode)) &&
            (this.searchName === "" || item.Title.value.includes(this.searchName)) &&
            (this.searchClient === "" || item.Client.value === this.searchClient) &&
            (isNaN(startDate__start) || itemDate__start >= startDate__start) && // 開始日が未指定または作成日時が開始日以降
            (isNaN(endDate__start) || itemDate__start <= endDate__start) && // 終了日が未指定または作成日時が終了日以前
            (isNaN(startDate__end) || itemDate__end >= startDate__end) && // 開始日が未指定または作成日時が開始日以降
            (isNaN(endDate__end) || itemDate__end <= endDate__end) && // 終了日が未指定または作成日時が終了日以前
            this.isAmountInRange(
              item.total__sales.value,
              this.minEarningsProspect,
              this.maxEarningsProspect
            ) &&
            this.isAmountInRange(
              item.total__sales_challenge.value,
              this.minEarningsAchievements,
              this.maxEarningsAchievements
            ) &&
            (this.searchCompany === "" || item.company.value === this.searchCompany) &&
            (this.searchDepartment === "" || item.department.value === this.searchDepartment) &&
            (this.searchAuthorizer === "" || item.authorizer.value === this.searchAuthorizer) &&
            (this.searchManager === "" || item.manager.value === this.searchManager) && 
            (this.searchWorker === "" || item.worker.value === this.searchWorker) && 
            (this.searchAuthor === "" || item.author.value === this.searchAuthor) && 
            (this.searchAuthorize === "" || item.ApprovalStatus.value === this.searchAuthorize) && 
            (this.searchAnswer === "" || item.AnswerStatus.value === this.searchAnswer) 
          );
        });

        
        let sortkey = this.sortKey;
        // ソート
        if(filteredData){
          if (this.sortDesc) {
            filteredData.sort(function(a,b){
              if(!a[sortkey] || !b[sortkey]){return false}
              let aData = (!isNaN(a[sortkey]['value']))?Number(a[sortkey]['value']):a[sortkey]['value'];
              let bData = (!isNaN(b[sortkey]['value']))?Number(b[sortkey]['value']):b[sortkey]['value'];
              return (aData > bData)?1:-1
            })
          } else {
            filteredData.sort(function(a,b){
              if(!a[sortkey] || !b[sortkey]){return false}
              let aData = (!isNaN(a[sortkey]['value']))?Number(a[sortkey]['value']):a[sortkey]['value'];
              let bData = (!isNaN(b[sortkey]['value']))?Number(b[sortkey]['value']):b[sortkey]['value'];
              return (aData < bData)?1:-1
            })
          }
        }
        console.log(filteredData)
        return filteredData;
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filteredTableData.slice(startIndex, endIndex);
    },
    totalPages() {
      const totalPagesNum = Math.ceil(this.filteredTableData.length / this.itemsPerPage);
      return totalPagesNum;
    },
  },
  created() {
    this.getTableData();
    this.getClientData();
    this.getCompanyData();
    this.getDepartmentData();
    this.getEmployeeData();
  },
  mounted() {
    this.getTableData();
    this.getClientData();
    this.getCompanyData();
    this.getDepartmentData();
    this.getEmployeeData();
  },
  methods: {
    getTableData: async function (){
      let params = {
        target: 'ProjectDetails',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      this.tableData = authed.records;
      console.log(authed)
    },
    getClientData: async function (){
      let params = {
        target: 'Client',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.clientSelectOption = authed.records;
    },
    getCompanyData: async function (){
      let params = {
        target: 'Company',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.companySelectOption = authed.records;
    },
    getDepartmentData: async function (){
      let params = {
        target: 'Department',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.departmentSelectOption = authed.records;
    },
    getEmployeeData: async function (){
      let params = {
        target: 'Employee',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.EmployeeSelectOption = authed.records;
    },
    isAmountInRange(amount, minAmount, maxAmount) {
      const numericAmount = parseFloat(amount);
      if (isNaN(numericAmount)) {
        return true; // amountが数値でない場合はフィルタリングせずに表示
      }

      if (minAmount !== "" && numericAmount < parseFloat(minAmount)) {
        return false;
      }
      if (maxAmount !== "" && numericAmount > parseFloat(maxAmount)) {
        return false;
      }

      return true;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    sortTable(key) {
      if (this.sortKey === key) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortKey = key;
        this.sortDesc = false;
      }
    },
    toggleSection(sectionName) {
      this.showSections[sectionName] = !this.showSections[sectionName];
    },
    formatNumber(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    inputClear(){
      this.searchCode = "";
      this.searchName = "";
      this.searchClient = "";
      this.searchCompany = "";
      this.searchDepartment = "";
      this.searchAuthorizer = "";
      this.searchManager = "";
      this.searchWorker = "";
      this.searchAuthor = "";
      this.searchAuthorize = "";
      this.searchAnswer = "";
      this.StartDate__start = "";
      this.StartDate__end = "";
      this.EndDate__start = "";
      this.EndDate__end = "";
      this.minEarningsProspect = "";
      this.maxEarningsProspect = "";
      this.minEarningsAchievements = "";
      this.maxEarningsAchievements = "";
    },
    setData(page,id){
      this.setStrage('loginTimes',new Date());
      this.setStrage('pages',page);
      this.setStrage('id',id);
    },
    setStrage(target,value){
      localStorage.setItem(target,value);
    },
  },
};
</script>