
<template>
  <div class="pageHeader">
    <h1 v-if="editFlag">見積編集({{ createRecordId }})</h1>
    <h1 v-if="copyFlag">見積複製</h1>
    <h1 v-if="createFlag">見積作成</h1>
    <button class="normalButton" @click="submitForm" :disabled="!UploadFlag">保存</button>
    <button class="normalButton" @click="inputCancel">キャンセル</button>
  </div>
  <div class="content-block">
    <div id="formImput">
      <form ref="myForm">
        <div class="FormGroup">
          <!-- 案件名 -->
          <h2>紐付け情報</h2>
          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="ProjectTitle">案件名</label>
                <span class="required">必須</span>
                <span class="tooltip-content">案件名の入力フォームです。(必須項目)</span>
              </div>
              <div class="inputBlock" :class="{ 'hasError': hasAlert.flag }">
                <input type="text" id="Project" :class="{ 'hasError': hasAlert.flag }" v-model="formData.Project" @input="getApplicationData(formData.Project)" />
                <span class="hasError-content">{{ hasAlert.text }}</span>
                <span class="modallink" @click="openModal()">選択する</span>
              </div>
            </div>
            <div class="inputCol-2-block" style="display:none;">
              <div class="nameBlock">
                <label for="ProjectId">案件ID</label>
                <span class="tooltip-content">案件IDの確認フォームです。(任意項目)</span>
              </div>
              <div class="inputBlock" >
                <input type="text" id="ProjectId" v-model="formData.ProjectId" readonly />
              </div>
            </div>
          </div>

<!-- モーダルウィンドウ -->
        <div v-if="selectModalFlag === true" class="modal" style="display: flex;">
          <div class="modal-content">
            <h2>案件選択</h2>
            <img src="../assets/image/close.png" class="close-right" @click="closeModal">
            <div class="searchBox">
              <div class="searchBox-content">
                   <div>
                    <label>案件名</label>
                    <input type="text" placeholder="案件名" v-model="searchName" style="width: 50%;"/>
                  </div>
                <div class="searchBox-content-block">
                  <div>
                    <label>取引先</label>
                      <select v-model="searchClient">
                        <option></option>
                        <option v-for="(option, index) in clientSelectOption" :key="index" :value="option.CustomerName.value">{{ option.CustomerName.value }}</option>
                      </select>
                  </div>
                  <div>
                    <label>作業者</label>
                      <select v-model="searchWorker">
                        <option></option>
                        <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                      </select>
                  </div>
                  <div>
                    <label>作成者</label>
                      <select v-model="searchAuthor">
                        <option></option>
                        <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                      </select>
                  </div>
                  <div>
                    <label>作成日</label>
                    <input type="date" v-model="searchStart"/> ~
                    <input type="date" v-model="searchEnd"/>
                  </div>
                </div>
                <div class="searchBox-content-block">
                  <div>
                    <label>承認ステータス</label>
                      <select v-model="searchApproval">
                        <option></option>
                        <option>未承認</option>
                        <option>申請中</option>
                        <option>承認済み</option>
                      </select>
                  </div>
                  <div>
                    <label>回答ステータス</label>
                      <select v-model="searchAnswer">
                        <option></option>
                        <option>対応中</option>
                        <option>完了</option>
                        <option>中止</option>
                      </select>
                  </div>
                </div>
              </div>
            </div>
            <table class="authorize-table">
              <thead>
                <th></th>
                <th>案件コード</th>
                <th>案件名</th>
                <th>取引先</th>
                <th>作成日時</th>
                <th>作業者</th>
                <th>作成者</th>
                <th>承認</th>
                <th>回答</th>
              </thead>
              <tbody>
                <tr v-for="(item, index) in paginatedData" :key="index">
                  <td @click="inputFormSelect(item.Title.value)"><span class="pointer">選択</span></td>
                  <td>{{ item.$id.value }}</td>
                  <td>{{ item.Title.value }}</td>
                  <td>{{ item.Client.value }}</td>
                  <td>{{ formatDateTime(item.作成日時.value) }}</td>
                  <td>{{ item.worker.value }}</td>
                  <td>{{ item.author.value }}</td>
                  <td>{{ item.ApprovalStatus.value }}</td>
                  <td>{{ item.AnswerStatus.value }}</td>
                </tr>
              </tbody>
            </table>
            <div class="pagination">
              <span>{{ currentPage }} / {{ totalPages }}</span>
              <img class="arrowButton" src="../assets/image/Expand_Arrow.png" @click="prevPage" :disabled="currentPage === 1">
              <img class="arrowButton" src="../assets/image/Expand_Arrow2.png" @click="nextPage" :disabled="currentPage * itemsPerPage >= totalFilteredItems" >
            </div>
            <div class="modal-button-block">
              <button class="normalButton" @click="closeModal()">閉じる</button>
            </div>
          </div>
        </div>
<!-- モーダルウィンドウ -->

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="OrderName">注文名</label>
                <span class="tooltip-content">注文名の入力フォームです。(必須項目)</span>
              </div>
              <div class="inputBlock">
                <input type="text" id="OrderName" v-model="formData.OrderName" @input="getApplicationData__Order(formData.OrderName)" />
                <span class="modallink" @click="openModal__Order()">選択する</span>
              </div>
            </div>
          </div>
        </div>

<!-- モーダルウィンドウ -->
        <div v-if="selectModalFlag__Order === true" class="modal" style="display: flex;">
          <div class="modal-content">
            <h2>注文選択</h2>
            <img src="../assets/image/close.png" class="close-right" @click="closeModal__Order">
            <div class="searchBox">
              <div class="searchBox-content">
                  <div>
                    <label>注文名</label>
                    <input type="text" placeholder="注文名" v-model="searchName__Order" style="width: 50%;"/>
                  </div>
                  <div>
                    <label>案件名</label>
                    <input type="text" placeholder="案件名" v-model="searchName__Order__Project" style="width: 50%;"/>
                  </div>
                <div class="searchBox-content-block">
                  <div>
                    <label>取引先</label>
                      <select v-model="searchClient__Order">
                        <option></option>
                        <option v-for="(option, index) in clientSelectOption" :key="index" :value="option.CustomerName.value">{{ option.CustomerName.value }}</option>
                      </select>
                  </div>
                  <div>
                    <label>作業者</label>
                      <select v-model="searchWorker__Order">
                        <option></option>
                        <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                      </select>
                  </div>
                  <div>
                    <label>作成者</label>
                      <select v-model="searchAuthor__Order">
                        <option></option>
                        <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                      </select>
                  </div>
                  <div>
                    <label>作成日</label>
                    <input type="date" v-model="searchStart__Order"/> ~
                    <input type="date" v-model="searchEnd__Order"/>
                  </div>
                </div>
                <div class="searchBox-content-block">
                  <div>
                    <label>承認ステータス</label>
                      <select v-model="searchApproval__Order">
                        <option></option>
                        <option>未承認</option>
                        <option>申請中</option>
                        <option>承認済み</option>
                      </select>
                  </div>
                  <div>
                    <label>回答ステータス</label>
                      <select v-model="searchAnswer__Order">
                        <option></option>
                        <option>対応中</option>
                        <option>受注済み</option>
                        <option>発注済み</option>
                        <option>失注</option>
                        <option>見送り</option>
                      </select>
                  </div>
                </div>
              </div>
            </div>
            <table class="authorize-table">
              <thead>
                <th></th>
                <th>案件コード</th>
                <th>案件名</th>
                <th>受注・発注</th>
                <th>取引先</th>
                <th>作成日時</th>
                <th>作業者</th>
                <th>作成者</th>
                <th>承認</th>
                <th>回答</th>
              </thead>
              <tbody>
                <tr v-for="(item, index) in paginatedData__Order" :key="index">
                  <td @click="inputFormSelect__Order(item.Title.value,item.Order.value)" v-if="item.ApprovalStatus.value === '承認済み'"><span class="pointer">選択</span></td>
                  <td v-else></td>
                  <td>{{ item.$id.value }}</td>
                  <td>{{ item.Title.value }}</td>
                  <td>{{ item.Order.value }}</td>
                  <td>{{ item.Client.value }}</td>
                  <td>{{ formatDateTime(item.作成日時.value) }}</td>
                  <td>{{ item.worker.value }}</td>
                  <td>{{ item.author.value }}</td>
                  <td>{{ item.ApprovalStatus.value }}</td>
                  <td>{{ item.AnswerStatus.value }}</td>
                </tr>
              </tbody>
            </table>
            <div class="pagination">
              <span>{{ currentPage__Order }} / {{ totalPages__Order }}</span>
              <img class="arrowButton" src="../assets/image/Expand_Arrow.png" @click="prevPage__Order" :disabled="currentPage__Order === 1">
              <img class="arrowButton" src="../assets/image/Expand_Arrow2.png" @click="nextPage__Order" :disabled="currentPage__Order * itemsPerPage__Order >= totalFilteredItems__Order" >
            </div>
            <div class="modal-button-block">
              <button class="normalButton" @click="closeModal__Order">閉じる</button>
            </div>
          </div>
        </div>
<!-- モーダルウィンドウ -->

        <div class="FormGroup">
          <h2>見積情報</h2>
          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Order">受注/発注</label>
                <span class="required">必須</span>
                <span class="tooltip-content">受注/発注の入力フォームです。(必須項目)</span>
              </div>
              <div class="inputBlock">
                <div class="radio" v-if="isEditFlag == false">
                  <div class="radio-block">
                    <label>受注</label>
                    <input type="radio" name="order" v-model="formData.Order" value="受注" :disabled="OrderFlag" @change="OrderInput()">
                  </div>
                  <div class="radio-block">
                    <label>発注</label>
                    <input type="radio" name="order" v-model="formData.Order" value="発注" :disabled="OrderFlag" @change="OrderInput()">
                  </div>
                </div>
                <div v-else>
                {{ formData.Order }}
                </div>
                <span>*保存後は変更不可。</span>
              </div>

            </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Title">見積名</label>
                <span class="required">必須</span>
                <span class="tooltip-content">見積名の入力フォームです。(必須項目)</span>
              </div>
              <div class="inputBlock" :class="{ 'hasError': !errors.Title.flag }">
                <input type="text" id="Title" v-model="formData.Title" :class="{ 'hasError': !errors.Title.flag }" />
                <span class="hasError-content">{{ errors.Title.msg }}</span>
              </div>
            </div>
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Client">取引先</label>
                <span class="required">必須</span>
                <span class="tooltip-content">取引先の入力フォームです。(必須項目)</span>
              </div>
              <div class="inputBlock" :class="{ 'hasError': !errors.Client.flag }">
                <select v-model="formData.Client" @change="ClientInput(formData.Client)">
                  <option></option>
                  <option v-for="(option, index) in clientSelectOption" :key="index" :value="option.CustomerName.value">{{ option.CustomerName.value }}</option>
                </select>
                <span class="hasError-content">{{ errors.Client.msg }}</span>
              </div>
            </div>
          </div>

        </div>

        <div class="FormGroup">
          <h2>見積書記載内容</h2>
          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="Consignor">委託者情報</label>
              <span class="tooltip-content">委託者情報の入力フォームです。</span>
            </div>
            <div class="inputBlock">
              <div>
                <label>会社名</label>
                <input type="text" v-model="formData.Consignor__company">
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-4items">
            <div class="nameBlock">
              <label for="Trustee">受託者情報</label>
              <span class="tooltip-content">受託者情報の入力フォームです。</span>
            </div>
            <div class="inputBlock">
              <div>
                <label>会社名</label>
                <input type="text" v-model="formData.Entrustor__company">
              </div>
              <div>
                <label>住所</label>
                <input type="text" v-model="formData.Entrustor__location">
              </div>
              <div>
                <label>担当者</label>
                <input type="text" v-model="formData.Entrustor__manager">
              </div>
              <div>
                <label>担当者の電話番号</label>
                <input type="text" v-model="formData.Entrustor__number">
              </div>
              <div>
                <label>担当者のメールアドレス</label>
                <input type="text" v-model="formData.Entrustor__mail">
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Date">発行日</label>
                <span class="tooltip-content">発行日の入力フォームです。</span>
              </div>
              <div class="inputBlock">
                <input type="date" id="Date" v-model="formData.Date"/>
              </div>
            </div>
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="OrderTitle">件名</label>
                <span class="tooltip-content">件名の入力フォームです。</span>
              </div>
              <div class="inputBlock">
                <input type="text" id="OrderTitle" v-model="formData.OrderTitle"/>
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="">実施期間</label>
              <span class="tooltip-content">実施期間の入力フォームです。</span>
            </div>
            <div class="inputBlock">
              <input type="text" id="input_startDate" v-model="formData.input_startDate"> ~ 
              <input type="text" id="input_endDate" v-model="formData.input_endDate">
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="input_endDate">有効期間</label>
              <span class="tooltip-content">有効期間の入力フォームです。</span>
            </div>
            <div class="inputBlock">
              <input type="text" id="valid_startDate" v-model="formData.valid_startDate"> ~ 
              <input type="text" id="valid_endDate" v-model="formData.valid_endDate">
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="memo">備考</label>
              <span class="tooltip-content">備考の入力フォームです。</span>
            </div>
            <div class="inputBlock">
              <textarea id="memo" v-model="formData.memo"></textarea>
            </div>
          </div>

          <div class="inputCol inputCol-1">
            <div class="nameBlock">
              <label for="Files">見積書ファイル</label>
            </div>
            <div class="inputBlock">
              <template v-if="formData.Files.length > 0">
                <div v-for="file in formData.Files" :key="file.name">{{ file.name }}</div>
                <!-- <label>変更</label><input type="file" id="Document" @change="handleFileChange" multiple /> -->
              </template>
              <template v-else>
                <input type="file" id="Files" @change="handleFileChange" multiple />
              </template>
              <template v-if="!UploadFlag">
                <img src="../assets/image/ZZ5H.gif" style="width: 25px;height: 25px;margin-left: 12px;">
              </template>
            </div>
          </div>

        </div>
        <div class="FormGroup">
        <h2>見積書の明細</h2>
        <!-- 複数の項目をまとめて追加できるフォーム領域 -->
          <div class="inputCol inputCol-4items">
            <div class="inputBlock">
              <b-table striped hover :fields="itemTableFields" :items="formData.itemTable" class="celltable">
                <template #cell(edit)="data">
                <div class="cellnum">{{data.index + 1}}</div>
                  <b-button v-if="data.index > 0" class="m-1" variant="success" :dataCol="data.index" @click="removePurchase(data.index)"><img src="../assets/image/gomibako.png"></b-button>
                  <b-button v-else variant="success" class="m-1" disabled :dataCol="data.index" ><img src="../assets/image/gomibako.png"></b-button>
                  <b-button variant="success"  class="m-1" :dataCol="data.index" @click="addPurchase(data.index)">＋</b-button>
                </template>

                <template #cell()="data">
<!--                  <label :for="data.field.key+data.index">{{data.field.label}}</label>-->
                  <template v-if="data.field">
                    <template v-if="data.field.type !='select'">
                      <div v-if="data.field.key === 'item__amount'" class="amount">
                        {{ formData.itemTable[data.index][data.field.key] }}
                      </div>
                      <div v-else>
                        <input class="m-1" :id="data.field.key+data.index" :type="data.field.type" v-model="formData.itemTable[data.index][data.field.key]" @input="updateTotal(data.index),updateValue(formData.itemTable[data.index][data.field.key],data.index,data.field)">
                      </div>
                    </template>
                    <template v-else>
                        <b-form-select :id="data.field.key+data.index" v-model="formData.itemTable[data.index][data.field.key]" class="m-1">
                          <template v-if="this[data.field.target].length > 0">
                          <b-form-select-option v-for="item in this[data.field.target]" :key="item" :value="item[data.field.data].value">
                            {{item[data.field.data].value}}
                          </b-form-select-option>
                          </template>
                        </b-form-select>
                    </template>
<!--                    <p v-for="item in this[data.field.target]" :key="item">{{data.field.data}}{{item}}</p>-->
                  </template>
                 </template>

              </b-table>
            </div>
          </div>

        <div class="total">
          <div class="normal">
          <div>管理費<span><input id="management" v-model="formData.management" type="text" @input="valueReplaceManagement(formData.management)">円</span></div>
          
          <div>消費税別<span>{{ formatNumber(totalSales) }}円</span></div>
          <div>合計<span>{{ formatNumber(total) }}円</span></div>

          <div>消費税<span>{{ formatNumber(tax) }}円</span></div>
          <div>税込請求金額<span>{{ formatNumber(total__tax) }}円</span></div>

          <!--
            <div>消費税別<span><input id="item__nottax" v-model="formData.item__nottax" type="number"></span></div>
            <div>管理費<span><input id="management" v-model="formData.management" type="number"></span></div>
            <div>合計<span><input id="total" v-model="formData.total" type="number"></span></div>
            <div>消費税<span><input id="tax" v-model="formData.tax" type="number"></span></div>
            <div>税込請求金額<span><input id="tax_included" v-model="formData.tax_included" type="number"></span></div>
          -->
          </div>
        </div>
      
        </div>

    <div class="FormGroup">
      <h2>担当情報</h2>
        <!-- 会社 -->
        <div class="inputCol inputCol-2">
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="company">会社</label>
              <span class="required">必須</span>
              <span class="tooltip-content">会社の入力フォームです。(必須項目)</span>
            </div>
            <div class="inputBlock" :class="{ 'hasError': !errors.company.flag }">
              <select id="company" :class="{ 'hasError': !errors.company.flag }" v-model="formData.company">
                <option></option>
                <option v-for="(option, index) in companySelectOption" :key="index" :value="option.CompanyName.value">{{ option.CompanyName.value }}</option>
              </select>
              <span class="hasError-content">{{ errors.company.msg }}</span>
            </div>
          </div>
        <!-- 部署 -->
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="department">部署</label>
              <span class="required">必須</span>
              <span class="tooltip-content">部署の入力フォームです。(必須項目)</span>
            </div>
            <div class="inputBlock" :class="{ 'hasError': !errors.department.flag }">
              <select id="department" :class="{ 'hasError': !errors.department.flag }" v-model="formData.department">
                <option></option>
                <option v-for="(option, index) in departmentSelectOption" :key="index" :value="option.Department_name.value">{{ option.Department_name.value }}</option>
              </select>
              <span class="hasError-content">{{ errors.department.msg }}</span>
            </div>
          </div>
        </div>

        <!-- 承認者 -->
        <div class="inputCol inputCol-2">
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="authorizer">承認者</label>
              <span class="required">必須</span>
              <span class="tooltip-content">承認者の入力フォームです。(必須項目)</span>
            </div>
            <div class="inputBlock" :class="{ 'hasError': !errors.authorizer.flag }">
              <select id="authorizer" :class="{ 'hasError': !errors.authorizer.flag }" v-model="formData.authorizer">
                <option></option>
                <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
              </select>
              <span class="hasError-content">{{ errors.authorizer.msg }}</span>
            </div>
          </div>
        <!-- 担当者 -->
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="manager">担当者</label>
              <span class="required">必須</span>
              <span class="tooltip-content">担当者の入力フォームです。(必須項目)</span>
            </div>
            <div class="inputBlock" :class="{ 'hasError': !errors.manager.flag }">
              <select id="manager" :class="{ 'hasError': !errors.manager.flag }" v-model="formData.manager">
                <option></option>
                <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
              </select>
              <span class="hasError-content">{{ errors.manager.msg }}</span>
            </div>
          </div>
        </div>

        <!-- 作業者 -->
        <div class="inputCol inputCol-2">
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="worker">作業者</label>
              <span class="required">必須</span>
              <span class="tooltip-content">作業者の入力フォームです。(必須項目)</span>
            </div>
            <div class="inputBlock" :class="{ 'hasError': !errors.worker.flag }">
              <select id="worker" :class="{ 'hasError': !errors.worker.flag }" v-model="formData.worker">
                <option></option>
                <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
              </select>
              <span class="hasError-content">{{ errors.worker.msg }}</span>
            </div>
          </div>
        <!-- 作成者 -->
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="author">作成者</label>
              <span class="required">必須</span>
              <span class="tooltip-content">作成者の入力フォームです。(自動入力)</span>
            </div>
            <div class="inputBlock">
              <input type="text" id="author" :class="{ 'hasError': !errors.author.flag }" v-model="formData.author" disabled>
              <span class="hasError-content">{{ errors.author.msg }}</span>
            </div>
          </div>
        </div>

    </div>

        <!-- 社内管理情報 -->
        
        <div class="FormGroup">
        <h2>社内管理情報</h2>
        <!-- 社内メモ -->
          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="internalMemo">社内メモ</label>
              <span class="tooltip-content">社内メモの入力フォームです。</span>
            </div>
            <div class="inputBlock">
              <textarea id="internalMemo" v-model="formData.internalMemo"></textarea>
            </div>
          </div>

        <!-- 関連ファイル -->
        
          <div class="inputCol inputCol-1">
            <div class="nameBlock">
              <label for="relatedFiles">関連ファイル</label>
            </div>
            <div class="inputBlock">
              <template v-if="formData.relatedFiles.length > 0">
                <div v-for="file in formData.relatedFiles" :key="file.name">{{ file.name }}</div>
                <!-- <label>変更</label><input type="file" id="Document" @change="handleFileChange" multiple /> -->
              </template>
              <template v-else>
                <input type="file" id="relatedFiles" @change="handleFileChange" multiple />
              </template>
              <template v-if="!UploadFlag">
                <img src="../assets/image/ZZ5H.gif" style="width: 25px;height: 25px;margin-left: 12px;">
              </template>
            </div>
          </div>

        </div>
      </form>
    </div>
    <button class="normalButton" @click="submitForm" :disabled="!UploadFlag" style="float: right; width: 140px;">保存</button>
  </div>
<div v-if="!dataLoading" class="loading">
  <img src="../assets/image/ZZ5H.gif">
</div>
</template>



<script>

export default {
  data() {
    return {
      formData: {
        LastUser: '',
        Project: '',
        ProjectId: '',
        OrderName: '',
        Order: '',
        Title: '',
        Client: '',
        Consignor__company: '',
        Entrustor__company: '',
        Entrustor__location: '',
        Entrustor__manager: '',
        Entrustor__number: '',
        Entrustor__mail: '',
        Date: '',
        OrderTitle: '',
        input_startDate: '',
        input_endDate: '',
        valid_startDate: '',
        valid_endDate: '',
        memo: '',
        itemTable:[
          {
            item   :'',
            item__startDate: '',
            item__endDate : '',
            item__price: '0',
            item__quantity: '0',
            item__unit: '',
            item__amount: '0',
          }
        ],
        itemTableTemplate:{
            item   :'',
            item__startDate: '',
            item__endDate : '',
            item__price: '0',
            item__quantity: '0',
            item__unit: '',
            item__amount: '0',
        },
      itemTableArray :1,
      itemTableFields:[
        {
          key:'item',
          label:'項目名',
          type:'text'
        },
        {
          key:'item__startDate',
          label:'開始日',
          type:'date'
        },
        {
          key:'item__endDate',
          label:'終了日',
          type:'date'
        },
        {
          key:'item__price',
          label:'単価',
          type:'text'
        },
        {
          key:'item__quantity',
          label:'数量',
          type:'text'
        },
        {
          key:'item__unit',
          label:'単位',
          type:'text'
        },
        {
          key:'item__amount',
          label:'金額',
          type:'text'
        },
        {
          key:'edit',
          label:'操作',
          type:'edit'
        }
      ],
        management: '0',
        total: '0',
        item__nottax: '0',
        tax: '0',
        tax_included: '0',

        company: '',
        department: '',
        authorizer: '',
        manager: '',
        worker: '',
        author: '',
        Files: [],
        relatedFiles: [], // ファイルデータを格納する配列

      },

      itemTableTemplate:{
            item   :'',
            item__startDate: '',
            item__endDate : '',
            item__price: '',
            item__quantity: '',
            item__unit: '',
            item__amount: '',
      },
      itemTableArray :1,
      itemTableFields:[
        {
          key:'item',
          label:'項目',
          type:'text'
        },
        {
          key:'item__startDate',
          label:'開始日',
          type:'date'
        },
        {
          key:'item__endDate',
          label:'終了日',
          type:'date'
        },
        {
          key:'item__price',
          label:'単価',
          type:'text'
        },
        {
          key:'item__quantity',
          label:'数量',
          type:'text'
        },
        {
          key:'item__unit',
          label:'単位',
          type:'text'
        },
        {
          key:'item__amount',
          label:'金額',
          type:'text'
        },
        {
          key:'edit',
          label:'操作',
          type:'edit'
        }
      ],

      errors: {
        Project: { msg:'', flag: true},
        Client: { msg:'', flag: true},
        Title: { msg:'', flag: true},
        company: { msg:'', flag: true},
        department: { msg:'', flag: true},
        authorizer: { msg:'', flag: true},
        manager: { msg:'', flag: true},
        worker: { msg:'', flag: true},
        author: { msg:'', flag: true},
      },

      clientSelectOption: [],
      companySelectOption: [],
      departmentSelectOption: [],
      EmployeeSelectOption: [],

      hasAlert : {
        flag : false,
        text : ''
      },
      hasAlert__Order : {
        flag : false,
        text : ''
      },
      isEditFlag : false,

      selectedFiles: [],
      formGroups: [['']],

      copyFlag: '',
      editFlag: '',
      createFlag: '',

//モーダルウィンドウ
      selectModalFlag: false,
      currentPage: 1, // 現在のページ
      itemsPerPage: 10, // 1ページあたりのアイテム数
      tableData: [],
      searchName: "",
      searchAuthor: "",
      searchClient: "",
      searchWorker: "",
      searchApproval: "承認済み",
      searchAnswer: "対応中",
      searchStart: "",
      searchEnd: "",
//
//モーダルウィンドウ
      selectModalFlag__Order: false,
      currentPage__Order: 1, // 現在のページ
      itemsPerPage__Order: 10, // 1ページあたりのアイテム数
      tableData__Order: [],
      searchName__Order: "",
      searchName__Order__Project: "",
      searchAuthor__Order: "",
      searchClient__Order: "",
      searchWorker__Order: "",
      searchApproval__Order: "承認済み",
      searchAnswer__Order: "対応中",
      searchStart__Order: "",
      searchEnd__Order: "",
//
      UploadFlag: true,
      OrderFlag: false,
    };
  },
  computed: {
//モーダルウィンドウ
    filteredTableData() {
      console.log(this.tableData)
        let filteredData = this.tableData.filter((item) => {

        const startDate = new Date(this.searchStart);
        const endDate = new Date(this.searchEnd);
        
        const itemDate = new Date(item.作成日時.value);

          return (
            (this.searchName === "" || item.Title.value.includes(this.searchName)) &&
            (this.searchClient === "" || item.Client.value === this.searchClient) &&
            (this.searchWorker === "" || item.worker.value === this.searchWorker) && 
            (this.searchAuthor === "" || item.author.value === this.searchAuthor) &&
            (this.searchApproval === "" || item.ApprovalStatus.value === this.searchApproval) &&
            (this.searchAnswer === "" || item.AnswerStatus.value === this.searchAnswer) &&
            (isNaN(startDate) || itemDate >= startDate) && // 開始日が未指定または作成日時が開始日以降
            (isNaN(endDate) || itemDate <= endDate) // 終了日が未指定または作成日時が終了日以前
          );
        });
        return filteredData;
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filteredTableData.slice(startIndex, endIndex);
    },
    totalPages() {
      const totalPagesNum = Math.ceil(this.filteredTableData.length / this.itemsPerPage);
      return totalPagesNum;
    },
//
//モーダルウィンドウ
    filteredTableData__Order() {
      console.log(this.tableData__Order)
        let filteredData__Order = this.tableData__Order.filter((item) => {
          const startDate = new Date(this.searchStart__Order);
          const endDate = new Date(this.searchEnd__Order);
          
          const itemDate = new Date(item.作成日時.value);
          return (
            (this.searchName__Order === "" || item.Title.value.includes(this.searchName__Order)) &&
            (this.searchName__Order__Project === "" || item.Project.value.includes(this.searchName__Order__Project)) &&
            (this.searchClient__Order === "" || item.Client.value === this.searchClient__Order) &&
            (this.searchWorker__Order === "" || item.worker.value === this.searchWorker__Order) && 
            (this.searchAuthor__Order === "" || item.author.value === this.searchAuthor__Order) &&
            (this.searchApproval__Order === "" || item.ApprovalStatus.value === this.searchApproval__Order) &&
            (isNaN(startDate) || itemDate >= startDate) && // 開始日が未指定または作成日時が開始日以降
            (isNaN(endDate) || itemDate <= endDate) // 終了日が未指定または作成日時が終了日以前
          );
        });
        return filteredData__Order;
    },
    paginatedData__Order() {
      const startIndex = (this.currentPage__Order - 1) * this.itemsPerPage__Order;
      const endIndex = startIndex + this.itemsPerPage__Order;
      return this.filteredTableData__Order.slice(startIndex, endIndex);
    },
    totalPages__Order() {
      const totalPagesNum = Math.ceil(this.filteredTableData__Order.length / this.itemsPerPage__Order);
      return totalPagesNum;
    },
//
    totalSales(){
      return this.formData.itemTable.reduce((total, group) => total + (parseFloat(this.valueReplace(group.item__amount)) || 0), 0);
    },
    total(){
      const management = this.valueReplace(this.formData.management);
      const A = Number(management)
      const B = Number(this.totalSales)
      console.log(A + B);
      return parseFloat(A) + parseFloat(B)
    },
    tax(){
      return  Math.floor(parseFloat(this.total) * 0.1)
    },
    total__tax(){
      var A = ""
      if(this.total + this.tax === "00"){
        A = 0
      }else{
        A = parseFloat(this.total) + parseFloat(this.tax)
      }
      return A
    },

    totalCost(){
      return this.formData.itemTable.reduce((total, group) => total + (parseFloat(group.cost) || 0), 0);
    },
    totalGrossProfit(){
      return this.formData.itemTable.reduce((total, group) => total + (parseFloat(group.grossProfit) || 0), 0);
    },
    totalChallengeSales() {
      // 複数のグループ内の売上（チャレンジ目標）を合算
      return this.formData.itemTable.reduce((total, group) => total + (parseFloat(group.sales_challenge) || 0), 0);
    },
    totalChallengeCost() {
      // 複数のグループ内の原価（チャレンジ目標）を合算
      return this.formData.itemTable.reduce((total, group) => total + (parseFloat(group.cost_challenge) || 0), 0);
    },
    totalChallengeGrossProfit() {
      // 複数のグループ内の粗利（チャレンジ目標）を合算
      return this.formData.itemTable.reduce((total, group) => total + (parseFloat(group.grossProfit_challenge) || 0), 0);
    },
    login_data:{
      get: function () {
        return this.$store.state.login_data
      },
      set: function (value) {
        this.$store.commit('login_data', value)
      }
    },
  },
  methods: {
    updateValue(value,index,field) {
      const formatvalue = value.replace(/,/g, '');
      let rawValue = formatvalue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      this.formData.itemTable[index][field.key] = rawValue
    },
    valueReplace(value){
      return value.replace(/,/g, '');
    },
    valueReplaceUpdate(value){
      return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    valueReplaceManagement(value){
      const valued = value.replace(/,/g, '');
      this.formData.management = valued.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    removeCommasFromValues(input) {
      if (typeof input === 'string') {
        return input.replace(/,/g, '');
      } else if (Array.isArray(input)) {
        return input.map(this.removeCommasFromValues);
      } else if (typeof input === 'object' && input !== null) {
        const newObj = {};
        for (let key in input) {
          if (Object.prototype.hasOwnProperty.call(input, key)) {
            newObj[key] = this.removeCommasFromValues(input[key]);
          }
        }
        return newObj;
      }
      return input;
    },
    cleanProspectData() {
      this.formData.itemTable = this.removeCommasFromValues(this.formData.itemTable);
      console.log(this.formData.itemTable);
    },
    formatNumber(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, '0');
      const day = today.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
//計算
    updateTotal(index) {
      // 合計金額を計算し、formDataにセット
      const price = this.valueReplace(this.formData.itemTable[index].item__price);
      const quantity = this.valueReplace(this.formData.itemTable[index].item__quantity);
      const total = Math.floor(price * quantity);
      console.log(total);
      this.formData.itemTable[index].item__amount = this.formatNumber(total);
    },
    getClientData: async function (){
      let params = {
        target: 'Client',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.clientSelectOption = authed.records;
    },
    getData : async function(){
      let params = {
        target: 'Quotation',
        querys: JSON.stringify(
            [
              {
                //対象カラム
                column :'DeleteStatus',
                //比較条件
                cond   :'in',
                //比較内容
                data   : '使用',
              },
              {
                //対象カラム
                column :'$id',
                //比較条件
                cond   :'=',
                //比較内容
                data   :this.createRecordId,
              }
            ]
        ),
        // join : JSON.stringify(),
      };
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      let updateData = {
        record : authed.records[0]
      }
      this.$commonPlugin.dataUpdate(this.formData,updateData,this.itemTableTemplate);
      this.dataLoading = true
    },
//モーダルウィンドウ
    inputFormSelect(value){
      this.formData.Project = value
      this.getApplicationData(value)
      this.closeModal()
    },
    openModal() {
      this.selectModalFlag = true; // モーダルを表示
    },
    closeModal() {
      this.selectModalFlag = false; // モーダルを非表示
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    getTableData: async function (){
      let params = {
        target: 'ProjectDetails',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      this.tableData = authed.records;
      console.log(authed)
    },
    formatDateTime(dateTimeString) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', timeZoneName: 'short', weekday: 'short' };
      const dateTime = new Date(dateTimeString);
      const stringDate = dateTime.toLocaleString('ja-JP', options);
      if (stringDate === "Invalid Date") {
        return "";
      } else {
        return stringDate.replace("JST", '').replace(',', '');
      }
    },
//
//モーダルウィンドウ
    inputFormSelect__Order(value,value2){
      this.formData.OrderName = value
      this.formData.Order = value2
      this.OrderFlag = true
      console.log(this.OrderFlag);
      this.closeModal__Order()
    },
    openModal__Order() {
      this.selectModalFlag__Order = true; // モーダルを表示
    },
    closeModal__Order() {
      this.selectModalFlag__Order = false; // モーダルを非表示
    },
    prevPage__Order() {
      if (this.currentPage__Order > 1) {
        this.currentPage__Order--;
      }
    },
    nextPage__Order() {
      if (this.currentPage__Order < this.totalPages__Order) {
        this.currentPage__Order++;
      }
    },
    getTableData__Order: async function (){
      let params = {
        target: 'Order',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      this.tableData__Order = authed.records;
      console.log(authed)
    },
//
//取引先情報取得
ClientInput(ls_target){
      if(ls_target == ""){
        return false;
      }
      let data = this.clientSelectOption;
      const foundItems = data.filter(item => item.CustomerName.value === ls_target);
      console.log(foundItems);
      if(this.formData.Order == "受注"){
        //自社情報を委託者情報に引用
        this.formData.Consignor__company = "株式会社リップルコミュニティ";
        //取引先情報を受託者情報に引用
        this.formData.Entrustor__company = foundItems[0].CustomerName.value;
        this.formData.Entrustor__location= foundItems[0].Prefectures.value+foundItems[0].Municipalities.value+foundItems[0].BuildingName.value;
        this.formData.Entrustor__manager= foundItems[0].BusinessPartnerPersonNames.value;
        this.formData.Entrustor__number= foundItems[0].BusinessPartnerPersonTelephoneNumber.value;
        this.formData.Entrustor__mail= foundItems[0].BusinessPartnerPersonEmailAddress.value;
      }
      if(this.formData.Order == "発注"){
        //自社情報を受託者情報に引用
        this.formData.Entrustor__company = "株式会社リップルコミュニティ";
        this.formData.Entrustor__location = "東京都新宿区西新宿6-12-7ストーク"
        this.formData.Entrustor__manager = "";
        this.formData.Entrustor__number = "0353154043";
        this.formData.Entrustor__mail = "info@ripty.jp";
        //取引先情報を委託者情報に引用
        this.formData.Consignor__company = foundItems[0].CustomerName.value;
      }
},
OrderInput(ls_target){
      console.log(ls_target);
        //自社情報を受託者情報に引用
        this.formData.Entrustor__company = "";
        this.formData.Entrustor__location = "";
        this.formData.Entrustor__manager = "";
        this.formData.Entrustor__number = "";
        this.formData.Entrustor__mail = "";
        //取引先情報を委託者情報に引用
        this.formData.Consignor__company = "";
      },
//
    getCompanyData: async function (){
      let params = {
        target: 'Company',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.companySelectOption= authed.records;
    },
    getEmployeeData: async function (){
      let params = {
        target: 'Employee',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.EmployeeSelectOption = authed.records;
    },
    getDepartmentData: async function (){
      let params = {
        target: 'Department',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.departmentSelectOption = authed.records;
    },
    getApplicationData : async function(ls_target){
      const targetColumn = {
        //formData = database
        ProjectId : 'record_id',
        company   : 'company',
        department: 'department',
        authorizer: 'authorizer',
        manager   : 'manager',
        worker    : 'worker',
        Client    : 'Client',
      }
      let params = {
        target: 'ProjectDetails',
        querys: JSON.stringify(
            [
              {
                //対象カラム
                column :'DeleteStatus',
                //比較条件
                cond   :'in',
                //比較内容
                data   :'使用',
              },
              {
                //対象カラム
                column :'Title',
                //比較条件
                cond   :'in',
                //比較内容
                data   : ls_target,
              }
            ]
        ),
        // join : JSON.stringify(),
      };
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      let database = authed.records;
      if(!database.length){
        this.hasAlert.flag = true
        this.hasAlert.text = 'データが見つかりません'

        for (let key in targetColumn) {
          this.formData[key] = '';
        }

        return false;
      }else{
        this.hasAlert.flag = false
        this.hasAlert.text = ''
      }
      // フォーム復元
      // 復元したいカラム = 復元するデータ
      for(let i = 0; i < database.length;i++){
        for (let key in targetColumn) {
          if(database[i][targetColumn[key]]){
            this.formData[key] = database[i][targetColumn[key]].value;
            this.searchName__Order__Project = database[i].Title.value
          }
        }
      }
    },
    getApplicationData__Order : async function(ls_target){
      const targetColumn = {
        //formData = database
        ProjectId : 'record_id',
        // company   : 'company',
        // department: 'department',
        // authorizer: 'authorizer',
        // manager   : 'manager',
        // worker    : 'worker',
        // Client    : 'Client',
      }
      let params = {
        target: 'Order',
        querys: JSON.stringify(
            [
              {
                //対象カラム
                column :'DeleteStatus',
                //比較条件
                cond   :'in',
                //比較内容
                data   :'使用',
              },
              {
                //対象カラム
                column :'Title',
                //比較条件
                cond   :'in',
                //比較内容
                data   : ls_target,
              }
            ]
        ),
        // join : JSON.stringify(),
      };
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      let database = authed.records;
      console.log(authed);
      if(!database.length){
        this.hasAlert__Order.flag = true
        this.hasAlert__Order.text = 'データが見つかりません'

        for (let key in targetColumn) {
          this.formData[key] = '';
        }

        return false;
      }else{
        this.hasAlert__Order.flag = false
        this.hasAlert__Order.text = ''
      }
      // フォーム復元
      // 復元したいカラム = 復元するデータ
      for(let i = 0; i < database.length;i++){
        for (let key in targetColumn) {
          if(database[i][targetColumn[key]]){
            this.formData[key] = database[i][targetColumn[key]].value;
          }
        }
      }

      if(ls_target == ""){
        this.getApplicationData(this.formData.Project)
      }
    },
    submitForm() {
      localStorage.setItem('hasSaved', 'true');
      if (this.$commonPlugin.validateForm(this.formData,this.errors)) {
        this.createFormMethod();
      }else{
        alert('入力内容にエラーが発生しました。');
      }
    },
    handleFileChange(event) {
      this.UploadFlag = false;
      const files = event.target.files;
      let selectedFiles = [];
      // 選択したファイルを配列に追加
      for (let i = 0; i < files.length; i++) {
        if(this.formData[event.target.id] == ''){
          this.formData[event.target.id] = [];
        }
        this.formData[event.target.id].push(files[i]);
        selectedFiles.push(files[i]);
      }
      // console.log(event.target.id)
      this.createFileOnData(event.target.id,selectedFiles);
    },
    createFileOnData: async function(target,selectedFiles){
      let params = {
        target  : 'Quotation',
        data    :JSON.stringify([
          {
            target : 'Worker',
            value  : this.login_data['Names'].value,
          }
        ]),
        filesTarget : 'File',
        files       : (selectedFiles)?selectedFiles : '',
      }
      // console.log(this.selectedFiles)
      let response = '';

      response = await this.$commonPlugin.axiosFileMultiAPI('file_upload_onData',params);

      console.log(response)
      this.formData[target] = [];
      response.result.forEach(item => {
        this.formData[target].push(...item.create_data);
      });
      console.log(this.formData);
      this.UploadFlag = true;
    },
    addPurchase(index){
      this.$commonPlugin.addArray(this.formData,index,'itemTable',this.itemTableTemplate);
    },
    removePurchase(index){
      this.$commonPlugin.removeArray(this.formData,index,'itemTable');
    },
    createFormMethod: async function(){
      console.log(this.formData);
      this.formData.LastUser = this.login_data["Names"].value
      this.formData.item__nottax = this.totalSales
      this.formData.total = this.total
      this.formData.tax = this.tax
      this.formData.tax_included = this.total__tax

      this.cleanProspectData(this.formData.itemTable);

      let pushArray = [];
      for(let key in this.formData){
        let data = {};
        data.target = key;
        data.value = this.formData[key];
        pushArray.push(data);
      }
      let params = {
        target: 'Quotation',
        data:JSON.stringify(pushArray),
        user:this.login_data["Names"].value,
        querys: JSON.stringify(
            [
              {
                //対象カラム
                column :'DeleteStatus',
                //比較条件
                cond   :'in',
                //比較内容
                data   : '使用',
              },
              {
                //対象カラム
                column :'$id',
                //比較条件
                cond   :'=',
                //比較内容
                data   :this.createRecordId,
              }
            ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_create_update_data_for_array_logic',params);
      this.$commonPlugin.dataUpdate(this.formData,authed,this.itemTableTemplate);
      if(authed.result.response === "失敗"){
        const msg = authed.result.errors;
        let messages = [];
        for (let key in msg) {
          if (msg[key].messages) {
            msg[key].messages.forEach((message) => {
              if (message !== "必須です。") {
                messages.push(message);
              }
            });
          }
        }
        alert('入力内容にエラーが発生しました。\n' +'・'+ messages.join('\n・'));
      }else{
        alert('保存しました。');
        var link = "";
        if(this.editFlag){
          link = "/QuotationDetails/"+authed.result.record.$id.value
          this.setData('QuotationDetails',authed.result.record.$id.value)
        }else{
          link = "/QuotationDetails/"+authed.result.id
          this.setData('QuotationDetails',authed.result.id)
        }
        this.$router.push(link);
      }
    },
    setData(page,id){
      this.setStrage('loginTimes',new Date());
      this.setStrage('pages',page);
      this.setStrage('id',id);
    },
    setStrage(target,value){
      localStorage.setItem(target,value);
    },
    inputCancel(){
    //  if (window.confirm('入力された内容を破棄してもよろしいですか？')) {
        var link = "/Quotation";
        this.$router.push(link);
    //  }
    }
  },
  created() {
    const urlParam = this.$commonPlugin.getUrlParameter('g'); // URLからパラメータを取得
    if (urlParam) {
      this.formData.Order = urlParam;
    }

    this.formData.Date = this.getCurrentDate();

    if(this.$commonPlugin.getUrlParameter('record_id')){
      this.createRecordId = this.$commonPlugin.getUrlParameter('record_id'); // URLからパラメータを取得
    }else{
      this.createRecordId = ""
    }
    
    this.createRecordId = this.$commonPlugin.getUrlParameter('record_id'); // URLからパラメータを取得
    if (this.createRecordId) {
      this.isEditFlag = true
    }
    const record_idParam = this.$commonPlugin.getUrlParameter('record_id');
    if(record_idParam){
      this.createRecordId = this.$commonPlugin.getUrlParameter('record_id'); // URLからパラメータを取得
    }
    const ProjectTitleParam = this.$commonPlugin.getUrlParameter('project_title');
    if(ProjectTitleParam){
      this.formData.Project = this.$commonPlugin.getUrlParameter('project_title');
      this.getApplicationData(this.formData.Project);
    }
    const ProjectIdParam = this.$commonPlugin.getUrlParameter('project_id');
    if(ProjectIdParam){
      this.formData.ProjectId = this.$commonPlugin.getUrlParameter('project_id');
    }

    console.log('-----------getTestData--------------')
    this.getData();
    console.log('-----------getTestData--------------')
    this.getClientData();
    this.getEmployeeData();
    this.getCompanyData();
    this.getDepartmentData();
    this.getTableData();
    this.getTableData__Order();
    this.copyFlag = this.$commonPlugin.getUrlParameter('copy');
    this.editFlag = this.$commonPlugin.getUrlParameter('edit');
    if (this.copyFlag) {
      this.createRecordId = ""
      this.editFlag = false
    }
    if (!this.copyFlag && !this.editFlag){
      this.createFlag = true
    }

    localStorage.setItem('hasSaved', 'false');
  },
  mounted() {
    this.formData.author = this.login_data.Names.value;
    this.searchAuthor = this.login_data.Names.value;
    this.searchWorker = this.login_data.Names.value;
    this.searchAuthor__Order = this.login_data.Names.value;
    this.searchWorker__Order = this.login_data.Names.value;
    console.log(this.login_data)
  }
};


</script>