<template>
<div class="pageHeader">
  <h1>設定</h1>
</div>
<div class="content-block">
  <h2>テーマカラー</h2>
  
</div>
</template> 
<script>
  export default {
    data() {
      return {

      };
    },
    computed: {

    },
    created() {

    },
    mounted() {

    },
    methods: {

    },
  };
</script>