import Axios from "axios";
class commonPlugin {
  constructor() {
    // プラグインの初期化
    if(this.checkDebugMode()){
      console.log('Hello This Common Plugin!')
    }
  }
  async axiosAPI(methods,param){
    const scope      = this;
    const axios      = Axios;
    const params     = new URLSearchParams();
    for(let key in param){
      params.append(key, param[key]);
    }
    const requestURL = process.env.VUE_APP_API_URL + process.env.VUE_APP_API_METHOD + methods
    return await axios.post(requestURL, params).then(function (response) {
      if(scope.checkDebugMode()){
        scope.handleMessage(response);
      }
      return response.data
    }).catch(function (error) {
      scope.handleError(error);
    });
  }
  async axiosFileAPI(methods,param){
    const scope      = this;
    const axios      = Axios;
    const params     = new FormData();
    for(let key in param){
      params.append(key, param[key]);
    }
    const requestURL = process.env.VUE_APP_API_URL + process.env.VUE_APP_API_METHOD + methods
    return await axios.post(requestURL, params).then(function (response) {
      if(scope.checkDebugMode()){
        scope.handleMessage(response);
      }
      return response.data
    }).catch(function (error) {
      scope.handleError(error);
    });
  }
  async axiosFileMultiAPI(methods,param){
    const scope      = this;
    const axios      = Axios;
    let params       = new FormData();
    for(let key in param){
      if(key == 'files'){
        console.log(param[key])
        for (let i = 0; i < param[key].length; i++) {
          params.append('files-'+i, param[key][i]);
        }
      }else{
        params.append(key, param[key]);
      }
    }
    const requestURL = process.env.VUE_APP_API_URL + process.env.VUE_APP_API_METHOD + methods
    console.log(params)
    return await axios.post(requestURL, params).then(function (response) {
      if(scope.checkDebugMode()){
        scope.handleMessage(response);
      }
      return response.data
    }).catch(function (error) {
      scope.handleError(error);
    });
  }

  /**外部API
   * [description]
   * @param  {[type]} methods [description]
   * @return {[type]}         [description]
   */
  async ExternalAxiosAPI(methods){
    const scope      = this;
    const axios      = Axios;
    return await axios.post(methods).then(function (response) {
      if(scope.checkDebugMode()){
        scope.handleMessage(response);
      }
      return response.data
    }).catch(function (error) {
      scope.handleError(error);
    });
  }

  /**成功時コンソール
   * [description]
   * @param  {[type]} message [description]
   * @return {[type]}         [description]
   */
  handleMessage(message) {
    if(this.typeEquals('String',message)){
      console.log("%c "+message+" %c",
          'background:#007bff ; padding: 2px; border-radius: 3px;  color: #fff',
          'background:transparent'
      );
    }else{
      console.log("%c "+this.getType(message)+" %c",
          'background:#17a2b8 ; padding: 2px; border-radius: 3px;  color: #fff',
          'background:transparent',
          message
      );
    }
    return false
  }
  /**エラー時コンソール
   * [description]
   * @param  {[type]} err [description]
   * @return {[type]}     [description]
   */
  handleError(err) {
    console.error(err);
    return false
  }
  /**
   *  値の有無をチェック
   *  phpのisset()に相当
   *  @param  data    値
   *  @return         true / false
   *
   */
  isset(data){
    if(data === "" || data === null || data === undefined){
      return false;
    }else{
      return true;
    }
  }

  isNum(value) {
    return ((typeof value === 'number') && (isFinite(value)));
  }

  getType(obj) {
    return Object.prototype.toString.call(obj).slice(8, -1);
  }

  typeEquals(type, obj) {
    return this.getType(obj) === type;
  }

  isEmpty(obj){
    return !Object.keys(obj).length;
  }

  checkDebugMode(){
    return process.env.VUE_APP_DEBUG_MODE;
  }
  validateForm(formData,errors){
    // console.log(errors)
    let result = true;
    for (const errorskey in errors) {
      if (!formData[errorskey]){
        errors[errorskey].msg = "必須項目です。";
        errors[errorskey].flag = false;
        result = false;
      }else{
        errors[errorskey].flag = true;
      }
    }
    console.log(result)
    return result;
  }
  dataUpdate(formData,data,Template){
    for (const dataKey in data.record) {
      if(formData[dataKey] != undefined){
        if(!Array.isArray( data.record[dataKey]['value'])){
          formData[dataKey] = data.record[dataKey]['value'];
        }else{
          if(data.record[dataKey]['type'] == 'FILE'){
            formData[dataKey] = data.record[dataKey]['value'];
          }else{
            for(let i = 0; i < data.record[dataKey]['value'].length;i++){
              for (const tableKey in data.record[dataKey]['value'][i]['value']) {
                if(!formData[dataKey][i]){
                  formData[dataKey][i] = Object.entries(Template);
                }
                formData[dataKey][i][tableKey] = data.record[dataKey]['value'][i]['value'][tableKey]['value'];
                console.log(formData);
              }
            }
          }

        }
      }
    }
  }
  addArray(formData,index,target,Template){
    Array.prototype.insert = function(index, item) {
      this.splice(index, 0, item);
    };
    let copyLists = [Template].map( list => ({...list}))
    console.log(copyLists);
    for (let i = 0; i < copyLists.length; i++) {
      const item = copyLists[i];
      for (const key in item) {
        if (Object.hasOwnProperty.call(item, key)) {
          if (key !== 'value') {
            item[key] = "";
            console.log(`Key: ${key}, Value: ${item[key]}`);
          }
        }
      }
    }
    formData[target].insert(index+1,copyLists[0]);
    console.log(formData[target])
  }
  removeArray(formData,index,target){
    formData[target].splice(index,1);
    console.log(formData[target])
  }
  getUrlParameter(parameterName) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(parameterName);
  }
}
export default commonPlugin;