<template>
<div style="text-align:center;">
  <img src="../assets/image/logo.gif" class="logoimage">
</div>
    <form>
      <div>
        <h1 style="font-size:24px;">ログイン</h1>
      </div>
      <div style="margin-top:24px;">
        <label for="username">メールアドレス</label>
        <input type="text" id="username" v-model="user_name" />
      </div>
      <div>
        <label for="password">パスワード</label>
        <input type="password" id="password" v-model="user_pass" />
      </div>
      <button :disabled="!areInputsValid" class="normalButton" type="button" @click="login">ログイン</button>
    </form>
    <p style="text-align:center">＊アカウント登録やパスワードの再発行は管理者にご連絡ください。</p>
</template>

<script>
export default {
  data() {
    return {
      user_name: 'tsunasawa.hironobu@gmail.com',
      user_pass: 'Ripple01'
    };
  },
  created() {
      this.LoginStrage();
    },
  computed: {
    areInputsValid() {
      return this.user_name.trim() !== '' && this.user_pass.trim() !== '';
    },
    login_data:{
      get: function () {
        return this.$store.state.login_data
      },
      set: function (value) {
        this.$store.commit('login_data', value)
      }
    }
  },
  methods: {
    LoginStrage(){
      const login_data = JSON.parse(localStorage.getItem('login_data'));
      if (login_data) {
        if(this.timecheck()){
          this.login_data = login_data
          this.setStrage('loginTimes',new Date())
          if(this.getStrage('pages')){
            console.log(this.getStrage('query'))
            if(this.getStrage('query') !== 'undefined'){
              this.$router.push({ path:this.getStrage('pages') ,query:JSON.parse(this.getStrage('query'))}); 
            }else{
              if(this.getStrage('id') !== 'undefined'){
                console.log(this.getStrage('pages')+'/'+this.getStrage('id'))
                this.$router.push({ path: this.getStrage('pages')+'/'+this.getStrage('id')  });
              }else{
                this.$router.push({ path: this.getStrage('pages')});
              }
            }
          }else{
            this.$router.push({ name: 'Top' });
          }
        }else{
          this.removeStrage('loginTimes');
          this.removeStrage('login_data');
          this.removeStrage('pages');
          this.removeStrage('id');
          alert('操作していない時間が2時間を超えたためログアウトされました。');
        }
      }else{
        this.removeStrage('loginTimes');
        this.removeStrage('login_data');
        this.removeStrage('pages');
      }
    },
    timecheck(){
      const strageDate = this.getStrage('loginTimes');
      const now = new Date();
      const strageDates = new Date(strageDate);
      now.setHours(now.getHours() - 2);

      //12:00      <    12:00 - 2:00 = 10
      return (strageDates > now)

    },
    checkStrage(target){
      return JSON.parse(localStorage.getItem(target));
    },
    getStrage(target){
      return localStorage.getItem(target);
    },
    setStrage(target,value){
      localStorage.setItem(target,value);
    },
    removeStrage(target){
      localStorage.removeItem(target);
    },
    login() {
      this.onLogin();
      return false;
      // if (this.username === 'user' && this.password === 'password') {
      //   alert('ログインしました。');
      //   // this.$router.push({ name: 'Top' });
      // } else {
      //   alert('ログインに失敗しました。');
      // }
    },
    onLogin: async function () {
      if (this.user_name === '' || this.user_pass === '') {
        alert('ログイン情報を入力してください');
        return false;
      }
      let params = {user_name: this.user_name, user_pass: this.user_pass};
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_login_logic',params);
      console.log(authed);
      if(authed.records){
        let response = await authed.records;
        if(response.length > 0){
          console.log(response[0]);
          //ログイン格納
          this.login_data = response[0];
          //ストレージ格納
          this.setStrage('login_data', JSON.stringify(this.login_data));
          this.setStrage('loginTimes',new Date())

          alert('ログイン成功しました。');
          await this.$router.push({ name: 'Top' });
        }else{
         alert('ログインに失敗しました。');
        }
      }
    },
    trigger: function (event) {
      // 日本語入力中のEnterキー操作は無効にする
      if (event.keyCode !== 13) {
        return false
      }
      this.onLogin()
    }
  }
};
</script>
<style scoped>

.col-md-3.col-12{
  display:none;
}
.col-md-9.col-12{
  width:100% !important;
}
form{
    text-align: center;
    width: 600px;
    margin: 60px auto 20px;
    border: 1px solid #cfcfcf;
    padding: 40px 120px;
    box-shadow: 0px 0px 5px #ccc;
}
form div{
  margin-bottom:16px;
}
form div input{
    width: 100%;
}
.logoimage{
    margin-top: 60px;
}
label{
    width: 160px;
    display:block;
    text-align:left;
}
</style>