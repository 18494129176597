
<template>
<div class="pageHeader">
  <h1>注文一覧</h1>
    <select class="normalButton" v-model="CreateOption">
      <option style="display:none">新規作成</option>
      <option value="受注">受注</option>
      <option value="発注">発注</option>
    </select>
</div>
  <div class="content-block">

    <div class="searchBox">
      <div class="searchBox-content">
        <h2>注文情報</h2>
        <button class="toggleSection" @click="toggleSection('案件情報')">
          <img class="toggleButton" src="../assets/image/Expand_Arrow.png" v-if="showSections['案件情報']">
          <img class="toggleButton_1" src="../assets/image/Expand_Arrow.png" v-if="!showSections['案件情報']">
        </button>
        <div class="searchBox-content-block" v-if="showSections['案件情報']">
          <div style="display: flex; margin-bottom: 0;">
            <div>
              <label>注文コード</label>
              <input type="text" placeholder="注文コード" v-model="searchCode" />
            </div>
            <div>
              <label>注文名</label>
              <input type="text" placeholder="注文名" v-model="searchName" style="width: 300px;"/>
            </div>
            <div>
              <label>受注/発注</label>
                <select v-model="searchOrders">
                  <option></option>
                  <option v-for="(option, index) in ordersSelectOption" :key="index" :value="option.value">{{ option.label }}</option>
                </select>
            </div>
          </div>
          <div style="display: flex; margin-bottom: 0;">
            <div>
              <label>案件名</label>
              <input type="text" placeholder="案件名" v-model="searchProjectName" style="width: 300px;"/>
            </div>
            <div>
              <label>取引先</label>
                <select v-model="searchClient">
                  <option></option>
                  <option v-for="(option, index) in clientSelectOption" :key="index" :value="option.CustomerName.value">{{ option.CustomerName.value }}</option>
                </select>
            </div>
          </div>
          <div style="display: flex; margin-bottom: 0;">
            <div>
              <label>発行日</label>
              <input type="date" v-model="StartDate__start"/> ~
              <input type="date" v-model="StartDate__end"/>
            </div>
          </div>
        </div>
      </div>
      <div class="searchBox-content">
        <h2>担当情報</h2>
        <button class="toggleSection" @click="toggleSection('担当情報')">
          <img class="toggleButton" src="../assets/image/Expand_Arrow.png" v-if="showSections['担当情報']">
          <img class="toggleButton_1" src="../assets/image/Expand_Arrow.png" v-if="!showSections['担当情報']">
        </button>
        <div class="searchBox-content-block" v-if="showSections['担当情報']">
          <div style="display: flex; margin-bottom: 0;">
            <div>
              <label>会社</label>
                <select v-model="searchCompany">
                  <option></option>
                  <option v-for="(option, index) in companySelectOption" :key="index" :value="option.CompanyName.value">{{ option.CompanyName.value }}</option>
                </select>
            </div>
            <div>
              <label>担当部署</label>
                <select v-model="searchDepartment">
                  <option></option>
                  <option v-for="(option, index) in departmentSelectOption" :key="index" :value="option.Department_name.value">{{ option.Department_name.value }}</option>
                </select>
            </div>
          </div>
          <div style="display: flex; margin-bottom: 0;">
            <div>
              <label>承認者</label>
                <select v-model="searchAuthorizer">
                  <option></option>
                  <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                </select>
            </div>
            <div>
              <label>担当者</label>
                <select v-model="searchManager">
                  <option></option>
                  <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                </select>
            </div>
            <div>
              <label>作業者</label>
                <select v-model="searchWorker">
                  <option></option>
                  <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                </select>
            </div>
            <div>
              <label>作成者</label>
                <select v-model="searchAuthor">
                  <option></option>
                  <option v-for="(option, index) in EmployeeSelectOption" :key="index" :value="option.Names.value">{{ option.Names.value }}</option>
                </select>
            </div>
          </div>
        </div>
      </div>
      <div class="searchBox-content">
        <h2>ステータス</h2>
        <button class="toggleSection" @click="toggleSection('ステータス')">
          <img class="toggleButton" src="../assets/image/Expand_Arrow.png" v-if="showSections['ステータス']">
          <img class="toggleButton_1" src="../assets/image/Expand_Arrow.png" v-if="!showSections['ステータス']">
        </button>
        <div class="searchBox-content-block" v-if="showSections['ステータス']">
          <div>
            <label>承認</label>
              <select v-model="searchAuthorize">
                <option></option>
                <option v-for="(option, index) in authorizeSelectOption" :key="index" :value="option.value">{{ option.label }}</option>
              </select>
          </div>
          <div>
            <label>回答</label>
              <select v-model="searchAnswer">
                <option></option>
                <option v-for="(option, index) in answerSelectOption" :key="index" :value="option.value">{{ option.label }}</option>
              </select>
          </div>
        </div>
      </div>
    </div>
    <div class="clearButtonBlock">
      <button @click="inputClear" class="clearButton normalButton">絞り込み条件をリセット</button>
    </div>
    <div class="listBox">
      <table v-if= "paginatedData.length > 0">
        <thead>
          <tr>
            <th @click="sortTable('$id')">注文コード
              <span v-if="sortKey === '$id'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('Title')">注文名
              <span v-if="sortKey === 'Title'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('Project')">案件名
              <span v-if="sortKey === 'Project'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('Order')">受注/発注
              <span v-if="sortKey === 'Order'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('Client')">取引先
              <span v-if="sortKey === 'Client'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('Date__issue')">発行日
              <span v-if="sortKey === 'Date__issue'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('company')">会社
              <span v-if="sortKey === 'company'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('department')">部署
              <span v-if="sortKey === 'department'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('authorizer')">承認者
              <span v-if="sortKey === 'authorizer'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('manager')">担当者
              <span v-if="sortKey === 'manager'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('worker')">作業者
              <span v-if="sortKey === 'worker'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('author')">作成者
              <span v-if="sortKey === 'author'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('ApprovalStatus')">承認
              <span v-if="sortKey === 'ApprovalStatus'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
            <th @click="sortTable('AnswerStatus')">回答
              <span v-if="sortKey === 'AnswerStatus'">
                <span v-if="sortDesc">↑</span><span v-else>↓</span>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in paginatedData" :key="index">
            <td>{{ item.$id.value  }}</td>
            <td>
              <router-link :to="{ name: '注文詳細', params: { id: item.$id.value } }" @click="setData('OrderDetails',item.$id.value)">
                {{ item.Title.value }}
              </router-link>
            </td>
            <td>{{ item.Project.value }}</td>
            <td>{{ item.Order.value }}</td>
            <td>{{ item.Client.value }}</td>
            <td>{{ item.Date__issue.value }}</td>
            <td>{{ item.company.value }}</td>
            <td>{{ item.department.value }}</td>
            <td>{{ item.authorizer.value }}</td>
            <td>{{ item.manager.value }}</td>
            <td>{{ item.worker.value }}</td>
            <td>{{ item.author.value }}</td>
            <td>{{ item.ApprovalStatus.value }}</td>
            <td>{{ item.AnswerStatus.value }}</td>
          </tr>
        </tbody>
      </table>
      <div v-else>
        <p class="notFound">一覧に表示できるデータがありません</p>
      </div>
    </div>
          <div class="pagination">
      <span>{{ currentPage }} / {{ totalPages }}</span>
        <img class="arrowButton" src="../assets/image/Expand_Arrow.png" @click="prevPage" :disabled="currentPage === 1">
        <img class="arrowButton" src="../assets/image/Expand_Arrow2.png" @click="nextPage" :disabled="currentPage * itemsPerPage >= totalFilteredItems" >
      </div>
  </div>
</template>

<script>

export default {
  components: {

  },
  data() {
    return {
      showSections: {
        '案件情報': true,   // 最初は表示
        '担当情報': false,  // 最初は非表示
        'ステータス': false,  // 最初は非表示
      },
      tableData: [],

      clientSelectOption: [],

      companySelectOption: [],
    
      departmentSelectOption: [],

      EmployeeSelectOption: [],

      authorizeSelectOption: [
        { value: '承認済み', label: '承認済み' },
        { value: '申請中', label: '申請中' },
        { value: '未承認', label: '未承認' },
      ],

      answerSelectOption: [
        { value: '対応中', label: '対応中' },
        { value: '受注済み', label: '受注済み' },
        { value: '発注済み', label: '発注済み' },
        { value: '失注', label: '失注' },
        { value: '見送り', label: '見送り' },
      ],

      ordersSelectOption: [
        { value: '受注', label: '受注' },
        { value: '発注', label: '発注' },
      ],

      CreateOption:"新規作成",


      // 以下はフィルタリングやページネーションに使用するデータ
      searchCode: "",
      searchName: "",
      searchProjectName: "",
      searchOrders: "",
      searchClient: "",
      searchCompany: "",
      searchDepartment: "",
      searchAuthorizer: "",
      searchManager: "",
      searchWorker: "",
      searchAuthor: "",
      searchAuthorize: "",
      searchAnswer: "",
      StartDate__start: "",
      StartDate__end: "",

      startDate: "",
      EndDate: "",
      minEarningsProspect: "",
      maxEarningsProspect: "",
      minEarningsAchievements: "",
      maxEarningsAchievements: "",

      sortKey: "code",
      sortDesc: false,
      currentPage: 1,
      itemsPerPage: 10,
    };
  },
  watch: {
    CreateOption(newVal) {
      // selectedOptionが変更されたときにリダイレクト
      // window.location.href = newVal;
      // console.log(newVal)
      this.$router.push({
        path: 'OrderCreate' ,
        query: { g: newVal , record_id: ''}
      });
    },
  },
  computed: {
    // フィルタリングされたデータを計算
    filteredTableData() {
      if(this.tableData.length<0){
        return false;
      }
      let filteredData = this.tableData.filter((item) => {
        const startDate = new Date(this.StartDate__start);
        const endDate = new Date(this.StartDate__end);
        const itemDate = new Date(item.Date__issue.value);
        return (
          (this.searchCode === "" || item.$id.value.includes(this.searchCode)) &&
          (this.searchName === "" || item.Title.value.includes(this.searchName)) &&
          (this.searchProjectName === "" || item.Project.value.includes(this.searchProjectName)) &&
          (this.searchOrders === "" || item.Order.value === this.searchOrders) &&
          (this.searchClient === "" || item.Client.value === this.searchClient) &&
          (this.searchCompany === "" || item.company.value === this.searchCompany) &&
          (this.searchDepartment === "" || item.department.value === this.searchDepartment) &&
          (this.searchAuthorizer === "" || item.authorizer.value === this.searchAuthorizer) &&
          (this.searchManager === "" || item.manager.value === this.searchManager) && 
          (this.searchWorker === "" || item.worker.value === this.searchWorker) && 
          (this.searchAuthor === "" || item.author.value === this.searchAuthor) && 
          (this.searchAuthorize === "" || item.ApprovalStatus.value === this.searchAuthorize) && 
          (this.searchAnswer === "" || item.AnswerStatus.value === this.searchAnswer) &&
          (isNaN(startDate) || itemDate >= startDate) && // 開始日が未指定または作成日時が開始日以降
          (isNaN(endDate) || itemDate <= endDate) // 終了日が未指定または作成日時が終了日以前
        );
      });

        let sortkey = this.sortKey;
        // ソート
        if(filteredData){
          if (this.sortDesc) {
            filteredData.sort(function(a,b){
              if(!a[sortkey] || !b[sortkey]){return false}
              let aData = (!isNaN(a[sortkey]['value']))?Number(a[sortkey]['value']):a[sortkey]['value'];
              let bData = (!isNaN(b[sortkey]['value']))?Number(b[sortkey]['value']):b[sortkey]['value'];
              return (aData > bData)?1:-1
            })
          } else {
            filteredData.sort(function(a,b){
              if(!a[sortkey] || !b[sortkey]){return false}
              let aData = (!isNaN(a[sortkey]['value']))?Number(a[sortkey]['value']):a[sortkey]['value'];
              let bData = (!isNaN(b[sortkey]['value']))?Number(b[sortkey]['value']):b[sortkey]['value'];
              return (aData < bData)?1:-1
            })
          }
        }
        console.log(filteredData)
        return filteredData;

    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filteredTableData.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.filteredTableData.length / this.itemsPerPage);
    },
  },
  created() {
    this.getTableData();
    this.getClientData();
    this.getCompanyData();
    this.getDepartmentData();
    this.getEmployeeData();
  },
  mounted() {
    this.getTableData();
    this.getClientData();
    this.getCompanyData();
    this.getDepartmentData();
    this.getEmployeeData();
  },
  methods: {
    getTableData: async function (){
      let params = {
        target: 'Order',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      this.tableData = authed.records;
      console.log(authed)
    },
    getClientData: async function (){
      let params = {
        target: 'Client',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.clientSelectOption = authed.records;
    },
    getCompanyData: async function (){
      let params = {
        target: 'Company',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.companySelectOption = authed.records;
    },
    getDepartmentData: async function (){
      let params = {
        target: 'Department',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.departmentSelectOption = authed.records;
    },
    getEmployeeData: async function (){
      let params = {
        target: 'Employee',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            }
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.EmployeeSelectOption = authed.records;
    },
    isAmountInRange(amount, minAmount, maxAmount) {
      const numericAmount = parseFloat(amount);
      if (isNaN(numericAmount)) {
        return true; // amountが数値でない場合はフィルタリングせずに表示
      }

      if (minAmount !== "" && numericAmount < parseFloat(minAmount)) {
        return false;
      }
      if (maxAmount !== "" && numericAmount > parseFloat(maxAmount)) {
        return false;
      }

      return true;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    sortTable(key) {
      if (this.sortKey === key) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortKey = key;
        this.sortDesc = false;
      }
    },

    toggleSection(sectionName) {
      this.showSections[sectionName] = !this.showSections[sectionName];
    },
    inputClear(){
      this.searchCode = "";
      this.searchName = "";
      this.searchProjectName = "";
      this.searchOrders = "";
      this.searchClient = "";
      this.searchCompany = "";
      this.searchDepartment = "";
      this.searchAuthorizer = "";
      this.searchManager = "";
      this.searchWorker = "";
      this.searchAuthor = "";
      this.searchAuthorize = "";
      this.searchAnswer = "";
      this.StartDate__start = "";
      this.StartDate__end = "";
      this.EndDate = "";
      this.minEarningsProspect = "";
      this.maxEarningsProspect = "";
      this.minEarningsAchievements = "";
      this.maxEarningsAchievements = "";
    },
    setData(page,id){
      this.setStrage('loginTimes',new Date());
      this.setStrage('pages',page);
      this.setStrage('id',id);
    },
    setStrage(target,value){
      localStorage.setItem(target,value);
    },
  },
};
</script>