<template>
  <commonHeader></commonHeader>
  <b-row>
    <b-col cols="12" md="3" :class="{'close': isMenuOpen , 'login-page': !showMenu}">
    <button @click="toggleMenu" class="menuToggle">&#9776;</button>
    <br style="clear:both">
      <SideMenu></SideMenu>
    </b-col>
    <b-col cols="12" md="9" :class="{'login-page': !showMenu , 'close': isMenuOpen}">
      <router-view/>
    </b-col>
  </b-row>
</template>

<script>
// @ is an alias to /src
import SideMenu from "@/views/parts/SideMenu";
import commonHeader from "@/components/common/common-header";

export default {
  name: 'HomeView',
  data() {
    return {
      isMenuOpen: false // メニューの初期状態は閉じた状態
    };
  },
  components:{
    SideMenu,
    commonHeader,
  },
  computed: {
    showMenu() {
      // 現在のルートから showMenu フラグを取得
      return this.$route.meta.showMenu !== false;
    }
  },
  methods: {
    toggleMenu(){
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
}
</script>

<style lang="scss">
@import "src/assets/scss/common/common";
</style>
