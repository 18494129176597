<template>
  <div class="pagetopButton"  v-if="dataLoading">
    <div class="custom-select">
      <label>売上実績:</label>
      <div class="selected-option" @click="toggleDropdown__sales">
        {{ displayData[0].$join_data.Expenses.records[0].SalesStatus.value }}<i class="fas fa-caret-down"></i>
      </div>
        <div class="options" :class="{ 'open': isDropdownOpen__sales }">
          <div>
            <input class="optionsInput" type="radio" name="SalesStatus" v-model="SalesStatusData.SalesStatus" value="未反映">
            <label>未反映</label>
          </div>
          <div>
            <input class="optionsInput" type="radio" name="SalesStatus" v-model="SalesStatusData.SalesStatus" value="反映済み">
            <label>反映済み</label>
          </div>
          <button @click="saveOption__sales" class="normalButton" :disabled="!ApprovalStatus">変更</button>
        </div>
    </div>
    <div class="custom-select">
      <label>回答:</label>
      <div class="selected-option" @click="toggleDropdown__answer">
        {{ displayData[0].$join_data.Expenses.records[0].AnswerStatus.value }}<i class="fas fa-caret-down"></i>
      </div>
        <div class="options" :class="{ 'open': isDropdownOpen__answer }">
          <div>
            <input class="optionsInput" type="radio" name="AnswerStatus" v-model="statusData.AnswerStatus" value="対応中">
            <label>対応中</label>
          </div>
          <div>
            <input class="optionsInput" type="radio" name="AnswerStatus" v-model="statusData.AnswerStatus" value="精算済み">
            <label>精算済み</label>
          </div>
          <div>
            <input class="optionsInput" type="radio" name="AnswerStatus" v-model="statusData.AnswerStatus" value="中止">
            <label>中止</label>
          </div>
          <button @click="saveOption__answer" class="normalButton" :disabled="!ApprovalStatus">変更</button>
        </div>
    </div>
    <div class="custom-select">
      <label>承認:</label>
      <button class="selected-option" @click="openModal" style="display:block; text-align:center;" :class="{'shinseityu': displayData[0].$join_data.Expenses.records[0].ApprovalStatus.value === '申請中'}">
      {{ displayData[0].$join_data.Expenses.records[0].ApprovalStatus.value }}
      </button>
      <div class="modal" v-if="isModalOpen" style="display:flex;">
        <div class="modal-content">
          <h2>承認</h2>
          <img src="../assets/image/close.png" class="close-right" @click="closeModal">
          <table class="authorize-table">
            <thead>
              <th>申請者</th>
              <th>申請日時</th>
              <th>申請内容</th>
              <th>承認者</th>
              <th>承認日時</th>
              <th>承認結果</th>
            </thead>
            <tbody>
              <tr v-for="(item, index) in paginatedData" :key="index">
                <td>{{ item.Applicant.value }}</td>
                <td>{{ formatDateTime(item.ApplicationDateAndTime.value) }}</td>
                <td>{{ item.ApplicationContents.value }}</td>
                <td>{{ item.Authorizer.value }}</td>
                <td>{{ formatDateTime(item.ApprovalDateAndTime.value) }}</td>
                <td>{{ item.ApprovalResults.value }}</td>
              </tr>
            </tbody>
          </table>
          <div class="pagination">
            <span>{{ currentPage }} / {{ totalPages }}</span>
            <img class="arrowButton" src="../assets/image/Expand_Arrow.png" @click="prevPage" :disabled="currentPage === 1">
            <img class="arrowButton" src="../assets/image/Expand_Arrow2.png" @click="nextPage" :disabled="currentPage * itemsPerPage >= totalFilteredItems" >
          </div>
          <div class="modal-button-block">
            <button class="normalButton" @click="closeModal">閉じる</button>
            <button class="normalButton" @click="submitForm('承認依頼')">承認依頼</button>
            <button class="normalButton" @click="submitForm('差し戻し依頼')">差し戻し依頼</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pageHeader"  v-if="dataLoading">
    <h1>経費詳細</h1>
    <div style="position:relative;">
      <select class="normalButton"
        style="
          padding-left: 6px;
          padding-right: 3px;
          width: 32px;
          min-width: auto;
          letter-spacing: -3px;
          appearance: none;
          height: 34px;
          margin-top: 3px;
          background: #F7F5F5;
        "
        v-model="ModalOption"
      >
        <option style="display:none">・・・</option>
        <option value="複製">複製</option>
        <option value="変更履歴">変更履歴</option>
      </select>
    </div>
    <router-link :to="editLink">
      <button class="normalButton" :disabled=" displayData[0].$join_data.Expenses.records[0].ApprovalStatus.value === '承認済み' ">編集</button>
    </router-link>
    <div class="modal" v-if="isModalOpen__change" style="display:flex;">
      <div class="modal-content">
        <h2>変更履歴</h2>
        <img src="../assets/image/close.png" class="close-right" @click="closeModal">
        <table class="authorize-table">
          <thead>
            <tr>
              <th>実行者</th>
              <th>変更日時</th>
              <th>変更内容</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in paginatedData__Revision" :key="index">
              <td>{{ item.updateUser.value }}</td>
              <td>{{ formatDateTime(item.created_at.value) }}</td>
              <td>
                <div class="changeItem" v-for="(changeItem, changeIndex) in item.changedProperties" :key="changeIndex">
                  <div class="changeItemName">{{ changeItem.key }}</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <span>{{ currentPage }} / {{ totalPages__Revision }}</span>
          <img class="arrowButton" src="../assets/image/Expand_Arrow.png" @click="prevPage" :disabled="currentPage === 1">
          <img class="arrowButton" src="../assets/image/Expand_Arrow2.png" @click="nextPage" :disabled="currentPage * itemsPerPage >= totalFilteredItems" >
        </div>
        <div class="modal-button-block">
          <button class="normalButton" @click="closeModal">閉じる</button>
        </div>
      </div>
    </div>
    <div class="modal" v-if="isModalOpen__hakko" style="display:flex;">
      <div class="modal-content">
        <h2>発行履歴</h2>
        <img src="../assets/image/close.png" class="close-right" @click="closeModal">
        <table class="authorize-table">
          <thead>
            <tr>
              <th>実行者</th>
              <th>発行日時</th>
              <th>発行種別</th>
              <th>発行内容</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in paginatedData__Hakko" :key="index">
              <td>{{ item.user.value }}</td>
              <td>{{ formatDateTime(item.created_at.value) }}</td>
              <td>{{ item.types.value }}</td>
              <td>{{ item.contents.value }}</td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <span>{{ currentPage }} / {{ totalPages__Hakko }}</span>
          <img class="arrowButton" src="../assets/image/Expand_Arrow.png" @click="prevPage" :disabled="currentPage === 1">
          <img class="arrowButton" src="../assets/image/Expand_Arrow2.png" @click="nextPage" :disabled="currentPage * itemsPerPage >= totalFilteredItems" >
        </div>
        <div class="modal-button-block">
          <button class="normalButton" @click="closeModal">閉じる</button>
        </div>
      </div>
    </div>
  </div>
  <div class="pageHeaderBlock" v-if="dataLoading">
    <div class="pageHeaderContent">
      <div v-if="tableData.length > 0">
        <span style="margin-left: 8px;">最終更新者</span>
        <span><img src="../../src/assets/image/AlarmClock.png">{{ formatDateTime(displayData[0].$join_data.Expenses.records[0].更新日時.value) }}</span>
        <span><img src="../../src/assets/image/MaleUser.png">{{ displayData[0].$join_data.Expenses.records[0].LastUser.value }}</span>
        <span style="margin-left: 8px;">最終承認者</span>
        <span>
          <img src="../../src/assets/image/Update.png">
          <template v-if="tableData[0].ApprovalResults.value === '承認済み'">
          {{ formatDateTime(paginatedData[0].更新日時.value) }}
          </template>
        </span>
        <span>
          <img src="../../src/assets/image/MaleUser_a.png">
          <template v-if="tableData[0].ApprovalResults.value === '承認済み'">
          {{ paginatedData[0].Authorizer.value }}
          </template>
        </span>
      </div>
      <div v-else>
        <span style="margin-left: 8px;">最終更新者</span>
        <span><img src="../../src/assets/image/AlarmClock.png">{{ formatDateTime(displayData[0].$join_data.Expenses.records[0].更新日時.value) }}</span>
        <span><img src="../../src/assets/image/MaleUser.png">{{ displayData[0].$join_data.Expenses.records[0].LastUser.value }}</span>
        <span style="margin-left: 8px;">最終承認者</span>
        <span><img src="../../src/assets/image/Update.png"></span>
        <span><img src="../../src/assets/image/MaleUser_a.png"></span>
      </div>
    </div>
  </div>

<div class="side-block-template" v-if="dataLoading">
  <div class="content-block side">
    <div id="formImput">
      <div class="FormGroup">
        <!-- 案件名 -->
        <h2>紐付け情報</h2>

        <div class="inputCol inputCol-2">
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="ProjectTitle">案件名</label>
            </div>
            <div class="inputBlock">
              <div>{{ displayData[0].$join_data.Expenses.records[0].ProjectTitle.value }}</div>
            </div>
            </div>
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Approval">稟議名</label>
              </div>
              <div class="inputBlock">
                <div>{{ displayData[0].$join_data.Expenses.records[0].Approval.value }}</div>
              </div>
            </div>
          </div>
      </div>
        <div class="FormGroup">
        <h2>経費情報</h2>

          <div class="inputCol inputCol-1">
            <div class="nameBlock">
              <label for="subject">件名</label>
            </div>
            <div class="inputBlock">
              <div>{{ displayData[0].$join_data.Expenses.records[0].subject.value }}</div>
            </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Filing_date">提出日</label>
              </div>
              <div class="inputBlock">
                <div>{{ displayData[0].$join_data.Expenses.records[0].Filing_date.value }}</div>
              </div>
            </div>
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Implementation_date">実施日</label>
              </div>
              <div class="inputBlock">
                <div>{{ displayData[0].$join_data.Expenses.records[0].Implementation_date.value }}</div>
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-2">
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Department_code">部門コード</label>
              </div>
              <div class="inputBlock">
                <div>{{ displayData[0].$join_data.Expenses.records[0].Department_code.value }}</div>
              </div>
            </div>
            <div class="inputCol-2-block">
              <div class="nameBlock">
                <label for="Department">部門名</label>
              </div>
              <div class="inputBlock">
                <div>{{ displayData[0].$join_data.Expenses.records[0].Department.value }}</div>
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-1">
            <div class="nameBlock">
              <label for="Submitter">提出者</label>
              <span class="tooltip-content">提出者の入力フォームです。</span>
            </div>
            <div class="inputBlock">
              <div>{{ displayData[0].$join_data.Expenses.records[0].Submitter.value }}</div>
            </div>
          </div>

          <div class="inputCol inputCol-1">
            <div class="nameBlock">
              <label for="document">添付書類</label>
            </div>
            <div class="inputBlock">
              <div>
                <ul class="filelist">
                  <li>
                    <div v-for="key in displayData[0].$join_data.Expenses.records[0]['document']['value']" :key="key.name">
                      <span style="margin-bottom:0;" @click="downloadFile('Expenses',key.fileKey,key.name);">
                      {{key.name}}
                      <img src="../../src/assets/image/dl.png">
                      </span>
                      {{fileKeyDate(key.fileKey)}}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="Purpose">目的・概要</label>
            </div>
            <div class="inputBlock">
              <div>{{ displayData[0].$join_data.Expenses.records[0].Purpose.value }}</div>
            </div>
          </div> 





        </div>
        <div class="FormGroup">
          <h2>申請情報</h2>
          <div class="table-content">
            <table>
              <thead>
                <th>購入金額</th>
                <th>購入先</th>
                <th>支払方法</th>
                <th>支払日</th>
                <th>勘定科目</th>
              </thead>
              <tbody>
                <tr v-for="(item, index) in displayData[0].$join_data.Expenses.records[0].PurchaseAmount.value" :key="index">
                  <td>{{ formatNumber(item.value.money.value) }}円</td>
                  <td>{{ item.value.Retailer.value }}</td>
                  <td>{{ item.value.Payment.value }}</td>
                  <td>{{ item.value.Payment_Date.value }}</td>
                  <td>{{ item.value.Account.value }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="total" style="border-bottom: 1px solid #ededed;">
            <div class="normal">
              <div>発生費用<span>{{ formatNumber(displayData[0].$join_data.Expenses.records[0].total.value) }}円</span></div>
            </div>
          </div>
          <div class="inputCol inputCol-textarea">
            <div class="nameBlock">
              <label for="Opinion">決済者および関係部門意見</label>
            </div>
            <div class="inputBlock">
              <div>{{ displayData[0].$join_data.Expenses.records[0].Opinion.value }}</div>
            </div>
          </div>
        </div>
        <!-- 社内管理情報 -->
        
      <div class="FormGroup">
        <h2>担当情報</h2>
        <!-- 会社 -->
        <div class="inputCol inputCol-2">
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="company">会社</label>
            </div>
            <div class="inputBlock">
              <div>{{ displayData[0].$join_data.Expenses.records[0].company.value }}</div>
            </div>
          </div>
        <!-- 部署 -->
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="department">部署</label>
            </div>
            <div class="inputBlock">
              <div>{{ displayData[0].$join_data.Expenses.records[0].department.value }}</div>
            </div>
          </div>
        </div>

        <!-- 承認者 -->
        <div class="inputCol inputCol-2">
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="authorizer">承認者</label>
            </div>
            <div class="inputBlock">
              <div>{{ displayData[0].$join_data.Expenses.records[0].authorizer.value }}</div>
            </div>
          </div>
        <!-- 担当者 -->
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="manager">担当者</label>
            </div>
            <div class="inputBlock">
              <div>{{ displayData[0].$join_data.Expenses.records[0].manager.value }}</div>
            </div>
          </div>
        </div>

        <!-- 作業者 -->
        <div class="inputCol inputCol-2">
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="worker">作業者</label>
            </div>
            <div class="inputBlock">
              <div>{{ displayData[0].$join_data.Expenses.records[0].worker.value }}</div>
            </div>
          </div>
        <!-- 作成者 -->
          <div class="inputCol-2-block">
            <div class="nameBlock">
              <label for="author">作成者</label>
            </div>
            <div class="inputBlock">
              <div>{{ displayData[0].$join_data.Expenses.records[0].author.value }}</div>
            </div>
          </div>
        </div>

        </div>

        <div class="FormGroup">
          <h2>社内管理情報</h2>
          <!-- 社内メモ -->
          <div class="inputCol inputCol-1">
            <div class="nameBlock">
              <label for="internalMemo">社内メモ</label>
            </div>
            <div class="inputBlock">
              <div>{{ displayData[0].$join_data.Expenses.records[0].internalMemo.value }}</div>
            </div>
          </div>

          <div class="inputCol inputCol-1">
            <div class="nameBlock">
              <label for="relatedFiles">関連ファイル</label>
            </div>
            <div class="inputBlock">
              <div>
                <ul class="filelist">
                  <li>
                    <div v-for="key in displayData[0].$join_data.Expenses.records[0]['relatedFiles']['value']" :key="key.name">
                      <span style="margin-bottom:0;" @click="downloadFile('Expenses',key.fileKey,key.name);">
                      {{key.name}}
                      <img src="../../src/assets/image/dl.png">
                      </span>
                      {{fileKeyDate(key.fileKey)}}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>
        <!-- 関連ファイル -->

      </div>
    </div>

  <div class="side-block">
    <div class="item-group">
    <h2>案件</h2>
      <div class="item-group-item" v-if="side_ProjectData.length > 0">
        <ul>
          <li v-for="(item, index) in side_ProjectData" :key="index">
            <div>
              <div>
              <router-link :to="{ name: '案件詳細', params: { id: item.$id.value } }">
              {{ item.Title.value }}
              </router-link>
              </div>
            </div>
            <div>
              <span>{{ item.AnswerStatus.value }}</span>
              <span>{{ item.SendingStatus.value }}</span>
              <span>{{ item.ApprovalStatus.value }}</span>
            </div>
          </li>
        </ul>
        <div class="table-content zisseki">
          <table>
            <thead>
              <th></th>
              <th>売上</th>
              <th>費用</th>
              <th>粗利</th>
            </thead>
            <tbody>
              <tr>
                <td>見込み</td>
                <td>{{ totalSales.toLocaleString() }}円</td>
                <td>{{ totalCost.toLocaleString() }}円</td>
                <td>{{ totalGrossProfit.toLocaleString() }}円</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="item-group-item border-item" v-else>
      <p>紐づけられたデータがありません。</p>
      </div>
    </div>

    <div class="item-group">
      <h2>契約</h2>
      <div class="item-group-item border-item" v-if="side_ContractData.length > 0">
        <ul>
          <li v-for="(item, index) in side_ContractData" :key="index">
            <div>
              <div>
              <router-link :to="{ name: '契約詳細', params: { id: item.$id.value } }">
              {{ item.Title.value }}
              </router-link>
              </div>
              <div>( {{ item.$id.value }} )</div>
            </div>
            <div>
              <span>{{ item.AnswerStatus.value }}</span>
              <span>{{ item.SendingStatus.value }}</span>
              <span>{{ item.ApprovalStatus.value }}</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="item-group-item border-item" v-else>
      <p>紐づけられたデータがありません。</p>
      </div>
    </div>

    <div class="item-group">
      <h2>注文</h2>
      <div class="item-group-item border-item" v-if="side_OrderData.length > 0">
        <ul>
          <li v-for="(item, index) in side_OrderData" :key="index">
            <div>
              <div>
              <router-link :to="{ name: '注文詳細', params: { id: item.$id.value } }">
              {{ item.Title.value }}
              </router-link>
              </div>
              <div>[{{ item.Order.value }}]( {{ item.$id.value }} )</div>
            </div>
            <div>
              <span>{{ item.AnswerStatus.value }}</span>
              <span>{{ item.SendingStatus.value }}</span>
              <span>{{ item.ApprovalStatus.value }}</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="item-group-item border-item" v-else>
      <p>紐づけられたデータがありません。</p>
      </div>
    </div>

    <div class="item-group">
      <h2>見積</h2>
      <div class="item-group-item border-item" v-if="side_QuotationData.length > 0">
        <ul>
          <li v-for="(item, index) in side_QuotationData" :key="index">
            <div>
              <div>
              <router-link :to="{ name: '見積詳細', params: { id: item.$id.value } }">
              {{ item.Title.value }}
              </router-link>
              </div>
              <div>[{{ item.Order.value }}]( {{ item.$id.value }} )</div>
            </div>
            <div class="option-block">
              <div>
                <span>{{ item.AnswerStatus.value }}</span>
                <span>{{ item.SendingStatus.value }}</span>
                <span>{{ item.ApprovalStatus.value }}</span>
              </div>
              <div class="amount">
                {{formatNumber(item.tax_included.value)}}円
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="item-group-item border-item" v-else>
      <p>紐づけられたデータがありません。</p>
      </div>
    </div>


    <div class="item-group">
      <h2>請求</h2>
      <div class="item-group-item border-item" v-if="side_ClaimData.length > 0">
        <ul>
          <li v-for="(item, index) in side_ClaimData" :key="index">
            <div>
              <div>
              <router-link :to="{ name: '請求詳細', params: { id: item.$id.value } }">
              {{ item.Title.value }}
              </router-link>
              </div>
              <div>[{{ item.Order.value }}]( {{ item.$id.value }} )</div>
            </div>
            <div class="option-block">
              <div>
                <span>{{ item.AnswerStatus.value }}</span>
                <span>{{ item.SendingStatus.value }}</span>
                <span>{{ item.ApprovalStatus.value }}</span>
                <span class="white">{{ item.SalesStatus.value }}</span>
              </div>
              <div class="amount">
                {{formatNumber(item.item__total.value)}}円
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="item-group-item border-item" v-else>
      <p>紐づけられたデータがありません。</p>
      </div>
    </div>


  </div>
</div>

<div v-if="!dataLoading" class="loading">
  <img src="../assets/image/ZZ5H.gif">
</div>
</template>



<script>

export default {
  data() {
    return {
      tableData: [],

      paymentstableData: [
        { name:"項目名1", sales:"1,000,000", sales_challenge:"1,000,000", cost:"1,000,000", cost_challenge:"1,000,000", grossProfit:"1,000,000", grossProfit_challenge:"1,000,000", },
        { name:"項目名1", sales:"1,000,000", sales_challenge:"1,000,000", cost:"1,000,000", cost_challenge:"1,000,000", grossProfit:"1,000,000", grossProfit_challenge:"1,000,000", },
        { name:"項目名1", sales:"1,000,000", sales_challenge:"1,000,000", cost:"1,000,000", cost_challenge:"1,000,000", grossProfit:"1,000,000", grossProfit_challenge:"1,000,000", },
        { name:"項目名1", sales:"1,000,000", sales_challenge:"1,000,000", cost:"1,000,000", cost_challenge:"1,000,000", grossProfit:"1,000,000", grossProfit_challenge:"1,000,000", },
      ],

      zissakitableData: [
        { name:"見込み", sales:"5,000,000", keihi:"2,000,000", arari:"3,000,000" },
        { name:"見込み(チャレンジ目標)", sales:"6,000,000", keihi:"2,500,000", arari:"3,500,000" },
        { name:"実績", sales:"3,000,000", keihi:"4,000,000", arari:"-1,000,000" },
      ],

      uriagetableData: [
        { name:"請求名サンプル", code:"S-0000000011", client:"取引先名サンプル", manager:"担当者名サンプル", amount:"400,000" },
        { name:"請求名サンプル", code:"S-0000000012", client:"取引先名サンプル", manager:"担当者名サンプル", amount:"400,000" },
        { name:"請求名サンプル", code:"S-0000000013", client:"取引先名サンプル", manager:"担当者名サンプル", amount:"400,000" },
        { name:"請求名サンプル", code:"S-0000000014", client:"取引先名サンプル", manager:"担当者名サンプル", amount:"400,000" },
        { name:"請求名サンプル", code:"S-0000000015", client:"取引先名サンプル", manager:"担当者名サンプル", amount:"400,000" },
        { name:"請求名サンプル", code:"S-0000000016", client:"取引先名サンプル", manager:"担当者名サンプル", amount:"400,000" },
      ],

      displayData:[],

      isDropdownOpen: false,
      options1: ["対応中", "完了", "中止"],
      options2: ["未送付", "送付済み"],
      Label1: "回答",
      Label2: "送付",
      isModalOpen: false,
      isEditFlag: false,
      currentPage: 1, // 現在のページ
      itemsPerPage: 10, // 1ページあたりのアイテム数

      totalSales: 0,
      totalSalesChallenge: 0,
      totalCost: 0,
      totalCostChallenge: 0,
      totalGrossProfit: 0,
      totalGrossProfitChallenge: 0,

      totalAmount: 0,

      dataLoading : false,
//
      statusData: {
        AnswerStatus: '',
      },
      SalesStatusData: {
        SalesStatus: '',
      },

      createRecordId : '',

      ApprovalStatus: false,
      CreateOption:"新規作成",
      ModalOption:"・・・",
      RevisionData: [],
      HakkoData: [],

      formData: {
        Code: "",
        id:"",
        Type : "経費",
        Title : "",
        Applicant : "",
        ApplicationContents : "",
      },
      Data: {
        id:"",
        Title:"",
        ApprovalStatus:"",
      },
      HakkoformData: {
        types:"",
        contents:"",
        user:"",
        APPID: "73",
        targetRecordID: "",
      },

      isDropdownOpen__answer: false,
      isDropdownOpen__sales: false,

      isModalOpen__change: false,
      isModalOpen__hakko: false,

      side_ProjectData: [],
      side_ContractData: [],
      side_OrderData: [],
      side_QuotationData: [],
      side_ClaimData: [],
//
      AggFlag: true,
    };
  },
  watch: {
    ModalOption(newVal) {
      console.log(newVal);
      const projectId = this.$route.params.id;
      if(newVal === "複製"){
        if (window.confirm('複製しますか？')) {
          this.$router.push({ 
            path: '/ExpensesCreate',
            query: { record_id: projectId, copy:true } 
          })
        }
      }
      if(newVal === "変更履歴"){
        console.log(newVal)
        this.isModalOpen__change = true;
      }
      if(newVal === "発行履歴"){
        console.log(newVal)
        this.isModalOpen__hakko = true;
      }
      this.ModalOption = "・・・";
    },
  },
  computed: {
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      console.log(this.tableData)
      return this.tableData.slice(startIndex, endIndex);
    },
    paginatedData__Revision(){
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      console.log(this.RevisionData)
      return this.RevisionData.slice(startIndex, endIndex);
    },
    paginatedData__Hakko(){
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      console.log(this.HakkoData)
      return this.HakkoData.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.tableData.length / this.itemsPerPage);
    },
    totalPages__Revision() {
      return Math.ceil(this.RevisionData.length / this.itemsPerPage);
    },
    totalPages__Hakko() {
      return Math.ceil(this.HakkoData.length / this.itemsPerPage);
    },
    login_data:{
      get: function () {
        return this.$store.state.login_data
      },
      set: function (value) {
        this.$store.commit('login_data', value)
      }
    },
  },
  created() {
    this.getData();
    this.getAutorizeData();
    const projectId = this.$route.params.id;
    this.editLink = { path: '/ExpensesCreate', query: { record_id: projectId , edit: true} }
    this.copyLink = { path: '/ExpensesCreate', query: { record_id: projectId, copy:true } }
  },
  methods: {
    downloadFile: async function(target,fileKey,fileName){
      let params = {
        target : target,
        fileKey: fileKey,
        fileName:fileName
      }
      let authed = await this.$commonPlugin.axiosAPI('downloadFile',params);
      // console.log(authed);
      this.downloadFiles(authed,fileName)
    },
    // Base64文字列を受け取り、ファイルをダウンロードする関数
    downloadFiles: async function(base64String, fileName) {
      // Base64文字列をデコードしてバイナリデータを取得
      const binaryString = atob(base64String);

      // バイナリデータをUint8Arrayに変換
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      // バイナリデータをBlobオブジェクトに変換
      const blob = new Blob([bytes], { type: 'application/octet-stream' });

      // BlobオブジェクトからURLを生成
      const url = URL.createObjectURL(blob);

      // ダウンロードリンクを生成
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;

      // リンクをクリックしてダウンロードを開始
      link.click();

      // 不要になったURLを解放
      URL.revokeObjectURL(url);
    },
    getData: async function (){
      const projectId = this.$route.params.id;
      console.log(projectId)
      let params = {
        target: 'Matter',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            },
          ]
        ),
        join : JSON.stringify(
          [
            {
              target : 'Expenses',
              querys: JSON.stringify(
                [
                  {
                    column : 'DeleteStatus',
                    cond   : 'in',
                    flag   : false,
                    data   : '使用',
                  },
                  {
                    column : 'record_id',
                    cond   : '=',
                    flag   : false,
                    data   : projectId,
                  }
                ]
              )
            },
            {
              target : 'Revision',
              querys: JSON.stringify(
                [
                  {
                    column : 'targetRecordID',
                    cond   : '=',
                    flag   : false,
                    data   : projectId,
                  },
                  {
                    column : 'APPID',
                    cond   : '=',
                    flag   : false,
                    data   : '73',
                  }
                ],
              )
            },
            {
              target : 'Hakko',
              querys: JSON.stringify(
                [
                  {
                    column : 'targetRecordID',
                    cond   : '=',
                    flag   : false,
                    data   : projectId,
                  },
                  {
                    column : 'APPID',
                    cond   : '=',
                    flag   : false,
                    data   : '73',
                  }
                ],
              )
            },
          ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.displayData = authed
      this.dataLoading = true
      console.log(this.displayData[0].$join_data.Hakko.records);
      if(this.displayData[0].$join_data.Revision.records){
        this.RevisionComparison(this.displayData[0].$join_data.Revision.records);
      }
      if(this.displayData[0].$join_data.Hakko.records){
        this.HakkoData = this.displayData[0].$join_data.Hakko.records
      }
      if(this.displayData[0].$join_data.Expenses.records[0].ApprovalStatus.value === "承認済み"){
        this.ApprovalStatus = true
      }
      this.getSideBlockContentData();
    },
    getSideBlockContentData: async function (){
      var projectId = this.displayData[0].$join_data.Expenses.records[0].ProjectId.value
      console.log(projectId)
      if(!projectId){
        projectId = 0
      }
      let params = {
        target: 'ProjectDetails',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            },
            {
              column :'$id',
              cond   : '=',
              flag   : false,
              data   : projectId,
            }
          ]
        )
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.side_ProjectData = authed.records

      params = {
        target: 'Contract',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            },
            {
              column :'ProjectId',
              cond   : '=',
              flag   : false,
              data   : projectId,
            }
          ]
        )
      };
      console.log(params)
      authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.side_ContractData = authed.records

      params = {
        target: 'Order',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            },
            {
              column :'projectId',
              cond   : '=',
              flag   : false,
              data   : projectId,
            }
          ]
        )
      };
      console.log(params)
      authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.side_OrderData = authed.records

      params = {
        target: 'Quotation',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            },
            {
              column :'ProjectId',
              cond   : '=',
              flag   : false,
              data   : projectId,
            }
          ]
        )
      };
      console.log(params)
      authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.side_QuotationData = authed.records

      params = {
        target: 'Claim',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            },
            {
              column :'ProjectId',
              cond   : '=',
              flag   : false,
              data   : projectId,
            }
          ]
        )
      };
      console.log(params)
      authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.side_ClaimData = authed.records
      this.calculateTotals();
    },
    RevisionComparison(records) {
        records.forEach(record => {
            if (record.old_text && record.old_text.value.length > 5) {
                const newData = JSON.parse(record.new_text.value);
                const old_text = JSON.parse(record.old_text.value);
                
                    const oldData = old_text.records[0];
                    const changedProperties = this.compareData(oldData, newData);
                    record.changedProperties = changedProperties;
                
            }
        });
        this.RevisionData = records;
    },
    compareData(oldData, newData) {
        const changedProperties = [];
        // oldData と newData の両方に存在するキーを取得
        const commonKeys = oldData ? Object.keys(oldData).filter(key => Object.prototype.hasOwnProperty.call(newData, key)) : [];
        commonKeys.forEach(key => {
            // typeとidは無視する
            if (key === 'type' || key === 'id') {
                return;
            }
            if (Array.isArray(oldData[key])) {
                // 配列の場合、配列の各要素を比較
                const arrayChanges = this.compareArrays(oldData[key], newData[key]);
                if (arrayChanges.length > 0) {
                    // 変更がある場合、変更を記録
                    changedProperties.push({
                        key,
                        changes: arrayChanges
                    });
                }
            } else if (typeof oldData[key] === 'object') {
                // オブジェクトの場合、個々のプロパティを比較
                const objectChanges = this.compareObjects(oldData[key], newData[key]);
                if (objectChanges.length > 0) {
                    // 変更がある場合、変更を記録
                    changedProperties.push({
                        key,
                        changes: objectChanges
                    });
                }
            } else {
                // 配列でもオブジェクトでもない場合は、値が異なる場合のみ変更を記録
                if (JSON.stringify(oldData[key]) !== JSON.stringify(newData[key])) {
                    changedProperties.push({
                        key,
                        old_value: oldData[key],
                        new_value: newData[key]
                    });
                }
            }
        });

        return changedProperties;
    },
    compareArrays(oldArray, newArray) {
        const changes = [];
        // 新しい配列の要素数が古い配列の要素数と異なる場合、変更があるとしてすべての要素を記録
        if (oldArray.length !== newArray.length) {
            for (let i = 0; i < newArray.length; i++) {
                changes.push({
                    index: i,
                    new_value: newArray[i]
                });
            }
            return changes;
        }

        // 各要素を比較
        for (let i = 0; i < oldArray.length; i++) {
            const subChanges = this.compareData(oldArray[i], newArray[i]);
            if (subChanges.length > 0) {
                // 変更がある場合、変更を記録
                changes.push({
                    index: i,
                    changes: subChanges
                });
            }
        }
        return changes;
    },
    compareObjects(oldObject, newObject) {
        const changedProperties = [];
        // 新しいオブジェクトのすべてのキーを走査
        for (const key in newObject) {
            // typeとidは無視する
            if (key === 'type' || key === 'id') {
                continue;
            }
            // キーが古いオブジェクトにも存在する場合、値を比較
            if (Object.prototype.hasOwnProperty.call(oldObject, key)) {
                // オブジェクトまたは配列の場合、再帰的に比較
                if (typeof oldObject[key] === 'object' || Array.isArray(oldObject[key])) {
                    const subChanges = this.compareData(oldObject[key], newObject[key]);
                    if (subChanges.length > 0) {
                        // 変更がある場合、変更を記録
                        changedProperties.push(...subChanges);
                    }
                } else {
                    // オブジェクトでも配列でもない場合は値を比較
                    if (JSON.stringify(oldObject[key]) !== JSON.stringify(newObject[key])) {
                        // 変更がある場合、変更を記録
                        changedProperties.push({
                            key,
                            old_value: oldObject[key],
                            new_value: newObject[key]
                        });
                    }
                }
            } else {
                // キーが古いオブジェクトに存在しない場合、新しい値を記録
                changedProperties.push({
                    key,
                    old_value: undefined,
                    new_value: newObject[key]
                });
            }
        }
        return changedProperties;
    },
    getAutorizeData: async function (){
      const projectId = this.$route.params.id;
      let params = {
        target: 'Agreement',
        querys: JSON.stringify(
          [
            {
              column :'DeleteStatus',
              cond   :'in',
              data   :'使用',
            },
            {
              column :'Type',
              cond   : '=',
              flag   : false,
              data   : '経費',
            },
            {
              column :'Code',
              cond   : '=',
              flag   : false,
              data   : projectId,
            }
          ]
        )
      };
      let authed = await this.$commonPlugin.axiosAPI('kintone_get_data_logic',params);
      console.log(authed)
      this.tableData = authed.records
      var approvalRequestFound = true;
      for (let i = 0; i < this.tableData.length; i++) {
        console.log(this.tableData[i]);
        if (this.tableData[i].ApplicationContents.value == '承認依頼') {
          approvalRequestFound = true;
        }
        if (approvalRequestFound && this.tableData[i].ApprovalResults.value !== '承認済み') {
          this.AggFlag = false;
          return;
        }
      }
    },
    submitForm(value) {
      this.formData.ApplicationContents = value
      this.formData.Code = this.$route.params.id;
      this.formData.id = this.$route.params.id;
      this.formData.Title = this.displayData[0].$join_data.Expenses.records[0].Title.value
      this.createFormMethod();
    },
    createFormMethod: async function(){
      this.formData.Applicant = this.login_data.Names.value
      this.formData.ApprovalResults = "申請中"
      console.log(this.formData);
      let pushArray = [];
      for(let key in this.formData){
        let data = {};
        data.target = key;
        data.value = this.formData[key];
        pushArray.push(data);
      }
      let params = {
        target: 'Agreement',
        data:JSON.stringify(pushArray),
        user:this.login_data.Names.value,
        querys: JSON.stringify(
            [
              {
                //対象カラム
                column :'DeleteStatus',
                //比較条件
                cond   :'in',
                //比較内容
                data   : '使用',
              },
              {
                //対象カラム
                column :'$id',
                //比較条件
                cond   :'=',
                //比較内容
                data   :this.createRecordId,
              }
            ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_create_update_data_for_array_logic',params);
      this.$commonPlugin.dataUpdate(this.formData,authed,this.PurchaseAmountTemplate);
      this.createFormMethod__Data();
    },
    createFormMethod__Data: async function(){
      this.Data.id = this.$route.params.id;
      this.Data.Title = this.displayData[0].$join_data.Expenses.records[0].Title.value
      this.Data.ApprovalStatus = "申請中"
      let pushArray = [];
      for(let key in this.Data){
        let data = {};
        data.target = key;
        data.value = this.Data[key];
        pushArray.push(data);
      }
      let params = {
        target: 'Expenses',
        data:JSON.stringify(pushArray),
        user:this.login_data.Names.value,
        querys: JSON.stringify(
            [
              {
                //対象カラム
                column :'$id',
                //比較条件
                cond   :'=',
                //比較内容
                data   :this.$route.params.id,
              }
            ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_create_update_data_for_array_logic',params);
      this.$commonPlugin.dataUpdate(this.formData,authed,this.PurchaseAmountTemplate);
      if(authed.result.response === "失敗"){
        alert('入力内容にエラーが発生しました。');
      }else{
        alert('保存しました。');
        this.getData();
        this.getAutorizeData();
      }
    },
    createHakkoMethod: async function(){
      const projectId = this.$route.params.id;
      if (window.confirm('PDFを発行しますか？')) {
        this.HakkoformData.user = this.login_data.Names.value
        this.HakkoformData.targetRecordID = this.$route.params.id;
        this.HakkoformData.types = "請求書"
        this.pdfLink = process.env.VUE_APP_PDF_URL+'invoice/'+projectId
        console.log(this.HakkoformData);
        let pushArray = [];
        for(let key in this.HakkoformData){
          let data = {};
          data.target = key;
          data.value = this.HakkoformData[key];
          pushArray.push(data);
        }
        let params = {
          target: 'Hakko',
          data:JSON.stringify(pushArray),
          user:this.login_data.Names.value,
          querys: JSON.stringify(
              [
                {
                  //対象カラム
                  column :'DeleteStatus',
                  //比較条件
                  cond   :'in',
                  //比較内容
                  data   : '使用',
                },
                {
                  //対象カラム
                  column :'$id',
                  //比較条件
                  cond   :'=',
                  //比較内容
                  data   :this.createRecordId,
                }
              ]
          ),
        };
        console.log(params)
        let authed = await this.$commonPlugin.axiosAPI('kintone_create_update_data_for_array_logic',params);
        this.$commonPlugin.dataUpdate(this.formData,authed);
        window.open(this.pdfLink,'_blank');
      }
    },
    saveOption__sales: async function(){
      console.log(this.SalesStatusData.SalesStatus);
      let pushArray = [];
      for(let key in this.SalesStatusData){
        let data = {};
        data.target = key;
        data.value = this.SalesStatusData[key];
        pushArray.push(data);
      }
      let params = {
        target: 'Expenses',
        data:JSON.stringify(pushArray),
        user:this.login_data.Names.value,
        querys: JSON.stringify(
            [
              {
                //対象カラム
                column :'$id',
                //比較条件
                cond   :'=',
                //比較内容
                data   :this.$route.params.id,
              }
            ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_create_update_data_for_array_logic',params);
      this.$commonPlugin.dataUpdate(this.formData,authed,this.itemTableTemplate);
      if(authed.result.response === "失敗"){
        alert('入力内容にエラーが発生しました。');
      }else{
        alert('保存しました。');
        this.getData();
        this.getAutorizeData();
      }
    },
    saveOption__answer: async function(){
      console.log(this.statusData.AnswerStatus);
      let pushArray = [];
      for(let key in this.statusData){
        let data = {};
        data.target = key;
        data.value = this.statusData[key];
        pushArray.push(data);
      }
      let params = {
        target: 'Expenses',
        data:JSON.stringify(pushArray),
        user:this.login_data.Names.value,
        querys: JSON.stringify(
            [
              {
                //対象カラム
                column :'$id',
                //比較条件
                cond   :'=',
                //比較内容
                data   :this.$route.params.id,
              }
            ]
        ),
      };
      console.log(params)
      let authed = await this.$commonPlugin.axiosAPI('kintone_create_update_data_for_array_logic',params);
      this.$commonPlugin.dataUpdate(this.formData,authed,this.itemTableTemplate);
      if(authed.result.response === "失敗"){
        alert('入力内容にエラーが発生しました。');
      }else{
        alert('保存しました。');
        this.getData();
        this.getAutorizeData();
      }
    },
    calculateTotals() {
      console.log(this.side_ProjectData[0])
      if(this.side_ProjectData[0]){
        this.totalSales = this.side_ProjectData[0].prospect.value.reduce((total, item) => total + parseFloat(item.value.sales.value.replace(/,/g, '')), 0);
        this.totalCost = this.side_ProjectData[0].prospect.value.reduce((total, item) => total + parseFloat(item.value.cost.value.replace(/,/g, '')), 0);
        this.totalSalesChallenge = this.side_ProjectData[0].prospect.value.reduce((total, item) => total + parseFloat(item.value.sales_challenge.value.replace(/,/g, '')), 0);
        this.totalCost = this.side_ProjectData[0].prospect.value.reduce((total, item) => total + parseFloat(item.value.cost.value.replace(/,/g, '')), 0);
        this.totalCostChallenge = this.side_ProjectData[0].prospect.value.reduce((total, item) => total + parseFloat(item.value.cost_challenge.value.replace(/,/g, '')), 0);
        this.totalGrossProfit = this.side_ProjectData[0].prospect.value.reduce((total, item) => total + parseFloat(item.value.grossProfit.value.replace(/,/g, '')), 0);
        this.totalGrossProfitChallenge = this.side_ProjectData[0].prospect.value.reduce((total, item) => total + parseFloat(item.value.grossProfit_challenge.value.replace(/,/g, '')), 0);
      }
    },
    calculateDifference(itemName1, itemName2, property) {
      const num1 = parseFloat(this.zissakitableData.find((item) => item.name === itemName1)[property].replace(/,/g, ''));
      const num2 = parseFloat(this.zissakitableData.find((item) => item.name === itemName2)[property].replace(/,/g, ''));
      const difference = num1 - num2;
      // 正か負かを判別して、+か-を表示
      if (difference > 0) {
        return `+${difference.toLocaleString()}`;
      } else if (difference < 0) {
        return `-${Math.abs(difference).toLocaleString()}`;
      } else {
        return '±0'; // 差額が0の場合
      }
    },
    toggleDropdown__sales() {
      this.isDropdownOpen__sales = !this.isDropdownOpen__sales;
    },
    toggleDropdown__answer() {
      this.isDropdownOpen__answer = !this.isDropdownOpen__answer;
    },
    selectOption(option) {
      this.selectedOption = option;
    },
    saveOption() {
      this.isDropdownOpen = false;
      console.log('保存されたオプション:', this.selectedOption);
    },
    saveAuthorize() {
      console.log("承認依頼");
    },
    editButton() {
      this.isEditFlag = true;
    },
    submitButton() {
      this.isEditFlag = false;
    },
    openModal() {
      this.isModalOpen = true; // モーダルを表示
    },
    closeModal() {
      this.isModalOpen = false; // モーダルを非表示
      this.isModalOpen__change = false;
      this.isModalOpen__hakko = false;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    formatNumber(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    formatDateTime(dateTimeString) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', timeZoneName: 'short', weekday: 'short' };
      const dateTime = new Date(dateTimeString);
      const stringDate = dateTime.toLocaleString('ja-JP', options);
      if (stringDate === "Invalid Date") {
        return "";
      } else {
        return stringDate.replace("JST", '').replace(',', '');
      }
    },
    isApprovalCheck(){
      const Approval = this.displayData[0].$join_data.Expenses.records[0].ApprovalStatus.value
      if(Approval !== "承認済み"){
        this.openModal()
      }
    },
    fileKeyDate(value){
      const Y = value.substr( 0, 4 );
      const M = value.substr( 4, 2 );
      const D = value.substr( 6, 2 );
      const H = value.substr( 8, 2 );
      const S = value.substr( 10, 2 );
      const val = Y + "-" + M + "-" + D + "T" + H + ":" + S + ":00Z";
      const aaa = this.formatDateTime(val);
      return aaa;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
