import { createRouter, createWebHistory } from 'vue-router'
import TopView from '../views/TopView.vue'
import ProjectView from '../views/ProjectView.vue'
import ProjectCreateView from '../views/ProjectCreateView.vue'
import ProjectDetailsView from '../views/ProjectDetailsView.vue'
import ContractView from '../views/ContractView.vue'
import ContractCreateView from '../views/ContractCreateView.vue'
import ContractDetailsView from '../views/ContractDetailsView.vue'
import OrderView from '../views/OrderView.vue'
import OrderCreateView from '../views/OrderCreateView.vue'
import OrderDetailsView from '../views/OrderDetailsView.vue'
import QuotationView from '../views/QuotationView.vue'
import QuotationCreateView from '../views/QuotationCreateView.vue'
import QuotationDetailsView from '../views/QuotationDetailsView.vue' 
import ClaimView from '../views/ClaimView.vue'
import ClaimCreateView from '../views/ClaimCreateView.vue'
import ClaimDetailsView from '../views/ClaimDetailsView.vue'
import ExpensesView from '../views/ExpensesView.vue'
import ExpensesCreateView from '../views/ExpensesCreateView.vue'
import ExpensesDetailsView from '../views/ExpensesDetailsView.vue'
import ApprovalView from '../views/ApprovalView.vue'
import ApprovalCreateView from '../views/ApprovalCreateView.vue'
import ApprovalDetailsView from '../views/ApprovalDetailsView.vue'

import sub_ContractCreateView from '../views/sub_ContractCreateView.vue'
import settingView from '../views/settingView.vue'

import LoginView from '../views/LoginPage.vue'

const routes = [
  {
    path: '/',
    name: 'ログイン',
    component: LoginView,
    meta: { showHeader: false , showMenu: false },
  },
  {
    path: '/Top',
    name: 'Top',
    component: TopView,
    meta: { showHeader: true , showMenu: true},
  },
  {
    path: '/Project',
    name: '案件',
    component: ProjectView,
    meta: { showHeader: true , showMenu: true},
  },
  {
    path: '/ProjectCreate',
    name: '案件作成',
    component: ProjectCreateView,
    meta: { showHeader: true , showMenu: true},
  },
  {
    path: '/ProjectDetails/:id',
    name: '案件詳細',
    component: ProjectDetailsView,
    meta: { showHeader: true , showMenu: true},
  },
  {
    path: '/Contract',
    name: '契約',
    component: ContractView,
    meta: { showHeader: true , showMenu: true},
  },
  {
    path: '/ContractCreate',
    name: '契約作成',
    component: ContractCreateView,
    meta: { showHeader: true , showMenu: true},
  },
  {
    path: '/sub_ContractCreate',
    name: '契約作成_',
    component: sub_ContractCreateView,
    meta: { showHeader: true , showMenu: true},
  },
  {
    path: '/ContractDetails/:id',
    name: '契約詳細',
    component: ContractDetailsView,
    meta: { showHeader: true , showMenu: true},
  },
  {
    path: '/Order',
    name: '注文',
    component: OrderView,
    meta: { showHeader: true , showMenu: true},
  },
  {
    path: '/OrderCreate',
    name: '注文作成',
    component: OrderCreateView,
    meta: { showHeader: true , showMenu: true},
  },
  {
    path: '/OrderDetails/:id',
    name: '注文詳細',
    component: OrderDetailsView,
    meta: { showHeader: true , showMenu: true},
  },
  {
    path: '/Quotation',
    name: '見積',
    component: QuotationView,
    meta: { showHeader: true , showMenu: true},
  },
  {
    path: '/QuotationCreate',
    name: '見積作成',
    component: QuotationCreateView,
    meta: { showHeader: true , showMenu: true},
  },
  {
    path: '/QuotationDetails/:id',
    name: '見積詳細',
    component: QuotationDetailsView,
    meta: { showHeader: true , showMenu: true},
  },
  {
    path: '/Claim',
    name: '請求',
    component: ClaimView,
    meta: { showHeader: true , showMenu: true},
  },
  {
    path: '/ClaimCreate',
    name: '請求作成',
    component: ClaimCreateView,
    meta: { showHeader: true , showMenu: true},
  },
  {
    path: '/ClaimDetails/:id',
    name: '請求詳細',
    component: ClaimDetailsView,
    meta: { showHeader: true , showMenu: true},
  },
  {
    path: '/Expenses',
    name: '経費',
    component: ExpensesView,
    meta: { showHeader: true , showMenu: true},
  },
  {
    path: '/ExpensesCreate',
    name: '経費作成',
    component: ExpensesCreateView,
    meta: { showHeader: true , showMenu: true},
  },
  {
    path: '/ExpensesDetails/:id',
    name: '経費詳細',
    component: ExpensesDetailsView,
    meta: { showHeader: true , showMenu: true},
  },
  {
    path: '/Approval',
    name: '稟議',
    component: ApprovalView,
    meta: { showHeader: true , showMenu: true},
  },
  {
    path: '/ApprovalCreate',
    name: '稟議作成',
    component: ApprovalCreateView,
    meta: { showHeader: true , showMenu: true},
  },
  {
    path: '/ApprovalDetails/:id',
    name: '稟議詳細',
    component: ApprovalDetailsView,
    meta: { showHeader: true , showMenu: true},
  },
  {
    path: '/settingPage',
    name: '設定',
    component: settingView,
    meta: { showHeader: true , showMenu: true},
  }
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes
})


let hasPrompted = false;

router.beforeEach((to, from, next) => {
  if (from.path.includes('Create') && !hasPrompted) {
    if(localStorage.getItem('hasSaved') == 'false'){
      const answer = confirm('入力された内容を破棄してもよろしいですか？');
      hasPrompted = true; 
      if (!answer) {
        hasPrompted = false; 
        return next(false);
      }
    }
  }
  hasPrompted = false;
  localStorage.setItem('hasSaved', 'false');
  next();
});


export default router
