import { createStore } from 'vuex'

export default createStore({
  state: {
    login_data  : false,

    AccountData : false,

    m_data        : {},
    m_data_option : {},
  },
  getters: {
  },
  mutations: {
    login_data(state, payload){
      state.login_data = payload
    },
    AccountData(state, payload){
      state.AccountData = payload
    },
    m_data(state, payload){
      state.m_data = payload
    },
    m_data_option(state, payload){
      state.m_data_option = payload
    },
  },
  actions: {
  },
  modules: {
  }
})
